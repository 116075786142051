import * as React from 'react';

import EventsFilterDialog from '../views/filter/events-filter-dialog';
import EventsFilterToolbar from '../views/filter/events-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type EventsFilterState = {
    searchString: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
    positionType: FilterCheckBoxStateValue;
    eventType: FilterCheckBoxStateValue;
};

const initialState: EventsFilterState = {
    searchString: null,
    branches: null,
    positionType: null,
    eventType: null,
};

const useEventsFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);

    const [expandedField, setExpandedField] = React.useState<keyof EventsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof EventsFilterState>('searchString');

    return {
        EventsFilterDialog: () => (
            <EventsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        EventsFilterToolbar: () => (
            <EventsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useEventsFilter;

import * as React from 'react';

import EventsFilterDialog from '../views/filter/events-filter-dialog';
import EventsFilterToolbar from '../views/filter/events-filter-toolbar';

import {
    FilterCheckBoxStateValue,
    FilterRadioStateValue,
    FilterSearchStateValue,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type EventsFilterState = {
    branches: FilterCheckBoxStateValue;
    state: FilterRadioStateValue;
    positionCount: FilterRadioStateValue;
    plan: FilterRadioStateValue;
    ticket: FilterRadioStateValue;
    searchNumber?: number | null;
    searchText?: FilterSearchStateValue;
};

const initialState: EventsFilterState = {
    branches: null,
    searchNumber: null,
    state: {
        id: 0,
        name: 'Все',
    },
    positionCount: {
        id: 0,
        name: 'Все',
    },
    plan: {
        id: 0,
        name: 'Все',
    },
    ticket: {
        id: 0,
        name: 'Все',
    },
};

const useEventsFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);

    const [expandedField, setExpandedField] = React.useState<keyof EventsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof EventsFilterState>('searchNumber');

    return {
        EventsFilterDialog: () => (
            <EventsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        EventsFilterToolbar: () => (
            <EventsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useEventsFilter;

import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}_notifications`]: {
        en: 'Notifications',
        ru: 'Уведомления',
    },
    [`${APP}_show_notification`]: {
        en: 'Show notification',
        ru: 'Показать уведомление',
    },
    [`${APP}_show_notification_with_close_button`]: {
        en: 'Show notification with close button',
        ru: 'Показать уведомление с кнопкой закрытия',
    },
    [`${APP}_dismiss_all_notifications`]: {
        en: 'Dismiss all notifications',
        ru: 'Скрыть все уведомления',
    },
    [`${APP}_notification_simple_message`]: {
        en: 'Simple notification',
        ru: 'Простое уведомление',
    },
};

export default locale;

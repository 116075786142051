import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';

type DataItem = {
    id: number;
    name: string;
    formValues: CreatePlannedWorkFormInputs;
};

const data: DataItem[] = [
    {
        id: 1,
        name: 'Название 1',
        formValues: {
            regions: [3],
            branches: ['VV'],
            workPlace: [{ id: 2, name: 'Астраханский филиал' }],
            isAutoHall: false,
            autoHall: [],
            object: '',
            timeSpending: '',
            type: '',
            startDateWork: null,
            endDateWork: null,
            isImpactService: false,
            startDateImpactService: null,
            endDateImpactService: null,
            technicalInfluence: '',
            isInfluenceB2b: false,
            clientId: '',
            clientInfluence: '',
            executors: [],
            customer: null,
            name: '',
            description: '',
            networkElements: [],
            ttNumber: '',
            isSmsNotify: false,
        },
    },
];

type CreatePlannedWorkLoadTemplatesConnectorProps = {
    onClose: () => void;
    setFormValues: React.Dispatch<React.SetStateAction<CreatePlannedWorkFormInputs>>;
};

const CreatePlannedWorkLoadTemplatesConnector: React.FC<CreatePlannedWorkLoadTemplatesConnectorProps> = (props) => {
    const { onClose, setFormValues } = props;
    const [selectedTemplate, setSelectedTemplate] = React.useState<DataItem>();
    // TODO тут должен быть запрос

    const handleDeleteClick = React.useCallback(
        (id: number) => () => {
            // TODO  тут должен быть запрос
        },
        [],
    );

    const handleApplyClick = React.useCallback(() => {
        if (selectedTemplate !== undefined) {
            setFormValues(selectedTemplate.formValues);
            onClose();
        }
    }, [selectedTemplate, setFormValues, onClose]);

    return (
        <>
            <DialogContent dividers>
                <List>
                    {data.map((elem) => (
                        <ListItem
                            key={elem.id}
                            disablePadding
                            secondaryAction={
                                <IconButton size="small" onClick={handleDeleteClick(elem.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton
                                selected={selectedTemplate?.id === 1}
                                onClick={() => setSelectedTemplate(elem)}
                            >
                                <ListItemText primary={elem.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button size="small" disableElevation variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={selectedTemplate === undefined}
                    size="small"
                    disableElevation
                    variant="contained"
                    onClick={handleApplyClick}
                >
                    <Translate app={APP} id="apply" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default CreatePlannedWorkLoadTemplatesConnector;

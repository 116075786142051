import * as React from 'react';
import { Control, Controller, UseFormResetField, UseFormWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import { getGroupedBranchesByRegion } from 'sub-apps/key-coordinators/views/connectors/key-coordinators-form-add-connector';

type CreatePlannedWorkRegionBranchFormControllersConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    watch: UseFormWatch<CreatePlannedWorkFormInputs>;
    resetField: UseFormResetField<CreatePlannedWorkFormInputs>;
};

const CreatePlannedWorkRegionBranchFormControllersConnector: React.FC<CreatePlannedWorkRegionBranchFormControllersConnectorProps> = (
    props,
) => {
    const { control, watch, resetField } = props;
    const { data: regionsBranches } = useGetRegionListQuery();

    const groupedBranchesByRegion = React.useMemo(() => getGroupedBranchesByRegion(regionsBranches?.data), [
        regionsBranches,
    ]);

    const regionsValue = watch('regions');

    const branchesByRegions = React.useMemo(
        () => regionsValue.map((regionId) => groupedBranchesByRegion[regionId]).flat(),
        [groupedBranchesByRegion, regionsValue],
    );

    if (regionsBranches && 'data' in regionsBranches)
        return (
            <>
                <Controller
                    name="regions"
                    control={control}
                    rules={{
                        required: `${APP}_field_required`,
                    }}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl fullWidth size="small" error={invalid && isTouched}>
                            <InputLabel margin="dense" htmlFor={field.name}>
                                <Translate app={APP} id="region" />
                            </InputLabel>
                            <Select
                                {...field}
                                multiple
                                onChange={(event) => {
                                    resetField('autoHall');
                                    resetField('workPlace');
                                    resetField('branches');
                                    field.onChange(event);
                                }}
                                label={<Translate app={APP} id="region" />}
                                id={field.name}
                            >
                                {regionsBranches?.data?.map((elem) => (
                                    <MenuItem key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {invalid && isTouched && error?.message && (
                                <FormHelperText error>
                                    <Translate app={APP} id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Controller
                    name="branches"
                    control={control}
                    rules={{
                        required: `${APP}_field_required`,
                    }}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl
                            size="small"
                            fullWidth
                            error={invalid && isTouched}
                            disabled={!regionsValue.length}
                            sx={{ marginTop: 2 }}
                        >
                            <InputLabel margin="dense" htmlFor={field.name}>
                                <Translate app={APP} id="branch" />
                            </InputLabel>
                            <Select
                                {...field}
                                onChange={(event) => {
                                    resetField('autoHall');
                                    resetField('workPlace');
                                    field.onChange(event);
                                }}
                                multiple
                                label={<Translate app={APP} id="branch" />}
                                id={field.name}
                            >
                                {branchesByRegions.map((elem) => (
                                    <MenuItem key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {invalid && isTouched && error?.message && (
                                <FormHelperText error>
                                    <Translate app={APP} id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </>
        );

    return null;
};

export default CreatePlannedWorkRegionBranchFormControllersConnector;

import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useKeyCoordinatorsFilter from '../hooks/use-key-coordinators-filter';
import KeyCoordinatorsDialog from './dialogs/key-coordinators-dialog';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import usePagination from 'hooks/use-pagination';
import { useConfirm } from 'material-ui-confirm';
import { useModal } from 'mui-modal-provider';
import columnLocalization from 'utils/column-localization';
import ConfirmOptionsCustom from 'utils/confirm-options-custom';

const data = [
    {
        id: 1,
        region: 'Уральский регион',
        branch: 'Екатеринбургский филиал',
        fullName: 'Звонарев Сергей Анатольевич',
        required: 'Да',
    },
    {
        id: 2,
        region: 'Уральский регион',
        branch: 'Ижевский филиал',
        fullName: 'Зотиков Алексей Георгиевич',
        required: 'Нет',
    },
];

const refetch = () => {};

const KeyCoordinatorsTable: React.FC = () => {
    const { Pagination } = usePagination(`${APP}_grid`, '5');
    const { KeyCoordinatorsFilterToolbar } = useKeyCoordinatorsFilter();
    const confirm = useConfirm();
    const { showModal } = useModal();

    const handleEditClick = React.useCallback(
        (coordinatorId: number) => () => {
            const modal = showModal(KeyCoordinatorsDialog, {
                coordinatorId,
                onClose: () => {
                    modal.hide();
                },
            });
        },
        [showModal],
    );

    const handleDeleteClick = React.useCallback(
        (coordinatorId: number) => () => {
            confirm(
                ConfirmOptionsCustom({
                    title: null,
                    description: <Translate app={APP} id="you_want_delete_coordinator" />,
                    confirmationText: <Translate app={APP} id="delete" />,
                    cancellationText: <Translate app={APP} id="cancel" />,
                }),
            ).then(() => {
                // TODO тут должена запускаться мутация
            });
        },
        [confirm],
    );

    const renderCellActions = React.useCallback(
        (params: any) => [
            <Tooltip title={<Translate app={APP} id="edit" />}>
                <IconButton size="small" onClick={handleEditClick(params.id)}>
                    <EditIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
            <Tooltip title={<Translate app={APP} id="delete" />}>
                <IconButton size="small" onClick={handleDeleteClick(params.id)}>
                    <DeleteIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
        ],
        [handleDeleteClick, handleEditClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_region`),
                field: 'region',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                flex: 1,
                minWidth: 150,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_full_name`),
                field: 'fullName',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_required`),
                field: 'required',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.2,
                minWidth: 60,
                getActions: renderCellActions,
            },
        ],
        [renderCellActions],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <KeyCoordinatorsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.actionsCell}`]: {
                                gridGap: 0,
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.length,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                </>
            )}
            <DataGridLoader visible={false} text={<Translate id="loading" />} />
        </>
    );
};

export default KeyCoordinatorsTable;

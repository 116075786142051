import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { TimeOnBatteriesFilterState } from '../../hooks/use-time-on-batteries-filter';

import { nanoid } from '@reduxjs/toolkit';
import DateIntervalDisplayItemContent from 'components/extended/data-grid/filter/date-interval-display-item-content';
import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import { FilterToolbarProps, SearchFilter } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';

const searchFilters: SearchFilter<TimeOnBatteriesFilterState>[] = [
    { field: 'position', id: nanoid(), label: <Translate app={APP} id="position" /> },
];

const TimeOnBatteriesFilterToolbar: React.FC<FilterToolbarProps<TimeOnBatteriesFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, ...other } = props;

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<TimeOnBatteriesFilterState>({
        ...other,
    });

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <SearchDisplayFilter
                    filterValues={filterValues}
                    searchFilters={searchFilters}
                    setFilterValues={setFilterValues}
                    currentSearchField={currentSearchField}
                    setCurrentSearchField={setCurrentSearchField}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="regions_branches" />}
                    Content={<DisplayItemContent valueLabel={filterValues.branches?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('branches')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="bs_down_date" />}
                    Content={
                        <DateIntervalDisplayItemContent
                            startDateValue={filterValues.startDateTimeDown}
                            endDateValue={filterValues.endDateTimeDown}
                        />
                    }
                    onClick={onClickFilterItem('startDateTimeDown')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="power_off_date" />}
                    Content={
                        <DateIntervalDisplayItemContent
                            startDateValue={filterValues.startDatePowerOff}
                            endDateValue={filterValues.endDatePowerOff}
                        />
                    }
                    onClick={onClickFilterItem('startDatePowerOff')}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate id="filter" />}>
                    <IconButton onClick={onClickOpenFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </FilterDisplayWrapper>
    );
};

export default TimeOnBatteriesFilterToolbar;

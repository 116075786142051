import * as React from 'react';

import { DataGrid, GridColDef, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useBlockedCellFilter from '../hooks/use-blocked-cell-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import usePagination from 'hooks/use-pagination';
import { PositionsBlockedCellColumnsOrderRequestModel } from 'services/positions/positions-models';
import { usePositionsBlockedCellGetListQuery } from 'services/positions/positions-services';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

const columns: GridColDef[] = [
    {
        renderHeader: columnLocalization(`${APP}_type`),
        field: 'type',
        minWidth: 50,
        flex: 0.4,
    },
    {
        renderHeader: columnLocalization(`${APP}_element`),
        field: 'position',
        minWidth: 100,
        flex: 0.5,
    },
    {
        renderHeader: columnLocalization(`${APP}_cell_id`),
        field: 'cellId',
        minWidth: 100,
        flex: 0.5,
    },
    {
        renderHeader: columnLocalization(`${APP}_block_type`),
        field: 'blockType',
        minWidth: 130,
        flex: 0.5,
    },
    {
        renderHeader: columnLocalization(`${APP}_user`),
        field: 'user',
        minWidth: 150,
        flex: 0.5,
    },
    {
        renderHeader: columnLocalization(`${APP}_reason`),
        field: 'reason',
        minWidth: 150,
        sortable: false,
        flex: 0.6,
    },
    {
        renderHeader: columnLocalization(`${APP}_time`),
        renderCell: renderDateCell('yyyy-MM-dd HH:mm:ss'),
        field: 'time',
        minWidth: 100,
        flex: 0.5,
    },
];

const BlockedCellTable: React.FC = () => {
    const { Pagination, rowsState } = usePagination(`${APP}_blocked_cell_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { BlockedCellFilterDialog, BlockedCellFilterToolbar, filterValues } = useBlockedCellFilter();

    const { data, isFetching, refetch } = usePositionsBlockedCellGetListQuery({
        value: {
            search: filterValues.search,
            typeIds: filterValues.types ? filterValues.types.map((elem) => Number(elem.id)) : undefined,
            blockTypeIds: filterValues.blockTypes ? filterValues.blockTypes.map((elem) => Number(elem.id)) : undefined,
            branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : undefined,
        },
        order: sortModel[0] as PositionsBlockedCellColumnsOrderRequestModel,
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    return (
        <>
            {data !== undefined && (
                <>
                    <BlockedCellFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <BlockedCellFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default BlockedCellTable;

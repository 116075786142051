import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Visitors',
        ru: 'Постетители',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_visitors`]: {
        en: 'Visitors',
        ru: 'Посетители',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
    [`${APP}_edit_visitor`]: {
        en: 'Edit visitor',
        ru: 'Изменить посетителя',
    },
    [`${APP}_add_visitor`]: {
        en: 'Add visitor',
        ru: 'Добавить посетителя',
    },
    [`${APP}_visitor_is_required`]: {
        en: 'Visitor is required',
        ru: 'Посетитель обязателен',
    },
    [`${APP}_phone_is_required`]: {
        en: 'Phone is required',
        ru: 'Телефон обязателен',
    },
    [`${APP}_visitor`]: {
        en: 'Visitor',
        ru: 'Посетитель',
    },
    [`${APP}_phone`]: {
        en: 'Phone',
        ru: 'Телефон',
    },
    [`${APP}_plw_must_be_7_digits`]: {
        en: 'PLW must be 7 digits',
        ru: 'ПР должен состоять из 7 цифр',
    },
    [`${APP}_plw`]: {
        en: 'PLW',
        ru: 'ПР',
    },
    [`${APP}_tt_must_be_7_digits`]: {
        en: 'TT must be 7 digits',
        ru: 'TT должен состоять из 7 цифр',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_created_time`]: {
        en: 'Created time',
        ru: 'Время создания',
    },
    [`${APP}_created`]: {
        en: 'Created',
        ru: 'Создал',
    },
    [`${APP}_closed_time`]: {
        en: 'Closed time',
        ru: 'Время закрытия',
    },
    [`${APP}_closed`]: {
        en: 'Closed',
        ru: 'Закрыл',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the record?',
        ru: 'Вы действительно хотите удалить запись?',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
};

export default locale;

import * as React from 'react';

import Loadable from '../../components/loadable';
import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';

const Routes = Loadable(React.lazy(() => import('./routes')));

const app: AppConfig = {
    name: APP,
    element: () => <Routes />,
    locale,
};

export default app;

import * as React from 'react';

import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';

import RefetchBar from '../components/extended/data-grid/data-grid-refetch-bar';

type RowsState = {
    pageNumber: number;
    rowPerPage: number;
    prevPageNumber: number;
    prevRowPerPage: number;
};

const PaginationWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 55,
});

export type PaginationProps = {
    total: number;
    refetchCallback?: () => void;
    forcedRefetch?: boolean;
    autoRefetch?: boolean;
    isFetching?: boolean;
    refetchTime?: number;
    rowsPerPageOptions?: number[];
};

const usePagination = (key: string, defaultRowPerPage: string | undefined = '1') => {
    if (localStorage.getItem(`${key}_rowPerPage`) === null)
        localStorage.setItem(`${key}_rowPerPage`, defaultRowPerPage);

    const [rowsState, setRowsState] = React.useState<RowsState>({
        pageNumber: 0,
        rowPerPage: Number(localStorage.getItem(`${key}_rowPerPage`)),
        prevPageNumber: 0,
        prevRowPerPage: Number(localStorage.getItem(`${key}_rowPerPage`)),
    });

    const handleChangePage = React.useCallback((event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
        setRowsState((prevState) => ({ ...prevState, pageNumber: _page, prevPageNumber: prevState.pageNumber }));
    }, []);

    const handleChangeRowsPerPage = React.useCallback(
        (total: number) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setRowsState((prevState) => {
                const pagesCount = Math.ceil(total / Number(event.target.value)) - 1;
                return {
                    pageNumber: pagesCount < prevState.pageNumber ? pagesCount : prevState.pageNumber,
                    rowPerPage: Number(event.target.value),
                    prevPageNumber: prevState.pageNumber,
                    prevRowPerPage: prevState.rowPerPage,
                };
            });
            localStorage.setItem(`${key}_rowPerPage`, event.target.value);
        },
        [key],
    );
    const Pagination = React.useCallback(
        (props: PaginationProps) => {
            const {
                total,
                refetchCallback,
                isFetching,
                refetchTime,
                autoRefetch = false,
                forcedRefetch = false,
                rowsPerPageOptions = [1, 2, 3],
            } = props;
            return (
                <PaginationWrapper>
                    <TablePagination
                        component="div"
                        count={total}
                        page={rowsState.pageNumber}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsState.rowPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage(total)}
                    />
                    {refetchCallback !== undefined ? (
                        <RefetchBar
                            refetchCallback={refetchCallback}
                            autoRefetch={autoRefetch}
                            forcedRefetch={forcedRefetch}
                            isFetching={isFetching}
                            refetchTime={refetchTime}
                        />
                    ) : null}
                </PaginationWrapper>
            );
        },
        [handleChangePage, handleChangeRowsPerPage, rowsState],
    );

    return {
        Pagination,
        rowsState,
        setRowsState,
    };
};

export default usePagination;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Create work',
        ru: 'Создать работу',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_field_required`]: {
        en: 'Field is required',
        ru: 'Обязательное поле',
    },
    [`${APP}_work_place`]: {
        en: 'Work place',
        ru: 'Место проведения',
    },
    [`${APP}_work_in_auto_hall`]: {
        en: 'Work in the auto hall',
        ru: 'Работа в автозале',
    },
    [`${APP}_auto_hall`]: {
        en: 'Auto hall',
        ru: 'Автозал',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_time_spending`]: {
        en: 'Time spending',
        ru: 'Время проведения',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_impact_service`]: {
        en: 'Impact on the service',
        ru: 'Влияние на сервис',
    },
    [`${APP}_influence_b2b`]: {
        en: 'Influence on B2B',
        ru: 'Влияние на B2B',
    },
    [`${APP}_executors`]: {
        en: 'Executors',
        ru: 'Исполнители',
    },
    [`${APP}_customer`]: {
        en: 'Customer',
        ru: 'Заказчик',
    },
    [`${APP}_subject_work`]: {
        en: 'Subject to work',
        ru: 'Предмет работ',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_network_elements`]: {
        en: 'Network elements',
        ru: 'Сетевые элементы',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'ТТ',
    },
    [`${APP}_tt_number_length_must_be_8_digits`]: {
        en: 'TT number length must be 8 digits',
        ru: 'Длина номера ТТ должна быть 8 цифр',
    },
    [`${APP}_send_sms_notify`]: {
        en: 'Send SMS notify',
        ru: 'Отправлять SMS уведомления',
    },
    [`${APP}_client_id`]: {
        en: 'Client ID',
        ru: 'Клиен ID',
    },
    [`${APP}_client_influence`]: {
        en: 'Client influence',
        ru: 'Клиентское влияние',
    },
    [`${APP}_date_start`]: {
        en: 'Date start',
        ru: 'Дата начала',
    },
    [`${APP}_date_end`]: {
        en: 'Date end',
        ru: 'Дата завершения',
    },
    [`${APP}_start_date_impact_service`]: {
        en: 'Service interruption start date',
        ru: 'Дата начала прерывания сервиса',
    },
    [`${APP}_end_date_impact_service`]: {
        en: 'Service interruption end date',
        ru: 'Дата завершения прерывания сервиса',
    },
    [`${APP}_technical_influence`]: {
        en: 'Technical influence',
        ru: 'Технологическое влияние',
    },
    [`${APP}_min_create_time`]: {
        en: 'Min create time, h',
        ru: 'Минимальное время заведения работ, ч',
    },
    [`${APP}_time_absence_service`]: {
        en: 'Time of absence of service, m.',
        ru: 'Время отсутствия сервиса, м.',
    },
    [`${APP}_load_work`]: {
        en: 'Load work',
        ru: 'Загрузить работу',
    },
    [`${APP}_save_template`]: {
        en: 'Save template',
        ru: 'Сохранить шаблон',
    },
    [`${APP}_create`]: {
        en: 'Create',
        ru: 'Создать',
    },
    [`${APP}_create`]: {
        en: 'Create',
        ru: 'Создать',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Название',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отменить',
    },
    [`${APP}_apply`]: {
        en: 'Apply',
        ru: 'Применить',
    },
    [`${APP}_date_cannot_be_less_min`]: {
        en: 'Date cannot be less than the minimum',
        ru: 'Дата не может быть меньше минимальной',
    },
    [`${APP}_date_cannot_be_greater_end_date`]: {
        en: 'Date cannot be greater than the end date',
        ru: 'Дата не может быть больше даты завершения',
    },
    [`${APP}_invalid_date`]: {
        en: 'Invalid date',
        ru: 'Некорректная дата',
    },
    [`${APP}_date_cannot_be_less_start_date`]: {
        en: 'Date cannot be less than the start date',
        ru: 'Дата не может быть меньше начальной даты',
    },
    [`${APP}_date_cannot_be_greater_max`]: {
        en: 'Date cannot be greater than the maximum',
        ru: 'Дата не может быть больше максимальной',
    },
};

export default locale;

import * as React from 'react';

import TroubleTicketViewChangeServiceStatusForm from '../forms/trouble-ticket-view-change-service-status-form';

import {
    useSmartQueueDictionaryServiceStatusGetListQuery,
    useSmartQueueStatusChangeMutation,
} from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

type TroubleTicketViewChangeServiceStatusFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
};

export type TroubleTicketViewChangeStatusFormInitialValues = {
    statusId: number;
    comment: string;
    isClosed: boolean;
};

const TroubleTicketViewChangeServiceStatusFormConnector: React.FC<TroubleTicketViewChangeServiceStatusFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber } = props;

    const { data: serviceStatusAvailableValues } = useSmartQueueDictionaryServiceStatusGetListQuery();

    const initialValues: TroubleTicketViewChangeStatusFormInitialValues = React.useMemo(
        () => ({
            statusId: serviceStatusAvailableValues ? serviceStatusAvailableValues.data[0].id : 1,
            comment: '',
            isClosed: false,
        }),
        [serviceStatusAvailableValues],
    );

    const [changeStatus, { isLoading, data }] = useSmartQueueStatusChangeMutation();

    const handleChangeStatus = React.useCallback(
        async (formData: TroubleTicketViewChangeStatusFormInitialValues) => {
            const result = await changeStatus({
                isClosed: formData.isClosed,
                serviceStatusId: formData.statusId,
                comment: formData.comment,
                number: ttNumber,
            });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [changeStatus, onClose, ttNumber],
    );

    if (serviceStatusAvailableValues !== undefined) {
        return (
            <TroubleTicketViewChangeServiceStatusForm
                onClose={onClose}
                submitCallback={handleChangeStatus}
                statuses={serviceStatusAvailableValues.data}
                initialValues={initialValues}
                isLoading={isLoading}
                errors={data?.errors}
            />
        );
    }

    return null;
};

export default TroubleTicketViewChangeServiceStatusFormConnector;

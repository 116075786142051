import * as React from 'react';

import KeyCoordinatorsForm from '../forms/key-coordinators-form';

import { RegionResponseModel, useGetRegionListQuery } from 'services/webapi/generated/webapi-services';

export type KeyCoordinatorsFormInitialValues = {
    region: number | '';
    branch: string | '';
    isRequired: boolean;
    coordinator: { id: number; name: string } | null;
};

const initialValues: KeyCoordinatorsFormInitialValues = {
    region: '',
    branch: '',
    isRequired: false,
    coordinator: null,
};

type KeyCoordinatorsFormAddConnectorProps = {
    onClose: () => void;
};

export const getGroupedBranchesByRegion = (data?: RegionResponseModel[]) => {
    if (data === undefined) return {};

    const result: { [key: number]: { id: string; name: string }[] } = {};

    data.forEach((item) => {
        result[item.id] = item.branches.map((elem) => ({ id: elem.prefix, name: elem.name }));
    });

    return result;
};

const KeyCoordinatorsFormAddConnector: React.FC<KeyCoordinatorsFormAddConnectorProps> = (props) => {
    const { onClose } = props;

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const groupedBranchesByRegion = React.useMemo(
        () => getGroupedBranchesByRegion(regionAndBranchAvailableValues?.data),
        [regionAndBranchAvailableValues],
    );

    const handleSubmit = React.useCallback(() => {
        // TODO тут должна запускаться мутация
        onClose();
    }, [onClose]);

    if (regionAndBranchAvailableValues?.data === undefined) return null;

    return (
        <KeyCoordinatorsForm
            isLoading={false}
            initialValues={initialValues}
            submitCallback={handleSubmit}
            onClose={onClose}
            regions={regionAndBranchAvailableValues.data}
            groupedBranches={groupedBranchesByRegion}
        />
    );
};

export default KeyCoordinatorsFormAddConnector;

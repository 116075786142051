import * as React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useTheme from '@mui/material/styles/useTheme';

import { yupResolver } from '@hookform/resolvers/yup';
import { nanoid } from '@reduxjs/toolkit';
import Translate from 'components/extended/translate';
import { AuthStringApiResponse } from 'services/auth/auth-models';
import { useSendPhoneVerificationCodeMutation, useVerifyPhoneMutation } from 'services/auth/auth-services';
import * as Yup from 'yup';
import 'yup-phone';

export type VerifyPhoneInputs = {
    phone: string;
};

export type VerifyPhoneCodeInputs = {
    code: string;
};

type VerifyPhoneFormProps = {
    defaultValues: VerifyPhoneInputs;
    onSuccessSubmit: () => void;
};

const VerifyPhoneForm: React.FC<VerifyPhoneFormProps> = (props) => {
    const { defaultValues, onSuccessSubmit } = props;
    const theme = useTheme();
    const [submitErrors, setSubmitErrors] = React.useState<string[]>([]);
    const [isSubmitCode, setIsSubmitCode] = React.useState(false);
    const [phone, setPhone] = React.useState(defaultValues.phone);
    const [countDownKey, setCountDownKey] = React.useState('firstCountDownKey');

    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
    } = useForm<VerifyPhoneInputs>({
        defaultValues: {
            phone: `+${defaultValues.phone}`,
        },
        resolver: yupResolver(
            Yup.object().shape({
                phone: Yup.string().phone('IN', false, 'phone_invalid_format').required('phone_is_required'),
            }),
        ),
        mode: 'all',
    });

    const {
        handleSubmit: handleSubmitCode,
        control: controlCode,
        formState: { isValid: isValidCode, isSubmitting: isSubmittingCode },
    } = useForm<VerifyPhoneCodeInputs>({
        defaultValues: {
            code: '',
        },
        resolver: yupResolver(
            Yup.object().shape({
                code: Yup.string().required('code_verification_is_required'),
            }),
        ),
        mode: 'all',
    });

    const [verifyPhone] = useVerifyPhoneMutation();

    const onSubmit = React.useCallback(
        async (valuesForm: VerifyPhoneCodeInputs) => {
            setSubmitErrors([]);

            const res = await verifyPhone(valuesForm);

            if ('error' in res && 'data' in res.error) {
                const data = res.error.data as AuthStringApiResponse;
                setSubmitErrors(data.errors);
            }

            if ('data' in res) {
                const { status, errors } = res.data;

                if (status === 'Success') {
                    onSuccessSubmit();
                } else {
                    setSubmitErrors(errors);
                }
            }

            return res;
        },
        [onSuccessSubmit, verifyPhone],
    );

    const [sendPhoneVerificationCode] = useSendPhoneVerificationCodeMutation();

    const onSubmitCode = React.useCallback(
        async (valuesForm: VerifyPhoneInputs) => {
            setSubmitErrors([]);
            setPhone(valuesForm.phone.replace(/\D/g, ''));

            const res = await sendPhoneVerificationCode({
                phone: valuesForm.phone.replace(/\D/g, ''),
            });

            if ('error' in res && 'data' in res.error) {
                const data = res.error.data as AuthStringApiResponse;
                setSubmitErrors(data.errors);
            }

            if ('data' in res) {
                const { status, errors } = res.data;

                if (status === 'Success') {
                    setIsSubmitCode(true);
                } else {
                    setSubmitErrors(errors);
                }
            }

            return res;
        },
        [sendPhoneVerificationCode],
    );

    const onResendCode = React.useCallback(async () => {
        setSubmitErrors([]);

        const res = await sendPhoneVerificationCode({
            phone: phone.replace(/\D/g, ''),
        });

        if ('error' in res && 'data' in res.error) {
            const data = res.error.data as AuthStringApiResponse;
            setSubmitErrors(data.errors);
        }

        if ('data' in res) {
            const { status, errors } = res.data;

            if (status === 'Success') {
                setCountDownKey(nanoid());
            } else {
                setSubmitErrors(errors);
            }
        }

        return res;
    }, [phone, sendPhoneVerificationCode]);

    const handleBack = React.useCallback(() => setIsSubmitCode(false), []);

    const countDownButton = ({
        minutes,
        seconds,
        completed,
    }: {
        minutes: number;
        seconds: number;
        completed: boolean;
    }) => (
        <Button
            disableElevation
            fullWidth
            size="large"
            variant="outlined"
            color="secondary"
            onClick={onResendCode}
            disabled={!completed}
            sx={{
                textTransform: 'none',
            }}
        >
            {completed ? (
                <Translate id="resend_verification_code" />
            ) : (
                <>
                    <Translate id="resend_verification_code_timeout" /> {minutes}:{zeroPad(seconds)}
                </>
            )}
        </Button>
    );

    if (isSubmitCode) {
        return (
            <form key="codeForm" noValidate onSubmit={handleSubmitCode(onSubmit)}>
                <Controller
                    name="code"
                    control={controlCode}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl fullWidth error={invalid && isTouched} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-code-verify-phone">
                                <Translate id="code_verification" />
                            </InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-code-verify-phone"
                                autoFocus
                                {...field}
                                type="text"
                                disabled={isSubmittingCode}
                            />

                            {invalid && isTouched && error?.message && (
                                <FormHelperText error id="standard-weight-helper-text-code-verify-phone">
                                    <Translate id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />

                {submitErrors.length > 0 && (
                    <Box sx={{ mt: 2, mb: 2.5 }}>
                        {submitErrors.map((_error) => (
                            <FormHelperText key={_error} error>
                                {_error}
                            </FormHelperText>
                        ))}
                    </Box>
                )}

                <Box sx={{ mt: 2 }}>
                    <Countdown key={countDownKey} date={Date.now() + 300000} renderer={countDownButton} />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <LoadingButton
                        disabled={!isValidCode}
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        loading={isSubmittingCode}
                    >
                        <Translate id="save" />
                    </LoadingButton>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        color="secondary"
                        onClick={handleBack}
                    >
                        <Translate id="return_login_form" />
                    </Button>
                </Box>
            </form>
        );
    }

    return (
        <form key="phoneForm" noValidate onSubmit={handleSubmit(onSubmitCode)}>
            <Controller
                name="phone"
                control={control}
                render={({ field, fieldState: { isTouched, invalid, error } }) => (
                    <FormControl fullWidth error={invalid && isTouched} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-phone-verify-phone">
                            <Translate id="phone" />
                        </InputLabel>

                        <InputMask mask="+9 (999) 999-99-99" {...field} disabled={isSubmittingCode}>
                            {() => <OutlinedInput id="outlined-adornment-phone-verify-phone" autoFocus type="tel" />}
                        </InputMask>

                        {invalid && isTouched && error?.message && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                <Translate id={error.message} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />

            {submitErrors.length > 0 && (
                <Box sx={{ mt: 2, mb: 2.5 }}>
                    {submitErrors.map((_error) => (
                        <FormHelperText key={_error} error>
                            {_error}
                        </FormHelperText>
                    ))}
                </Box>
            )}

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                >
                    <Translate id="save" />
                </LoadingButton>
            </Box>
        </form>
    );
};

export default VerifyPhoneForm;

import * as React from 'react';

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { APP, TroubleTicketVariantsEnum, troubleTicketViewGridSpacing } from '../../../constants';
import TroubleTicketViewCommentInputConnector from './connectors/trouble-ticket-view-comment-input-connector';
import TroubleTicketViewCommentsDialog from './dialogs/trouble-ticket-view-comments-dialog';

import Translate from 'components/extended/translate';
import { useModal } from 'mui-modal-provider';

type TroubleTicketViewCommentsToolbarProps = {
    isGroupComments: boolean;
    onClickGroupComments: () => void;
    isImportant: boolean;
    onClickImportant: () => void;
    variant: TroubleTicketVariantsEnum;
    ttNumber: number;
};

const TroubleTicketViewCommentsToolbar: React.FC<TroubleTicketViewCommentsToolbarProps> = (props) => {
    const { isGroupComments, isImportant, onClickImportant, variant, ttNumber, onClickGroupComments } = props;

    const { showModal } = useModal();

    const handleOpenFullScreenCommentsClick = React.useCallback(() => {
        const modal = showModal(TroubleTicketViewCommentsDialog, {
            onClose: () => {
                modal.hide();
            },
            ttNumber,
        });
    }, [showModal, ttNumber]);

    return (
        <Grid container direction="column" spacing={troubleTicketViewGridSpacing}>
            <Grid item>
                <TroubleTicketViewCommentInputConnector ttNumber={ttNumber} />
            </Grid>
            <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Grid container direction="row" spacing={{ xs: 0.5, lg: 1 }}>
                            {/* TODO Тут должна быть кнопка экспорта */}
                            {variant === TroubleTicketVariantsEnum.View && (
                                <>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isGroupComments} onClick={onClickGroupComments} />
                                            }
                                            label={<Translate app={APP} id="group_comments" />}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox checked={isImportant} onClick={onClickImportant} />}
                                            label={<Translate app={APP} id="only_important" />}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {variant === TroubleTicketVariantsEnum.View && (
                        <Grid item>
                            <IconButton onClick={handleOpenFullScreenCommentsClick}>
                                <ZoomOutMapIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TroubleTicketViewCommentsToolbar;

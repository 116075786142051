import * as React from 'react';

import { Add, ExpandMore, Remove } from '@mui/icons-material';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { AvailableValue, AvailableValueTree, FilterCheckBoxStateValue } from './types';

import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import { deepCopy } from 'utils/deep-copy';

type FilterCheckBoxTreeProps<FilterState> = {
    field: keyof FilterState;
    value: FilterCheckBoxStateValue;
    label: string | JSX.Element;
    expanded: boolean;
    availableValues: AvailableValueTree[];
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
};

const FilterCheckBoxTree = <FilterState,>(props: FilterCheckBoxTreeProps<FilterState>) => {
    const { field, value, label, expanded, availableValues, setLocalFilterValues } = props;

    const [open, setOpen] = React.useState<number[]>([]);

    const handleToggle = React.useCallback(
        (
            _value: AvailableValueTree | AvailableValue,
            checked: boolean | undefined,
            level: number,
            indeterminate?: boolean,
        ) => () => {
            let newValue = value !== null ? deepCopy(value) : [];

            if (level === 1) {
                availableValues.forEach((listElem) => {
                    if (listElem.id === _value.id) {
                        listElem.children.forEach((childElem) => {
                            if ((checked && indeterminate) || !checked) {
                                newValue.push(childElem);
                            } else {
                                newValue = newValue.filter((item) => item.id !== childElem.id);
                            }
                        });
                    }
                });
                setLocalFilterValues((prevState) => ({
                    ...prevState,
                    [field]: newValue,
                }));
            }

            if (level === 2) {
                if (!checked) {
                    newValue.push(_value as AvailableValue);
                } else {
                    newValue = newValue.filter((item) => item.id !== (_value as AvailableValue).id);
                }
                setLocalFilterValues((prevState) => ({
                    ...prevState,
                    [field]: newValue,
                }));
            }
        },
        [availableValues, field, setLocalFilterValues, value],
    );

    const handleClickCollapse = React.useCallback(
        (index: number) => () => {
            open.indexOf(index) > -1 ? setOpen(open.filter((el) => el !== index)) : setOpen([...open, index]);
        },
        [open],
    );

    return (
        <Accordion
            defaultExpanded={expanded}
            disableGutters
            sx={{ [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } }}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 60 }}>
                <Box>
                    <Typography>{label}</Typography>
                    <Typography>
                        <DisplayItemContent valueLabel={value?.map((elem) => elem.name)} />
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {availableValues.map((_value, index) => {
                        const checked =
                            value !== null
                                ? _value.children.every((elem) =>
                                      value !== null ? value.some((_elem) => _elem.id === elem.id) : false,
                                  )
                                : false;

                        const indeterminate = checked
                            ? false
                            : _value.children.some((elem) =>
                                  value !== null ? value.some((_elem) => _elem.id === elem.id) : false,
                              );

                        return (
                            <Box key={_value.id}>
                                <ListItem
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleToggle(_value, checked, 1, indeterminate)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked}
                                            indeterminate={indeterminate}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={_value.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="start" onClick={handleClickCollapse(index)}>
                                            {open.indexOf(index) > -1 ? <Remove /> : <Add />}
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={open.indexOf(index) > -1} timeout="auto">
                                    <List sx={{ pl: 2 }}>
                                        {_value.children.map((__value, _index) => {
                                            const _checked =
                                                value !== null ? value.some((_elem) => _elem.id === __value.id) : false;

                                            return (
                                                <ListItem
                                                    key={`${_value.id} - ${__value.id}`}
                                                    dense
                                                    button
                                                    onClick={handleToggle(__value, _checked, 2)}
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={_checked}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            color="primary"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={__value.name} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            </Box>
                        );
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilterCheckBoxTree;

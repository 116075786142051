import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Events',
        ru: 'События',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_by_number`]: {
        en: 'By number',
        ru: 'По номеру',
    },
    [`${APP}_by_description`]: {
        en: 'By description',
        ru: 'По описанию',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_event_number`]: {
        en: '№',
        ru: '№',
    },
    [`${APP}_cause`]: {
        en: 'Cause',
        ru: 'Причина',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_created_user`]: {
        en: 'Create user',
        ru: 'Создатель',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_ticket_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_time_open`]: {
        en: 'Time open',
        ru: 'Дата',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_state`]: {
        en: 'Condition',
        ru: 'Состояние',
    },
    [`${APP}_position_count`]: {
        en: 'Position Count',
        ru: 'Количество БС',
    },
    [`${APP}_plan`]: {
        en: 'Existence of an incident',
        ru: 'Наличие инцидента',
    },
    [`${APP}_ticket`]: {
        en: 'Existence of an ticket',
        ru: 'Наличие плановой раоты',
    },
    [`${APP}_close`]: {
        en: 'Close',
        ru: 'Закрыть',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_create_time`]: {
        en: 'Create time',
        ru: 'Время создания',
    },
    [`${APP}_a`]: {
        en: 'A',
        ru: 'A',
    },
    [`${APP}_alarm_activity`]: {
        en: 'Alarm activity, %',
        ru: 'Активность аварии, %',
    },
    [`${APP}_n`]: {
        en: 'N',
        ru: 'Н',
    },
    [`${APP}_non_activity`]: {
        en: 'Non activity, min',
        ru: 'Не активно, мин',
    },
    [`${APP}_r`]: {
        en: 'R',
        ru: 'П',
    },
    [`${APP}_repeat_count`]: {
        en: 'Repeat counts',
        ru: 'Кол-во повторений',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_close`]: {
        en: 'Close',
        ru: 'Закрыть',
    },
    [`${APP}_you_want_close_event`]: {
        en: 'You want to close the incident?',
        ru: 'Вы хотите закрыть инцидент?',
    },
};

export default locale;

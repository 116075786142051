import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';

import { APP } from '../constants';
import usePositionsStatisticsFilter from '../hooks/use-positions-statistics-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import { format } from 'date-fns';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { PositionsStatisticColumnsOrderRequestModel } from 'services/positions/positions-models';
import { usePositionsStatisticPositionGetListQuery } from 'services/positions/positions-services';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

type PositionsStatisticsTableProps = {
    positionNumber?: string;
};

const PositionsStatisticsTable: React.FC<PositionsStatisticsTableProps> = (props) => {
    const { positionNumber } = props;

    const { Pagination, rowsState } = usePagination(`${APP}_statistics_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const {
        PositionsStatisticsFilterDialog,
        PositionsStatisticsFilterToolbar,
        filterValues,
    } = usePositionsStatisticsFilter(positionNumber);

    const { data, refetch, isFetching } = usePositionsStatisticPositionGetListQuery({
        value: {
            search: filterValues.search,
            position: filterValues.positionNumber,
            isTraffic: filterValues.isTraffic.id,
            isPower: filterValues.isPower.id,
            isDoor: filterValues.isDoor.id,
            isTemp: filterValues.isTemp.id,
            startDate: filterValues.startDate.id
                ? format(new Date(filterValues.startDate.id), 'yyyy-MM-dd')
                : undefined,
            endDate: filterValues.endDate.id ? format(new Date(filterValues.endDate.id), 'yyyy-MM-dd') : undefined,
            branches: filterValues.branches?.map((elem) => String(elem.id)),
            typeIds: filterValues.types?.map((elem) => Number(elem.id)),
        },
        order: sortModel[0] as PositionsStatisticColumnsOrderRequestModel,
        pagination: {
            rowPerPage: rowsState.rowPerPage,
            pageNumber: rowsState.pageNumber + 1,
        },
    });

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: POSITION_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns = React.useMemo(
        (): GridColDef[] => [
            {
                renderHeader: columnLocalization(`${APP}_position`),
                renderCell: renderCellChip,
                field: 'position',
                minWidth: 100,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                minWidth: 90,
                sortable: false,
                flex: 0.6,
            },
            {
                renderHeader: columnLocalization(`${APP}_type`),
                field: 'type',
                minWidth: 50,
                sortable: false,
                flex: 0.4,
            },
            {
                renderHeader: columnLocalization(`${APP}_traffic_hour`),
                field: 'trafficHour',
                minWidth: 145,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_traffic`),
                field: 'traffic',
                minWidth: 145,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_power`),
                field: 'power',
                minWidth: 150,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_temp`),
                field: 'temp',
                minWidth: 160,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_door`),
                field: 'door',
                minWidth: 145,
                sortable: false,
                flex: 0.5,
            },
            {
                renderHeader: columnLocalization(`${APP}_date`),
                renderCell: renderDateCell('dd.MM.yyyy'),
                field: 'date',
                minWidth: 135,
                sortable: false,
                flex: 0.5,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <PositionsStatisticsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        columnVisibilityModel={{
                            position: !positionNumber,
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <PositionsStatisticsFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default PositionsStatisticsTable;

import * as React from 'react';

import Box from '@mui/material/Box';

import MainCard, { MainCardProps } from 'components/cards/main-card';
import LocalizationSection from 'layouts/main-layout/header/localization-section';

const AuthCardWrapper: React.FC<MainCardProps> = (props) => {
    const { children, ...other } = props;

    return (
        <MainCard
            sx={{
                maxWidth: { xs: 400, lg: 475 },
                margin: { xs: 2.5, md: 3 },
                '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%',
                },
                position: 'relative',
            }}
            content={false}
            {...other}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <LocalizationSection />
            </Box>
            <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
        </MainCard>
    );
};

export default AuthCardWrapper;

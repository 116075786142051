import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';

import { APP } from '../constants';
import useEquipmentFilter from '../hooks/use-equipment-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { PositionsEquipmentColumnsOrderRequestModel } from 'services/positions/positions-models';
import { usePositionsEquipmentGetListQuery } from 'services/positions/positions-services';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import columnLocalization from 'utils/column-localization';

type EquipmentTableProps = {
    positionNumber?: string;
};

const EquipmentTable: React.FC<EquipmentTableProps> = (props) => {
    const { positionNumber } = props;

    const { Pagination, rowsState } = usePagination(`${APP}_equipment_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { EquipmentFilterDialog, EquipmentFilterToolbar, filterValues } = useEquipmentFilter(positionNumber);

    const { data, refetch, isFetching } = usePositionsEquipmentGetListQuery({
        value: {
            search: filterValues.search,
            position: filterValues.positionNumber,
            branches: filterValues.branches?.map((elem) => String(elem.id)),
            vendorIds: filterValues.vendors?.map((elem) => Number(elem.id)),
            equipmentTypeIds: filterValues.equipmentTypes?.map((elem) => Number(elem.id)),
        },
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
        order: sortModel[0] as PositionsEquipmentColumnsOrderRequestModel,
    });

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: POSITION_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip sx={{ userSelect: 'text' }} onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_type`),
                field: 'equipmentType',
                flex: 0.5,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization(`${APP}_position`),
                renderCell: renderCellChip,
                field: 'position',
                flex: 0.5,
                minWidth: 80,
            },
            {
                renderHeader: columnLocalization(`${APP}_vendor`),
                field: 'vendor',
                flex: 0.5,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization(`${APP}_title`),
                field: 'externalName',
                flex: 0.5,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization(`${APP}_model`),
                field: 'model',
                flex: 0.5,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_description`),
                field: 'description',
                flex: 0.5,
                minWidth: 150,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_ip`),
                field: 'ip',
                flex: 0.5,
                minWidth: 120,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <EquipmentFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        columnVisibilityModel={{
                            position: !positionNumber,
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <EquipmentFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default EquipmentTable;

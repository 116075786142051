import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useEprListFilter from '../hooks/use-epr-list-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { APP as EPR_VIEW_APP } from 'sub-apps/epr/epr-view/constants';
import columnLocalization from 'utils/column-localization';

const rows = [
    {
        id: 0,
        personalId: 'wx952625',
        staffId: 'gwx952625',
        remedyId: 'no_remedy_id',
        rusName: 'Габдракипова Рита Раяновна',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.791533,
        eprQ2: 0.984267,
        eprQ3: 0.895323,
        eprQ4: 1.12248,
        eprYear: 3.79361,
    },
    {
        id: 1,
        personalId: 'wx1046554',
        staffId: 'iwx1046554',
        remedyId: 'no_remedy_id',
        rusName: 'Идиятуллин Булат Азатович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0,
        eprQ2: 0.361538,
        eprQ3: 0.53802,
        eprQ4: 0,
        eprYear: 0.899558,
    },
    {
        id: 2,
        personalId: 'wx964257',
        staffId: 'kwx964257',
        remedyId: 'no_remedy_id',
        rusName: 'Катаскин Линар Владимирович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.663917,
        eprQ2: 0.9585,
        eprQ3: 0.846295,
        eprQ4: 0.778391,
        eprYear: 3.2471,
    },
    {
        id: 3,
        personalId: 'wx980001',
        staffId: 'mwx980001',
        remedyId: 'В338374',
        rusName: 'Махмутова Эвилина Ильгизовна',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.692178,
        eprQ2: 0.875387,
        eprQ3: 0.814823,
        eprQ4: 0.878465,
        eprYear: 3.26085,
    },
    {
        id: 4,
        personalId: 'wx995797',
        staffId: 'mwx995797',
        remedyId: 'no_remedy_id',
        rusName: 'Минимухаметова Алсу Ильнуровна',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.658003,
        eprQ2: 0.97373,
        eprQ3: 0.925253,
        eprQ4: 0.964618,
        eprYear: 3.5216,
    },
    {
        id: 5,
        personalId: 'wx1079338',
        staffId: 'mwx1079338',
        remedyId: 'no_remedy_id',
        rusName: 'Муталлапов Динар Амурович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0,
        eprQ2: 0,
        eprQ3: 0.484698,
        eprQ4: 0.726933,
        eprYear: 1.21163,
    },
    {
        id: 6,
        personalId: 'wx1063513',
        staffId: 'nwx1063513',
        remedyId: 'no_remedy_id',
        rusName: 'Нигматуллина Миляуша',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0,
        eprQ2: 0.00124875,
        eprQ3: 0.574515,
        eprQ4: 0,
        eprYear: 0.575764,
    },
    {
        id: 7,
        personalId: 'wx998681',
        staffId: 'nwx998681',
        remedyId: 'no_remedy_id',
        rusName: 'Никоноров Михаил Родионович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.547901,
        eprQ2: 0.382321,
        eprQ3: 0.605826,
        eprQ4: 0,
        eprYear: 1.53605,
    },
    {
        id: 8,
        personalId: 'WX501951',
        staffId: 'pwx501951',
        remedyId: 'no_remedy_id',
        rusName: 'Прокофьев Дмитрий Андреевич',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.116832,
        eprQ2: 0.0843688,
        eprQ3: 0.108109,
        eprQ4: 0.0644555,
        eprYear: 0.373765,
    },
    {
        id: 9,
        personalId: 'wx1076106',
        staffId: 'rwx1076106',
        remedyId: 'no_remedy_id',
        rusName: 'Рахматуллин Азат Расимович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0,
        eprQ2: 0,
        eprQ3: 0.571634,
        eprQ4: 0.663006,
        eprYear: 1.23464,
    },
    {
        id: 10,
        personalId: 'wx736687',
        staffId: 'kwx736687',
        remedyId: 'В296452',
        rusName: 'Хабибрахманова Татьяна Маратовна',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0.871278,
        eprQ2: 0.949324,
        eprQ3: 1.26088,
        eprQ4: 1.26237,
        eprYear: 4.34385,
    },
    {
        id: 11,
        personalId: 'wx1078859',
        staffId: 'kwx1078859',
        remedyId: 'no_remedy_id',
        rusName: 'Хасанчин Тагир Ильдарович',
        region: 'Волга',
        branch: 'Уфимский',
        teamName: 'Monitoring TN',
        teamId: 300002,
        eprQ1: 0,
        eprQ2: 0,
        eprQ3: 0.442182,
        eprQ4: 0.702155,
        eprYear: 1.14434,
    },
];

const renderCellRounding = (count: number) => (params: GridRenderCellParams) =>
    params?.value ? params.value.toFixed(count) : 0;

const EprListTable: React.FC = () => {
    const renderCellChipObject = React.useCallback(
        (params: GridRenderCellParams<string>) => <Chip size="small" label={params.value} />,
        [],
    );

    const { createTab } = useGlobalTabs();

    const handleCreateTabClick = React.useCallback(() => {
        createTab({
            id: undefined,
            app: EPR_VIEW_APP,
            name: '123',
            path: '123',
            isUnique: true,
        });
    }, [createTab]);

    const { EprListFilterToolbar } = useEprListFilter();

    const { Pagination } = usePagination(`${APP}_epr_list_grid`, '5');

    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_full_name`),
                field: 'rusName',
                renderCell: renderCellChipObject,
                width: 280,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`region`),
                field: 'region',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`branch`),
                field: 'branch',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_team`),
                field: 'teamName',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_q1`),
                renderCell: renderCellRounding(3),
                field: 'eprQ1',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_q2`),
                renderCell: renderCellRounding(3),
                field: 'eprQ2',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_q3`),
                renderCell: renderCellRounding(3),
                field: 'eprQ3',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_q4`),
                renderCell: renderCellRounding(3),
                field: 'eprQ4',
                flex: 1,
                sortable: false,
            },
        ],
        [renderCellChipObject],
    );

    return (
        <>
            {rows !== undefined && (
                <>
                    <EprListFilterToolbar />
                    <DataGrid
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.row}:hover`]: {
                                cursor: 'pointer',
                            },
                        }}
                        density="compact"
                        autoHeight
                        rows={rows}
                        onRowClick={handleCreateTabClick}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        getRowHeight={() => 'auto'}
                        onSortModelChange={setSortModel}
                        components={{
                            Pagination,
                        }}
                        componentsProps={{
                            pagination: {
                                total: rows.length,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                </>
            )}
            <DataGridLoader visible={false} text={<Translate app={APP} id="loading" />} />
        </>
    );
};

export default EprListTable;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'EPR',
        ru: 'EPR',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_loading`]: {
        en: 'Loading',
        ru: 'Загрузка',
    },
    [`${APP}_team`]: {
        en: 'Team',
        ru: 'Группа',
    },
    [`${APP}_q1`]: {
        en: 'Q1',
        ru: 'Q1',
    },
    [`${APP}_q2`]: {
        en: 'Q2',
        ru: 'Q2',
    },
    [`${APP}_q3`]: {
        en: 'Q3',
        ru: 'Q3',
    },
    [`${APP}_q4`]: {
        en: 'Q4',
        ru: 'Q4',
    },
    [`${APP}_direction`]: {
        en: 'Direction',
        ru: 'Функция',
    },
    [`${APP}_group`]: {
        en: 'Group',
        ru: 'Группа',
    },
};

export default locale;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import { APP, CREATE_PLANNED_WORK_FORM_ID } from '../../constants';
import CreatePlannedWorkAutoHallFormControllerConnector from '../connectors/create-planned-work-auto-hall-form-controller-connector';
import CreatePlannedWorkCustomerFormControllerConnector from '../connectors/create-planned-work-customer-form-controller-connector';
import CreatePlannedWorkExecutorsFormControllerConnector from '../connectors/create-planned-work-executors-form-controller-connector';
import CreatePlannedWorkNetworkElementsFormControllerConnector from '../connectors/create-planned-work-network-elements-form-controller-connector';
import CreatePlannedWorkObjectFormControllerConnector from '../connectors/create-planned-work-object-form-controller-connector';
import CreatePlannedWorkRegionBranchFormControllersConnector from '../connectors/create-planned-work-region-branch-form-controllers-connector';
import CreatePlannedWorkTimeSpendingFormControllerConnector from '../connectors/create-planned-work-time-spending-form-controller-connector';
import CreatePlannedWorkTypeFormControllerConnector from '../connectors/create-planned-work-type-form-controller-connector';
import CreatePlannedWorkWorkPlaceFormControllerConnector from '../connectors/create-planned-work-work-place-form-controller-connector';
import CreatePlannedWorkDatesImpactServiceIntervalContainer from '../create-planned-work-dates-impact-service-interval-container';
import CreatePlannedWorkDatesWorkIntervalContainer from '../create-planned-work-dates-work-interval-container';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';
import { differenceInMinutes } from 'date-fns';

type CreatePlannedWorkFormProps = {
    formValues: CreatePlannedWorkFormInputs;
    onClickSaveTemplate: () => void;
};

const dateMask = '__.__.____ __:__';
const dateFormat = 'dd.MM.yyyy HH:mm';

const CreatePlannedWorkForm: React.FC<CreatePlannedWorkFormProps> = (props) => {
    const { formValues, onClickSaveTemplate } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
        watch,
        setValue,
        resetField,
        reset,
    } = useForm<CreatePlannedWorkFormInputs>({
        defaultValues: formValues,
        mode: 'all',
    });

    React.useEffect(() => {
        reset(formValues);
    }, [formValues, reset]);

    const handleSubmitForm = React.useCallback(() => {}, []);

    const typeValue = watch('type');
    const isInfluenceB2bValue = watch('isInfluenceB2b');
    const startDateImpactServiceValue = watch('startDateImpactService');
    const endDateImpactServiceValue = watch('endDateImpactService');

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form noValidate onSubmit={handleSubmit(handleSubmitForm)} id={CREATE_PLANNED_WORK_FORM_ID}>
                <CreatePlannedWorkRegionBranchFormControllersConnector
                    control={control}
                    resetField={resetField}
                    watch={watch}
                />
                <Controller
                    name="isAutoHall"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            sx={{ marginTop: 1 }}
                            control={<Checkbox {...field} size="small" checked={field.value} />}
                            label={<Translate app={APP} id="work_in_auto_hall" />}
                        />
                    )}
                />
                {watch('isAutoHall') && (
                    <CreatePlannedWorkAutoHallFormControllerConnector control={control} watch={watch} />
                )}
                <CreatePlannedWorkWorkPlaceFormControllerConnector control={control} watch={watch} />
                <CreatePlannedWorkObjectFormControllerConnector control={control} resetField={resetField} />
                <CreatePlannedWorkTimeSpendingFormControllerConnector control={control} resetField={resetField} />
                <CreatePlannedWorkTypeFormControllerConnector control={control} watch={watch} setValue={setValue} />
                <Box>
                    <Controller
                        name="isInfluenceB2b"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                sx={{ marginTop: 1 }}
                                control={<Checkbox {...field} size="small" checked={field.value} />}
                                label={<Translate app={APP} id="influence_b2b" />}
                            />
                        )}
                    />
                </Box>
                {isInfluenceB2bValue && (
                    <>
                        <Controller
                            name="clientId"
                            control={control}
                            rules={{
                                required: 'field_required',
                            }}
                            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 1 }}>
                                    <InputLabel margin="dense" htmlFor={field.name}>
                                        <Translate app={APP} id="client_id" />
                                    </InputLabel>
                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        label={<Translate app={APP} id="client_id" />}
                                    />
                                    {invalid && isTouched && error?.message && (
                                        <FormHelperText error>
                                            <Translate app={APP} id={error.message} />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="clientInfluence"
                            control={control}
                            rules={{
                                required: 'field_required',
                            }}
                            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                                    <InputLabel margin="dense" htmlFor={field.name}>
                                        <Translate app={APP} id="client_influence" />
                                    </InputLabel>
                                    <OutlinedInput
                                        {...field}
                                        multiline
                                        rows={3}
                                        id={field.name}
                                        label={<Translate app={APP} id="client_influence" />}
                                    />
                                    {invalid && isTouched && error?.message && (
                                        <FormHelperText error>
                                            <Translate app={APP} id={error.message} />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </>
                )}
                {typeValue !== '' && (
                    <Box my={1}>
                        <Alert severity="info">
                            <Translate app={APP} id="min_create_time" />
                            {` ${isInfluenceB2bValue ? typeValue.minCreateTimeB2b : typeValue.minCreateTime}`}
                        </Alert>
                    </Box>
                )}
                <CreatePlannedWorkDatesWorkIntervalContainer
                    control={control}
                    watch={watch}
                    dateFormat={dateFormat}
                    dateMask={dateMask}
                />
                <Box>
                    <Controller
                        name="isImpactService"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                sx={{ marginTop: 1 }}
                                control={<Checkbox {...field} size="small" checked={field.value} />}
                                label={<Translate app={APP} id="impact_service" />}
                            />
                        )}
                    />
                </Box>
                {watch('isImpactService') && (
                    <>
                        <CreatePlannedWorkDatesImpactServiceIntervalContainer
                            control={control}
                            watch={watch}
                            dateFormat={dateFormat}
                            dateMask={dateMask}
                        />
                        {startDateImpactServiceValue !== null && endDateImpactServiceValue !== null && (
                            <Box my={1}>
                                <Alert severity="info">
                                    <Translate app={APP} id="time_absence_service" />
                                    {` ${differenceInMinutes(endDateImpactServiceValue, startDateImpactServiceValue)}`}
                                </Alert>
                            </Box>
                        )}
                        <Controller
                            name="technicalInfluence"
                            control={control}
                            rules={{
                                required: 'field_required',
                            }}
                            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                                    <InputLabel margin="dense" htmlFor={field.name}>
                                        <Translate app={APP} id="technical_influence" />
                                    </InputLabel>
                                    <OutlinedInput
                                        {...field}
                                        multiline
                                        rows={3}
                                        id={field.name}
                                        label={<Translate app={APP} id="technical_influence" />}
                                    />
                                    {invalid && isTouched && error?.message && (
                                        <FormHelperText error>
                                            <Translate app={APP} id={error.message} />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </>
                )}
                <CreatePlannedWorkExecutorsFormControllerConnector control={control} watch={watch} />
                <CreatePlannedWorkCustomerFormControllerConnector control={control} watch={watch} />
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: 'field_required',
                    }}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                            <InputLabel margin="dense" htmlFor={field.name}>
                                <Translate app={APP} id="subject_work" />
                            </InputLabel>
                            <OutlinedInput
                                {...field}
                                id={field.name}
                                label={<Translate app={APP} id="subject_work" />}
                            />
                            {invalid && isTouched && error?.message && (
                                <FormHelperText error>
                                    <Translate app={APP} id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Controller
                    name="description"
                    control={control}
                    rules={{
                        required: 'field_required',
                    }}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                            <InputLabel margin="dense" htmlFor={field.name}>
                                <Translate app={APP} id="description" />
                            </InputLabel>
                            <OutlinedInput
                                {...field}
                                multiline
                                rows={3}
                                id={field.name}
                                label={<Translate app={APP} id="description" />}
                            />
                            {invalid && isTouched && error?.message && (
                                <FormHelperText error>
                                    <Translate app={APP} id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <CreatePlannedWorkNetworkElementsFormControllerConnector control={control} watch={watch} />
                <Controller
                    name="ttNumber"
                    control={control}
                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                        <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                            <InputLabel margin="dense" htmlFor={field.name}>
                                <Translate app={APP} id="tt" />
                            </InputLabel>
                            <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="tt" />} />
                            {invalid && isTouched && error?.message && (
                                <FormHelperText error>
                                    <Translate app={APP} id={error.message} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Controller
                    name="isSmsNotify"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            sx={{ marginTop: 1 }}
                            control={<Checkbox {...field} size="small" checked={field.value} />}
                            label={<Translate app={APP} id="send_sms_notify" />}
                        />
                    )}
                />
            </form>
            <Box sx={{ paddingTop: 2 }}>
                <Grid container spacing={2} direction="row">
                    <Grid item>
                        <LoadingButton
                            disabled={!isValid}
                            disableElevation
                            size="small"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={false}
                            form={CREATE_PLANNED_WORK_FORM_ID}
                        >
                            <Translate app={APP} id="create" />
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="outlined" onClick={onClickSaveTemplate}>
                            <Translate app={APP} id="save_template" />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default CreatePlannedWorkForm;

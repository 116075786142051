import jwtDecode from 'jwt-decode';
import { AccessTokenPayload, AuthTokenPayload } from 'types';

export function verifyToken(token: string | null) {
    if (token) {
        try {
            const tokenPayload = jwtDecode<AccessTokenPayload | AuthTokenPayload>(token);

            return tokenPayload.exp > Date.now() / 1000;
        } catch (error) {
            return false;
        }
    }

    return false;
}

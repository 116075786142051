import { AppConfig } from '../index';
import { APP } from './constants';
import KeyCoordinators from './key-coordinators';
import locale from './locales/locale';

const app: AppConfig = {
    name: APP,
    element: () => <KeyCoordinators />,
    locale,
};

export default app;

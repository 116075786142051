import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent, { cardContentClasses } from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { APP } from '../../../constants';

import Translate from 'components/extended/translate';

type ManagementCardProps = {
    role: string;
    fullName: string;
    today: string | null;
    phone: string;
    altPhone: string;
};

const PositionViewManagementCard: React.FC<ManagementCardProps> = (props) => {
    const { role, fullName, today, phone, altPhone } = props;

    return (
        <Card variant="outlined">
            <CardContent sx={{ padding: 2, [`&.${cardContentClasses.root}:last-child`]: { paddingBottom: 2 } }}>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        <Typography variant="body2" color="text.secondary">
                            {role}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">{fullName}</Typography>
                    </Grid>
                    {today !== null && (
                        <Grid item>
                            <Typography variant="body2" color="info.main">
                                {today}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography>
                                    <Translate app={APP} id="main_phone_number" />
                                    {`: ${phone}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Translate app={APP} id="additional_number" />
                                    {`: ${altPhone}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PositionViewManagementCard;

import * as React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';

import useAppSelector from 'hooks/use-app-selector';
import MainLayout from 'layouts/main-layout';
import MinimalLayout from 'layouts/minimal-layout';
import NavMotion from 'layouts/nav-motion';
import appSlice from 'store/app-slice';
import AuthPage from 'views/auth-pages/auth-page';
import LoginPage from 'views/auth-pages/login-page';

const Routes: React.FC = () => {
    const auth = useAppSelector(appSlice.selectors.authSelector);

    switch (auth) {
        case 'authorized':
            return (
                <ReactRoutes>
                    <Route path="*" element={<MainLayout />} />
                </ReactRoutes>
            );
        case 'authenticated':
            return (
                <ReactRoutes>
                    <Route
                        element={
                            <NavMotion>
                                <MinimalLayout />
                            </NavMotion>
                        }
                    >
                        <Route path="*" element={<AuthPage />} />
                    </Route>
                </ReactRoutes>
            );
    }

    return (
        <ReactRoutes>
            <Route
                element={
                    <NavMotion>
                        <MinimalLayout />
                    </NavMotion>
                }
            >
                <Route path="*" element={<LoginPage />} />
            </Route>
        </ReactRoutes>
    );
};

export default Routes;

import { dialogClasses } from '@mui/material/Dialog';

import { ConfirmOptions } from 'material-ui-confirm';

const ConfirmOptionsCustom = (
    options: Pick<ConfirmOptions, 'title' | 'description' | 'confirmationText' | 'cancellationText'>,
): ConfirmOptions => ({
    title: options.title,
    description: options.description,
    confirmationText: options.confirmationText,
    cancellationText: options.cancellationText,
    dialogProps: { sx: { [`.${dialogClasses.paper}`]: { padding: 0 } }, fullWidth: false },
    titleProps: {
        sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    },
    confirmationButtonProps: { variant: 'contained', size: 'small' },
    cancellationButtonProps: { variant: 'outlined', color: 'primary', size: 'small' },
});

export default ConfirmOptionsCustom;

import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import ChangePasswordForm from './auth-forms/change-password-form';
import VerifyPhoneForm from './auth-forms/verify-phone-form';
import AuthCardWrapper from './wrapers/auth-card-wrapper';
import AuthWrapper from './wrapers/auth-wrapper';

import Translate from 'components/extended/translate';
import Logo from 'components/logo';
import useAppDispatch from 'hooks/use-app-dispatch';
import useAppSelector from 'hooks/use-app-selector';
import { useGetAccessTokenMutation } from 'services/auth/auth-services';
import appSlice from 'store/app-slice';
import { verifyToken } from 'utils/token';

type FormNames = 'change_password' | 'verify_phone' | null;

const AuthPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [activeForm, setActiveForm] = React.useState<FormNames>(null);
    const [phone, setPhone] = React.useState<string>('');
    const authToken = useAppSelector(appSlice.selectors.authTokenSelector);
    const [getAccessToken] = useGetAccessTokenMutation();

    const handleLogout = React.useCallback(() => {
        dispatch(appSlice.actions.logout());
    }, [dispatch]);

    const initialization = React.useCallback(async () => {
        if (authToken && verifyToken(authToken)) {
            const res = await getAccessToken();

            if ('data' in res) {
                const {
                    data: { status, data },
                } = res;

                if (status === 'Success') {
                    if (data.needVerifyPhone) {
                        setActiveForm(() => {
                            setPhone(data.phone ?? '');
                            return 'verify_phone';
                        });
                    } else if (data.needChangePassword) {
                        setActiveForm('change_password');
                    }
                }
            }
        }
    }, [authToken, getAccessToken]);

    const switchForm = (_activeForm: FormNames) => {
        switch (_activeForm) {
            case 'change_password':
                return <ChangePasswordForm onSuccessSubmit={reInitialization} />;
            case 'verify_phone':
                return <VerifyPhoneForm defaultValues={{ phone }} onSuccessSubmit={reInitialization} />;
        }

        return null;
    };

    const reInitialization = React.useCallback(() => {
        initialization();
    }, [initialization]);

    React.useEffect(() => {
        initialization();
    }, [initialization]);

    return activeForm === null ? null : (
        <AuthWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: 'calc(100vh - 68px)' }}
                    >
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Logo />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography variant="caption" fontSize="16px" textAlign="center">
                                                        <Translate id={`${activeForm}_title`} />
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {switchForm(activeForm)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ mt: 1 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ mt: 2 }}>
                                            <Button
                                                onClick={handleLogout}
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                <Translate id="logout" />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default AuthPage;

import { PaletteMode } from '@mui/material';

import { Property } from 'csstype';
import { createAction } from 'redux-act';

export const setThemeMode = createAction<PaletteMode>('@customization/SET_THEME_MODE');
export const presetColors = createAction<string>('@customization/PRESET_COLORS');
export const themeLocale = createAction<string>('@customization/THEME_LOCALE');
export const setMenuOpen = createAction<boolean>('@customization/SET_MENU');
export const setFontFamily = createAction<Property.FontFamily>('@customization/SET_FONT_FAMILY');
export const setBorderRadius = createAction<number | undefined>('@customization/SET_BORDER_RADIUS');
export const setOutlinedFilled = createAction<boolean>('@customization/SET_OUTLINED_FILLED');

import { FrontLogRequestModel } from 'services/fluentd/generated/fluentd-services';
import { client } from 'utils/client';
import { v4 as uuid } from 'uuid';

const log = (data: FrontLogRequestModel) => ({
    client: {
        userAgent: client.getUserAgentLowerCase(),
        browser: {
            browser: client.getBrowser(),
            version: client.getBrowserVersion(),
            majorVersion: client.getBrowserMajorVersion(),
        },
        engine: {
            engine: client.getEngine(),
            version: client.getEngineVersion(),
        },
        os: {
            os: client.getOS(),
            version: client.getOSVersion(),
        },
        device: {
            device: client.getDevice(),
            type: client.getDeviceType(),
            vendor: client.getDeviceVendor(),
        },
        screen: {
            print: client.getScreenPrint(),
            currentResolution: client.getCurrentResolution(),
            availableResolution: client.getAvailableResolution(),
        },
        timeZone: client.getTimeZone(),
    },
    location: window.location,
    ...data,
    id: data.id ?? uuid(),
});

const error = (data: FrontLogRequestModel) =>
    log({
        level: 'ERROR',
        ...data,
    });

const warn = (data: FrontLogRequestModel) =>
    log({
        level: 'WARNING',
        ...data,
    });

const info = (data: FrontLogRequestModel) =>
    log({
        level: 'INFO',
        ...data,
    });

const logger = {
    error,
    warn,
    info,
};

export default logger;

import * as React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import { APP } from '../constants';
import TopologyReactGraphVis from './topology-react-graph-vis';

import Translate from 'components/extended/translate';
import { useTopologyGetQuery } from 'services/topology/topology-services';

type TopologyReactGraphVisConnectorProps = {
    branchPrefix: string;
};

const TopologyReactGraphVisConnector: React.FC<TopologyReactGraphVisConnectorProps> = (props) => {
    const { branchPrefix } = props;
    const { data, isFetching, isError } = useTopologyGetQuery({ branchPrefix });

    if (isError)
        return (
            <Alert variant="filled" severity="error" sx={{ justifyContent: 'center' }}>
                <Translate app={APP} id="error_loading_data" />
            </Alert>
        );
    // TODO подумать над лоадырем при загрузке
    if (data === undefined || isFetching) return null;

    if (data.data.nodes.length === 0)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Translate app={APP} id="not_data" />
            </Box>
        );

    return <TopologyReactGraphVis data={data.data} branchPrefix={branchPrefix} />;
};

export default TopologyReactGraphVisConnector;

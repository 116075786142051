import * as React from 'react';

import KeyCoordinatorsForm from '../forms/key-coordinators-form';
import { getGroupedBranchesByRegion } from './key-coordinators-form-add-connector';

import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';

type KeyCoordinatorsFormEditConnectorProps = {
    onClose: () => void;
};

const data = {
    region: 3,
    branch: 'VV',
    isRequired: false,
    coordinator: { id: 1, name: 'Иванов Иван Иванович' },
};

const KeyCoordinatorsFormEditConnector: React.FC<KeyCoordinatorsFormEditConnectorProps> = (props) => {
    const { onClose } = props;

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const groupedBranchesByRegion = React.useMemo(
        () => getGroupedBranchesByRegion(regionAndBranchAvailableValues?.data),
        [regionAndBranchAvailableValues],
    );

    // TODO тут должен быть запрос за данными

    const handleSubmit = React.useCallback(() => {
        // TODO тут должна запускаться мутация
        onClose();
    }, [onClose]);

    if (regionAndBranchAvailableValues?.data === undefined) return null;

    return (
        <KeyCoordinatorsForm
            onClose={onClose}
            submitCallback={handleSubmit}
            isLoading={false}
            initialValues={data}
            groupedBranches={groupedBranchesByRegion}
            regions={regionAndBranchAvailableValues.data}
        />
    );
};

export default KeyCoordinatorsFormEditConnector;

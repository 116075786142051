import * as React from 'react';

import { APP } from '../../constants';
import {
    PlannedWorksFilterState,
    plannedWorksIsApplyLabelsValues,
    plannedWorksIsArchiveLabelsValues,
    plannedWorksOrderAvailableValues,
} from '../../hooks/use-planned-works-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDateInterval from 'components/extended/data-grid/filter/filter-date-interval';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import FilterRadio from 'components/extended/data-grid/filter/filter-radio';
import FilterSwitch from 'components/extended/data-grid/filter/filter-switch';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const statusesAvailableValues = [
    {
        id: 1,
        name: 'Аварийный откат',
    },
    {
        id: 2,
        name: 'Актуализация БД',
    },
    {
        id: 3,
        name: 'Визирование',
    },
    {
        id: 4,
        name: 'Завершена',
    },
    {
        id: 5,
        name: 'Завершена, подтвердить',
    },
    {
        id: 6,
        name: 'Исполняется',
    },
    {
        id: 7,
        name: 'Отменена',
    },
    {
        id: 8,
        name: 'Планируется',
    },
    {
        id: 9,
        name: 'Подтверждение восстановления',
    },
    {
        id: 10,
        name: 'Согласование административным руководителем',
    },
];

const objectTypesAvailableValues = [
    {
        id: 1,
        name: 'IMS',
        children: [
            { id: 1, name: 'Аварийно восстановительные работы' },
            { id: 2, name: 'Ввод в эксплуатацию нового оборудования' },
        ],
    },
    {
        id: 2,
        name: 'NSS(CS Core)',
        children: [
            { id: 3, name: 'Аварийно восстановительные работы' },
            { id: 4, name: 'Ввод в эксплуатацию нового оборудования' },
        ],
    },
];

const PlannedWorksFilterDialog: React.FC<FilterDialogProps<PlannedWorksFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
        isError,
        setIsError,
    } = useManagementFilterDialog<PlannedWorksFilterState>(props);

    // TODO тут должен быть запрос за словарями

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
            isError={isError}
        >
            <FilterRadio
                field="order"
                value={localFilterValues.order}
                label={<Translate app={APP} id="order" />}
                expanded={expandedField === 'order'}
                setLocalFilterValues={setLocalFilterValues}
                availableValues={plannedWorksOrderAvailableValues}
            />
            <FilterCheckBox
                field="statuses"
                value={localFilterValues.statuses}
                label={<Translate app={APP} id="status" />}
                expanded={expandedField === 'statuses'}
                availableValues={statusesAvailableValues}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterSwitch
                field="isApply"
                value={localFilterValues.isApply}
                label={<Translate app={APP} id="hide_is_apply" />}
                labelsValues={plannedWorksIsApplyLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="isArchive"
                value={localFilterValues.isArchive}
                label={<Translate app={APP} id="show_archived" />}
                labelsValues={plannedWorksIsArchiveLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterCheckBoxTree
                field="objectTypes"
                value={localFilterValues.objectTypes}
                label={<Translate app={APP} id="object_types" />}
                expanded={expandedField === 'objectTypes'}
                availableValues={objectTypesAvailableValues}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterDateInterval
                startDateField="startDate"
                endDateField="endDate"
                startDateValue={localFilterValues.startDate}
                endDateValue={localFilterValues.endDate}
                startDateLabel={<Translate id="with" />}
                endDateLabel={<Translate id="before" />}
                expanded={expandedField === 'startDate' || expandedField === 'endDate'}
                label={<Translate app={APP} id="date" />}
                setLocalFilterValues={setLocalFilterValues}
                setIsError={setIsError}
            />
        </FilterDialog>
    );
};

export default PlannedWorksFilterDialog;

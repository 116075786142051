import * as React from 'react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { APP } from '../constants';
import CreatePlannedWorkLoadTemplateDialog from './dialogs/create-planned-work-load-template-dialog';
import CreatePlannedWorkSaveTemplateFormDialog from './dialogs/create-planned-work-save-template-form-dialog';
import CreatePlannedWorkForm from './forms/create-planned-work-form';

import MainCard from 'components/cards/main-card';
import Translate from 'components/extended/translate';
import { useModal } from 'mui-modal-provider';

export type CreatePlannedWorkFormInputs = {
    regions: number[];
    branches: string[];
    workPlace: { id: number; name: string }[];
    isAutoHall: boolean;
    autoHall: { id: number; name: string }[];
    object: number | '';
    timeSpending: number | '';
    type: { id: number; minCreateTime: number; minCreateTimeB2b: number; maxWorkingTime: number } | '';
    startDateWork: Date | null;
    endDateWork: Date | null;
    isImpactService: boolean;
    startDateImpactService: Date | null;
    endDateImpactService: Date | null;
    technicalInfluence: string;
    isInfluenceB2b: boolean;
    clientId: string;
    clientInfluence: string;
    executors: { id: number; name: string }[];
    customer: { id: number; name: string } | null;
    name: string;
    description: string;
    networkElements: { id: number; name: string }[];
    ttNumber: number | '';
    isSmsNotify: boolean;
};

const initialFormValues: CreatePlannedWorkFormInputs = {
    regions: [],
    branches: [],
    workPlace: [],
    isAutoHall: false,
    autoHall: [],
    object: '',
    timeSpending: '',
    type: '',
    startDateWork: null,
    endDateWork: null,
    isImpactService: false,
    startDateImpactService: null,
    endDateImpactService: null,
    technicalInfluence: '',
    isInfluenceB2b: false,
    clientId: '',
    clientInfluence: '',
    executors: [],
    customer: null,
    name: '',
    description: '',
    networkElements: [],
    ttNumber: '',
    isSmsNotify: false,
};

const CreatePlannedWorkMain: React.FC = () => {
    const { showModal } = useModal();
    const [formValues, setFormValues] = React.useState<CreatePlannedWorkFormInputs>(initialFormValues);

    const handleSaveTemplate = React.useCallback(() => {
        const modal = showModal(CreatePlannedWorkSaveTemplateFormDialog, {
            onClose: () => {
                modal.hide();
            },
        });
    }, [showModal]);

    const handleLoadWork = React.useCallback(() => {
        const modal = showModal(CreatePlannedWorkLoadTemplateDialog, {
            onClose: () => {
                modal.hide();
            },
            setFormValues,
        });
    }, [showModal]);

    return (
        <Container maxWidth="md">
            <MainCard>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Button size="small" variant="contained" onClick={handleLoadWork}>
                            <Translate app={APP} id="load_work" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <CreatePlannedWorkForm formValues={formValues} onClickSaveTemplate={handleSaveTemplate} />
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
};

export default CreatePlannedWorkMain;

import * as React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type DataGridLoaderProps = {
    visible: boolean;
    cancelCallback?: () => void;
    text?: React.ReactNode;
};

const LoaderWrapper = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.4)',
});

const DataGridLoader: React.FC<DataGridLoaderProps> = (props) => {
    const { visible, cancelCallback, text } = props;
    if (visible) {
        return (
            <LoaderWrapper>
                <CircularProgress />
                {text === undefined ? null : (
                    <Typography variant="h5" sx={{ marginTop: '1em' }}>
                        {text}
                    </Typography>
                )}
                {cancelCallback === undefined ? null : (
                    <Button variant="contained" disableElevation sx={{ marginTop: '1em' }} onClick={cancelCallback}>
                        Cancel
                    </Button>
                )}
            </LoaderWrapper>
        );
    }
    return null;
};

export default DataGridLoader;

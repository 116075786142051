import * as React from 'react';

import { FilterToolbarProps } from 'components/extended/data-grid/filter/types';

type UseManagementFilterToolbarProps<State> = Pick<FilterToolbarProps<State>, 'setOpen' | 'setExpandedField'>;

const useManagementFilterToolbar = <State>(props: UseManagementFilterToolbarProps<State>) => {
    const { setOpen, setExpandedField } = props;

    const onClickFilterItem = React.useCallback(
        (field: keyof State) => () => {
            setOpen(true);
            setExpandedField(field);
        },
        [setExpandedField, setOpen],
    );

    const onClickOpenFilter = React.useCallback(() => setOpen(true), [setOpen]);

    return {
        onClickFilterItem,
        onClickOpenFilter,
    };
};

export default useManagementFilterToolbar;

import * as React from 'react';
import { useIntl } from 'react-intl';

import Icon from '@mui/material/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import useAppDispatch from 'hooks/use-app-dispatch';
import useGlobalTabs from 'hooks/use-global-tabs';
import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';
import customizationSlice from 'store/customization';
import { LinkTarget } from 'types';

type NavItemProps = {
    item: MenuItemResponseModel;
    level: number;
};

const NavItem: React.FC<NavItemProps> = (props) => {
    const { item, level } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { createTab } = useGlobalTabs();

    const itemTitle = intl.formatMessage({ id: item.name, defaultMessage: item.name });
    const itemShortTitle =
        item.shortName !== '' ? intl.formatMessage({ id: item.shortName, defaultMessage: item.shortName }) : '';

    const itemIcon = (
        <Icon
            style={{
                fontSize: '1rem',
            }}
        >
            {item?.icon ?? 'do_not_disturb_alt'}
        </Icon>
    );

    const itemTarget: LinkTarget = '_self';

    let listItemProps: {
        component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
        // eslint-disable-next-line
    } = {
        component: React.forwardRef((_props, ref) => <span ref={ref} {..._props} />),
    };
    if (item.isExternal) {
        listItemProps = { component: 'a', href: item.path, target: itemTarget };
    }

    const itemHandler = React.useCallback(() => {
        createTab({
            name: itemShortTitle,
            app: item.application,
            path: item.path,
            isUnique: item.isUnique,
            icon: item.icon,
        });
        dispatch(customizationSlice.actions.setMenuOpen(false));
    }, [createTab, dispatch, item, itemShortTitle]);

    return item.isVisible ? (
        <ListItemButton
            {...listItemProps}
            sx={{
                borderRadius: 0,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${16 + (level - 1) * 24}px`,
            }}
            onClick={itemHandler}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: 30 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="body1" color="inherit">
                        {itemTitle}
                    </Typography>
                }
            />
        </ListItemButton>
    ) : null;
};

export default NavItem;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Active alarms',
        ru: 'Активные аварии',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Объект',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_element`]: {
        en: 'Element',
        ru: 'Элемент',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Название',
    },
    [`${APP}_alarm_text`]: {
        en: 'Alarm text',
        ru: 'Текст аварии',
    },
    [`${APP}_events`]: {
        en: 'Events',
        ru: 'События',
    },
    [`${APP}_comment_text`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_date_time`]: {
        en: 'Insert date',
        ru: 'Время появления',
    },
    [`${APP}_equipment_type`]: {
        en: 'Equipment type',
        ru: 'Тип оборудования',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_create_event`]: {
        en: 'Create event',
        ru: 'Создать событие',
    },
    [`${APP}_bind_alarm`]: {
        en: 'Link accident to an event',
        ru: 'Привязать аварию к событию',
    },
    [`${APP}_bind_alarms`]: {
        en: 'Link accidents to an event',
        ru: 'Привязать аварии к событию',
    },
    [`${APP}_unbind_alarm`]: {
        en: 'Unbind alarm',
        ru: 'Открепить аварию от события',
    },
    [`${APP}_create_event_form`]: {
        en: 'Create event',
        ru: 'Создание события',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_priority_is_required`]: {
        en: 'Priority is required',
        ru: 'Приоритет обязателен',
    },
    [`${APP}_event_is_required`]: {
        en: 'Event is required',
        ru: 'Выбор события обязателен',
    },
    [`${APP}_closing_time`]: {
        en: 'Closing time',
        ru: 'Время закрытия',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_cause`]: {
        en: 'Cause',
        ru: 'Причина',
    },
    [`${APP}_cause_is_required`]: {
        en: 'Cause is required',
        ru: 'Причина обязательна',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_description_is_required`]: {
        en: 'Description is required',
        ru: 'Описание обязательно',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_you_want_unbind_alarm`]: {
        en: 'Are you sure you want to unpin reserve from events?',
        ru: 'Вы действительно хотите открепить аварию от события?',
    },
    [`${APP}_you_want_unbind_alarms`]: {
        en: 'Are you sure you want to unpin reserves from events?',
        ru: 'Вы действительно хотите открепить аварии от события?',
    },
    [`${APP}_unbind`]: {
        en: 'Unbind',
        ru: 'Открепить',
    },
    [`${APP}_unbind_alarm_button`]: {
        en: 'Unbind alarm',
        ru: 'Открепить аварию',
    },
    [`${APP}_unbind_alarms_button`]: {
        en: 'Unbind alarms',
        ru: 'Открепить аварии',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_selected`]: {
        en: 'Selected',
        ru: 'Выбрано',
    },
    [`${APP}_show`]: {
        en: 'Show',
        ru: 'Отображаются',
    },
    [`${APP}_hide`]: {
        en: 'Hide',
        ru: 'Скрыты',
    },
    [`${APP}_all_alarms`]: {
        en: 'All alarms',
        ru: 'Все аварии',
    },
    [`${APP}_yes`]: {
        en: 'Yes',
        ru: 'Да',
    },
    [`${APP}_system`]: {
        en: 'System',
        ru: 'Система',
    },
    [`${APP}_search_by_object`]: {
        en: 'Search by object',
        ru: 'Поиск по объекту',
    },
    [`${APP}_is_no_filter`]: {
        en: 'Do not display filtered',
        ru: 'Не отображать фильтрованные',
    },
    [`${APP}_is_no_event`]: {
        en: 'Only alarms without events',
        ru: 'Только аварии без событий',
    },
    [`${APP}_equipment_type`]: {
        en: 'Equipment type',
        ru: 'Тип оборудования',
    },
};

export default locale;

import { FEEDBACK_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import {
    FeedbackAppResponseModelApiResponse,
    FeedbackAppResponseModelIEnumerableApiResponse,
    FeedbackCommentResponseModelIEnumerableApiResponse,
    FeedbackFeedbackChangeStatusRequestModel,
    FeedbackFeedbackCommentRequestModel,
    FeedbackFeedbackCountByAppResponseModelIEnumerableApiResponse,
    FeedbackFeedbackCountByStatusResponseModelIEnumerableApiResponse,
    FeedbackFeedbackFilterValueRequestModelFeedbackColumnsFilterRequestModel,
    FeedbackFeedbackLikeActionRequestModel,
    FeedbackFeedbackRequestModel,
    FeedbackFeedbackResponseModelApiResponse,
    FeedbackFeedbackResponseModelIEnumerableApiResponse,
    FeedbackFeedbackStatusResponseModelApiResponse,
    FeedbackFeedbackStatusResponseModelIEnumerableApiResponse,
    FeedbackStringApiResponse,
} from './feedback-models';
import {
    FEEDBACK_APP_COUNT,
    FEEDBACK_COMMENT_LIST,
    FEEDBACK_DICTIONARY_APP,
    FEEDBACK_DICTIONARY_STATUS,
    FEEDBACK_LIST,
    FEEDBACK_STATUS_COUNT,
} from './tags';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const feedbackServices = createApi({
    reducerPath: 'feedbackServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: FEEDBACK_SERVICE_URL,
    }),
    tagTypes: [
        FEEDBACK_DICTIONARY_APP,
        FEEDBACK_DICTIONARY_STATUS,
        FEEDBACK_LIST,
        FEEDBACK_APP_COUNT,
        FEEDBACK_STATUS_COUNT,
        FEEDBACK_COMMENT_LIST,
    ],
    endpoints: (build) => ({
        feedbackStatusGetList: build.query<FeedbackFeedbackStatusResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Status/GetList`, method: 'GET' }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id }) => ({ type: FEEDBACK_DICTIONARY_STATUS, id } as const)),
                          { type: FEEDBACK_DICTIONARY_STATUS, id: 'LIST' },
                      ]
                    : [{ type: FEEDBACK_DICTIONARY_STATUS, id: 'LIST' }],
        }),
        feedbackStatusGetById: build.query<FeedbackFeedbackStatusResponseModelApiResponse, number>({
            query: (id) => ({ url: `/api/Dictionary/Status/${id}`, method: 'GET' }),
            providesTags: (result, error, id) => [{ type: FEEDBACK_DICTIONARY_STATUS, id }],
        }),
        feedbackAppGetList: build.query<FeedbackAppResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/App/GetList`, method: 'GET' }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id }) => ({ type: FEEDBACK_DICTIONARY_APP, id } as const)),
                          { type: FEEDBACK_DICTIONARY_APP, id: 'LIST' },
                      ]
                    : [{ type: FEEDBACK_DICTIONARY_APP, id: 'LIST' }],
        }),
        feedbackAppGetById: build.query<FeedbackAppResponseModelApiResponse, number>({
            query: (id) => ({ url: `/api/Dictionary/App/${id}`, method: 'GET' }),
            providesTags: (result, error, id) => [{ type: FEEDBACK_DICTIONARY_APP, id }],
        }),
        feedbackStatusCountGetList: build.query<
            FeedbackFeedbackCountByStatusResponseModelIEnumerableApiResponse,
            number
        >({
            query: (appId) => ({ url: `/api/Feedback/GetCountByStatus/${appId}`, method: 'GET' }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ statusId: id }) => ({ type: FEEDBACK_STATUS_COUNT, id } as const)),
                          { type: FEEDBACK_STATUS_COUNT, id: 'LIST' },
                      ]
                    : [{ type: FEEDBACK_STATUS_COUNT, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        feedbackAppCountGetList: build.query<FeedbackFeedbackCountByAppResponseModelIEnumerableApiResponse, number>({
            query: (statusId) => ({ url: `/api/Feedback/GetCountByApp/${statusId}`, method: 'GET' }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ appId: id }) => ({ type: FEEDBACK_APP_COUNT, id } as const)),
                          { type: FEEDBACK_APP_COUNT, id: 'LIST' },
                      ]
                    : [{ type: FEEDBACK_APP_COUNT, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        feedbackAdd: build.mutation<FeedbackFeedbackResponseModelIEnumerableApiResponse, FeedbackFeedbackRequestModel>({
            query: (queryArg) => ({ url: `/api/Feedback`, method: 'POST', body: queryArg }),
            invalidatesTags: [{ type: FEEDBACK_LIST, id: 'LIST' }],
        }),
        feedbackGetList: build.query<
            FeedbackFeedbackResponseModelIEnumerableApiResponse,
            FeedbackFeedbackFilterValueRequestModelFeedbackColumnsFilterRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Feedback/GetList`,
                method: 'POST',
                body: queryArg,
            }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id }) => ({ type: FEEDBACK_LIST, id } as const)),
                          { type: FEEDBACK_LIST, id: `LIST` },
                      ]
                    : [{ type: FEEDBACK_LIST, id: 'LIST' }],
        }),
        feedbackGetById: build.query<FeedbackFeedbackResponseModelApiResponse, number>({
            query: (id) => ({ url: `/api/Feedback/${id}`, method: 'GET' }),
            providesTags: (result, error, id) => [{ type: FEEDBACK_LIST, id }],
        }),
        feedbackLike: build.mutation<FeedbackStringApiResponse, FeedbackFeedbackLikeActionRequestModel>({
            query: (queryArg) => ({ url: `/api/Feedback/Like`, method: 'POST', body: queryArg }),
            // TODO: временно отключен кэш
            // invalidatesTags: (result, error, { feedbackId }) => [{ type: FEEDBACK_LIST, id: feedbackId }],
        }),
        feedbackCommentAdd: build.mutation<FeedbackStringApiResponse, FeedbackFeedbackCommentRequestModel>({
            query: (queryArg) => ({ url: `/api/Feedback/Comment/Add`, method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, queryArg) => [
                { type: FEEDBACK_COMMENT_LIST, id: `LIST-${queryArg.feedbackId}` },
            ],
        }),
        feedbackCommentGetList: build.query<FeedbackCommentResponseModelIEnumerableApiResponse, number>({
            query: (id) => ({
                url: `/api/Feedback/Comment/GetList/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) =>
                result && result?.data
                    ? [
                          ...result.data.map((el) => ({ type: FEEDBACK_COMMENT_LIST, id: el.id } as const)),
                          { type: FEEDBACK_COMMENT_LIST, id: `LIST-${id}` },
                      ]
                    : [{ type: FEEDBACK_COMMENT_LIST, id: `LIST-${id}` }],
        }),
        feedbackChangeStatus: build.mutation<
            FeedbackFeedbackResponseModelApiResponse,
            FeedbackFeedbackChangeStatusRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Feedback/ChangeStatus`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) => [{ type: FEEDBACK_LIST, id: queryArg.id }],
        }),
    }),
});

export const {
    useFeedbackStatusGetListQuery,
    useFeedbackAppGetListQuery,
    useFeedbackAddMutation,
    useFeedbackGetListQuery,
    useFeedbackAppCountGetListQuery,
    useFeedbackStatusCountGetListQuery,
    useFeedbackStatusGetByIdQuery,
    useFeedbackAppGetByIdQuery,
    useFeedbackLikeMutation,
    useFeedbackGetByIdQuery,
    useFeedbackCommentAddMutation,
    useFeedbackCommentGetListQuery,
    useFeedbackChangeStatusMutation,
} = feedbackServices;

import * as React from 'react';

import TroubleTicketViewCommentInput from '../trouble-ticket-view-comment-input';

import { useSmartQueueCommentAddMutation } from 'services/smart-queue/smart-queue-services';

type TroubleTicketViewCommentInputConnectorProps = {
    ttNumber: number;
};

const TroubleTicketViewCommentInputConnector: React.FC<TroubleTicketViewCommentInputConnectorProps> = (props) => {
    const { ttNumber } = props;

    const [addComment, { isLoading }] = useSmartQueueCommentAddMutation();

    const handleAddComment = React.useCallback(
        (comment: string) => () => {
            addComment({
                number: ttNumber,
                comment,
            });
        },
        [addComment, ttNumber],
    );

    return <TroubleTicketViewCommentInput handleSendComment={handleAddComment} isLoading={isLoading} />;
};

export default TroubleTicketViewCommentInputConnector;

import * as React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MUILink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PROMO_PAGE, SUPPORT_EMAIL } from '../../constants';
import LoginForm, { LoginFormInputs } from './auth-forms/login-form';
import ResetPasswordForm from './auth-forms/reset-password-form';
import SecondFactorForm from './auth-forms/second-factor-form';
import AuthCardWrapper from './wrapers/auth-card-wrapper';
import AuthWrapper from './wrapers/auth-wrapper';

import Translate from 'components/extended/translate';
import Logo from 'components/logo';

type FormNames = 'login' | 'second_factor' | 'reset_password';

const LoginPage: React.FC = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [activeForm, setActiveForm] = React.useState<FormNames>('login');
    const [loginFormInputsValues, setLoginFormInputsValues] = React.useState<LoginFormInputs>({
        username: '',
        password: '',
    });

    const handleBack = React.useCallback(() => setActiveForm('login'), []);
    const handleSuccessLoginFormSubmit = React.useCallback((data: LoginFormInputs) => {
        setLoginFormInputsValues(data);
        setActiveForm('second_factor');
    }, []);

    const handleResetPassword = React.useCallback(() => setActiveForm('reset_password'), []);

    const handleSuccessResetPasswordFormSubmit = React.useCallback(() => {
        setActiveForm('login');
    }, []);

    const switchForm = (_activeForm: FormNames) => {
        switch (_activeForm) {
            case 'login':
                return (
                    <LoginForm onSuccessSubmit={handleSuccessLoginFormSubmit} defaultValues={loginFormInputsValues} />
                );
            case 'second_factor':
                return <SecondFactorForm onBack={handleBack} loginFormInputsValues={loginFormInputsValues} />;
            case 'reset_password':
                return (
                    <ResetPasswordForm
                        onBack={handleBack}
                        onSuccessSubmit={handleSuccessResetPasswordFormSubmit}
                        defaultValues={loginFormInputsValues}
                    />
                );
        }

        return null;
    };

    return (
        <AuthWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: 'calc(100vh - 68px)' }}
                    >
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Logo />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography variant="caption" fontSize="16px" textAlign="center">
                                                        <Translate id={`${activeForm}_title`} />
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {switchForm(activeForm)}
                                    </Grid>
                                    {activeForm !== 'reset_password' && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider sx={{ mt: 1 }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item container direction="column" alignItems="center" xs={12}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            textDecoration: 'none',
                                                            [`&:hover`]: {
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                            },
                                                        }}
                                                        onClick={handleResetPassword}
                                                    >
                                                        <Translate id="forgot_password" />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <MUILink href={PROMO_PAGE} target="_blank">
                                                <Translate id="promotion_page" />
                                            </MUILink>
                                            <MUILink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MUILink>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default LoginPage;

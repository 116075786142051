import * as React from 'react';

import { DateTimeValidationError } from '@mui/lab/internal/pickers/date-time-utils';
import { DateValidationError } from '@mui/lab/internal/pickers/date-utils';

import { FilterDateStateValue } from './types';

import Translate from 'components/extended/translate';

type DateHelperTextProps = {
    startDateValue: FilterDateStateValue;
    endDateValue: FilterDateStateValue;
    error: DateValidationError | DateTimeValidationError;
};

const DateIntervalHelperText: React.FC<DateHelperTextProps> = (props) => {
    const { startDateValue, endDateValue, error } = props;

    switch (error) {
        case 'invalidDate':
            return <Translate id="invalid_date" />;
        case 'minDate':
            if (startDateValue.id && endDateValue.id) {
                return <Translate id="end_date_less_start_date" />;
            }
            return <Translate id="min_date" />;
        case 'maxDate':
            if (startDateValue.id && endDateValue.id) {
                return <Translate id="start_date_more_end_date" />;
            }
            return <Translate id="max_date" />;
        default:
            return null;
    }
};

export default DateIntervalHelperText;

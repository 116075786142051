import * as React from 'react';
import 'react-intl';

import { APP } from '../../constants';
import { TimeOnBatteriesFilterState } from '../../hooks/use-time-on-batteries-filter';

import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDateTimeInterval from 'components/extended/data-grid/filter/filter-date-time-interval';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const TimeOnBatteriesFilterDialog: React.FC<FilterDialogProps<TimeOnBatteriesFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
        isError,
        setIsError,
    } = useManagementFilterDialog<TimeOnBatteriesFilterState>(props);

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
            isError={isError}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterDateTimeInterval
                startDateField="startDateTimeDown"
                startDateValue={localFilterValues.startDateTimeDown}
                startDateLabel={<Translate id="with" />}
                endDateField="endDateTimeDown"
                endDateValue={localFilterValues.endDateTimeDown}
                endDateLabel={<Translate id="before" />}
                label={<Translate app={APP} id="bs_down_date" />}
                expanded={expandedField === 'startDateTimeDown' || expandedField === 'endDateTimeDown'}
                setLocalFilterValues={setLocalFilterValues}
                setIsError={setIsError}
            />
            <FilterDateTimeInterval
                startDateField="startDatePowerOff"
                startDateValue={localFilterValues.startDatePowerOff}
                startDateLabel={<Translate id="with" />}
                endDateField="endDatePowerOff"
                endDateValue={localFilterValues.endDatePowerOff}
                endDateLabel={<Translate id="before" />}
                label={<Translate app={APP} id="power_off_date" />}
                expanded={expandedField === 'startDatePowerOff' || expandedField === 'endDatePowerOff'}
                setLocalFilterValues={setLocalFilterValues}
                setIsError={setIsError}
            />
        </FilterDialog>
    );
};

export default TimeOnBatteriesFilterDialog;

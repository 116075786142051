import * as React from 'react';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import usePlannedWorksFilter from '../hooks/use-planned-works-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { APP as PLANNED_WORK_VIEW_APP } from 'sub-apps/planned-work-view/constants';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

const rows = [
    {
        id: 1,
        endDate: '2022-09-05T16:00:25.440Z',
        branch: 'Пермский филиал',
        number: '1494093',
        initiator: 'Белинская Анастасия Леонидовна',
        subjectWork:
            'Резервирование сервисов agg2-Dzerzhinskogo1k27.PERM Eth-Trunk1 на agg1-Dzerzhinskogo1k27.PERM PO3291267',
        object: 'TN SDH/PDH/РРЛ/MEN/SAT региональная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 1,
        status: 'Планируется',
        type: 'Модернизация каналов при согласии клиентов',
        isVising: true,
        isVisitorViewed: true,
    },
    {
        id: 2,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 2,
        status: 'Исполняется',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: true,
        isVisitorViewed: false,
    },
    {
        id: 3,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 3,
        status: 'Завершена, подтвердите',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: true,
    },
    {
        id: 4,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 4,
        status: 'Отменена',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 5,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 5,
        status: 'Аварийный откат',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 6,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 6,
        status: 'Черновик',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 7,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 7,
        status: 'Визирование',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 8,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 8,
        status: 'Согласование административным руководителем',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 9,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 9,
        status: 'Актуализация БД',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
    {
        id: 10,
        branch: 'Московский филиал',
        endDate: '2022-09-05T07:00:00.000Z',
        number: '1493742',
        initiator: 'Шлыков Олег Юрьевич',
        subjectWork:
            'PO 3394920. Расширение стыка PE-RS DirectNet на 50G (0200-53) Москва, Бутлерова ул, д 7, эт.7 (0200-1063) Москва, Бутлерова ул, д 7, эт 10',
        object: 'TN IPBB фиксированная',
        startDate: '2022-09-05T07:00:00.000Z',
        statusId: 10,
        status: 'Подтверждение восстановления',
        type: 'Работы на сетевых линках при полном резервировании',
        isVising: false,
        isVisitorViewed: false,
    },
];

const plannedStatusClassName = 'planned-status';
const executedStatusClassName = 'executed-status';
const completedConfirmStatusClassName = 'completed-confirm-status';
const completedStatusClassName = 'completed-status';
const cancelledStatusClassName = 'cancelled-status';
const rollbackStatusClassName = 'rollback-status';
const draftStatusClassName = 'draft-status';
const visingStatusClassName = 'vising-status';
const agreementBossStatusClassName = 'agreement-boss-status';
const actualDbStatusClassName = 'actual-db-status';
const recoveryApplyStatusClassName = 'recovery-apply-status';

const renderCellActions = (params: any) => [
    <Tooltip
        title={<Translate app={APP} id={params.row.isVisitorViewed ? 'viewed_endorser' : 'not_viewed_endorser'} />}
    >
        <Box>
            {params.row.isVisitorViewed ? (
                <VisibilityIcon
                    sx={{
                        fontSize: 20,
                    }}
                    color="primary"
                />
            ) : (
                <VisibilityOffIcon
                    sx={{
                        fontSize: 20,
                    }}
                    color="disabled"
                />
            )}
        </Box>
    </Tooltip>,
    <Tooltip title={<Translate app={APP} id={params.row.isVising ? 'approved' : 'approval'} />}>
        <Box>
            {params.row.isVising ? (
                <RadioButtonCheckedIcon
                    sx={{
                        fontSize: 20,
                    }}
                    color="primary"
                />
            ) : (
                <RadioButtonUncheckedIcon
                    sx={{
                        fontSize: 20,
                    }}
                    color="disabled"
                />
            )}
        </Box>
    </Tooltip>,
];

const PlannedWorksTable: React.FC = () => {
    const { Pagination } = usePagination(`${APP}_grid`, '5');
    const { PlannedWorksFilterDialog, PlannedWorksFilterToolbar } = usePlannedWorksFilter();

    // TODO тут должен быть запрос

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: PLANNED_WORK_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip sx={{ userSelect: 'text' }} onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_number`),
                renderCell: renderCellChip,
                field: 'number',
                flex: 0.5,
                minWidth: 80,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_subject_work`),
                field: 'subjectWork',
                flex: 1.5,
                minWidth: 235,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                flex: 0.7,
                minWidth: 90,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_start_work`),
                renderCell: renderDateCell('dd.MM.yyyy HH:mm'),
                field: 'startDate',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_end_work`),
                renderCell: renderDateCell('dd.MM.yyyy HH:mm'),
                field: 'endDate',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_initiator`),
                field: 'initiator',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_object`),
                field: 'object',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_type`),
                field: 'type',
                flex: 1,
                minWidth: 120,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_status`),
                cellClassName: (params) => {
                    if (params.value) {
                        switch (params.row.statusId) {
                            case 1:
                                return plannedStatusClassName;
                            case 2:
                                return executedStatusClassName;
                            case 3:
                                return completedConfirmStatusClassName;
                            case 4:
                                return completedStatusClassName;
                            case 5:
                                return cancelledStatusClassName;
                            case 6:
                                return rollbackStatusClassName;
                            case 7:
                                return draftStatusClassName;
                            case 8:
                                return visingStatusClassName;
                            case 9:
                                return agreementBossStatusClassName;
                            case 10:
                                return actualDbStatusClassName;
                            case 11:
                                return recoveryApplyStatusClassName;
                            default:
                                return '';
                        }
                    }
                    return '';
                },
                field: 'status',
                flex: 0.8,
                minWidth: 105,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.45,
                minWidth: 70,
                getActions: renderCellActions,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            <Box
                sx={{
                    [`& .${plannedStatusClassName}`]: {
                        backgroundColor: 'rgba(178,255,89,0.5)',
                    },
                    [`& .${executedStatusClassName}`]: {
                        backgroundColor: 'rgba(254,255,0,0.5)',
                    },
                    [`& .${completedConfirmStatusClassName}`]: {
                        backgroundColor: 'rgba(189,189,189,0.5)',
                    },
                    [`& .${completedStatusClassName}`]: {
                        backgroundColor: 'transparent',
                    },
                    [`& .${cancelledStatusClassName}`]: {
                        backgroundColor: 'rgba(83,109,254,0.5)',
                    },
                    [`& .${rollbackStatusClassName}`]: {
                        backgroundColor: 'rgba(255,0,0,0.5)',
                    },
                    [`& .${draftStatusClassName}`]: {
                        backgroundColor: 'rgba(255,196,0,0.5)',
                    },
                    [`& .${visingStatusClassName}`]: {
                        backgroundColor: 'rgba(38,198,218,0.5)',
                    },
                    [`& .${agreementBossStatusClassName}`]: {
                        backgroundColor: 'rgba(213,0,249,0.5)',
                    },
                    [`& .${actualDbStatusClassName}`]: {
                        backgroundColor: 'rgba(255,0,0,0.5)',
                    },
                    [`& .${recoveryApplyStatusClassName}`]: {
                        backgroundColor: 'rgba(189,189,189,0.5)',
                    },
                }}
            >
                <PlannedWorksFilterToolbar />
                <DataGrid
                    density="compact"
                    autoHeight
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    disableColumnMenu
                    getRowHeight={() => 'auto'}
                    components={{
                        Pagination,
                        NoRowsOverlay,
                    }}
                    componentsProps={{
                        pagination: {
                            total: rows.length,
                            rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 0.5,
                        },
                    }}
                />
                <PlannedWorksFilterDialog />
            </Box>
            <DataGridLoader visible={false} text={<Translate id="loading" />} />
        </>
    );
};

export default PlannedWorksTable;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import { APP } from '../../constants';
import { AccessesFormInitialValues } from '../connectors/accesses-form-add-connector';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import * as Yup from 'yup';

type AccessFormProps = {
    onClose: () => void;
    initialValues: AccessesFormInitialValues;
    submitCallback: (arg: AccessesFormInitialValues) => void;
    isLoading: boolean;
    errors?: string[];
};

const AccessesForm: React.FC<AccessFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, isLoading, errors } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<AccessesFormInitialValues>({
        defaultValues: initialValues,
        mode: 'all',
        resolver: yupResolver(
            Yup.object().shape({
                comment: Yup.string().required('comment_is_required'),
                phone: Yup.string().required('phone_is_required'),
            }),
        ),
    });

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id="position-information-add-access-form">
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor="outlined-comment">
                                    <Translate app={APP} id="comment" />
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-comment"
                                    autoFocus
                                    {...field}
                                    label={<Translate app={APP} id="comment" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error id="helper-text-comment">
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel htmlFor="outlined-phone">
                                    <Translate app={APP} id="phone" />
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-phone"
                                    {...field}
                                    label={<Translate app={APP} id="phone" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error id="helper-text-phone">
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form="position-information-add-access-form"
                >
                    <Translate app={APP} id="submit" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AccessesForm;

import * as React from 'react';

import { DialogContent } from '@mui/material';

import AccessesForm from '../forms/accesses-form';
import { AccessesFormInitialValues } from './accesses-form-add-connector';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import { IOKO } from 'constants/sub-apps';
import { usePositionsAccessGetQuery, usePositionsAccessUpdateMutation } from 'services/positions/positions-services';
import { ServiceResponseStatusEnum } from 'types';

type AccessesFormConnectorProps = {
    onClose: () => void;
    accessId: number;
};

const AccessesFormUpdateConnector: React.FC<AccessesFormConnectorProps> = (props) => {
    const { onClose, accessId } = props;

    const { data, isSuccess, isFetching } = usePositionsAccessGetQuery(accessId);

    const initialValues: AccessesFormInitialValues = {
        comment: data ? data.data.comment : '',
        phone: data ? data.data.phone : '',
    };

    const [updateAccess, { isLoading, data: responseMutation }] = usePositionsAccessUpdateMutation();

    const onSubmitUpdateAccess = React.useCallback(
        async (formValues: AccessesFormInitialValues) => {
            const result = await updateAccess({ ...formValues, id: accessId, systemId: IOKO });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [updateAccess, accessId, onClose],
    );

    if (isSuccess) {
        return (
            <AccessesForm
                onClose={onClose}
                submitCallback={onSubmitUpdateAccess}
                initialValues={initialValues}
                isLoading={isLoading}
                errors={responseMutation?.errors}
            />
        );
    }

    if (isFetching) {
        return (
            <DialogContent sx={{ position: 'relative', height: 50 }}>
                <DataGridLoader visible={isFetching} />
            </DialogContent>
        );
    }
    return null;
};

export default AccessesFormUpdateConnector;

import { AppConfig } from '../index';
import Accesses from './accesses';
import { APP } from './constants';
import locale from './locales/locale';

const app: AppConfig = {
    name: APP,
    element: () => <Accesses />,
    locale,
};

export default app;

// Ticket
export const SMART_QUEUE_TICKET = 'SMART-QUEUE-TICKET';
export const SMART_QUEUE_TICKET_CHILD = 'SMART-QUEUE-TICKET-CHILD';
export const SMART_QUEUE_TICKET_DESCRIPTION = 'SMART-QUEUE-TICKET-DESCRIPTION';
export const SMART_QUEUE_TICKET_RESPONSIBLE = 'SMART-QUEUE-TICKET-RESPONSIBLE';
export const SMART_QUEUE_TICKET_TIME = 'SMART-QUEUE-TICKET-TIME';
export const SMART_QUEUE_TICKET_POSITIONS = 'SMART-QUEUE-TICKET-POSITIONS';
export const SMART_QUEUE_TICKET_COMMENTS = 'SMART-QUEUE-TICKET-COMMENTS';
export const SMART_QUEUE_TICKET_HISTORY = 'SMART-QUEUE-TICKET-HISTORY';
export const SMART_QUEUE_TICKET_HISTORY_ROLES = 'SMART-QUEUE-TICKET-HISTORY-ROLES';
export const SMART_QUEUE_POSSIBLE_REASONS_TICKETS = 'SMART-QUEUE-POSSIBLE-REASONS-TICKETS';
export const SMART_QUEUE_TICKET_INFO = 'SMART-QUEUE-TICKET-INFO';

import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import useTheme from '@mui/material/styles/useTheme';

import { DRAWER_WIDTH } from '../../../constants';
import LogoSection from './logo-section';
import MenuList from './menu-list';
import UserCard from './user-card';

type SidebarProps = {
    drawerOpen?: boolean;
    drawerToggle?: () => void;
    window?: Window;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
    const { drawerOpen, drawerToggle, window } = props;
    const theme = useTheme();

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }}>
            <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    [`& .${drawerClasses.paper}`]: {
                        width: DRAWER_WIDTH,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box>
                    <Box sx={{ display: 'flex', px: 2, py: 1.375, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                </Box>
                <Divider />
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: 'calc(100vh - 54px)',
                    }}
                >
                    <UserCard />
                    <Divider />
                    <MenuList />
                </PerfectScrollbar>
            </Drawer>
        </Box>
    );
};

export default Sidebar;

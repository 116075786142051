import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles/createTheme';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';

import Header from './header';
import Sidebar from './sidebar';

import FallbackErrorCard from 'components/cards/fallback-error-card';
import GlobalTabsContent from 'components/extended/global-tabs/global-tabs-content';
import LoadingBar from 'components/extended/loading-bar';
import Loader from 'components/loader';
import useAppDispatch from 'hooks/use-app-dispatch';
import useAppSelector from 'hooks/use-app-selector';
import { useGetApiMeQuery } from 'services/webapi/generated/webapi-services';
import appSlice from 'store/app-slice';
import customizationSlice from 'store/customization';
import errorSlice from 'store/error';

type MainStyleProps = {
    theme: Theme;
};

const Main = styled('main')(({ theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    position: 'relative',
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
    },
}));

const MainLayout: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const leftDrawerOpened = useAppSelector(customizationSlice.selectors.customizationOpenedSelector);
    const { isSuccess } = useGetApiMeQuery(undefined, { refetchOnMountOrArgChange: true });

    const handleLeftDrawerToggle = React.useCallback(
        () => dispatch(customizationSlice.actions.setMenuOpen(!leftDrawerOpened)),
        [dispatch, leftDrawerOpened],
    );

    const error = useAppSelector(errorSlice.selectors.errorSelector);

    const resetGlobalError = React.useCallback(() => {
        dispatch(
            appSlice.actions.setEntryPoint({
                path: window.location.pathname,
                search: window.location.search,
                hash: window.location.hash,
            }),
        );
        dispatch(errorSlice.actions.resetError());
    }, [dispatch]);

    if (isSuccess)
        return error.isError ? (
            <Box sx={{ height: '100vh' }}>
                <FallbackErrorCard error={error.error} resetErrorBoundary={resetGlobalError} />
            </Box>
        ) : (
            <Box sx={{ display: 'flex' }}>
                <LoadingBar />
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        maxWidth: '100vw !important',
                        borderBottomColor: 'divider',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }}
                >
                    <Toolbar
                        sx={{
                            paddingLeft: `${theme.typography.mainContent.padding} !important`,
                            paddingRight: `0 !important`,
                            alignItems: 'flex-start',
                            pb: 0,
                            pt: 0,
                        }}
                    >
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
                <Main theme={theme}>
                    <GlobalTabsContent />
                    <Outlet />
                </Main>
            </Box>
        );

    return (
        <Box sx={{ height: '100vh' }}>
            <Loader />
        </Box>
    );
};

export default MainLayout;

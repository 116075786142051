import * as React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';

type CreatePlannedWorkCustomerFormControllerConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    watch: UseFormWatch<CreatePlannedWorkFormInputs>;
};

const options = [
    {
        id: 1,
        name: 'Иванов Иван Иванович',
    },
    {
        id: 2,
        name: 'Петров Петр Петрович',
    },
];

const CreatePlannedWorkCustomerFormControllerConnector: React.FC<CreatePlannedWorkCustomerFormControllerConnectorProps> = (
    props,
) => {
    const { control, watch } = props;

    const customerValue = watch('customer');

    const [executors, setExecutors] = React.useState(customerValue !== null ? customerValue.name : '');

    return (
        <Controller
            name="customer"
            control={control}
            rules={{
                required: `${APP}_field_required`,
            }}
            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                    <Autocomplete
                        {...field}
                        size="small"
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        filterOptions={(x) => x}
                        options={options}
                        loading={false}
                        value={field.value}
                        onChange={(event, data) => field.onChange(data)}
                        onInputChange={(event, newInputValue) => {
                            setExecutors(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<Translate app={APP} id="customer" />}
                                value={executors}
                                error={invalid && isTouched}
                            />
                        )}
                    />
                    {invalid && isTouched && error?.message && (
                        <FormHelperText error>
                            <Translate app={APP} id={error.message} />
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default CreatePlannedWorkCustomerFormControllerConnector;

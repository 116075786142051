import config from '../../config';
import {
    presetColors,
    setBorderRadius,
    setFontFamily,
    setMenuOpen,
    setOutlinedFilled,
    setThemeMode,
    themeLocale,
} from './actions';
import { CustomizationStateProps } from './types';

import { createReducer } from 'redux-act';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: CustomizationStateProps = {
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    themeMode: config.theme,
    presetColor: config.presetColor,
    locale: config.i18n,
    opened: false,
    spacing: config.spacing,
};

export default persistReducer(
    { key: 'customization', storage, blacklist: ['opened'] },
    createReducer({}, initialState)
        .on(setThemeMode, (state, themeMode) => ({
            ...state,
            themeMode,
        }))
        .on(presetColors, (state, presetColor) => ({
            ...state,
            presetColor,
        }))
        .on(themeLocale, (state, locale) => ({
            ...state,
            locale,
        }))
        .on(setMenuOpen, (state, opened) => ({
            ...state,
            opened,
        }))
        .on(setFontFamily, (state, fontFamily) => ({
            ...state,
            fontFamily,
        }))
        .on(setBorderRadius, (state, borderRadius) => ({
            ...state,
            borderRadius,
        }))
        .on(setOutlinedFilled, (state, outlinedFilled) => ({
            ...state,
            outlinedFilled,
        })),
);

import * as React from 'react';

import Box from '@mui/material/Box';
import { cardContentClasses } from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { APP } from '../constants';
import useTopologyFilter from '../hooks/use-topology-filter';
import TopologyReactGraphVisConnector from './topology-react-graph-vis-connector';

import MainCard from 'components/cards/main-card';
import Translate from 'components/extended/translate';

const TopologyMain: React.FC = () => {
    const { TopologyFilterToolbar, filterValues } = useTopologyFilter();

    return (
        <Grid container direction="column">
            <Grid item>
                <TopologyFilterToolbar />
            </Grid>
            <Grid item>
                <MainCard contentSX={{ [`&.${cardContentClasses.root}:last-child`]: { paddingBottom: 0 }, padding: 0 }}>
                    {filterValues.branch === '' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                            <Translate app={APP} id="need_select_branch" />
                        </Box>
                    ) : (
                        <TopologyReactGraphVisConnector branchPrefix={filterValues.branch} />
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default TopologyMain;

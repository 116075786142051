import Box, { BoxProps } from '@mui/material/Box';

type FilterDisplayItemWrapperProps = {
    sx?: BoxProps['sx'];
};

const FilterDisplayItemWrapper: React.FC<FilterDisplayItemWrapperProps> = (props) => {
    const { children, sx } = props;
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default FilterDisplayItemWrapper;

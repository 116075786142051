// Access
export const POSITIONS_ACCESS = 'POSITIONS-ACCESS';
export const POSITIONS_ACCESS_GET_HISTORY = 'POSITIONS-ACCESS-GET-HISTORY';
// Account
export const POSITIONS_ACCOUNT = 'POSITIONS-ACCOUNT';
// Battery History
export const POSITIONS_BATTERY_HISTORY = 'POSITIONS-BATTERY-HISTORY';
// Blocked cell
export const POSITIONS_BLOCKED_CELL = 'POSITIONS-BLOCKED-CELL';
// Comment
export const POSITIONS_COMMENT = 'POSITIONS-COMMENT';
export const POSITIONS_COMMENT_GET_HISTORY = 'POSITIONS-COMMENT-GET-HISTORY';
// Electricity Suppliers
export const POSITIONS_ELECTRICITY_SUPPLIER = 'POSITIONS-ELECTRICITY_SUPPLIER';
export const POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY = 'POSITIONS-ELECTRICITY-SUPPLIER-GET-HISTORY';
// Equipment
export const POSITIONS_EQUIPMENT = 'POSITIONS-EQUIPMENT';
// Manager
export const POSITIONS_MANAGER = 'POSITIONS-MANAGER';
export const POSITIONS_MANAGER_GET_HISTORY = 'POSITIONS-MANAGER-GET-HISTORY';
// Position
export const POSITIONS_POSITION = 'POSITIONS-POSITION';
export const POSITIONS_POSITION_TYPE = 'POSITIONS-POSITION-TYPE';
// Sharing
export const POSITIONS_SHARING = 'POSITIONS-SHARING';
// Statistics
export const POSITIONS_STATISTIC = 'POSITIONS-STATISTIC';
// Transport
export const POSITIONS_TRANSPORT = 'POSITIONS-TRANSPORT';
export const POSITIONS_TRANSPORT_GET_HISTORY = 'POSITIONS-TRANSPORT-GET-HISTORY';
// Visitor
export const POSITIONS_VISITOR = 'POSITIONS-VISITOR';
export const POSITIONS_VISITOR_GET_HISTORY = 'POSITIONS-VISITOR-GET-HISTORY';

import type { PaletteMode } from '@mui/material';

type Config = {
    appName: string;
    basename: string;
    prefix: string;
    defaultPath: string;
    loginPath: string;
    authPath: string;
    fontFamily: string;
    borderRadius: number;
    outlinedFilled: boolean;
    theme: PaletteMode;
    presetColor: string;
    i18n: string;
    spacing: number;
};

const config: Config = {
    appName: 'iOko',
    basename: '',
    prefix: 'ioko',
    defaultPath: '/',
    loginPath: '/login',
    authPath: '/auth',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    outlinedFilled: true,
    theme: 'light',
    presetColor: 'default',
    i18n: 'ru',
    spacing: 8,
};

export default config;

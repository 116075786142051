import * as React from 'react';

import { FilterDateStateValue } from './types';

import Translate from 'components/extended/translate';

type ContentProps = {
    startDateValue: FilterDateStateValue;
    endDateValue: FilterDateStateValue;
};

const DateIntervalDisplayItemContent: React.FC<ContentProps> = (props) => {
    const { startDateValue, endDateValue } = props;

    if (startDateValue.id && endDateValue.id) {
        return <>{`${startDateValue.name} - ${endDateValue.name}`}</>;
    }
    if (startDateValue.id) {
        return (
            <>
                <Translate id="with" /> {startDateValue.name}
            </>
        );
    }
    if (endDateValue.id) {
        return (
            <>
                <Translate id="before" /> {endDateValue.name}
            </>
        );
    }
    return <Translate id="all" />;
};

export default DateIntervalDisplayItemContent;

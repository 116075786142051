import { authServices } from './auth/auth-services';

import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import jwtDecode from 'jwt-decode';
import { RootState } from 'store';
import appSlice from 'store/app-slice';
import { KeyedObject, ServiceResponseStatusEnum } from 'types';
import { client } from 'utils/client';
import { verifyToken } from 'utils/token';

const mutex = new Mutex();

export function fetchBaseQueryProtected(
    props: FetchBaseQueryArgs | undefined,
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
    return async (args, api, extraOptions) => {
        await mutex.waitForUnlock();
        const state = api.getState() as RootState;
        const accessToken = state.app.accessToken;

        if (accessToken) {
            if (accessToken && verifyToken(accessToken)) {
                return fetchBaseQuery({
                    ...props,
                    prepareHeaders: (_headers, _api) => {
                        _headers.set('client-id', client.getFingerprint().toString());
                        _headers.set('Authorization', `Bearer ${accessToken}`);
                        return props?.prepareHeaders ? props.prepareHeaders(_headers, _api) : _headers;
                    },
                })(args, api, extraOptions);
            }

            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const decodedAccessToken: KeyedObject = jwtDecode(accessToken);
                    const refreshToken = decodedAccessToken?.refreshToken;

                    if (refreshToken && typeof refreshToken === 'string' && verifyToken(refreshToken)) {
                        const refreshResult = await api.dispatch(
                            authServices.endpoints.refresh.initiate({
                                refreshToken,
                            }),
                        );

                        if ('data' in refreshResult) {
                            const { data: response } = refreshResult;

                            if (response.status === ServiceResponseStatusEnum.Success) {
                                const newAccessToken = response.data.token;

                                if (newAccessToken) {
                                    return await fetchBaseQuery({
                                        ...props,
                                        prepareHeaders: (_headers, _api) => {
                                            _headers.set('client-id', client.getFingerprint().toString());
                                            if (newAccessToken) {
                                                _headers.set('Authorization', `Bearer ${newAccessToken}`);
                                            }
                                            return props?.prepareHeaders
                                                ? props.prepareHeaders(_headers, _api)
                                                : _headers;
                                        },
                                    })(args, api, extraOptions);
                                }
                            }
                        }
                    }

                    api.dispatch(appSlice.actions.logout());

                    return await fetchBaseQuery({
                        ...props,
                        prepareHeaders: (_headers, _api) => {
                            _headers.set('client-id', client.getFingerprint().toString());

                            return props?.prepareHeaders ? props.prepareHeaders(_headers, _api) : _headers;
                        },
                    })(args, api, extraOptions);
                } finally {
                    release();
                }
            } else {
                await mutex.waitForUnlock();
                return fetchBaseQueryProtected(props)(args, api, extraOptions);
            }
        }

        return fetchBaseQueryProtected(props)(args, api, extraOptions);
    };
}

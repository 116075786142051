import * as React from 'react';

import { RegionResponseModelIEnumerableApiResponse } from 'services/webapi/generated/webapi-services';

const RegionBranchResponseOverrides = (data?: RegionResponseModelIEnumerableApiResponse) =>
    React.useMemo(() => {
        if (data) {
            if (data.data) {
                return data.data.map((elem) => ({
                    id: elem.id,
                    name: elem.name,
                    children: elem.branches.map((_elem) => ({ id: _elem.prefix, name: _elem.name })),
                }));
            }
        }
        return [];
    }, [data]);

export default RegionBranchResponseOverrides;

import * as React from 'react';
import { useIntl } from 'react-intl';

import { List } from '@mui/material';

import NavCollapse from './nav-collapse';
import NavItem from './nav-item';

import base64 from 'base-64';
import useSelector from 'hooks/use-app-selector';
import useGlobalTabs from 'hooks/use-global-tabs';
import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';
import appSlice from 'store/app-slice';
import { globalTabsActiveSelector, globalTabsSelector } from 'store/global-tabs/selectors';
import utf8 from 'utf8';
import { menuFlat } from 'utils/menu';
import { buildPath } from 'utils/router';

const MenuList: React.FC = () => {
    const intl = useIntl();
    const userMenu = useSelector(appSlice.selectors.userMenuSelector);
    const entryPoint = useSelector(appSlice.selectors.entryPointSelector);
    const tabs = useSelector(globalTabsSelector);
    const activeTab = useSelector(globalTabsActiveSelector);
    const { createTab, setActiveTab } = useGlobalTabs();

    React.useEffect(() => {
        const userMenuFlat: MenuItemResponseModel[] = [];
        userMenu.forEach((item) => {
            menuFlat(item).forEach((_item) => userMenuFlat.push(_item));
        });

        const entryPointArr = entryPoint.path !== '/' ? entryPoint.path.split('/').filter((el) => el !== '') : ['/'];

        const tabId = entryPointArr[0];
        const tabNameDecoded = tabId.split('-del-');
        let tabName: string | undefined;

        if (tabNameDecoded.length > 1) {
            try {
                tabName = utf8.decode(base64.decode(decodeURIComponent(tabNameDecoded[1])));
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }

        const app = entryPointArr[1];
        const path = entryPointArr.length < 3 ? '/' : buildPath(entryPointArr.slice(2), true);

        let isEntry = true;

        tabs.forEach((el) => {
            if (el.id === tabId) {
                isEntry = false;
            }
        });

        userMenuFlat
            .filter((el) => el.isDefault || (isEntry && el.application === app))
            .sort((a, b) => {
                if (a.isDefault === b.isDefault) return 0;
                return a.isDefault ? -1 : 1;
            })
            .forEach((el) => {
                const name =
                    el.shortName === '' ? '' : intl.formatMessage({ id: el.shortName, defaultMessage: 'Not title' });

                createTab({
                    name: el.isDefault ? name : tabName,
                    app: el.application,
                    path: el.isDefault ? el.path : path,
                    search: el.isDefault ? '' : entryPoint.search,
                    hash: el.isDefault ? '' : entryPoint.hash,
                    isUnique: el.isUnique,
                    icon: el.icon,
                    isClosable: el.isClosable,
                });

                if (el.isDefault) {
                    if (activeTab !== null) {
                        setActiveTab(activeTab.id);
                    }
                }
            });

        // eslint-disable-next-line
    }, []);

    const items = userMenu.map((menu) =>
        menu.childs.length > 0 ? (
            <NavCollapse key={menu.id} menu={menu} level={1} />
        ) : (
            <NavItem key={menu.id} item={menu} level={1} />
        ),
    );

    return <List>{items}</List>;
};

export default MenuList;

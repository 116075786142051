import * as React from 'react';

import { APP } from '../../constants';
import { EquipmentFilterState } from '../../hooks/use-equipment-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    usePositionsDictionaryEquipmentTypeGetListQuery,
    usePositionsDictionaryVendorGetListQuery,
} from 'services/positions/positions-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const EquipmentFilterDialog: React.FC<FilterDialogProps<EquipmentFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<EquipmentFilterState>(props);

    const { data: equipmentTypeAvailableValues } = usePositionsDictionaryEquipmentTypeGetListQuery();
    const { data: vendorAvailableValues } = usePositionsDictionaryVendorGetListQuery();
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="vendors"
                value={localFilterValues.vendors}
                label={<Translate app={APP} id="vendor" />}
                expanded={expandedField === 'vendors'}
                availableValues={vendorAvailableValues ? vendorAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="equipmentTypes"
                value={localFilterValues.equipmentTypes}
                label={<Translate app={APP} id="equipment_type" />}
                expanded={expandedField === 'equipmentTypes'}
                availableValues={equipmentTypeAvailableValues ? equipmentTypeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default EquipmentFilterDialog;

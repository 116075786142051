import * as React from 'react';

import useAppDispatch from './use-app-dispatch';
import useSelector from './use-app-selector';

import { nanoid } from '@reduxjs/toolkit';
import base64 from 'base-64';
import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';
import appSlice from 'store/app-slice';
import globalTabsSlice, { Tab, TabId, TabLocationAction } from 'store/global-tabs';
import utf8 from 'utf8';
import { menuFlat } from 'utils/menu';

const useGlobalTabs = () => {
    const dispatch = useAppDispatch();
    const userMenu = useSelector(appSlice.selectors.userMenuSelector);
    const userMenuFlat = React.useMemo(() => {
        const _userMenuFlat: MenuItemResponseModel[] = [];
        userMenu.forEach((item) => {
            menuFlat(item).forEach((_item) => _userMenuFlat.push(_item));
        });

        return _userMenuFlat;
    }, [userMenu]);

    const createTab = React.useCallback(
        (tab: Partial<Tab>) => {
            if (tab?.app !== undefined) {
                const menuItem = userMenuFlat.find((el) => el.application === tab.app);
                if (menuItem !== undefined) {
                    const name = tab.name ?? menuItem.name;
                    const id = tab.id ?? encodeURIComponent(`${nanoid(10)}-del-${base64.encode(utf8.encode(name))}`);
                    dispatch(
                        globalTabsSlice.actions.create({
                            ...tab,
                            id,
                            app: tab?.app ?? menuItem.application,
                            path: tab?.path ?? '/',
                            search: tab?.search ?? '',
                            hash: tab?.hash ?? '',
                            isClosable: tab.isClosable ?? menuItem.isClosable,
                            name,
                            isUnique: tab.isUnique ?? menuItem.isUnique,
                            isActive: true,
                            icon: tab?.icon ?? menuItem.icon,
                        }),
                    );

                    return id;
                }
            }

            return undefined;
        },
        [dispatch, userMenuFlat],
    );

    const closeTab = React.useCallback((id: TabId) => dispatch(globalTabsSlice.actions.close(id)), [dispatch]);

    const closeAllTabs = React.useCallback(() => dispatch(globalTabsSlice.actions.close(undefined)), [dispatch]);

    const setActiveTab = React.useCallback(
        (id: TabId) => {
            dispatch(globalTabsSlice.actions.setActive(id));
        },
        [dispatch],
    );

    const setLocation = React.useCallback(
        (id: TabId, location: TabLocationAction) => {
            dispatch(globalTabsSlice.actions.setLocation({ id, location }));
        },
        [dispatch],
    );

    return React.useMemo(() => ({ createTab, closeTab, closeAllTabs, setActiveTab, setLocation }), [
        createTab,
        closeTab,
        closeAllTabs,
        setActiveTab,
        setLocation,
    ]);
};

export default useGlobalTabs;

import * as React from 'react';

import EprViewFilterToolbar from '../views/filter/epr-view-filter-toolbar';

import {
    AvailableValue,
    FilterCheckBoxStateValue,
    FilterSearchStateValue,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type EprListFilterState = {
    search: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
    direction: AvailableValue;
    group: AvailableValue;
};

const initialState: EprListFilterState = {
    search: null,
    branches: null,
    direction: {
        id: 6,
        name: 'Front Office',
    },
    group: {
        id: 0,
        name: 'Все группы',
    },
};

const useEprViewFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));

    const [currentSearchField, setCurrentSearchField] = React.useState<keyof EprListFilterState>('search');

    return {
        EprListFilterToolbar: () => (
            <EprViewFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
            />
        ),
        filterValues,
    };
};

export default useEprViewFilter;

import * as React from 'react';

import WorkTypesMatrixFilterToolbar from '../views/filter/work-types-matrix-filter-toolbar';

import { deepCopy } from 'utils/deep-copy';

export type WorkTypesMatrixFilterState = {
    object?: number;
};

const initialState: WorkTypesMatrixFilterState = {
    object: undefined,
};

const useWorkTypesMatrixFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));

    return {
        TroubleTicketsFilterToolbar: () => (
            <WorkTypesMatrixFilterToolbar filterValues={filterValues} setFilterValues={setFilterValues} />
        ),
        filterValues,
    };
};

export default useWorkTypesMatrixFilter;

import * as React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { AvailableValue } from './types';

type FilterDisplayItemMenuProps = {
    onClick: (value: AvailableValue) => void;
    value: string;
    title: string | JSX.Element;
    availableValues: AvailableValue[];
    sx?: BoxProps['sx'];
};

const FilterDisplayItemMenu: React.FC<FilterDisplayItemMenuProps> = (props) => {
    const { onClick, value, title, sx, availableValues } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuOpen = Boolean(anchorEl);

    const handleCloseMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleMenuItemClick = React.useCallback(
        (_value: AvailableValue) => () => {
            onClick(_value);
            handleCloseMenu();
        },
        [handleCloseMenu, onClick],
    );

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    return (
        <>
            <Box
                sx={{
                    pt: 0,
                    pr: 0.625,
                    pb: 0.625,
                    pl: 0,
                    display: {
                        xs: 'none',
                        md: 'block',
                    },
                    ...sx,
                }}
            >
                <Tooltip
                    title={
                        <>
                            {title}: {value}
                        </>
                    }
                >
                    <Card variant="outlined" sx={{ height: 40, maxWidth: 160, minWidth: 60 }}>
                        <CardActionArea onClick={openMenu} sx={{ height: 40 }}>
                            <CardContent
                                sx={{
                                    pt: 0.5,
                                    pb: 0,
                                    px: 0.625,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '0.65rem',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        marginBottom: 0,
                                        lineHeight: '1.03',
                                    }}
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                >
                                    {value}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Tooltip>
            </Box>
            <Menu anchorEl={anchorEl} keepMounted open={menuOpen} onClose={handleCloseMenu}>
                <MenuList dense sx={{ py: 0 }}>
                    {availableValues.map((elem) => (
                        <MenuItem key={elem.id} onClick={handleMenuItemClick(elem)}>
                            {elem.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
};

export default FilterDisplayItemMenu;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { WorkTypesMatrixEditFormInitialValues } from '../connector/work-types-matrix-edit-form-connector';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import * as Yup from 'yup';

type WorkTypesMatrixEditFormProps = {
    onClose: () => void;
    submitCallback: () => void;
    initialValues: WorkTypesMatrixEditFormInitialValues;
    isLoading: boolean;
    errors?: string[];
};

const formId = 'work-types-matrix-edit-form';

const WorkTypesMatrixEditForm: React.FC<WorkTypesMatrixEditFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues: initialValues,
        mode: 'all',
        resolver: yupResolver(
            Yup.object().shape({
                object: Yup.string().required(`${APP}_field_required`),
                type: Yup.string().required(`${APP}_field_required`),
                minCreateTime: Yup.number()
                    .typeError(`${APP}_can_only_positive_integer`)
                    .min(0, `${APP}_can_only_positive_integer`)
                    .integer(`${APP}_can_only_positive_integer`)
                    .required(`${APP}_field_required`),
                minCreateTimeB2b: Yup.number()
                    .typeError(`${APP}_can_only_positive_integer`)
                    .min(0, `${APP}_can_only_positive_integer`)
                    .integer(`${APP}_can_only_positive_integer`)
                    .required(`${APP}_field_required`),
                allowedSpendDay: Yup.string().required(`${APP}_field_required`),
                needApprove: Yup.string().required(`${APP}_field_required`),
            }),
        ),
    });

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="object"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                                disabled
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="object" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="object" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                                disabled
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="type" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    multiline
                                    label={<Translate app={APP} id="type" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="minCreateTime"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="min_create_time" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="min_create_time" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="minCreateTimeB2b"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="min_create_time_b2b" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="min_create_time_b2b" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="allowedSpendDay"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="allowed_spend_day" />
                                </InputLabel>
                                <Select
                                    {...field}
                                    label={<Translate app={APP} id="allowed_spend_day" />}
                                    id={field.name}
                                >
                                    <MenuItem value={1}>
                                        <Translate app={APP} id="yes" />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <Translate app={APP} id="no" />
                                    </MenuItem>
                                </Select>
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="needApprove"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="need_approve" />
                                </InputLabel>
                                <Select {...field} label={<Translate app={APP} id="need_approve" />} id={field.name}>
                                    <MenuItem value={1}>
                                        <Translate app={APP} id="yes" />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <Translate app={APP} id="no" />
                                    </MenuItem>
                                </Select>
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="save" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default WorkTypesMatrixEditForm;

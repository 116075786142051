const routerSvg =
    '<svg width="161" height="161" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M-1-1h582v402H-1z"/><g stroke="#000"><circle cy="80.441" cx="80.441" fill-opacity="null" stroke-opacity="null" stroke-width="9" fill="#FFF" r="76"/><path stroke-miterlimit="10" d="M12.353 73.412h15v-9l40 15-40 15v-9h-15v-12zm135.882 11.706h-15v9l-40-15 40-15v9h15v12zm-74.029-18.5v-15h-9l15-40 15 40h-9v15h-12zm12 26.764v15h9l-15 40-15-40h9v-15h12z"/></g></svg>';

const ROUTER_SVG_URL = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(routerSvg)}`;

const switchSvg =
    '<svg width="161" height="161" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M-1-1h582v402H-1z"/><g stroke="#000"><path stroke-width="9" fill="#FFF" d="M4.857 4.857h151.571v151.571H4.857z"/><path stroke-miterlimit="10" d="M91.286 71h-40v9l-40-15 40-15v9h40v12zM69 29h40v-9l40 15-40 15v-9H69V29zm0 59.714h40v-9l40 15-40 15v-9H69v-12zm23.429 42.572h-40v9l-40-15 40-15v9h40v12z"/></g></svg>';

const SWITCH_SVG_URL = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(switchSvg)}`;

const imagesTypesUrl = { router: ROUTER_SVG_URL, switch: SWITCH_SVG_URL };

export default imagesTypesUrl;

import { createApi } from '@reduxjs/toolkit/query/react';
import { WEBAPI_SERVICE_URL } from 'services/constants';
import { fetchBaseQueryProtected } from 'services/fetch-base-query-protected';

export const commonWebapiServices = createApi({
    reducerPath: 'webapiServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: WEBAPI_SERVICE_URL,
    }),
    endpoints: () => ({}),
    tagTypes: ['webapi'],
});

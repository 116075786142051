import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Equipment',
        ru: 'Список оборудования',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_vendor`]: {
        en: 'Vendor',
        ru: 'Вендор',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Название',
    },
    [`${APP}_model`]: {
        en: 'Model',
        ru: 'Модель',
    },
    [`${APP}_internal_name`]: {
        en: 'Name in NMS',
        ru: 'Название в NMS',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_ip`]: {
        en: 'IP',
        ru: 'IP',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_equipment_type`]: {
        en: 'Equipment Type',
        ru: 'Тип Оборудования',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
};

export default locale;

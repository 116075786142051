import { close, create, setActive, setLocation } from './actions';
import { TabsStateProps } from './types';

import { createReducer } from 'redux-act';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: TabsStateProps = {
    tabs: [],
};

export default persistReducer(
    { key: 'globalTabs', storage },
    createReducer({}, initialState)
        .on(create, (state, tab) => {
            if (tab.isUnique) {
                const findTab = state.tabs.find((item) => item.app === tab.app && item.path === tab.path);
                if (findTab !== undefined) {
                    if (findTab.isActive) return state;
                    return {
                        ...state,
                        tabs: state.tabs.map((item) => ({
                            ...item,
                            isActive: item.id === findTab.id,
                        })),
                    };
                }
            }
            return {
                ...state,
                tabs: [...state.tabs.map((item) => ({ ...item, isActive: false })), tab],
            };
        })
        .on(close, (state, id) => {
            let removedIndex: number | undefined;

            const newItems = state.tabs.filter((tab, index) => {
                if (tab.id === id) {
                    removedIndex = tab.isActive ? index : undefined;
                    return false;
                }
                return true;
            });

            if (removedIndex !== undefined) {
                const newActiveIndex = removedIndex < newItems.length ? removedIndex : removedIndex - 1;

                return {
                    ...state,
                    tabs: [
                        ...newItems.map((tab, index) => ({
                            ...tab,
                            isActive: newActiveIndex === index,
                        })),
                    ],
                };
            }

            return {
                ...state,
                tabs: newItems,
            };
        })
        .on(setActive, (state, id) => ({
            ...state,
            tabs: [
                ...state.tabs.map((tab) => ({
                    ...tab,
                    isActive: tab.id === id,
                })),
            ],
        }))
        .on(setLocation, (state, { id, location }) => ({
            ...state,
            tabs: [
                ...state.tabs.map((tab) => ({
                    ...tab,
                    path: tab.id === id ? location.path : tab.path,
                    search: tab.id === id ? location.search : tab.search,
                    hash: tab.id === id ? location.hash : tab.hash,
                })),
            ],
        })),
);

import * as React from 'react';

import { APP } from '../../constants';
import {
    TroubleTicketsFilterState,
    keyBSLabelValues,
    overdueLabelValues,
    periodicLabelValues,
    powerLabelValues,
    showInWorkLabelValues,
    withGeouniteLabelValues,
    withInfluenceLabelValues,
} from '../../hooks/use-trouble-tickets-filter';
import TroubleTicketsRoleFilter from './trouble-tickets-role-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDateTimeInterval from 'components/extended/data-grid/filter/filter-date-time-interval';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import FilterRadio from 'components/extended/data-grid/filter/filter-radio';
import FilterSwitch from 'components/extended/data-grid/filter/filter-switch';
import { AvailableValue, FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    useSmartQueueDictionaryActivityStatusGetListQuery,
    useSmartQueueDictionaryPriorityGetListQuery,
    useSmartQueueDictionaryServiceStatusGetListQuery,
    useSmartQueueDictionarySolutionStatusGetListQuery,
} from 'services/smart-queue/smart-queue-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const creationSystemAvailableValues: AvailableValue[] = [
    { id: 1, name: 'Все' },
    { id: 2, name: 'TT созданные Limon' },
    { id: 3, name: 'TT созданные Netcool' },
    { id: 4, name: 'TT созданные не FO' },
    { id: 5, name: 'TT созданные Оргструктура МТС' },
    { id: 6, name: 'TT созданные не Huawei' },
    { id: 7, name: 'Другие' },
];

const TroubleTicketsFilterDialog: React.FC<FilterDialogProps<TroubleTicketsFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
        setIsError,
        isError,
    } = useManagementFilterDialog<TroubleTicketsFilterState>(props);

    const { data: priorityAvailableValues } = useSmartQueueDictionaryPriorityGetListQuery();
    const { data: activityStatusAvailableValues } = useSmartQueueDictionaryActivityStatusGetListQuery();
    const { data: solutionStatusAvailableValues } = useSmartQueueDictionarySolutionStatusGetListQuery();
    const { data: serviceStatusAvailableValues } = useSmartQueueDictionaryServiceStatusGetListQuery();
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
            isError={isError}
        >
            <FilterCheckBox
                field="priorities"
                value={localFilterValues.priorities}
                label={<Translate app={APP} id="priorities" />}
                expanded={expandedField === 'priorities'}
                availableValues={priorityAvailableValues ? priorityAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterSwitch
                field="showInWork"
                value={localFilterValues.showInWork}
                label={<Translate app={APP} id="show_in_work" />}
                labelsValues={showInWorkLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="displayClosed"
                value={localFilterValues.displayClosed}
                label={<Translate app={APP} id="display_closed" />}
                labelsValues={showInWorkLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterDateTimeInterval
                startDateField="startDate"
                endDateField="endDate"
                startDateValue={localFilterValues.startDate}
                endDateValue={localFilterValues.endDate}
                startDateLabel={<Translate id="with" />}
                endDateLabel={<Translate id="before" />}
                label={<Translate app={APP} id="period" />}
                expanded={expandedField === 'startDate' || expandedField === 'endDate'}
                setLocalFilterValues={setLocalFilterValues}
                setIsError={setIsError}
            />
            <FilterSwitch
                field="overdue"
                value={localFilterValues.overdue}
                label={<Translate app={APP} id="overdue" />}
                labelsValues={overdueLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterRadio
                field="incidentStatus"
                value={localFilterValues.incidentStatus}
                label={<Translate app={APP} id="incident_status" />}
                expanded={expandedField === 'incidentStatus'}
                setLocalFilterValues={setLocalFilterValues}
                availableValues={activityStatusAvailableValues ? activityStatusAvailableValues.data : []}
            />
            <FilterSwitch
                field="withInfluence"
                value={localFilterValues.withInfluence}
                label={<Translate app={APP} id="with_influence" />}
                labelsValues={withInfluenceLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="keyBS"
                value={localFilterValues.keyBS}
                label={<Translate app={APP} id="key_bs" />}
                labelsValues={keyBSLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="withGeounite"
                value={localFilterValues.withGeounite}
                label={<Translate app={APP} id="with_geounite" />}
                labelsValues={withGeouniteLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="power"
                value={localFilterValues.power}
                label={<Translate app={APP} id="power" />}
                labelsValues={powerLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterSwitch
                field="periodic"
                value={localFilterValues.periodic}
                label={<Translate app={APP} id="periodic" />}
                labelsValues={periodicLabelValues}
                setLocalFilterValues={setLocalFilterValues}
                app={APP}
            />
            <FilterRadio
                field="creationSystem"
                value={localFilterValues.creationSystem}
                label={<Translate app={APP} id="creation_system" />}
                expanded={expandedField === 'creationSystem'}
                setLocalFilterValues={setLocalFilterValues}
                availableValues={creationSystemAvailableValues}
            />
            <FilterCheckBox
                field="serviceStatus"
                value={localFilterValues.serviceStatus}
                label={<Translate app={APP} id="service_status" />}
                expanded={expandedField === 'serviceStatus'}
                availableValues={serviceStatusAvailableValues ? serviceStatusAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="status"
                value={localFilterValues.status}
                label={<Translate app={APP} id="status" />}
                expanded={expandedField === 'status'}
                availableValues={solutionStatusAvailableValues ? solutionStatusAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBoxTree
                field="placeOfOrigin"
                value={localFilterValues.placeOfOrigin}
                label={<Translate app={APP} id="place_of_origin" />}
                expanded={expandedField === 'placeOfOrigin'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <TroubleTicketsRoleFilter
                roleField="roles"
                groupRoleField="groupRole"
                roleValue={localFilterValues.roles}
                groupRoleValue={localFilterValues.groupRole}
                expanded={expandedField === 'roles' || expandedField === 'groupRole'}
                setLocalFilterValues={setLocalFilterValues}
                label={<Translate app={APP} id="role" />}
            />
        </FilterDialog>
    );
};

export default TroubleTicketsFilterDialog;

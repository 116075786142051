import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps, dialogClasses } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';

type TroubleTicketViewDialogProps = {
    open: boolean;
    onClose: () => void;
    title: React.ReactNode;
    maxWidth?: DialogProps['maxWidth'];
    fullWidth?: DialogProps['fullWidth'];
};

const TroubleTicketViewDialog: React.FC<TroubleTicketViewDialogProps> = (props) => {
    const { open, onClose, title, children, maxWidth = 'xs', fullWidth } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            sx={{ [`.${dialogClasses.paper}`]: { padding: 0 } }}
        >
            <DialogTitle
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1, pl: 2, pr: 1 }}
            >
                <Typography>{title}</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {children}
        </Dialog>
    );
};

export default TroubleTicketViewDialog;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Comments',
        ru: 'Комментарии',
    },
    [`${APP}_edit_comment`]: {
        en: 'Edit comment',
        ru: 'Редактировать комментарий',
    },
    [`${APP}_add_comment`]: {
        en: 'Add comment',
        ru: 'Добавить комментарий',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_priority_is_required`]: {
        en: 'Priority is required',
        ru: 'Приоритет обязателен',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the record?',
        ru: 'Вы действительно хотите удалить запись?',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Коммментарии',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
};

export default locale;

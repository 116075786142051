import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { EventsFilterState } from '../../hooks/use-events-filter';

import { nanoid } from '@reduxjs/toolkit';
import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import { FilterToolbarProps, SearchFilter } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';

const searchFilters: SearchFilter<EventsFilterState>[] = [
    { field: 'searchString', id: nanoid(), label: <Translate app={APP} id="search" /> },
];

const EventsFilterToolbar: React.FC<FilterToolbarProps<EventsFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, ...other } = props;

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<EventsFilterState>({ ...other });

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <SearchDisplayFilter
                    filterValues={filterValues}
                    searchFilters={searchFilters}
                    setFilterValues={setFilterValues}
                    currentSearchField={currentSearchField}
                    setCurrentSearchField={setCurrentSearchField}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="regions_branches" />}
                    Content={<DisplayItemContent valueLabel={filterValues.branches?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('branches')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="position_type" />}
                    Content={<DisplayItemContent valueLabel={filterValues.positionType?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('positionType')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="event_type" />}
                    Content={<DisplayItemContent valueLabel={filterValues.eventType?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('eventType')}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate id="filter" />}>
                    <IconButton onClick={onClickOpenFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </FilterDisplayWrapper>
    );
};

export default EventsFilterToolbar;

import * as React from 'react';

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const ErrorBoundary = Loadable(React.lazy(() => import('./error-boundary')));

const app: AppConfig = {
    name: APP,
    element: () => <ErrorBoundary />,
    locale,
};

export default app;

import * as React from 'react';

import CreatePlannedWorkSaveTemplateForm from '../forms/create-planned-work-save-template-form';

export type CreatePlannedWorkSaveTemplateFormInputs = {
    name: string;
};

const initialValues: CreatePlannedWorkSaveTemplateFormInputs = {
    name: '',
};

type CreatePlannedWorkSaveTemplateFormConnectorProps = {
    onClose: () => void;
};

const CreatePlannedWorkSaveTemplateFormConnector: React.FC<CreatePlannedWorkSaveTemplateFormConnectorProps> = (
    props,
) => {
    const { onClose } = props;

    const handleSaveTemplate = React.useCallback(() => {
        onClose();
        // TODO тут должен отправляться запрос
    }, [onClose]);

    return (
        <CreatePlannedWorkSaveTemplateForm
            initialValues={initialValues}
            onClose={onClose}
            submitCallback={handleSaveTemplate}
            isLoading={false}
        />
    );
};

export default CreatePlannedWorkSaveTemplateFormConnector;

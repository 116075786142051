import * as React from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import DoneIcon from '@mui/icons-material/Done';
import PeopleIcon from '@mui/icons-material/People';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ShareIcon from '@mui/icons-material/Share';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useTroubleTicketsFilter from '../hooks/use-trouble-tickets-filter';
import TroubleTicketsCloseTtDialog from './dialogs/trouble-tickets-close-tt-dialog';
import RowMenu from './row-menu/row-menu';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { useModal } from 'mui-modal-provider';
import {
    SmartQueueDeadlineLevels,
    SmartQueueTicketResponseModel,
    SmartQueueTicketSort,
} from 'services/smart-queue/smart-queue-models';
import { useSmartQueueTicketGetListQuery } from 'services/smart-queue/smart-queue-services';
import { Tab } from 'store/global-tabs';
import { APP as EVENT_VIEW_APP } from 'sub-apps/monitoring/event-view/constants';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import { APP as TROUBLE_TICKET_VIEW_APP } from 'sub-apps/trouble-ticket-view/constants';
import TroubleTicketViewCommentsDialog from 'sub-apps/trouble-ticket-view/views/body/comments/dialogs/trouble-ticket-view-comments-dialog';
import TroubleTicketViewEditInformationDialog from 'sub-apps/trouble-ticket-view/views/body/information/dialogs/trouble-ticket-view-edit-information-dialog';
import TroubleTicketViewEditResponsibleDialog from 'sub-apps/trouble-ticket-view/views/body/information/dialogs/trouble-ticket-view-edit-responsible-dialog';
import TroubleTicketViewPossibleReasonsOplDialog from 'sub-apps/trouble-ticket-view/views/body/possible-reasons/dialogs/trouble-ticket-view-possible-reasons-opl-dialog';
import TroubleTicketViewPossibleReasonsTtDialog from 'sub-apps/trouble-ticket-view/views/body/possible-reasons/dialogs/trouble-ticket-view-possible-reasons-tt-dialog';
import TroubleTicketViewChangeDependentsDialog from 'sub-apps/trouble-ticket-view/views/header/dialogs/trouble-ticket-view-change-dependents-dialog';
import TroubleTicketViewChangePriorityDialog from 'sub-apps/trouble-ticket-view/views/header/dialogs/trouble-ticket-view-change-priority-dialog';
import TroubleTicketViewChangeServiceStatusDialog from 'sub-apps/trouble-ticket-view/views/header/dialogs/trouble-ticket-view-change-service-status-dialog';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

const deadLineLevelNormalClassName = 'dead-line-level-normal';
const deadLineLevelDangerClassName = 'dead-line-level-danger';
const deadLineLevelOverdueClassName = 'dead-line-level-overdue';

const getWeightClassName = (params: GridCellParams<SmartQueueTicketResponseModel['weight']>) => {
    if (params.value !== undefined && params.value !== null) {
        switch (params.row.deadLineLevel) {
            case SmartQueueDeadlineLevels.Normal:
                return deadLineLevelNormalClassName;
            case SmartQueueDeadlineLevels.Danger:
                return deadLineLevelDangerClassName;
            case SmartQueueDeadlineLevels.Overdue:
                return deadLineLevelOverdueClassName;
            default:
                return '';
        }
    }
    return '';
};

const renderCellWeight = (cell: GridRenderCellParams<SmartQueueTicketResponseModel['weight']>) =>
    cell.value !== undefined &&
    cell.value !== null &&
    cell.row.solutionStatusId !== 4 &&
    cell.row.serviceStatusId !== 1 && (
        <Chip className={getWeightClassName(cell)} size="small" label={cell.value} sx={{ userSelect: 'text' }} />
    );

const renderCellPriority = (cell: GridRenderCellParams<SmartQueueTicketResponseModel['priority']>) =>
    cell.value !== undefined && (
        <Chip
            size="small"
            label={cell.value}
            color={cell.value === '1' || cell.value === '2' ? 'error' : 'default'}
            sx={{ userSelect: 'text' }}
        />
    );

const TroubleTicketsTable: React.FC = () => {
    const { Pagination, rowsState } = usePagination(`${APP}_grid`, '5');
    const { TroubleTicketsFilterDialog, TroubleTicketsFilterToolbar, filterValues } = useTroubleTicketsFilter();
    const theme = useTheme();

    const { data, refetch, isFetching } = useSmartQueueTicketGetListQuery({
        value: {
            number: filterValues.ttNumber ? Number(filterValues.ttNumber) : undefined,
            serviceStatusIds: filterValues.serviceStatus?.map((elem) => Number(elem.id)),
            solutionStatusIds: filterValues.status?.map((elem) => Number(elem.id)),
            branches: filterValues.placeOfOrigin?.map((elem) => String(elem.id)),
            priorityIds: filterValues.priorities?.map((elem) => Number(elem.id)),
            isTraffic: filterValues.withInfluence.id,
            isPower: filterValues.power.id,
            isPeriodical: filterValues.periodic.id,
            isOverdue: filterValues.overdue.id,
            startDate: filterValues.startDate.id,
            endDate: filterValues.endDate.id,
            order: filterValues.order.id as SmartQueueTicketSort,
            groupRoleIds: filterValues.groupRole.id ? [Number(filterValues.groupRole.id)] : undefined,
            roleIds: filterValues.roles?.map((elem) => Number(elem.id)),
        },
        pagination: {
            rowPerPage: rowsState.rowPerPage,
            pageNumber: rowsState.pageNumber + 1,
        },
    });

    const { createTab } = useGlobalTabs();

    const handleCreateTabClick = React.useCallback(
        (app: Tab['app'], name: Tab['name'], path: Tab['path']) => () => {
            createTab({
                id: undefined,
                app,
                name,
                path,
                isUnique: true,
            });
        },
        [createTab],
    );

    const { showModal } = useModal();

    const handleOplClick = React.useCallback(
        (number: number) => () => {
            const modal = showModal(TroubleTicketViewPossibleReasonsOplDialog, {
                onClose: () => {
                    modal.hide();
                },
                oplNumber: number,
            });
        },
        [showModal],
    );

    const handleTtClick = React.useCallback(
        (openedTtNumber: number, selectedTtNumber: number) => () => {
            const modal = showModal(TroubleTicketViewPossibleReasonsTtDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber: selectedTtNumber,
                openedTtNumber,
            });
        },
        [showModal],
    );

    const handleCommentsClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewCommentsDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleChangeResponsibleClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewEditResponsibleDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleBindClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewChangeDependentsDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleChangeStatusClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewChangeServiceStatusDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleChangePriorityClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewChangePriorityDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleChangeDescriptionClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketViewEditInformationDialog, {
                onClose: () => {
                    modal.hide();
                },
                ttNumber,
            });
        },
        [showModal],
    );

    const handleCloseClick = React.useCallback(
        (ttNumber: number) => () => {
            const modal = showModal(TroubleTicketsCloseTtDialog, {
                onClose: () => {
                    modal.hide();
                },
            });
        },
        [showModal],
    );

    const renderCellActions = React.useCallback(
        (params: any) => [
            <Tooltip title={<Translate app={APP} id="comments" />}>
                <IconButton size="small" onClick={handleCommentsClick(params.id)}>
                    <CommentIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
            <Tooltip title={<Translate app={APP} id="change_responsible" />}>
                <IconButton size="small" onClick={handleChangeResponsibleClick(params.id)}>
                    <PeopleIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
            <Tooltip title={<Translate app={APP} id="bind" />}>
                <IconButton size="small" onClick={handleBindClick(params.id)}>
                    <ShareIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
            <Tooltip title={<Translate app={APP} id="change_status" />}>
                <IconButton size="small" onClick={handleChangeStatusClick(params.id)}>
                    <DoneIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
            <RowMenu>
                <RowMenu.Item
                    title={<Translate app={APP} id="change_responsible" />}
                    onClick={handleChangeResponsibleClick(params.id)}
                    Icon={PeopleIcon}
                />
                <RowMenu.Item
                    title={<Translate app={APP} id="change_priority" />}
                    onClick={handleChangePriorityClick(params.id)}
                    Icon={PriorityHighIcon}
                    disable={params.row.serviceStatusId === 1}
                />
                <RowMenu.Item
                    title={<Translate app={APP} id="bind" />}
                    onClick={handleBindClick(params.id)}
                    Icon={DoneIcon}
                    disable={params.row.serviceStatusId === 1}
                />
                <RowMenu.Item
                    title={<Translate app={APP} id="edit_description" />}
                    onClick={handleChangeDescriptionClick(params.id)}
                    Icon={TextFieldsIcon}
                    disable={params.row.serviceStatusId === 1}
                />
                <RowMenu.Item
                    title={<Translate app={APP} id="change_status" />}
                    onClick={handleChangeStatusClick(params.id)}
                    Icon={AssignmentIcon}
                    disable={params.row.serviceStatusId === 1}
                />
                <RowMenu.Item
                    title={<Translate app={APP} id="close" />}
                    onClick={handleCloseClick(params.id)}
                    Icon={CloseIcon}
                    disable={params.row.serviceStatusId !== 1} // TODO как появится ролевка, добавить проверку ролей
                />
            </RowMenu>,
        ],
        [
            handleBindClick,
            handleChangeDescriptionClick,
            handleChangePriorityClick,
            handleChangeResponsibleClick,
            handleChangeStatusClick,
            handleCloseClick,
            handleCommentsClick,
        ],
    );

    const renderCellTt = React.useCallback(
        (cell: GridRenderCellParams<SmartQueueTicketResponseModel['id']>) =>
            cell.value !== undefined && (
                <Chip
                    onClick={handleCreateTabClick(TROUBLE_TICKET_VIEW_APP, String(cell.value), String(cell.value))}
                    size="small"
                    label={cell.value}
                    sx={{ userSelect: 'text' }}
                />
            ),
        [handleCreateTabClick],
    );

    const renderCellPossibleReasons = React.useCallback(
        (cell: GridRenderCellParams) => (
            <>
                {cell.row.reasonPlanWorks.length < 6 && cell.row.reasonPlanWorks.length !== 0 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, alignItems: 'center' }}>
                        <Translate app={APP} id="plw" />:
                        {(cell.row.reasonPlanWorks as SmartQueueTicketResponseModel['reasonPlanWorks']).map((elem) => (
                            <Chip
                                key={elem}
                                onClick={handleOplClick(Number(elem))}
                                size="small"
                                label={elem}
                                sx={{ userSelect: 'text' }}
                            />
                        ))}
                    </Box>
                )}
                {cell.row.reasonTickets.length < 6 && cell.row.reasonTickets.length !== 0 && (
                    <>
                        <Translate app={APP} id="tt" />:
                        {(cell.row.reasonTickets as SmartQueueTicketResponseModel['reasonTickets']).map((elem) => (
                            <Chip
                                key={elem}
                                onClick={handleTtClick(Number(elem), Number(cell.row.id))}
                                size="small"
                                label={elem}
                                sx={{ userSelect: 'text' }}
                            />
                        ))}
                    </>
                )}
            </>
        ),
        [handleOplClick, handleTtClick],
    );

    const renderCellEvent = React.useCallback(
        (cell: GridRenderCellParams<SmartQueueTicketResponseModel['event']>) =>
            cell.value !== undefined &&
            cell.value !== null && (
                <Chip
                    onClick={handleCreateTabClick(EVENT_VIEW_APP, String(cell.value), String(cell.value))}
                    size="small"
                    label={cell.value}
                    sx={{ userSelect: 'text' }}
                />
            ),
        [handleCreateTabClick],
    );

    const renderCellElement = React.useCallback(
        (cell: GridRenderCellParams<SmartQueueTicketResponseModel['positions']>) => {
            if (cell.value !== undefined && cell.value.length < 6) {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5 }}>
                        {cell.value.map((elem) => (
                            <Chip
                                key={elem}
                                onClick={handleCreateTabClick(POSITION_VIEW_APP, elem, elem)}
                                size="small"
                                label={elem}
                                sx={{ userSelect: 'text' }}
                            />
                        ))}
                    </Box>
                );
            }
            return '>5';
        },
        [handleCreateTabClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization('tt', APP),
                renderCell: renderCellTt,
                field: 'id',
                width: 90,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('p', APP, 'priority'),
                renderCell: renderCellPriority,
                field: 'priority',
                width: 35,
                minWidth: 35,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('created_date', APP),
                renderCell: renderDateCell('dd.MM.yyyy HH:mm'),
                field: 'createdDate',
                width: 85,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('branch', APP),
                field: 'branch',
                width: 130,
                minWidth: 90,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('short_description', APP),
                field: 'shortDescription',
                flex: 0.5,
                minWidth: 140,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('role', APP),
                field: 'role',
                flex: 0.3,
                minWidth: 100,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('service_status', APP),
                field: 'serviceStatus',
                width: 115,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('element', APP),
                renderCell: renderCellElement,
                field: 'positions',
                width: 80,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('cdt', APP),
                field: 'cdt',
                width: 65,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('event', APP),
                renderCell: renderCellEvent,
                field: 'event',
                width: 80,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('possible_reasons', APP),
                renderCell: renderCellPossibleReasons,
                field: 'possibleReasons',
                flex: 0.3,
                minWidth: 115,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization('weight', APP),
                valueGetter: (params) => (params.row.isOverdue ? params.row.weight : params.row.deadLineValue),
                renderCell: renderCellWeight,
                field: 'weight',
                flex: 0.2,
                minWidth: 80,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions',
                type: 'actions',
                width: 150,
                getActions: renderCellActions,
            },
        ],
        [renderCellActions, renderCellElement, renderCellEvent, renderCellPossibleReasons, renderCellTt],
    );

    return (
        <>
            {data !== undefined && (
                <Box
                    sx={{
                        [`& .${deadLineLevelNormalClassName}`]: {
                            backgroundColor: theme.palette.warning.main,
                            color: theme.palette.warning.contrastText,
                        },
                        [`& .${deadLineLevelDangerClassName}`]: {
                            backgroundColor: theme.palette.warning.dark,
                            color: theme.palette.warning.contrastText,
                        },
                        [`& .${deadLineLevelOverdueClassName}`]: {
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                        },
                    }}
                >
                    <TroubleTicketsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        disableVirtualization
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.actionsCell}`]: {
                                gridGap: 0,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <TroubleTicketsFilterDialog />
                </Box>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default TroubleTicketsTable;

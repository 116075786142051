import { GridRenderCellParams } from '@mui/x-data-grid';

import { format } from 'date-fns';

const renderDateCell = (
    formatDate: string = 'dd.MM.yyyy HH:mm:ss',
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    },
) => ({ value }: GridRenderCellParams<string>) => (value ? format(new Date(value), formatDate, options) : value);

export default renderDateCell;

import * as actions from './actions';
import reducer from './reducer';
import * as selectors from './selectors';

const errorSlice = {
    actions,
    selectors,
    reducer,
};

export * from './types';
export default errorSlice;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useTheme from '@mui/material/styles/useTheme';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import { useSnackbar } from 'notistack';
import { AuthAuthTokenResponseModelApiResponse } from 'services/auth/auth-models';
import { useResetPasswordMutation } from 'services/auth/auth-services';
import * as Yup from 'yup';

export type ResetPasswordFormInputs = {
    username: string;
};

type ResetPasswordFormProps = {
    defaultValues: ResetPasswordFormInputs;
    onSuccessSubmit: (data: ResetPasswordFormInputs) => void;
    onBack: () => void;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
    const { defaultValues, onSuccessSubmit, onBack } = props;
    const theme = useTheme();
    const [submitErrors, setSubmitErrors] = React.useState<string[]>([]);
    const [resetPassword] = useResetPasswordMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleBack = React.useCallback(() => {
        onBack();
    }, [onBack]);

    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
    } = useForm<ResetPasswordFormInputs>({
        defaultValues,
        resolver: yupResolver(
            Yup.object().shape({
                username: Yup.string().email('invalid_email').required('email_is_required'),
            }),
        ),
        mode: 'all',
    });

    const onSubmit = React.useCallback(
        async (valuesForm: ResetPasswordFormInputs) => {
            setSubmitErrors([]);

            const res = await resetPassword({
                ...valuesForm,
            });

            if ('error' in res && 'data' in res.error) {
                const data = res.error.data as AuthAuthTokenResponseModelApiResponse;
                setSubmitErrors(data.errors);
            }

            if ('data' in res) {
                const { status, errors, data } = res.data;

                if (status === 'Success') {
                    enqueueSnackbar(data, {
                        variant: 'success',
                    });
                    onSuccessSubmit(valuesForm);
                } else {
                    setSubmitErrors(errors);
                }
            }

            return res;
        },
        [enqueueSnackbar, onSuccessSubmit, resetPassword],
    );

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="username"
                control={control}
                render={({ field, fieldState: { isTouched, invalid, error: _error } }) => (
                    <FormControl fullWidth error={invalid && isTouched} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-reset">
                            <Translate id="email" />
                        </InputLabel>
                        <OutlinedInput id="outlined-adornment-email-reset" autoFocus {...field} type="email" />
                        {invalid && isTouched && _error?.message && (
                            <FormHelperText error id="standard-weight-helper-text-email-reset">
                                <Translate id={_error.message} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />

            {submitErrors.length > 0 && (
                <Box sx={{ mt: 2, mb: 2.5 }}>
                    {submitErrors.map((_error) => (
                        <FormHelperText key={_error} error>
                            {_error}
                        </FormHelperText>
                    ))}
                </Box>
            )}

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                >
                    <Translate id="send" />
                </LoadingButton>
            </Box>

            <Box sx={{ mt: 3 }}>
                <Button
                    disableElevation
                    fullWidth
                    type="submit"
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                >
                    <Translate id="return_login_form" />
                </Button>
            </Box>
        </form>
    );
};

export default ResetPasswordForm;

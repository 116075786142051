import * as React from 'react';

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const Routes = Loadable(React.lazy(() => import('./routes')));

const app: AppConfig = {
    name: APP,
    element: (tab) => <Routes tab={tab} />,
    locale,
};

export default app;

import { Tab } from '../store/global-tabs';
import { Locales } from '../types';
import accessesApp from './accesses';
import blockedCellApp from './blocked-cell';
import createPlannedWorkApp from './create-planned-work';
import electricitySuppliersApp from './electricity-suppliers';
import eprListApp from './epr/epr-list';
import eprViewApp from './epr/epr-view';
import equipmentApp from './equipment';
import errorBoundaryApp from './error-boundary';
import feedbackItemApp from './feedback-item';
import feedbacksApp from './feedbacks';
import gridApp from './grid';
import homeApp from './home';
import keyCoordinatorsApp from './key-coordinators';
import activeAlarmsApp from './monitoring/active-alarms';
import activeAlarmsByTypeApp from './monitoring/alarms-by-type';
import eventViewApp from './monitoring/event-view';
import eventsApp from './monitoring/events';
import eventsByTypeApp from './monitoring/events-by-type';
import notificationsApp from './notifications';
import plannedWorkViewApp from './planned-work-view';
import plannedWorksApp from './planned-works';
import positionViewApp from './position-view';
import positionsApp from './positions';
import positionsCommentsApp from './positions-comments';
import positionsStatisticsApp from './positions-statistics';
import profileApp from './profile';
import routesApp from './routes';
import sharingPositionsApp from './sharing-positions';
import timeOnBatteriesApp from './time-on-batteries';
import topologyApp from './topology';
import troubleTicketViewApp from './trouble-ticket-view';
import troubleTicketsApp from './trouble-tickets';
import visitorsApp from './visitors';
import widgetsApp from './widgets';
import workTypesMatrixApp from './work-types-matrix';

export const apps: AppConfig[] = [
    homeApp,
    errorBoundaryApp,
    routesApp,
    notificationsApp,
    gridApp,
    positionsApp,
    activeAlarmsApp,
    activeAlarmsByTypeApp,
    eventsApp,
    eventsByTypeApp,
    eprListApp,
    eprViewApp,
    eventViewApp,
    positionViewApp,
    accessesApp,
    timeOnBatteriesApp,
    blockedCellApp,
    sharingPositionsApp,
    positionsStatisticsApp,
    equipmentApp,
    profileApp,
    feedbacksApp,
    troubleTicketsApp,
    troubleTicketViewApp,
    plannedWorksApp,
    plannedWorkViewApp,
    feedbackItemApp,
    workTypesMatrixApp,
    keyCoordinatorsApp,
    createPlannedWorkApp,
    topologyApp,
    widgetsApp,
];

export type AppRoute = {
    app: string;
    element: (tab: Tab) => JSX.Element;
};

export type AppsRoutes = AppRoute[];

export type AppConfig = {
    name: string;
    element: (tab: Tab) => JSX.Element;
    locale?: Locales;
};

export const appsLocales: Locales = {
    ...homeApp?.locale,
    ...errorBoundaryApp?.locale,
    ...routesApp?.locale,
    ...notificationsApp?.locale,
    ...gridApp?.locale,
    ...positionsApp?.locale,
    ...activeAlarmsApp?.locale,
    ...activeAlarmsByTypeApp?.locale,
    ...eventsApp?.locale,
    ...eventsByTypeApp?.locale,
    ...eventViewApp?.locale,
    ...eprListApp?.locale,
    ...eprViewApp?.locale,
    ...positionViewApp?.locale,
    ...accessesApp?.locale,
    ...timeOnBatteriesApp?.locale,
    ...blockedCellApp?.locale,
    ...sharingPositionsApp?.locale,
    ...positionsStatisticsApp?.locale,
    ...equipmentApp?.locale,
    ...visitorsApp?.locale,
    ...profileApp?.locale,
    ...positionsCommentsApp?.locale,
    ...electricitySuppliersApp?.locale,
    ...feedbacksApp?.locale,
    ...troubleTicketsApp?.locale,
    ...troubleTicketViewApp?.locale,
    ...plannedWorksApp?.locale,
    ...plannedWorkViewApp?.locale,
    ...feedbackItemApp?.locale,
    ...workTypesMatrixApp?.locale,
    ...keyCoordinatorsApp?.locale,
    ...createPlannedWorkApp?.locale,
    ...topologyApp?.locale,
    ...widgetsApp?.locale,
};

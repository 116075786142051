import * as React from 'react';
import { ReactElement } from 'react-markdown';
import { useLocation } from 'react-router-dom';

type NavigationScrollProps = { children: ReactElement | null };

const NavigationScroll: React.FC<NavigationScrollProps> = (props) => {
    const { children } = props;
    const location = useLocation();
    const { pathname } = location;

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [pathname]);

    return children || null;
};

export default NavigationScroll;

import * as React from 'react';

import { AppConfig } from '../../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const AlarmsByType = Loadable(React.lazy(() => import('./alarms-by-type')));

const app: AppConfig = {
    name: APP,
    element: () => <AlarmsByType />,
    locale,
};

export default app;

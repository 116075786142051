import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Topology',
        ru: 'Топология',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_need_select_branch`]: {
        en: 'You need to select a branch',
        ru: 'Необходимо выбрать филиал',
    },
    [`${APP}_not_data`]: {
        en: 'Not data',
        ru: 'Нет данных',
    },
    [`${APP}_error_loading_data`]: {
        en: 'An error occurred while loading the data',
        ru: 'Произошла ошибка при загрузке данных',
    },
};

export default locale;

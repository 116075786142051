import * as React from 'react';

import { APP } from '../../constants';
import KeyCoordinatorsFormAddConnector from '../connectors/key-coordinators-form-add-connector';
import KeyCoordinatorsFormEditConnector from '../connectors/key-coordinators-form-edit-connector';

import Translate from 'components/extended/translate';
import TroubleTicketViewDialog from 'sub-apps/trouble-ticket-view/views/trouble-ticket-view-dialog';

type KeyCoordinatorsDialogProps = {
    open: boolean;
    onClose: () => void;
    coordinatorId?: number;
};

const KeyCoordinatorsDialog: React.FC<KeyCoordinatorsDialogProps> = (props) => {
    const { onClose, coordinatorId, open } = props;

    return (
        <TroubleTicketViewDialog
            onClose={onClose}
            open={open}
            title={coordinatorId === undefined ? <Translate app={APP} id="add" /> : <Translate app={APP} id="edit" />}
            fullWidth
            maxWidth="xs"
        >
            {coordinatorId === undefined ? (
                <KeyCoordinatorsFormAddConnector onClose={onClose} />
            ) : (
                <KeyCoordinatorsFormEditConnector onClose={onClose} />
            )}
        </TroubleTicketViewDialog>
    );
};

export default KeyCoordinatorsDialog;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Time on batteries',
        ru: 'Время на аккумуляторах',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_power_off_date`]: {
        en: 'Power off date',
        ru: 'Дата отключения питания',
    },
    [`${APP}_bs_down_date`]: {
        en: 'BS down date',
        ru: 'Дата падения БС',
    },
    [`${APP}_time_on_batteries`]: {
        en: 'Time on batteries',
        ru: 'Время жизни на аккумуляторе',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_no_data`]: {
        en: 'No Data',
        ru: 'Нет данных',
    },
    [`${APP}_minutes`]: {
        en: 'min.',
        ru: 'мин.',
    },
};

export default locale;

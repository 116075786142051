import * as React from 'react';

import { TopologyFilterState } from '../../hooks/use-topology-filter';
import TopologyBranchFilterConnector from './topology-branch-filter-connector';

import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';

type TopologyFilterToolbarProps = {
    filterValues: TopologyFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<TopologyFilterState>>;
};

const TopologyFilterToolbar: React.FC<TopologyFilterToolbarProps> = (props) => {
    const { filterValues, setFilterValues } = props;

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <TopologyBranchFilterConnector filterValues={filterValues} setFilterValues={setFilterValues} />
            </FilterDisplayItemWrapper>
        </FilterDisplayWrapper>
    );
};

export default TopologyFilterToolbar;

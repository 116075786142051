import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import { AvailableValue } from 'components/extended/data-grid/filter/types';
import { SmartQueueRoleResponseModel } from 'services/smart-queue/smart-queue-models';
import {
    useSmartQueueDictionaryRoleGetListQuery,
    useSmartQueueDictionaryRoleGroupGetListQuery,
} from 'services/smart-queue/smart-queue-services';

type TroubleTicketsRoleFilterProps<FilterState> = {
    groupRoleField: keyof FilterState;
    roleField: keyof FilterState;
    groupRoleValue: AvailableValue;
    roleValue: AvailableValue[];
    label: string | JSX.Element;
    expanded: boolean;
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
};

const typesRoleSingleSelect = [
    { id: '1', name: 'Группы ролей' },
    { id: '2', name: 'Роли' },
];

const rolesOptionsOverrides = (data: SmartQueueRoleResponseModel[] | undefined) =>
    data ? data.map((elem) => ({ id: elem.id, name: elem.name })) : [];

const TroubleTicketsRoleFilter = <FilterState,>(props: TroubleTicketsRoleFilterProps<FilterState>) => {
    const { groupRoleField, roleField, groupRoleValue, roleValue, label, expanded, setLocalFilterValues } = props;
    const [currentTypeRole, setCurrentTypeRole] = React.useState(
        roleValue.length > 0 ? typesRoleSingleSelect[1] : typesRoleSingleSelect[0],
    );
    const [rolesValue, setRolesValue] = React.useState('');

    const { data: roleGroupData } = useSmartQueueDictionaryRoleGroupGetListQuery();
    const { data: roleData, isFetching } = useSmartQueueDictionaryRoleGetListQuery({
        value: {
            role: rolesValue,
        },
        pagination: {
            rowPerPage: 10,
            pageNumber: 1,
        },
    });

    const options = React.useMemo(() => rolesOptionsOverrides(roleData?.data), [roleData?.data]);

    const handleTypeRoleChange = React.useCallback(
        (event) => {
            setCurrentTypeRole(() => {
                event.target.value === '1'
                    ? setLocalFilterValues((prevState) => ({ ...prevState, [roleField]: [] }))
                    : setLocalFilterValues((prevState) => ({ ...prevState, [groupRoleField]: { id: '', name: '' } }));
                return typesRoleSingleSelect.find((elem) => elem.id === event.target.value) ?? { id: '', name: '' };
            });
        },
        [groupRoleField, roleField, setLocalFilterValues],
    );

    const handleRoleGroupChange = React.useCallback(
        (event) =>
            setLocalFilterValues((prevState) => ({
                ...prevState,
                [groupRoleField]: roleGroupData?.data?.find((elem) => elem.id === event.target.value) ?? {
                    id: '',
                    name: '',
                },
            })),
        [groupRoleField, roleGroupData?.data, setLocalFilterValues],
    );

    const handleRolesChange = React.useCallback(
        (event: any, newValue: AvailableValue[]) => {
            setLocalFilterValues((prevState) => ({
                ...prevState,
                [roleField]: newValue,
            }));
        },
        [roleField, setLocalFilterValues],
    );

    return (
        <Accordion
            defaultExpanded={expanded}
            disableGutters
            sx={{ [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ minHeight: 60 }}>
                <Box>
                    <Typography>{label}</Typography>
                    <Typography>
                        <DisplayItemContent
                            valueLabel={
                                currentTypeRole.id === '1' ? groupRoleValue.name : roleValue.map((elem) => elem.name)
                            }
                        />
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl fullWidth>
                    <Select value={currentTypeRole.id} onChange={handleTypeRoleChange}>
                        {typesRoleSingleSelect.map((elem) => (
                            <MenuItem key={elem.id} value={elem.id}>
                                {elem.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {currentTypeRole.id === '1' && (
                    <Box sx={{ pt: 1.5 }}>
                        <FormControl fullWidth>
                            <InputLabel id="role-group-select-label">{currentTypeRole.name}</InputLabel>
                            <Select
                                labelId="role-group-select-label"
                                label={currentTypeRole.name}
                                value={groupRoleValue.id}
                                onChange={handleRoleGroupChange}
                            >
                                {roleGroupData?.data?.map((elem) => (
                                    <MenuItem key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {currentTypeRole.id === '2' && (
                    <Autocomplete
                        sx={{ pt: 1.5 }}
                        multiple
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        filterOptions={(x) => x}
                        options={options}
                        filterSelectedOptions
                        loading={isFetching}
                        value={roleValue}
                        onChange={handleRolesChange}
                        onInputChange={(event, newInputValue) => {
                            setRolesValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={currentTypeRole.name}
                                value={rolesValue}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default TroubleTicketsRoleFilter;

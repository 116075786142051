import * as React from 'react';

import Card, { CardProps } from '@mui/material/Card';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import CardHeader, { CardHeaderProps, cardHeaderClasses } from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { KeyedObject } from 'types';

const headerSX = {
    [`& .${cardHeaderClasses.action}`]: { mr: 0 },
};

export type MainCardProps = {
    border?: boolean;
    boxShadow?: boolean;
    children: React.ReactNode | string;
    style?: React.CSSProperties;
    content?: boolean;
    className?: string;
    contentClass?: string;
    contentSX?: CardContentProps['sx'];
    darkTitle?: boolean;
    sx?: CardProps['sx'];
    secondary?: CardHeaderProps['action'];
    shadow?: string;
    elevation?: number;
    title?: React.ReactNode | string;
} & KeyedObject;

const MainCard = React.forwardRef((props: MainCardProps, ref: React.Ref<HTMLDivElement>) => {
    const {
        border = true,
        boxShadow,
        children,
        content = true,
        contentClass = '',
        contentSX = {},
        darkTitle,
        secondary,
        shadow,
        sx = {},
        title,
        ...others
    } = props;

    const theme = useTheme();

    return (
        <Card
            ref={ref}
            {...others}
            sx={{
                border: border ? '1px solid' : 'none',
                borderColor:
                    theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                ':hover': {
                    boxShadow: () => {
                        if (boxShadow) {
                            const _shadow =
                                theme.palette.mode === 'dark'
                                    ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                                    : '0 2px 14px 0 rgb(32 40 45 / 8%)';
                            return shadow || _shadow;
                        }

                        return 'inherit';
                    },
                },
                ...sx,
            }}
        >
            {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
            {darkTitle && title && (
                <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
            )}

            {title && <Divider />}

            {content && (
                <CardContent sx={contentSX} className={contentClass}>
                    {children}
                </CardContent>
            )}
            {!content && children}
        </Card>
    );
});

export default MainCard;

import * as React from 'react';

import { APP } from '../../../constants';
import TroubleTicketViewDialog from '../../trouble-ticket-view-dialog';
import TroubleTicketViewChangeServiceStatusFormConnector from '../connectors/trouble-ticket-view-change-service-status-form-connector';

import Translate from 'components/extended/translate';

type TroubleTicketViewChangeServiceStatusDialogProps = {
    open: boolean;
    onClose: () => void;
    ttNumber: number;
};

const TroubleTicketViewChangeServiceStatusDialog: React.FC<TroubleTicketViewChangeServiceStatusDialogProps> = (
    props,
) => {
    const { open, onClose, ttNumber } = props;

    return (
        <TroubleTicketViewDialog
            onClose={onClose}
            open={open}
            title={<Translate app={APP} id="change_status" />}
            fullWidth
            maxWidth="xs"
        >
            <TroubleTicketViewChangeServiceStatusFormConnector onClose={onClose} ttNumber={ttNumber} />
        </TroubleTicketViewDialog>
    );
};

export default TroubleTicketViewChangeServiceStatusDialog;

import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { APP } from '../../../constants';

import Translate from 'components/extended/translate';
import PositionViewManagementCard from 'sub-apps/position-view/views/body/management/position-view-management-card';

type TroubleTicketViewManagementCardsAccordionProps = {
    data: {
        id: number;
        role: string;
        fullName: string;
        today: string | null;
        phone: string;
        altPhone: string;
    }[];
};

const TroubleTicketViewManagementCardsAccordion: React.FC<TroubleTicketViewManagementCardsAccordionProps> = (props) => {
    const { data } = props;

    return (
        <Accordion disableGutters variant="outlined">
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 40 }}>
                <Typography>
                    <Translate app={APP} id="management" />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction="column" spacing={{ xs: 1, lg: 2 }}>
                    {data.map((elem) => (
                        <Grid item key={elem.id}>
                            <PositionViewManagementCard
                                role={elem.role}
                                phone={elem.phone}
                                altPhone={elem.altPhone}
                                fullName={elem.fullName}
                                today={elem.today}
                            />
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default TroubleTicketViewManagementCardsAccordion;

import * as React from 'react';

import TroubleTicketViewEditResponsibleForm from '../forms/trouble-ticket-view-edit-responsible-form';

import {
    useSmartQueueHistoryRolesGetQuery,
    useSmartQueueResponsibleChangeMutation,
    useSmartQueueTicketResponsibleGetQuery,
} from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

type TroubleTicketViewEditResponsibleFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
};

export type TroubleTicketViewEditResponsibleFormInitialValues = {
    responsibleGroup: { id: number; name: string };
    comment: string;
    management: string;
    fullName: string;
    phone: string;
};

const TroubleTicketViewEditResponsibleFormConnector: React.FC<TroubleTicketViewEditResponsibleFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber } = props;

    const { data: historyRoles } = useSmartQueueHistoryRolesGetQuery(ttNumber);

    const [changeResponsible, { isLoading, data: responseMutation }] = useSmartQueueResponsibleChangeMutation();

    const { data } = useSmartQueueTicketResponsibleGetQuery(ttNumber);

    const initialValues: TroubleTicketViewEditResponsibleFormInitialValues = React.useMemo(
        () => ({
            responsibleGroup: data ? { id: data.data.roleId, name: data.data.role } : { id: 0, name: '' },
            comment: '',
            management: '',
            fullName: '',
            phone: '',
        }),
        [data],
    );

    const handleEditResponsible = React.useCallback(
        async (formData: TroubleTicketViewEditResponsibleFormInitialValues) => {
            const result = await changeResponsible({
                number: ttNumber,
                roleId: formData.responsibleGroup.id,
                comment: formData.comment,
            });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [changeResponsible, onClose, ttNumber],
    );

    if (data === undefined) return null;

    return (
        <TroubleTicketViewEditResponsibleForm
            onClose={onClose}
            submitCallback={handleEditResponsible}
            isLoading={isLoading}
            initialValues={initialValues}
            historyResponsibleGroup={historyRoles?.data}
            errors={responseMutation?.errors}
        />
    );
};

export default TroubleTicketViewEditResponsibleFormConnector;

import * as React from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import { APP } from '../../../constants';
import { TroubleTicketViewChangeStatusFormInitialValues } from '../connectors/trouble-ticket-view-change-service-status-form-connector';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import { SmartQueueServiceStatusResponseModelIEnumerableApiResponse } from 'services/smart-queue/smart-queue-models';
import * as Yup from 'yup';

type TroubleTicketViewChangeServiceStatusFormProps = {
    onClose: () => void;
    statuses: SmartQueueServiceStatusResponseModelIEnumerableApiResponse['data'];
    submitCallback: (formData: TroubleTicketViewChangeStatusFormInitialValues) => void;
    initialValues: TroubleTicketViewChangeStatusFormInitialValues;
    isLoading: boolean;
    errors?: string[];
};

const formId = 'trouble-ticket-view-change-service-status';

const TroubleTicketViewChangeServiceStatusForm: React.FC<TroubleTicketViewChangeServiceStatusFormProps> = (props) => {
    const { onClose, statuses, submitCallback, initialValues, errors, isLoading } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
        watch,
        setValue,
    } = useForm({
        defaultValues: initialValues,
        mode: 'all',
        resolver: yupResolver(
            Yup.object().shape({
                statusId: Yup.number().required('status_is_required'),
                comment: Yup.string().required('comment_is_required'),
                isClosed: Yup.boolean(),
            }),
        ),
    });

    const handleCloseTtClick = (
        field: ControllerRenderProps<TroubleTicketViewChangeStatusFormInitialValues, 'isClosed'>,
    ) => (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event.target.checked);
        if (event.target.checked) setValue('statusId', 1);
    };

    const isClosed = watch('isClosed');

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="statusId"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                                disabled={isClosed}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="status" />
                                </InputLabel>
                                <Select {...field} id={field.name} label={<Translate app={APP} id="status" />}>
                                    {statuses.map((elem) => (
                                        <MenuItem key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="isClosed"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox onChange={handleCloseTtClick(field)} checked={field.value} />}
                                label={<Translate app={APP} id="close_tt" />}
                                sx={{ marginTop: 1 }}
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="comment" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="comment" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="save" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TroubleTicketViewChangeServiceStatusForm;

import {
    PositionsAccessCreateRequestModel,
    PositionsAccessFilterValueRequestModelAccessColumnsFilterRequestModel,
    PositionsAccessResponseModelApiResponse,
    PositionsAccessResponseModelIEnumerableApiResponse,
    PositionsAccessUpdateRequestModel,
    PositionsAccountResponseModelApiResponse,
    PositionsBatteryHistoryFilterValueRequestModelBatteryHistoryColumnsFilterRequestModel,
    PositionsBatteryHistoryResponseModelIEnumerableApiResponse,
    PositionsBlockTypeResponseModelIEnumerableApiResponse,
    PositionsBlockedCellFilterValueRequestModelBlockedCellColumnsFilterRequestModel,
    PositionsBlockedCellResponseModelIEnumerableApiResponse,
    PositionsCommentCreateRequestModel,
    PositionsCommentExtendedResponseModelApiResponse,
    PositionsCommentPriorityResponseModelIEnumerableApiResponse,
    PositionsCommentResponseModelApiResponse,
    PositionsCommentResponseModelIEnumerableApiResponse,
    PositionsCommentUpdateRequestModel,
    PositionsElectricitySupplierCreateRequestModel,
    PositionsElectricitySupplierResponseModelApiResponse,
    PositionsElectricitySupplierResponseModelIEnumerableApiResponse,
    PositionsElectricitySupplierUpdateRequestModel,
    PositionsEquipmentFilterValueRequestModelEquipmentColumnsFilterForExportRequestModel,
    PositionsEquipmentFilterValueRequestModelEquipmentColumnsFilterRequestModel,
    PositionsEquipmentResponseModelIEnumerableApiResponse,
    PositionsEquipmentTypeResponseModelIEnumerableApiResponse,
    PositionsExtendedPositionResponseModelApiResponse,
    PositionsManagerCreateRequestModel,
    PositionsManagerResponseModelApiResponse,
    PositionsManagerResponseModelIEnumerableApiResponse,
    PositionsManagerUpdateRequestModel,
    PositionsOwnerResponseModelIEnumerableApiResponse,
    PositionsPositionChangePriorityRequestModel,
    PositionsPositionExtensionResponseModelIEnumerableApiResponse,
    PositionsPositionFilterValueRequestModelPositionColumnsFilterForExportRequestModel,
    PositionsPositionFilterValueRequestModelPositionColumnsFilterRequestModel,
    PositionsPositionFilterValueRequestModelPositionColumnsFilterSaveRequestModel,
    PositionsPositionResponseModelIEnumerableApiResponse,
    PositionsPriorityResponseModelIEnumerableApiResponse,
    PositionsRangeResponseModelIEnumerableApiResponse,
    PositionsSharingFilterValueRequestModelSharingColumnsFilterForExportRequestModel,
    PositionsSharingFilterValueRequestModelSharingColumnsFilterRequestModel,
    PositionsSharingResponseModelIEnumerableApiResponse,
    PositionsStatisticFilterCountResponseModelApiResponse,
    PositionsStatisticFilterValueRequestModel,
    PositionsStatisticFilterValueRequestModelStatisticColumnsFilterForExportRequestModel,
    PositionsStatisticFilterValueRequestModelStatisticColumnsFilterRequestModel,
    PositionsStatisticResponseModelIEnumerableApiResponse,
    PositionsStringApiResponse,
    PositionsSystemResponseModelIEnumerableApiResponse,
    PositionsTransportCreateRequestModel,
    PositionsTransportResponseModelApiResponse,
    PositionsTransportResponseModelIEnumerableApiResponse,
    PositionsTransportUpdateRequestModel,
    PositionsTypeResponseModelIEnumerableApiResponse,
    PositionsVendorResponseModelIEnumerableApiResponse,
    PositionsVisitorCreateRequestModel,
    PositionsVisitorResponseModelApiResponse,
    PositionsVisitorResponseModelIEnumerableApiResponse,
    PositionsVisitorUpdateRequestModel,
} from './positions-models';
import {
    POSITIONS_ACCESS,
    POSITIONS_ACCESS_GET_HISTORY,
    POSITIONS_ACCOUNT,
    POSITIONS_BATTERY_HISTORY,
    POSITIONS_BLOCKED_CELL,
    POSITIONS_COMMENT,
    POSITIONS_COMMENT_GET_HISTORY,
    POSITIONS_ELECTRICITY_SUPPLIER,
    POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY,
    POSITIONS_EQUIPMENT,
    POSITIONS_MANAGER,
    POSITIONS_MANAGER_GET_HISTORY,
    POSITIONS_POSITION,
    POSITIONS_POSITION_TYPE,
    POSITIONS_SHARING,
    POSITIONS_STATISTIC,
    POSITIONS_TRANSPORT,
    POSITIONS_TRANSPORT_GET_HISTORY,
    POSITIONS_VISITOR,
    POSITIONS_VISITOR_GET_HISTORY,
} from './tags';

import { createApi } from '@reduxjs/toolkit/query/react';
import { POSITIONS_SERVICE_URL } from 'services/constants';
import { fetchBaseQueryProtected } from 'services/fetch-base-query-protected';

export const positionsServices = createApi({
    reducerPath: 'positionsServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: POSITIONS_SERVICE_URL,
    }),
    tagTypes: [
        POSITIONS_ACCESS,
        POSITIONS_ACCESS_GET_HISTORY,
        POSITIONS_ACCOUNT,
        POSITIONS_BATTERY_HISTORY,
        POSITIONS_BLOCKED_CELL,
        POSITIONS_COMMENT,
        POSITIONS_COMMENT_GET_HISTORY,
        POSITIONS_ELECTRICITY_SUPPLIER,
        POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY,
        POSITIONS_EQUIPMENT,
        POSITIONS_MANAGER,
        POSITIONS_MANAGER_GET_HISTORY,
        POSITIONS_POSITION,
        POSITIONS_POSITION_TYPE,
        POSITIONS_SHARING,
        POSITIONS_STATISTIC,
        POSITIONS_TRANSPORT,
        POSITIONS_TRANSPORT_GET_HISTORY,
        POSITIONS_VISITOR,
        POSITIONS_VISITOR_GET_HISTORY,
    ],
    endpoints: (build) => ({
        // Access
        positionsAccessGet: build.query<PositionsAccessResponseModelApiResponse, number>({
            query: (accessId) => ({ url: `/api/Access/${accessId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_ACCESS, id }],
            keepUnusedDataFor: 0,
        }),
        positionsAccessGetListByPositionNumber: build.query<PositionsAccessResponseModelIEnumerableApiResponse, string>(
            {
                query: (positionNumber) => ({ url: `/api/Access/GetList/${positionNumber}` }),
                providesTags: (result, error, positionNumber) =>
                    result
                        ? [
                              ...result.data.map(({ id }) => ({ type: POSITIONS_ACCESS, id } as const)),
                              { type: POSITIONS_ACCESS, id: `LIST-${positionNumber}` },
                          ]
                        : [{ type: POSITIONS_ACCESS, id: `LIST-${positionNumber}` }],
            },
        ),
        positionsAccessGetList: build.query<
            PositionsAccessResponseModelIEnumerableApiResponse,
            PositionsAccessFilterValueRequestModelAccessColumnsFilterRequestModel
        >({
            query: (queryArg) => ({ url: '/api/Access/GetList', method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_ACCESS, id } as const)),
                          { type: POSITIONS_ACCESS, id: 'LIST' },
                      ]
                    : [{ type: POSITIONS_ACCESS, id: 'LIST' }],
        }),
        positionsAccessAdd: build.mutation<PositionsAccessResponseModelApiResponse, PositionsAccessCreateRequestModel>({
            query: (queryArg) => ({ url: `/api/Access`, method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_ACCESS, id: `LIST-${positionNumber}` },
                { type: POSITIONS_ACCESS, id: 'LIST' },
            ],
        }),
        positionsAccessUpdate: build.mutation<
            PositionsAccessResponseModelApiResponse,
            PositionsAccessUpdateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Access`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: POSITIONS_ACCESS, id: `LIST-${result.data.position}` },
                          { type: POSITIONS_ACCESS, id: 'LIST' },
                      ]
                    : [],
        }),
        positionsAccessDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (accessId) => ({ url: `/api/Access/${accessId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_ACCESS, id }],
        }),
        positionsAccessGetHistory: build.query<
            PositionsAccessResponseModelIEnumerableApiResponse,
            { positionNumber: string; rowPerPage: number; pageNumber: number }
        >({
            query: (queryArg) => ({
                url: `/api/Access/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_ACCESS_GET_HISTORY, id } as const)),
                          { type: POSITIONS_ACCESS_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_ACCESS_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
        // Account
        positionsAccountGet: build.query<PositionsAccountResponseModelApiResponse, boolean>({
            query: (isRawFilter) => ({ url: `/api/Account`, params: { isRawFilter } }),
            providesTags: [POSITIONS_ACCOUNT],
        }),
        positionsAccountSetDefaultFilter: build.mutation<
            PositionsStringApiResponse,
            PositionsPositionFilterValueRequestModelPositionColumnsFilterSaveRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Account/SetDefaultFilter`,
                method: 'POST',
                body: queryArg,
            }),
            invalidatesTags: () => [POSITIONS_ACCOUNT],
        }),
        // Battery History
        positionsBatteryHistoryGetListByPositionNumber: build.query<
            PositionsBatteryHistoryResponseModelIEnumerableApiResponse,
            string
        >({
            query: (positionNumber) => ({ url: `/api/BatteryHistory/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_BATTERY_HISTORY, id } as const)),
                          { type: POSITIONS_BATTERY_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_BATTERY_HISTORY, id: `LIST-${positionNumber}` }],
        }),
        positionsBatteryHistoryGetList: build.query<
            PositionsBatteryHistoryResponseModelIEnumerableApiResponse,
            PositionsBatteryHistoryFilterValueRequestModelBatteryHistoryColumnsFilterRequestModel
        >({
            query: (queryArg) => ({ url: '/api/BatteryHistory/GetList', method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_BATTERY_HISTORY, id } as const)),
                          { type: POSITIONS_BATTERY_HISTORY, id: 'LIST' },
                      ]
                    : [{ type: POSITIONS_BATTERY_HISTORY, id: 'LIST' }],
        }),
        // Blocked cell
        positionsBlockedCellGetList: build.query<
            PositionsBlockedCellResponseModelIEnumerableApiResponse,
            PositionsBlockedCellFilterValueRequestModelBlockedCellColumnsFilterRequestModel
        >({
            query: (queryArg) => ({ url: '/api/BlockedCell/GetList', method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_BLOCKED_CELL, id } as const)),
                          { type: POSITIONS_BLOCKED_CELL, id: 'LIST' },
                      ]
                    : [{ type: POSITIONS_BLOCKED_CELL, id: 'LIST' }],
        }),
        // Comment
        positionsCommentGet: build.query<PositionsCommentExtendedResponseModelApiResponse, number>({
            query: (commentId) => ({ url: `/api/Comment/${commentId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_COMMENT, id }],
            keepUnusedDataFor: 0,
        }),
        positionsCommentGetList: build.query<PositionsCommentResponseModelIEnumerableApiResponse, string>({
            query: (positionNumber) => ({ url: `/api/Comment/GetList/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_COMMENT, id } as const)),
                          { type: POSITIONS_COMMENT, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_COMMENT, id: `LIST-${positionNumber}` }],
        }),
        positionsCommentAdd: build.mutation<
            PositionsCommentResponseModelApiResponse,
            PositionsCommentCreateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Comment`, method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_COMMENT, id: `LIST-${positionNumber}` },
            ],
        }),
        positionsCommentUpdate: build.mutation<
            PositionsCommentResponseModelApiResponse,
            PositionsCommentUpdateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Comment`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result) =>
                result ? [{ type: POSITIONS_COMMENT, id: `LIST-${result.data.position}` }] : [],
        }),
        positionsCommentDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (commentId) => ({ url: `/api/Comment/${commentId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_COMMENT, id }],
        }),
        positionsCommentGetHistory: build.query<
            PositionsCommentResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Comment/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_COMMENT_GET_HISTORY, id } as const)),
                          { type: POSITIONS_COMMENT_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_COMMENT_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
        // Dictionary
        positionsDictionaryPriorityGetList: build.query<PositionsPriorityResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Priority/GetList` }),
        }),
        positionsDictionarySystemGetList: build.query<PositionsSystemResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/System/GetList` }),
        }),
        positionsDictionaryCommentPriorityGetList: build.query<
            PositionsCommentPriorityResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/CommentPriority/GetList` }),
        }),
        positionsDictionaryTypeGetList: build.query<PositionsTypeResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Type/GetList` }),
        }),
        positionsDictionaryRangeGetList: build.query<PositionsRangeResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Range/GetList` }),
        }),
        positionsDictionaryOwnerGetList: build.query<PositionsOwnerResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Owner/GetList` }),
        }),
        positionsDictionaryEquipmentTypeGetList: build.query<
            PositionsEquipmentTypeResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/EquipmentType/GetList` }),
        }),
        positionsDictionaryVendorGetList: build.query<PositionsVendorResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Vendor/GetList` }),
        }),
        positionsDictionaryBlockTypeGetList: build.query<PositionsBlockTypeResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/BlockType/GetList` }),
        }),
        // Electricity Suppliers
        positionsElectricitySupplierGet: build.query<PositionsElectricitySupplierResponseModelApiResponse, number>({
            query: (supplierId) => ({ url: `/api/ElectricitySupplier/${supplierId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_ELECTRICITY_SUPPLIER, id }],
            keepUnusedDataFor: 0,
        }),
        positionsElectricitySupplierGetList: build.query<
            PositionsElectricitySupplierResponseModelIEnumerableApiResponse,
            string
        >({
            query: (positionNumber) => ({ url: `/api/ElectricitySupplier/GetList/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_ELECTRICITY_SUPPLIER, id } as const)),
                          { type: POSITIONS_ELECTRICITY_SUPPLIER, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_ELECTRICITY_SUPPLIER, id: `LIST-${positionNumber}` }],
        }),
        positionsElectricitySupplierAdd: build.mutation<
            PositionsElectricitySupplierResponseModelApiResponse,
            PositionsElectricitySupplierCreateRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/ElectricitySupplier`,
                method: 'POST',
                body: queryArg,
            }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_ELECTRICITY_SUPPLIER, id: `LIST-${positionNumber}` },
            ],
        }),
        positionsElectricitySupplierUpdate: build.mutation<
            PositionsElectricitySupplierResponseModelApiResponse,
            PositionsElectricitySupplierUpdateRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/ElectricitySupplier`,
                method: 'PUT',
                body: queryArg,
            }),
            invalidatesTags: (result) =>
                result ? [{ type: POSITIONS_ELECTRICITY_SUPPLIER, id: `LIST-${result.data.position}` }] : [],
        }),
        positionsElectricitySupplierDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (supplierId) => ({ url: `/api/ElectricitySupplier/${supplierId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_ELECTRICITY_SUPPLIER, id }],
        }),
        positionsElectricitySupplierGetHistory: build.query<
            PositionsElectricitySupplierResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/ElectricitySupplier/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(
                              ({ id }) => ({ type: POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY, id } as const),
                          ),
                          { type: POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_ELECTRICITY_SUPPLIER_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
        // Equipment
        positionsEquipmentGetList: build.query<
            PositionsEquipmentResponseModelIEnumerableApiResponse,
            PositionsEquipmentFilterValueRequestModelEquipmentColumnsFilterRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Equipment/GetList`,
                method: 'POST',
                body: queryArg,
            }),
            providesTags: (result, error, { value: { position: positionNumber } }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_EQUIPMENT, id } as const)),
                          { type: POSITIONS_EQUIPMENT, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_EQUIPMENT, id: `LIST-${positionNumber}` }],
        }),
        positionsEquipmentExport: build.query<
            unknown,
            PositionsEquipmentFilterValueRequestModelEquipmentColumnsFilterForExportRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Equipment/ExportToXlsx`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        // Manager
        positionsManagerGet: build.query<PositionsManagerResponseModelApiResponse, number>({
            query: (managerId) => ({ url: `/api/Manager/${managerId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_MANAGER, id }],
            keepUnusedDataFor: 0,
        }),
        positionsManagerGetList: build.query<PositionsManagerResponseModelIEnumerableApiResponse, string>({
            query: (positionNumber) => ({ url: `/api/Manager/GetList/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_MANAGER, id } as const)),
                          { type: POSITIONS_MANAGER, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_MANAGER, id: `LIST-${positionNumber}` }],
        }),
        positionsManagerAdd: build.mutation<
            PositionsManagerResponseModelApiResponse,
            PositionsManagerCreateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Manager`, method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_MANAGER, id: `LIST-${positionNumber}` },
            ],
        }),
        positionsManagerUpdate: build.mutation<
            PositionsManagerResponseModelApiResponse,
            PositionsManagerUpdateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Manager`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result) =>
                result ? [{ type: POSITIONS_MANAGER, id: `LIST-${result.data.position}` }] : [],
        }),
        positionsManagerDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (managerId) => ({ url: `/api/Manager/${managerId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_MANAGER, id }],
        }),
        positionsManagerGetHistory: build.query<
            PositionsManagerResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Manager/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_MANAGER_GET_HISTORY, id } as const)),
                          { type: POSITIONS_MANAGER_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_MANAGER_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
        // Position
        positionsPositionGet: build.query<PositionsExtendedPositionResponseModelApiResponse, string>({
            query: (positionNumber) => ({ url: `/api/Position/${positionNumber}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_POSITION, id }],
        }),
        positionsPositionGetList: build.query<
            PositionsPositionResponseModelIEnumerableApiResponse,
            PositionsPositionFilterValueRequestModelPositionColumnsFilterRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Position/GetList`,
                method: 'POST',
                body: queryArg,
            }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(
                              ({ number: positionNumber }) =>
                                  ({ type: POSITIONS_POSITION, id: positionNumber } as const),
                          ),
                          { type: POSITIONS_POSITION, id: 'LIST' },
                      ]
                    : [{ type: POSITIONS_POSITION, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        positionsPositionTypeGetList: build.query<
            PositionsPositionExtensionResponseModelIEnumerableApiResponse,
            string
        >({
            query: (positionNumber) => ({ url: `/api/Position/Type/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_POSITION_TYPE, id } as const)),
                          { type: POSITIONS_POSITION_TYPE, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_POSITION_TYPE, id: `LIST-${positionNumber}` }],
        }),
        positionsPositionExport: build.query<
            unknown,
            PositionsPositionFilterValueRequestModelPositionColumnsFilterForExportRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Position/ExportToXlsx`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        positionsPositionChangePriority: build.mutation<
            PositionsStringApiResponse,
            PositionsPositionChangePriorityRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Position/ChangePriority`,
                method: 'POST',
                body: queryArg,
            }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_POSITION, id: positionNumber },
                { type: POSITIONS_POSITION, id: 'LIST' },
            ],
        }),
        // Sharing
        positionsSharingGetList: build.query<
            PositionsSharingResponseModelIEnumerableApiResponse,
            PositionsSharingFilterValueRequestModelSharingColumnsFilterRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Sharing/GetList`,
                method: 'POST',
                body: queryArg,
            }),
            providesTags: (result, error, { value: { position: positionNumber } }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_SHARING, id } as const)),
                          { type: POSITIONS_SHARING, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_SHARING, id: `LIST-${positionNumber}` }],
        }),
        positionsSharingExport: build.query<
            unknown,
            PositionsSharingFilterValueRequestModelSharingColumnsFilterForExportRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Sharing/ExportToXlsx`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        // Statistics
        positionsStatisticPositionGetList: build.query<
            PositionsStatisticResponseModelIEnumerableApiResponse,
            PositionsStatisticFilterValueRequestModelStatisticColumnsFilterRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Statistic/GetAlarmsOnPositionList`,
                method: 'POST',
                body: queryArg,
            }),
            providesTags: (result, error, { value: { position: positionNumber } }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_STATISTIC, id } as const)),
                          { type: POSITIONS_STATISTIC, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_STATISTIC, id: `LIST-${positionNumber}` }],
        }),
        positionsStatisticPositionCount: build.mutation<
            PositionsStatisticFilterCountResponseModelApiResponse,
            PositionsStatisticFilterValueRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Statistic/GetAlarmsOnPositionCount`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        positionsStatisticExport: build.query<
            unknown,
            PositionsStatisticFilterValueRequestModelStatisticColumnsFilterForExportRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Statistic/ExportToXlsx`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        // Transport
        positionsTransportGet: build.query<PositionsTransportResponseModelApiResponse, number>({
            query: (transportId) => ({ url: `/api/Transport/${transportId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_TRANSPORT, id }],
            keepUnusedDataFor: 0,
        }),
        positionsTransportGetList: build.query<PositionsTransportResponseModelIEnumerableApiResponse, string>({
            query: (positionNumber) => ({ url: `/api/Transport/GetList/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_TRANSPORT, id } as const)),
                          { type: POSITIONS_TRANSPORT, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_TRANSPORT, id: `LIST-${positionNumber}` }],
        }),
        positionsTransportAdd: build.mutation<
            PositionsTransportResponseModelApiResponse,
            PositionsTransportCreateRequestModel
        >({
            query: (queryArg) => ({
                url: `/api/Transport`,
                method: 'POST',
                body: queryArg,
            }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_TRANSPORT, id: `LIST-${positionNumber}` },
            ],
        }),
        positionsTransportUpdate: build.mutation<
            PositionsTransportResponseModelApiResponse,
            PositionsTransportUpdateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Transport`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result) =>
                result ? [{ type: POSITIONS_TRANSPORT, id: `LIST-${result.data.position}` }] : [],
        }),
        positionsTransportDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (transportId) => ({ url: `/api/Transport/${transportId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_TRANSPORT, id }],
        }),
        positionsTransportGetHistory: build.query<
            PositionsTransportResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Transport/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_TRANSPORT_GET_HISTORY, id } as const)),
                          { type: POSITIONS_TRANSPORT_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_TRANSPORT_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
        // Visitor
        positionsVisitorGet: build.query<PositionsVisitorResponseModelApiResponse, number>({
            query: (visitorId) => ({ url: `/api/Visitor/${visitorId}` }),
            providesTags: (result, error, id) => [{ type: POSITIONS_VISITOR, id }],
            keepUnusedDataFor: 0,
        }),
        positionsVisitorGetList: build.query<PositionsVisitorResponseModelIEnumerableApiResponse, string>({
            query: (positionNumber) => ({ url: `/api/Visitor/GetList/${positionNumber}` }),
            providesTags: (result, error, positionNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_VISITOR, id } as const)),
                          { type: POSITIONS_VISITOR, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_VISITOR, id: `LIST-${positionNumber}` }],
        }),
        positionsVisitorAdd: build.mutation<
            PositionsVisitorResponseModelApiResponse,
            PositionsVisitorCreateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Visitor`, method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, { position: positionNumber }) => [
                { type: POSITIONS_VISITOR, id: `LIST-${positionNumber}` },
            ],
        }),
        positionsVisitorUpdate: build.mutation<
            PositionsVisitorResponseModelApiResponse,
            PositionsVisitorUpdateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Visitor`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result) =>
                result ? [{ type: POSITIONS_VISITOR, id: `LIST-${result.data.position}` }] : [],
        }),
        positionsVisitorDelete: build.mutation<PositionsStringApiResponse, number>({
            query: (visitorId) => ({ url: `/api/Visitor/${visitorId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, id) => [{ type: POSITIONS_VISITOR, id }],
        }),
        positionsVisitorGetHistory: build.query<
            PositionsVisitorResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Visitor/GetHistory/${queryArg.positionNumber}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { positionNumber }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: POSITIONS_VISITOR_GET_HISTORY, id } as const)),
                          { type: POSITIONS_VISITOR_GET_HISTORY, id: `LIST-${positionNumber}` },
                      ]
                    : [{ type: POSITIONS_VISITOR_GET_HISTORY, id: `LIST-${positionNumber}` }],
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    usePositionsAccessGetQuery,
    usePositionsAccessGetListByPositionNumberQuery,
    usePositionsAccessGetListQuery,
    usePositionsAccessAddMutation,
    usePositionsAccessUpdateMutation,
    usePositionsAccessDeleteMutation,
    usePositionsAccessGetHistoryQuery,
    usePositionsAccountGetQuery,
    usePositionsAccountSetDefaultFilterMutation,
    usePositionsBatteryHistoryGetListByPositionNumberQuery,
    usePositionsBatteryHistoryGetListQuery,
    usePositionsBlockedCellGetListQuery,
    usePositionsCommentGetQuery,
    usePositionsCommentDeleteMutation,
    usePositionsCommentGetListQuery,
    usePositionsCommentGetHistoryQuery,
    usePositionsCommentAddMutation,
    usePositionsCommentUpdateMutation,
    usePositionsDictionaryPriorityGetListQuery,
    usePositionsDictionarySystemGetListQuery,
    usePositionsDictionaryCommentPriorityGetListQuery,
    usePositionsDictionaryTypeGetListQuery,
    usePositionsDictionaryRangeGetListQuery,
    usePositionsDictionaryOwnerGetListQuery,
    usePositionsDictionaryEquipmentTypeGetListQuery,
    usePositionsDictionaryVendorGetListQuery,
    usePositionsDictionaryBlockTypeGetListQuery,
    usePositionsElectricitySupplierGetQuery,
    usePositionsElectricitySupplierDeleteMutation,
    usePositionsElectricitySupplierGetListQuery,
    usePositionsElectricitySupplierGetHistoryQuery,
    usePositionsElectricitySupplierAddMutation,
    usePositionsElectricitySupplierUpdateMutation,
    usePositionsEquipmentGetListQuery,
    useLazyPositionsEquipmentExportQuery,
    usePositionsManagerGetQuery,
    usePositionsManagerDeleteMutation,
    usePositionsManagerGetListQuery,
    usePositionsManagerGetHistoryQuery,
    usePositionsManagerAddMutation,
    usePositionsManagerUpdateMutation,
    usePositionsPositionGetQuery,
    usePositionsPositionGetListQuery,
    usePositionsPositionTypeGetListQuery,
    useLazyPositionsPositionExportQuery,
    usePositionsPositionChangePriorityMutation,
    usePositionsSharingGetListQuery,
    useLazyPositionsSharingExportQuery,
    usePositionsStatisticPositionGetListQuery,
    usePositionsStatisticPositionCountMutation,
    useLazyPositionsStatisticExportQuery,
    usePositionsTransportGetQuery,
    usePositionsTransportDeleteMutation,
    usePositionsTransportGetListQuery,
    usePositionsTransportGetHistoryQuery,
    usePositionsTransportAddMutation,
    usePositionsTransportUpdateMutation,
    usePositionsVisitorGetQuery,
    usePositionsVisitorDeleteMutation,
    usePositionsVisitorGetListQuery,
    usePositionsVisitorGetHistoryQuery,
    usePositionsVisitorAddMutation,
    usePositionsVisitorUpdateMutation,
} = positionsServices;

import * as React from 'react';

import { APP } from '../../constants';
import CreatePlannedWorkLoadTemplatesConnector from '../connectors/create-planned-work-load-templates-connector';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';
import TroubleTicketViewDialog from 'sub-apps/trouble-ticket-view/views/trouble-ticket-view-dialog';

type CreatePlannedWorkLoadTemplateDialogProps = {
    open: boolean;
    onClose: () => void;
    setFormValues: React.Dispatch<React.SetStateAction<CreatePlannedWorkFormInputs>>;
};

const CreatePlannedWorkLoadTemplateDialog: React.FC<CreatePlannedWorkLoadTemplateDialogProps> = (props) => {
    const { open, onClose, setFormValues } = props;

    return (
        <TroubleTicketViewDialog
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            open={open}
            title={<Translate app={APP} id="save_template" />}
        >
            <CreatePlannedWorkLoadTemplatesConnector onClose={onClose} setFormValues={setFormValues} />
        </TroubleTicketViewDialog>
    );
};

export default CreatePlannedWorkLoadTemplateDialog;

import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { KeyCoordinatorsFilterState } from '../../hooks/use-key-coordinators-filter';
import KeyCoordinatorsRegionOrBranchFilterConnector from './key-coordinators-region-or-branch-filter-connector';

import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import Translate from 'components/extended/translate';

type KeyCoordinatorsFilterToolbarProps = {
    filterValues: KeyCoordinatorsFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<KeyCoordinatorsFilterState>>;
    handleAddClick: () => void;
};

const KeyCoordinatorsFilterToolbar: React.FC<KeyCoordinatorsFilterToolbarProps> = (props) => {
    const { filterValues, setFilterValues, handleAddClick } = props;

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <KeyCoordinatorsRegionOrBranchFilterConnector
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate app={APP} id="add" />}>
                    <IconButton onClick={handleAddClick}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </FilterDisplayWrapper>
    );
};

export default KeyCoordinatorsFilterToolbar;

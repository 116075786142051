import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { APP, TroubleTicketVariantsEnum } from '../../../../constants';
import TroubleTicketViewCommentsConnector from '../connectors/trouble-ticket-view-comments-connector';

import Translate from 'components/extended/translate';

type TroubleTicketViewCommentsDialogProps = {
    open: boolean;
    onClose: () => void;
    ttNumber: number;
};

const TroubleTicketViewCommentsDialog: React.FC<TroubleTicketViewCommentsDialogProps> = (props) => {
    const { open, onClose, ttNumber } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            fullScreen
            sx={{ [`.${dialogClasses.paper}`]: { padding: 0 } }}
        >
            <DialogTitle
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1, pl: 2, pr: 1 }}
            >
                <Typography>
                    <Translate app={APP} id="comments" />
                </Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <TroubleTicketViewCommentsConnector variant={TroubleTicketVariantsEnum.Table} ttNumber={ttNumber} />
            </DialogContent>
        </Dialog>
    );
};

export default TroubleTicketViewCommentsDialog;

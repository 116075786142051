import * as React from 'react';

import { WorkTypesMatrixFilterState } from '../../hooks/use-work-types-matrix-filter';
import WorkTypesMatrixObjectFilter from './work-types-matrix-object-filter';

type WorkTypesMatrixObjectFilterConnectorProps = {
    filterValues: WorkTypesMatrixFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<WorkTypesMatrixFilterState>>;
};

const data = [
    { id: 1, name: 'IMC' },
    { id: 2, name: 'NSS (CS Core)' },
    { id: 3, name: 'TVE' },
];

const WorkTypesMatrixObjectFilterConnector: React.FC<WorkTypesMatrixObjectFilterConnectorProps> = (props) => {
    const { filterValues, setFilterValues } = props;
    // TODO тут должен быть запрос за словарем
    return (
        <WorkTypesMatrixObjectFilter
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            availableValues={data}
        />
    );
};

export default WorkTypesMatrixObjectFilterConnector;

import * as React from 'react';

import PositionsStatisticsFilterDialog from '../views/filter/positions-statistics-filter-dialog';
import PositionsStatisticsFilterToolbar from '../views/filter/positions-statistics-filter-toolbar';

import {
    FilterCheckBoxStateValue,
    FilterDateStateValue,
    FilterSearchStateValue,
    FilterSwitchStateValue,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type PositionsStatisticsFilterState = {
    search: FilterSearchStateValue;
    isTraffic: FilterSwitchStateValue;
    isPower: FilterSwitchStateValue;
    isDoor: FilterSwitchStateValue;
    isTemp: FilterSwitchStateValue;
    startDate: FilterDateStateValue;
    endDate: FilterDateStateValue;
    branches: FilterCheckBoxStateValue;
    types: FilterCheckBoxStateValue;
    positionNumber?: FilterSearchStateValue;
};

const usePositionsStatisticsFilter = (positionNumber?: string) => {
    const initialState: PositionsStatisticsFilterState = React.useMemo(
        (): PositionsStatisticsFilterState => ({
            search: null,
            positionNumber,
            isTraffic: { id: false, name: '' },
            isPower: { id: false, name: '' },
            isDoor: { id: false, name: '' },
            isTemp: { id: false, name: '' },
            startDate: { id: null, name: null },
            endDate: { id: null, name: null },
            branches: null,
            types: null,
        }),
        [positionNumber],
    );

    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof PositionsStatisticsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof PositionsStatisticsFilterState>('search');

    return {
        PositionsStatisticsFilterDialog: () => (
            <PositionsStatisticsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        PositionsStatisticsFilterToolbar: () => (
            <PositionsStatisticsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
                variant={positionNumber ? 'position-view' : 'app'}
            />
        ),
        filterValues,
    };
};

export default usePositionsStatisticsFilter;

import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';

import { IconMenu2 } from '@tabler/icons';
import GlobalTabs from 'components/extended/global-tabs/global-tabs';

type HeaderProps = {
    handleLeftDrawerToggle: () => void;
};

const Header: React.FC<HeaderProps> = (props) => {
    const { handleLeftDrawerToggle } = props;
    const theme = useTheme();

    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="nowrap"
        >
            <Grid item flexGrow={1}>
                <Grid container spacing={0} direction="column">
                    <Grid item flex="0 1 auto">
                        <Grid container alignItems="center" spacing={0} sx={{ minHeight: 54 }}>
                            <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        transition: 'all .2s ease-in-out',
                                        background:
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.dark.main
                                                : theme.palette.secondary.light,
                                        color:
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.secondary.main
                                                : theme.palette.secondary.dark,
                                        '&:hover': {
                                            background:
                                                theme.palette.mode === 'dark'
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.secondary.dark,
                                            color: theme.palette.secondary.light,
                                        },
                                    }}
                                    onClick={handleLeftDrawerToggle}
                                    color="inherit"
                                >
                                    <IconMenu2 stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ButtonBase>
                            <GlobalTabs />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;

import * as React from 'react';

import PositionsStatisticsTable from './positions-statistics-table';

import MainCard from 'components/cards/main-card';

type PositionsStatisticsTableContainerProps = {
    positionNumber?: string;
};

const PositionsStatisticsTableContainer: React.FC<PositionsStatisticsTableContainerProps> = (props) => {
    const { positionNumber } = props;

    return positionNumber ? (
        <MainCard sx={{ width: '100%', position: 'relative' }}>
            <PositionsStatisticsTable positionNumber={positionNumber} />
        </MainCard>
    ) : (
        <PositionsStatisticsTable />
    );
};

export default PositionsStatisticsTableContainer;

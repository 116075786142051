import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}`]: {
        en: 'Home',
        ru: 'Главная',
    },
    [`${APP}_news`]: {
        en: 'News',
        ru: 'Новости',
    },
    [`${APP}_birthdays`]: {
        en: 'Birthdays',
        ru: 'Именинники',
    },
    [`${APP}_today`]: {
        en: 'Today',
        ru: 'Сегодня',
    },
    [`${APP}_news_not_found`]: {
        en: 'News not found...',
        ru: 'Нет новостей...',
    },
    [`${APP}_add_news`]: {
        en: 'Add news',
        ru: 'Добавить новость',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_content`]: {
        en: 'Full text',
        ru: 'Полная описание',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Короткое описание',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_description_is_required`]: {
        en: 'Description is required',
        ru: 'Короткое описание обязательно',
    },
    [`${APP}_content_is_required`]: {
        en: 'Full text is required',
        ru: 'Полное описание обязательно',
    },
    [`${APP}_title_is_required`]: {
        en: 'Title is required',
        ru: 'Заголовок обязателен',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Заголовок',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_required_image`]: {
        en: 'Image required',
        ru: 'Изображение обязательно',
    },
    [`${APP}_drag_and_upload`]: {
        en: 'Drag and drop an image here or click',
        ru: 'Перетащите изображения в это поле или нажмите для выбора с компьютера',
    },
};

export default locale;

import { AppConfig } from '../../index';
import { APP } from './constants';
import EprList from './epr-list';
import locale from './locales/locale';

const app: AppConfig = {
    name: APP,
    element: () => <EprList />,
    locale,
};

export default app;

import * as React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

import { useRowMenu } from './row-menu';

type RowMenuItemProps = {
    title: React.ReactNode;
    onClick: () => void;
    disable?: boolean;
    Icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
};

const RowMenuItem: React.FC<RowMenuItemProps> = (props) => {
    const { title, onClick, disable, Icon } = props;

    const { closeMenu } = useRowMenu();

    const handleCloseItemClick = React.useCallback(
        (callback: any) => () => {
            callback();
            closeMenu();
        },
        [closeMenu],
    );

    return (
        <MenuItem onClick={handleCloseItemClick(onClick)} disabled={disable}>
            {Icon && (
                <ListItemIcon>
                    <Icon fontSize="small" />
                </ListItemIcon>
            )}
            <ListItemText>{title}</ListItemText>
        </MenuItem>
    );
};

export default RowMenuItem;

import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import config from 'config';
import useSelector from 'hooks/use-app-selector';
import useGlobalTabs from 'hooks/use-global-tabs';
import { TabId } from 'store/global-tabs';
import { globalTabsActiveSelector, globalTabsSelector } from 'store/global-tabs/selectors';

const GlobalTabs: React.FC = () => {
    const tabs = useSelector(globalTabsSelector);
    const activeTab = useSelector(globalTabsActiveSelector);
    const { closeTab, setActiveTab } = useGlobalTabs();

    React.useEffect(() => {
        const title = activeTab?.name ?? config.appName;
        if (document.title !== title) document.title = title;
    }, [activeTab]);

    const handleCloseTab = React.useCallback(
        (key: TabId) => (event: React.MouseEvent<HTMLButtonElement>) => {
            closeTab(key);
            event.stopPropagation();
        },
        [closeTab],
    );

    const handleChange = React.useCallback((event: React.SyntheticEvent, key: TabId) => setActiveTab(key), [
        setActiveTab,
    ]);

    return (
        <Box
            sx={{
                maxWidth: '100%',
            }}
        >
            <Tabs
                value={activeTab?.id}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    [`& .${tabsClasses.flexContainer}`]: {
                        borderBottom: 0,
                        borderColor: 'divider',
                    },
                    minHeight: 40,
                    maxWidth: 'calc(100vw - 54px)',
                    mb: -0.125,
                    px: 2,
                    pt: 2,
                    pr: 0,
                }}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.id}
                        value={tab.id}
                        icon={
                            tab?.icon ? (
                                <Icon
                                    fontSize="small"
                                    sx={{
                                        fontSize: '1em !important',
                                        marginRight: (theme) => `${tab.name ? theme.spacing(1) : 0} !important`,
                                    }}
                                >
                                    {tab.icon}
                                </Icon>
                            ) : undefined
                        }
                        disableRipple
                        sx={(theme) => ({
                            minHeight: 40,
                            minWidth: 30,
                            textTransform: 'none',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            borderBottomWidth: 0,
                            borderColor: 'divider',
                            color: theme.palette.primary.dark,
                            mb: -0.125,
                            mx: 0.5,
                            padding: theme.spacing(1, tab.isClosable ? 3 : 2, 1, 2),
                            [`&.${tabClasses.selected}`]: {
                                backgroundColor: theme.typography.mainContent.backgroundColor,
                                color: theme.palette.primary.main,
                            },
                        })}
                        iconPosition="start"
                        component="div"
                        label={
                            <span>
                                {tab.name}
                                {tab.isClosable && (
                                    <IconButton
                                        size="small"
                                        onClick={handleCloseTab(tab.id)}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            color: 'inherit',
                                        }}
                                    >
                                        <CloseIcon
                                            sx={{
                                                width: 12,
                                                height: 12,
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </span>
                        }
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default GlobalTabs;

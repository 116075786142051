import * as React from 'react';

import { Refresh } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import AutoRefetch from './data-grid-auto-refetch';

type RefetchBarProps = {
    refetchCallback: () => void;
    refetchTime?: number;
    forcedRefetch?: boolean;
    autoRefetch?: boolean;
    isFetching?: boolean;
};

const RefetchBar: React.FC<RefetchBarProps> = (props) => {
    const { refetchTime, refetchCallback, forcedRefetch, autoRefetch, isFetching } = props;
    return (
        <>
            {autoRefetch && refetchCallback !== undefined ? (
                <AutoRefetch refetchCallback={refetchCallback} refetchTime={refetchTime} isFetching={isFetching} />
            ) : null}
            {forcedRefetch && refetchCallback !== undefined ? (
                <IconButton sx={{ marginRight: '0.5em' }} onClick={refetchCallback}>
                    <Refresh />
                </IconButton>
            ) : null}
        </>
    );
};

export default RefetchBar;

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';
import SharingPositions from './sharing-positions';

const app: AppConfig = {
    name: APP,
    element: () => <SharingPositions />,
    locale,
};

export default app;

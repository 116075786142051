import * as React from 'react';

import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';

import { APP } from '../../../constants';

import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

type TroubleTicketViewCommentsTableProps = {
    data: {
        id: number;
        action: string;
        comment: string;
        user: string;
        role: string;
        createdDate: string;
    }[];
};

const columns: GridColDef[] = [
    {
        renderHeader: columnLocalization(`${APP}_created_date`),
        renderCell: renderDateCell('dd.MM.yyyy HH:mm'),
        field: 'createdDate',
        flex: 0.3,
        minWidth: 120,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: columnLocalization(`${APP}_role`),
        field: 'role',
        flex: 0.3,
        minWidth: 100,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: columnLocalization(`${APP}_full_name`),
        field: 'user',
        flex: 0.5,
        minWidth: 120,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: columnLocalization(`${APP}_action`),
        field: 'action',
        flex: 0.5,
        minWidth: 120,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: columnLocalization(`${APP}_comment`),
        field: 'comment',
        flex: 0.8,
        minWidth: 150,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
];

const TroubleTicketViewCommentsTable: React.FC<TroubleTicketViewCommentsTableProps> = (props) => {
    const { data } = props;

    return (
        <DataGrid
            density="compact"
            autoHeight
            rows={data}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            getRowHeight={() => 'auto'}
            components={{ NoRowsOverlay }}
            sx={{
                [`& .${gridClasses.cell}`]: {
                    py: 0.5,
                },
            }}
        />
    );
};

export default TroubleTicketViewCommentsTable;

import * as React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useWorkTypesMatrixFilter from '../hooks/use-work-types-matrix-filter';
import WorkTypesMatrixEditDialog from './dialogs/work-types-matrix-edit-dialog';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import usePagination from 'hooks/use-pagination';
import { useModal } from 'mui-modal-provider';
import columnLocalization from 'utils/column-localization';

const data = [
    {
        id: 1,
        object: 'NSS(CS Core)',
        type: 'Аварийно-восстановительные работы по которым нет влияние на трафик',
        minCreateTime: 0,
        minExpertTime: 0,
        minCreateTimeB2b: 0,
        minExpertTimeB2b: 0,
        allowedSpendDay: 'Нет',
        needApprove: 'Нет',
    },
    {
        id: 2,
        object: 'NSS(CS Core)',
        type: 'Аварийно-восстановительные работы по которым уже есть влияние на трафик',
        minCreateTime: 1,
        minExpertTime: 2,
        minCreateTimeB2b: 3,
        minExpertTimeB2b: 4,
        allowedSpendDay: 'Да',
        needApprove: 'Да',
    },
];

const refetch = () => {};

const WorkTypesMatrixTable: React.FC = () => {
    const { Pagination } = usePagination(`${APP}_grid`, '5');
    const { TroubleTicketsFilterToolbar } = useWorkTypesMatrixFilter();
    const { showModal } = useModal();

    // TODO тут должен быть запрос

    const handleEditClick = React.useCallback(
        (id: number) => () => {
            const modal = showModal(WorkTypesMatrixEditDialog, {
                onClose: () => {
                    modal.hide();
                },
            });
        },
        [showModal],
    );

    const renderCellActions = React.useCallback(
        (params: any) => [
            <Tooltip title={<Translate app={APP} id="edit" />}>
                <IconButton size="small" onClick={handleEditClick(params.id)}>
                    <EditIcon
                        sx={{
                            fontSize: 20,
                        }}
                    />
                </IconButton>
            </Tooltip>,
        ],
        [handleEditClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_object`),
                field: 'object',
                flex: 0.6,
                minWidth: 105,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_type`),
                field: 'type',
                flex: 1,
                minWidth: 160,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_min_create_time`),
                field: 'minCreateTime',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_min_expert_time`),
                field: 'minExpertTime',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_min_create_time_b2b`),
                field: 'minCreateTimeB2b',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_min_expert_time_b2b`),
                field: 'minExpertTimeB2b',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_allowed_spend_day`),
                field: 'allowedSpendDay',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_need_approve`),
                field: 'needApprove',
                flex: 1,
                minWidth: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.2,
                minWidth: 40,
                getActions: renderCellActions,
            },
        ],
        [renderCellActions],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <TroubleTicketsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.actionsCell}`]: {
                                gridGap: 0,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px',
                            },
                            [`& .${gridClasses.overlay}`]: {
                                backgroundColor: 'transparent',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.length,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                </>
            )}
            <DataGridLoader visible={false} text={<Translate id="loading" />} />
        </>
    );
};

export default WorkTypesMatrixTable;

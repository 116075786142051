// Dictionary
export const FEEDBACK_DICTIONARY_APP = 'FEEDBACK_DICTIONARY_APP';
export const FEEDBACK_DICTIONARY_STATUS = 'FEEDBACK_DICTIONARY_STATUS';

// Feedback
export const FEEDBACK_LIST = 'FEEDBACK_LIST';
export const FEEDBACK_APP_COUNT = 'FEEDBACK_APP_COUNT';
export const FEEDBACK_STATUS_COUNT = 'FEEDBACK_STATUS_COUNT';

// Comments
export const FEEDBACK_COMMENT_LIST = 'FEEDBACK_COMMENT_LIST';

import * as React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type FilterItemProps = {
    onClick: () => void;
    Content: JSX.Element;
    title: string | JSX.Element;
    sx?: BoxProps['sx'];
};

const FilterDisplayItem: React.FC<FilterItemProps> = (props) => {
    const { onClick, title, Content, sx } = props;

    return (
        <Box
            sx={{
                pt: 0,
                pr: 0.625,
                pb: 0.625,
                pl: 0,
                display: {
                    xs: 'none',
                    md: 'block',
                },
                ...sx,
            }}
        >
            <Tooltip
                title={
                    <>
                        {title}: {Content}
                    </>
                }
            >
                <Card variant="outlined" sx={{ height: 40, maxWidth: 160, minWidth: 60 }}>
                    <CardActionArea onClick={onClick} sx={{ height: 40 }}>
                        <CardContent
                            sx={{
                                pt: 0.5,
                                pb: 0,
                                px: 0.625,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    marginBottom: 0,
                                    lineHeight: '1.03',
                                }}
                                color="textSecondary"
                                gutterBottom
                            >
                                {title}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                                {Content}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Tooltip>
        </Box>
    );
};

export default FilterDisplayItem;

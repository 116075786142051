import Box from '@mui/material/Box';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={300} enterNextDelay={500} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        maxWidth: 220,
        padding: '7px 15px',
        border: `1px solid ${theme.palette.action.hover}`,
    },
}));
const DataGridCellTooltip = (value: string) => (
    <HtmlTooltip title={value}>
        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</Box>
    </HtmlTooltip>
);

export default DataGridCellTooltip;

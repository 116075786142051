import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { AvailableValue, FilterCheckBoxStateValue } from './types';

import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import { deepCopy } from 'utils/deep-copy';

type filterCheckBoxProps<FilterState> = {
    field: keyof FilterState;
    value: FilterCheckBoxStateValue;
    label: string | JSX.Element;
    expanded: boolean;
    availableValues: AvailableValue[];
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
};

const FilterCheckBox = <FilterState,>(props: filterCheckBoxProps<FilterState>) => {
    const { field, value, label, expanded, availableValues, setLocalFilterValues } = props;

    const handleToggle = React.useCallback(
        (check: boolean | undefined, _value: AvailableValue) => () => {
            let newValue = value !== null ? deepCopy(value) : [];

            if (!check) {
                newValue.push(_value);
            } else {
                newValue = newValue.filter((item) => item.id !== _value.id);
            }
            setLocalFilterValues((prevState) => ({
                ...prevState,
                [field]: newValue,
            }));
        },
        [field, setLocalFilterValues, value],
    );

    return (
        <Accordion
            defaultExpanded={expanded}
            disableGutters
            sx={{ [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } }}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 60 }}>
                <Box>
                    <Typography>{label}</Typography>
                    <Typography>
                        <DisplayItemContent valueLabel={value?.map((elem) => elem.name)} />
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {availableValues.map((_value) => {
                        const checked = value !== null ? value.some((elem) => elem.id === _value.id) : false;

                        return (
                            <ListItem key={_value.id} dense button onClick={handleToggle(checked, _value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText primary={_value.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilterCheckBox;

import { createSelector } from '@reduxjs/toolkit';
import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';
import { RootState } from 'store';

export const authSelector = (state: RootState) => state.app.auth;
export const authTokenSelector = (state: RootState) => state.app.authToken;
export const accessTokenSelector = (state: RootState) => state.app.accessToken;
export const isInitSelector = (state: RootState) => state.app.isInit;
export const entryPointSelector = (state: RootState) => state.app.entryPoint;
export const userSelector = (state: RootState) => state.app.user;
export const userMenuSelector = createSelector(userSelector, (user) => {
    if (user !== null) return user.menu;

    return [] as MenuItemResponseModel[];
});

import * as React from 'react';

import DialogContent from '@mui/material/DialogContent';

import TroubleTicketViewPossibleReasonsTtForm from '../forms/trouble-ticket-view-possible-reasons-tt-form';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import { format } from 'date-fns';
import { useSmartQueueBindTtMutation, useSmartQueueTicketGetQuery } from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

type TroubleTicketViewPossibleReasonsTtFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
    openedTtNumber: number;
};

export type TroubleTicketViewPossibleReasonsTtFormInitialValues = {
    region: string;
    branch: string;
    shortDescription: string;
    priority: string;
    role: string;
    serviceStatus: string;
    solutionStatus: string;
    createdDate: string;
    initiator: string;
};

const TroubleTicketViewPossibleReasonsTtFormConnector: React.FC<TroubleTicketViewPossibleReasonsTtFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber, openedTtNumber } = props;

    const [bindTt, { isLoading: mutationIsLoading, data: responseMutationData }] = useSmartQueueBindTtMutation();
    const { data, isFetching, isSuccess } = useSmartQueueTicketGetQuery(openedTtNumber);

    const initialValues: TroubleTicketViewPossibleReasonsTtFormInitialValues = React.useMemo(
        () => ({
            region: data ? data.data.region : '',
            branch: data ? data.data.branch : '',
            shortDescription: data ? data.data.shortDescription : '',
            priority: data ? data.data.priority : '',
            role: data ? data.data.role : '',
            serviceStatus: data ? data.data.serviceStatus : '',
            solutionStatus: data ? data.data.solutionStatus : '',
            createdDate: data ? format(new Date(data.data.createdDate), 'dd.MM.yyyy hh:mm') : '',
            initiator: data ? data.data.createdUser : '',
        }),
        [data],
    );

    const handleBindTt = React.useCallback(async () => {
        const result = await bindTt({
            number: ttNumber,
            mainTicket: openedTtNumber,
        });
        if ('data' in result) {
            if (result.data.status === ServiceResponseStatusEnum.Success) {
                onClose();
            }
        }
    }, [bindTt, onClose, openedTtNumber, ttNumber]);

    if (isSuccess) {
        return (
            <TroubleTicketViewPossibleReasonsTtForm
                initialValues={initialValues}
                isLoading={mutationIsLoading}
                submitCallback={handleBindTt}
                onClose={onClose}
                errors={responseMutationData?.errors}
            />
        );
    }
    if (isFetching) {
        return (
            <DialogContent sx={{ position: 'relative', height: 50 }}>
                <DataGridLoader visible={isFetching} />
            </DialogContent>
        );
    }
    return null;
};

export default TroubleTicketViewPossibleReasonsTtFormConnector;

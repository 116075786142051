import * as React from 'react';

import { APP } from '../../constants';
import { AccessesFilterState } from '../../hooks/use-accesses-filter';

import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const AccessesFilterDialog: React.FC<FilterDialogProps<AccessesFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<AccessesFilterState>(props);

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default AccessesFilterDialog;

import * as React from 'react';

import { AppConfig } from '..';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const Profile = Loadable(React.lazy(() => import('./profile')));

const profileApp: AppConfig = {
    name: APP,
    element: () => <Profile />,
    locale,
};

export default profileApp;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import { APP } from '../../../constants';
import { TroubleTicketViewChangeDependentsFormInitialValues } from '../connectors/trouble-ticket-view-change-dependents-form-connector';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import * as Yup from 'yup';

type TroubleTicketViewChangeDependentsFormProps = {
    onClose: () => void;
    submitCallback: (formData: TroubleTicketViewChangeDependentsFormInitialValues) => void;
    initialValues: TroubleTicketViewChangeDependentsFormInitialValues;
    isLoading: boolean;
    errors?: string[];
};

const formId = 'trouble-ticket-view-change-dependents';

const TroubleTicketViewChangeDependentsForm: React.FC<TroubleTicketViewChangeDependentsFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues: initialValues,
        mode: 'all',
        resolver: yupResolver(
            Yup.object().shape({
                mainTtNumber: Yup.string().matches(/^[0-9]{8}$/i, {
                    message: 'main_tt_number_length_must_be_8_digits',
                }),
            }),
        ),
    });

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="mainTtNumber"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="main_tt_number" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="main_tt_number" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="save" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TroubleTicketViewChangeDependentsForm;

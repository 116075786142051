import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Routes',
        ru: 'Роуты',
    },
};

export default locale;

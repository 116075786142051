import * as actions from './actions';
import reducer from './reducer';
import * as selectors from './selectors';

const customizationSlice = {
    actions,
    selectors,
    reducer,
};

export * from './types';
export default customizationSlice;

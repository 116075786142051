import * as React from 'react';

import AccessesFilterDialog from '../views/filter/accesses-filter-dialog';
import AccessesFilterToolbar from '../views/filter/accesses-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type AccessesFilterState = {
    search: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
};

const initialState: AccessesFilterState = {
    search: null,
    branches: null,
};

const useAccessesFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof AccessesFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof AccessesFilterState>('search');

    return {
        AccessesFilterDialog: () => (
            <AccessesFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        AccessesFilterToolbar: () => (
            <AccessesFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useAccessesFilter;

import { loadingBarMiddleware, loadingBarReducer } from 'react-redux-loading-bar';

import appSlice from './app-slice';
import customizationSlice from './customization';
import errorSlice from './error';
import globalTabsSlice from './global-tabs';

import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { authServices } from 'services/auth/auth-services';
import { feedbackServices } from 'services/feedback/feedback-services';
import { fileServices } from 'services/file/file-services';
import { fluentdServices } from 'services/fluentd/generated/fluentd-services';
import { monitoringServices } from 'services/monitoring/monitoring-services';
import { newsServices } from 'services/news/news-services';
import { positionsServices } from 'services/positions/positions-services';
import { smartQueueServices } from 'services/smart-queue/smart-queue-services';
import { topologyServices } from 'services/topology/topology-services';
import { webapiServices } from 'services/webapi/generated/webapi-services';

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        customization: customizationSlice.reducer,
        loadingBar: loadingBarReducer,
        error: errorSlice.reducer,
        globalTabs: globalTabsSlice.reducer,
        [authServices.reducerPath]: authServices.reducer,
        [webapiServices.reducerPath]: webapiServices.reducer,
        [fluentdServices.reducerPath]: fluentdServices.reducer,
        [positionsServices.reducerPath]: positionsServices.reducer,
        [monitoringServices.reducerPath]: monitoringServices.reducer,
        [smartQueueServices.reducerPath]: smartQueueServices.reducer,
        [feedbackServices.reducerPath]: feedbackServices.reducer,
        [fileServices.reducerPath]: fileServices.reducer,
        [topologyServices.reducerPath]: topologyServices.reducer,
        [newsServices.reducerPath]: newsServices.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(
                loadingBarMiddleware({
                    promiseTypeSuffixes: ['pending', 'fulfilled', 'rejected'],
                }),
            )
            .concat(
                authServices.middleware,
                webapiServices.middleware,
                fluentdServices.middleware,
                positionsServices.middleware,
                monitoringServices.middleware,
                smartQueueServices.middleware,
                feedbackServices.middleware,
                fileServices.middleware,
                topologyServices.middleware,
                newsServices.middleware,
            ),
});

export const persistentStore = persistStore(store);

export type RootState = Readonly<ReturnType<typeof store.getState>>;
export type AppDispatch = typeof store.dispatch;

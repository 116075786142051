import { Tab, TabId, TabLocationAction } from './types';

import { createAction } from 'redux-act';

export const create = createAction<Tab>('@global_tabs/CREATE_TAB');

export const close = createAction<TabId | undefined>('@global_tabs/CLOSE_TAB');

export const remove = createAction<TabId>('@global_tabs/REMOVE_TAB');

export const setActive = createAction<TabId>('@global_tabs/SET_ACTIVE_TAB');

export const setLocation = createAction<{ id: TabId; location: TabLocationAction }>('@global_tabs/SET_LOCATION');

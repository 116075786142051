import * as React from 'react';

import PlannedWorksFilterDialog from '../views/filter/planned-works-filter-dialog';
import PlannedWorksFilterToolbar from '../views/filter/planned-works-filter-toolbar';

import {
    AvailableValue,
    FilterCheckBoxStateValue,
    FilterDateStateValue,
    FilterSearchStateValue,
    FilterSwitchStateValue,
    SwitchLabelsValues,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export const plannedWorksOrderAvailableValues = [
    {
        id: 1,
        name: 'Начало работы',
    },
    {
        id: 2,
        name: 'Отправка на работу',
    },
    {
        id: 3,
        name: 'Изменения',
    },
];

export const plannedWorksIsApplyLabelsValues: SwitchLabelsValues = { true: 'hidden', false: 'showed' };
export const plannedWorksIsArchiveLabelsValues: SwitchLabelsValues = { true: 'showed', false: 'hidden' };

export type PlannedWorksFilterState = {
    pwNumber: FilterSearchStateValue;
    order: AvailableValue;
    statuses: FilterCheckBoxStateValue;
    isApply: FilterSwitchStateValue;
    isArchive: FilterSwitchStateValue;
    objectTypes: FilterCheckBoxStateValue;
    branches: FilterCheckBoxStateValue;
    startDate: FilterDateStateValue;
    endDate: FilterDateStateValue;
};

const initialState: PlannedWorksFilterState = {
    pwNumber: null,
    order: plannedWorksOrderAvailableValues[0],
    statuses: null,
    isApply: { id: false, name: plannedWorksIsApplyLabelsValues.false },
    isArchive: { id: false, name: plannedWorksIsArchiveLabelsValues.false },
    objectTypes: null,
    branches: null,
    startDate: { id: null, name: null },
    endDate: { id: null, name: null },
};

const usePlannedWorksFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof PlannedWorksFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof PlannedWorksFilterState>('pwNumber');

    return {
        PlannedWorksFilterDialog: () => (
            <PlannedWorksFilterDialog
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setExpandedField={setExpandedField}
                initialState={initialState}
            />
        ),
        PlannedWorksFilterToolbar: () => (
            <PlannedWorksFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default usePlannedWorksFilter;

import * as React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { BoolFieldButtonGroupFilterFields, FilterSwitchStateValue } from './types';

type BoolFieldButtonGroupFilterProps<FilterState> = {
    filterValues: FilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
    filterFields: BoolFieldButtonGroupFilterFields<FilterState>;
    sx?: BoxProps['sx'];
};

const BoolFieldButtonGroupDisplayFilter = <FilterState,>(props: BoolFieldButtonGroupFilterProps<FilterState>) => {
    const { filterValues, setFilterValues, filterFields, sx } = props;

    const handleChange = React.useCallback(
        (event: React.MouseEvent<HTMLElement>, newAlignment: [keyof FilterState]) => {
            const boolFieldButtonGroupState: { [key: string | number | symbol]: FilterSwitchStateValue } = {};

            filterFields.forEach((field) => {
                boolFieldButtonGroupState[field.field] = { id: newAlignment.includes(field.field), name: '' };
            });

            setFilterValues((prevState) => ({ ...prevState, ...boolFieldButtonGroupState }));
        },
        [filterFields, setFilterValues],
    );

    return (
        <Box
            sx={{
                position: 'relative',
                display: {
                    xs: 'none',
                    md: 'flex',
                },
                flexWrap: 'wrap',
                alignItems: 'center',
                pt: 0,
                pb: 0.625,
                pl: 0,
                ...sx,
            }}
        >
            <ToggleButtonGroup
                color="standard"
                value={filterFields
                    .filter((elem) => ((filterValues[elem.field] as unknown) as FilterSwitchStateValue).id)
                    .map((_elem) => _elem.field)}
                onChange={handleChange}
                sx={{
                    pr: 0.625,
                    pl: 0,
                    [`& .${toggleButtonClasses.root}`]: {
                        height: 40,
                        py: 0.75,
                        px: 2,
                        mb: {
                            xs: 1,
                            sm: 1,
                            md: 0,
                        },
                    },
                }}
            >
                {filterFields.map((_elem) => (
                    <ToggleButton key={_elem.id} value={_elem.field}>
                        {_elem.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
};

export default BoolFieldButtonGroupDisplayFilter;

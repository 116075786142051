import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Trouble ticket',
        ru: 'Инцидент',
    },
    [`${APP}_information`]: {
        en: 'Information',
        ru: 'Информация',
    },
    [`${APP}_possible_reasons`]: {
        en: 'Possible reasons',
        ru: 'Возможные причины',
    },
    [`${APP}_subordinates`]: {
        en: 'Subordinates',
        ru: 'Подчиненные',
    },
    [`${APP}_elements`]: {
        en: 'Elements',
        ru: 'Элементы',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Комментарии',
    },
    [`${APP}_responsible`]: {
        en: 'Responsible',
        ru: 'Ответственные',
    },
    [`${APP}_edit`]: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    [`${APP}_responsible_group`]: {
        en: 'Responsible group',
        ru: 'Ответственная группа',
    },
    [`${APP}_coordination_group`]: {
        en: 'Coordination group',
        ru: 'Группа координации',
    },
    [`${APP}_service_impact`]: {
        en: 'Service impact',
        ru: 'Влияние на сервис',
    },
    [`${APP}_alarms_is_active`]: {
        en: 'Alarms is active',
        ru: 'Аварии активны',
    },
    [`${APP}_short_description`]: {
        en: 'Short description',
        ru: 'Краткое описание',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_plw`]: {
        en: 'PLW',
        ru: 'ПР',
    },
    [`${APP}_send`]: {
        en: 'Send',
        ru: 'Отправить',
    },
    [`${APP}_group_comments`]: {
        en: 'Group comments',
        ru: 'Сгруппировать комментарии',
    },
    [`${APP}_only_important`]: {
        en: 'Only important',
        ru: 'Показать только важные',
    },
    [`${APP}_enter_your_comment`]: {
        en: 'Enter your comment',
        ru: 'Введите текст комментария',
    },
    [`${APP}_cells`]: {
        en: 'Cells',
        ru: 'Сектора',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_service_status`]: {
        en: 'Service status',
        ru: 'Состояние услуги',
    },
    [`${APP}_solution_status`]: {
        en: 'Solution status',
        ru: 'Состояние решения',
    },
    [`${APP}_dependents`]: {
        en: 'Dependents',
        ru: 'Зависимость',
    },
    [`${APP}_change_priority`]: {
        en: 'Change priority',
        ru: 'Изменить приоритет',
    },
    [`${APP}_priority_is_required`]: {
        en: 'Priority is required',
        ru: 'Приоритет обязателен',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_auto_control_on`]: {
        en: 'Auto control on',
        ru: 'Автоуправление включено',
    },
    [`${APP}_auto_control_off`]: {
        en: 'Auto control off',
        ru: 'Автоуправление выключено',
    },
    [`${APP}_change_status`]: {
        en: 'Change status',
        ru: 'Изменить статус',
    },
    [`${APP}_status_is_required`]: {
        en: 'Status is required',
        ru: 'Статус обязателен',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_bind`]: {
        en: 'Bind',
        ru: 'Подчинить',
    },
    [`${APP}_main_tt_number_is_required`]: {
        en: 'Main TT number is required',
        ru: 'Номер главного ТТ обязателен',
    },
    [`${APP}_main_tt_number`]: {
        en: 'Main TT number',
        ru: 'Номер главного ТТ',
    },
    [`${APP}_main_tt_number_length_must_be_8_digits`]: {
        en: 'TT number length must be 8 digits',
        ru: 'Длина номера ТТ должна быть 8 цифр',
    },
    [`${APP}_edit_description`]: {
        en: 'Edit description',
        ru: 'Изменить описание',
    },
    [`${APP}_short_description_is_required`]: {
        en: 'Short description is required',
        ru: 'Краткое описание обязательно',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_phone`]: {
        en: 'Phone',
        ru: 'Телефон',
    },
    [`${APP}_notify_person_on_duty`]: {
        en: 'Notify person on duty',
        ru: 'Оповестить дежурного',
    },
    [`${APP}_full_name_is_required`]: {
        en: 'Full name is required',
        ru: 'ФИО обязательно',
    },
    [`${APP}_phone_is_required`]: {
        en: 'Phone is required',
        ru: 'Телефон обязателен',
    },
    [`${APP}_management`]: {
        en: 'Management',
        ru: 'Дежурный',
    },
    [`${APP}_responsible_group_is_required`]: {
        en: 'Responsible group is required',
        ru: 'Ответственная группа обязательна',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Название',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_date_start`]: {
        en: 'Start date',
        ru: 'Дата начала',
    },
    [`${APP}_date_end`]: {
        en: 'End date',
        ru: 'Дата окончания',
    },
    [`${APP}_date_start_service`]: {
        en: 'Service break start date',
        ru: 'Дата начала перерыва сервиса',
    },
    [`${APP}_date_end_service`]: {
        en: 'Service break end date',
        ru: 'Дата окончания перерыва сервиса',
    },
    [`${APP}_initiator`]: {
        en: 'Initiator',
        ru: 'Инициатор',
    },
    [`${APP}_executor`]: {
        en: 'Executor',
        ru: 'Исполнитель',
    },
    [`${APP}_close_in_favor_plw`]: {
        en: 'Close in favor of PLW',
        ru: 'Закрыть в пользу ПР',
    },
    [`${APP}_role`]: {
        en: 'Role',
        ru: 'Роль',
    },
    [`${APP}_created_date`]: {
        en: 'Created date',
        ru: 'Дата создания',
    },
    [`${APP}_bind_to_incident`]: {
        en: 'Bind to incident',
        ru: 'Подчинить к инциденту',
    },
    [`${APP}_action`]: {
        en: 'Action',
        ru: 'Действие',
    },
    [`${APP}_you_want_disable_auto_control`]: {
        en: 'Are you sure you want to DISABLE automatic incident management?',
        ru: 'Вы уверены что хотите ОТКЛЮЧИТЬ автоматическое управление инцидентом?',
    },
    [`${APP}_you_want_enable_auto_control`]: {
        en: 'Are you sure you want to ENABLE automatic incident management?',
        ru: 'Вы уверены что хотите ВКЛЮЧИТЬ автоматическое управление инцидентом?',
    },
    [`${APP}_ok`]: {
        en: 'OK',
        ru: 'ОК',
    },
    [`${APP}_open_time`]: {
        en: 'Open time',
        ru: 'Открыт',
    },
    [`${APP}_target_time_for_decision`]: {
        en: 'Target time for decision',
        ru: 'Целевой срок решения',
    },
    [`${APP}_target_time_for_decision`]: {
        en: 'Target time for decision',
        ru: 'Целевой срок решения',
    },
    [`${APP}_weight`]: {
        en: 'Weight',
        ru: 'Вес',
    },
    [`${APP}_cdt`]: {
        en: 'CDT',
        ru: 'CDT',
    },
    [`${APP}_main`]: {
        en: 'Main',
        ru: 'Главный',
    },
    [`${APP}_single`]: {
        en: 'Single',
        ru: 'Отдельный',
    },
    [`${APP}_yes`]: {
        en: 'Yes',
        ru: 'Да',
    },
    [`${APP}_no`]: {
        en: 'No',
        ru: 'Нет',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Имя',
    },
    [`${APP}_change_responsible`]: {
        en: 'Edit responsible',
        ru: 'Изменить ответственного',
    },
    [`${APP}_close_tt`]: {
        en: 'Close trouble ticket',
        ru: 'Закрыть инцидент',
    },
    [`${APP}_description_is_required`]: {
        en: 'Description is required',
        ru: 'Описание обязательно',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'ТТ',
    },
    [`${APP}_user`]: {
        en: 'User',
        ru: 'Пользователь',
    },
    [`${APP}_old_value`]: {
        en: 'Old value',
        ru: 'Предыдущее значение',
    },
    [`${APP}_new_value`]: {
        en: 'New value',
        ru: 'Новое значение',
    },
    [`${APP}_date`]: {
        en: 'Date',
        ru: 'Дата',
    },
    [`${APP}_history`]: {
        en: 'History',
        ru: 'История',
    },
    [`${APP}_event`]: {
        en: 'Event',
        ru: 'Событие',
    },
};

export default locale;

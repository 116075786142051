import * as React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import GlobalTabPanel from './global-tab-panel';

import ErrorBoundary from 'components/error-boundary';
import useSelector from 'hooks/use-app-selector';
import useGlobalTabs from 'hooks/use-global-tabs';
import ModalProvider from 'mui-modal-provider';
import { globalTabsActiveSelector, globalTabsSelector } from 'store/global-tabs/selectors';
import { apps } from 'sub-apps';
import { buildPath } from 'utils/router';

const GlobalTabsContent: React.FC = () => {
    const tabs = useSelector(globalTabsSelector);
    const activeTab = useSelector(globalTabsActiveSelector);
    const navigate = useNavigate();
    const location = useLocation();
    const { setLocation } = useGlobalTabs();

    React.useEffect(() => {
        if (activeTab) {
            if (location.pathname.indexOf(activeTab.id) > -1) {
                if (
                    buildPath([activeTab.id, activeTab.app, activeTab.path]) !== location.pathname ||
                    activeTab.search !== location.search ||
                    activeTab.hash !== location.hash
                ) {
                    setLocation(activeTab.id, {
                        path: location.pathname.replace(buildPath([activeTab.id, activeTab.app]), ''),
                        search: location.search,
                        hash: location.hash,
                    });
                }
            } else {
                navigate(
                    {
                        pathname: buildPath([activeTab.id, activeTab.app, activeTab.path]),
                        search: activeTab.search,
                        hash: activeTab.hash,
                    },
                    { replace: true },
                );
            }
        }
    }, [activeTab, location, navigate, setLocation]);

    return (
        <Routes>
            <Route
                path=":tabId/:app/*"
                element={tabs.map((tab) => (
                    <GlobalTabPanel key={tab.id} tabId={tab.id} visible={activeTab?.id === tab.id}>
                        {apps
                            .filter((el) => el.name === tab.app)
                            .map((el) => (
                                <ErrorBoundary key={tab.id}>
                                    <ModalProvider>{el.element(tab)}</ModalProvider>
                                </ErrorBoundary>
                            ))}
                    </GlobalTabPanel>
                ))}
            />
        </Routes>
    );
};

export default GlobalTabsContent;

import * as React from 'react';

import TroubleTicketViewChangePriorityForm from '../forms/trouble-ticket-view-change-priority-form';

import {
    useSmartQueueDictionaryPriorityGetListQuery,
    useSmartQueuePriorityChangeMutation,
} from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

export type TroubleTicketViewChangePriorityFormInitialValues = {
    priorityId: number;
    comment: string;
};

type TroubleTicketViewChangePriorityFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
};

const TroubleTicketViewChangePriorityFormConnector: React.FC<TroubleTicketViewChangePriorityFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber } = props;

    const [changePriority, { isLoading, data }] = useSmartQueuePriorityChangeMutation();

    const { data: priorityAvailableValues } = useSmartQueueDictionaryPriorityGetListQuery();

    const initialValues: TroubleTicketViewChangePriorityFormInitialValues = {
        priorityId: priorityAvailableValues ? priorityAvailableValues.data[0].id : 1,
        comment: '',
    };

    const handleChangePriority = React.useCallback(
        async (formData: TroubleTicketViewChangePriorityFormInitialValues) => {
            const result = await changePriority({
                ...formData,
                number: ttNumber,
            });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [changePriority, onClose, ttNumber],
    );

    if (priorityAvailableValues !== undefined) {
        return (
            <TroubleTicketViewChangePriorityForm
                onClose={onClose}
                submitCallback={handleChangePriority}
                priorities={priorityAvailableValues.data}
                initialValues={initialValues}
                isLoading={isLoading}
                errors={data?.errors}
            />
        );
    }

    return null;
};

export default TroubleTicketViewChangePriorityFormConnector;

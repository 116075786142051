import * as React from 'react';

import { APP } from '../../../constants';
import TroubleTicketViewDialog from '../../trouble-ticket-view-dialog';
import TroubleTicketViewChangeDependentsFormConnector from '../connectors/trouble-ticket-view-change-dependents-form-connector';

import Translate from 'components/extended/translate';

type TroubleTicketViewChangeDependentsDialogProps = {
    open: boolean;
    onClose: () => void;
    ttNumber: number;
};

const TroubleTicketViewChangeDependentsDialog: React.FC<TroubleTicketViewChangeDependentsDialogProps> = (props) => {
    const { open, onClose, ttNumber } = props;

    return (
        <TroubleTicketViewDialog
            onClose={onClose}
            open={open}
            title={<Translate app={APP} id="bind" />}
            fullWidth
            maxWidth="xs"
        >
            <TroubleTicketViewChangeDependentsFormConnector onClose={onClose} ttNumber={ttNumber} />
        </TroubleTicketViewDialog>
    );
};

export default TroubleTicketViewChangeDependentsDialog;

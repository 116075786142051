import { PaletteMode } from '@mui/material';

import config from 'config';

export function themeModeChecker<T1 extends any, T2 extends any>(mode: PaletteMode | undefined, var1: T1, var2: T2) {
    if (mode === 'dark') return var1;
    return var2;
}

export function themeGetSpacing(spacing: number) {
    return spacing * config.spacing;
}

import * as React from 'react';

import { TroubleTicketVariantsEnum } from '../../../../constants';
import TroubleTicketViewCommentsMain from '../trouble-ticket-view-comments-main';

import { useSmartQueueTicketCommentsGetQuery } from 'services/smart-queue/smart-queue-services';

type TroubleTicketViewCommentsConnectorProps = {
    variant: TroubleTicketVariantsEnum;
    ttNumber: number;
};

const TroubleTicketViewCommentsConnector: React.FC<TroubleTicketViewCommentsConnectorProps> = (props) => {
    const { variant, ttNumber } = props;

    const { data, isFetching } = useSmartQueueTicketCommentsGetQuery(ttNumber);

    if (data === undefined) return null;

    return (
        <TroubleTicketViewCommentsMain data={data.data} variant={variant} isFetching={isFetching} ttNumber={ttNumber} />
    );
};

export default TroubleTicketViewCommentsConnector;

import * as React from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

import useSelector from 'hooks/use-app-selector';
import allLocales from 'locales';
import customizationSlice from 'store/customization';

const Locales: React.FC = (props) => {
    const { children } = props;
    const customization = useSelector(customizationSlice.selectors.customizationSelector);
    const [messages, setMessages] = React.useState<
        Record<string, string> | Record<string, MessageFormatElement[]> | undefined
    >();

    React.useEffect(() => {
        const locale: { [k: string]: string } = {};

        for (const key in allLocales) {
            if ({}.hasOwnProperty.call(allLocales, key)) {
                // @ts-ignore
                locale[key] = allLocales[key][customization.locale];
            }
        }

        setMessages(locale);
    }, [customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={customization.locale} defaultLocale="ru" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;

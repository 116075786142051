import { commonFluentdServices as api } from '../common-fluentd-services';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        sendLog: build.mutation<SendLogApiResponse, SendLogApiArg>({
            query: (queryArg) => ({ url: `/front.log`, method: 'POST', body: queryArg.frontLogRequestModel }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as fluentdServices };
export type SendLogApiResponse = unknown;
export type SendLogApiArg = {
    frontLogRequestModel: FrontLogRequestModel;
};
export type FrontLogRequestModel = {
    id?: string | null;
    [key: string]: any;
};
export const { useSendLogMutation } = injectedRtkApi;

import * as React from 'react';

import TopologyFilterToolbar from '../views/filters/topology-filter-toolbar';

import { deepCopy } from 'utils/deep-copy';

export type TopologyFilterState = {
    branch: string;
};

const initialState: TopologyFilterState = {
    branch: '',
};

const useTopologyFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));

    return {
        TopologyFilterToolbar: () => (
            <TopologyFilterToolbar setFilterValues={setFilterValues} filterValues={filterValues} />
        ),
        filterValues,
    };
};

export default useTopologyFilter;

import * as React from 'react';

import TroubleTicketViewChangeDependentsForm from '../forms/trouble-ticket-view-change-dependents-form';

import { useSmartQueueBindTtMutation } from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

type TroubleTicketViewChangeDependentsFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
};

export type TroubleTicketViewChangeDependentsFormInitialValues = {
    mainTtNumber: string;
};

const initialValues: TroubleTicketViewChangeDependentsFormInitialValues = {
    mainTtNumber: '',
};

const TroubleTicketViewChangeDependentsFormConnector: React.FC<TroubleTicketViewChangeDependentsFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber } = props;

    const [bindTt, { isLoading, data }] = useSmartQueueBindTtMutation();

    const handleChangeDependents = React.useCallback(
        async (formData: TroubleTicketViewChangeDependentsFormInitialValues) => {
            const result = await bindTt({
                number: ttNumber,
                mainTicket: Number(formData.mainTtNumber),
            });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [bindTt, onClose, ttNumber],
    );

    return (
        <TroubleTicketViewChangeDependentsForm
            onClose={onClose}
            submitCallback={handleChangeDependents}
            isLoading={isLoading}
            initialValues={initialValues}
            errors={data?.errors}
        />
    );
};

export default TroubleTicketViewChangeDependentsFormConnector;

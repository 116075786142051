import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Alarms by type',
        ru: 'Аварии по типам',
    },
    [`${APP}_object_number`]: {
        en: '№ position',
        ru: '№ БС',
    },
    [`${APP}_site_name`]: {
        en: 'Site name',
        ru: 'Название',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_r_site`]: {
        en: 'RSite',
        ru: 'RSite',
    },
    [`${APP}_remedy_id`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_plan_id`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_role`]: {
        en: 'Role',
        ru: 'Роль',
    },
    [`${APP}_bs_type`]: {
        en: 'Position type',
        ru: 'Тип БС',
    },
    [`${APP}_date`]: {
        en: 'Time to open',
        ru: 'Время появления',
    },
    [`${APP}_tt_type`]: {
        en: 'Alarm type',
        ru: 'Тип аварии',
    },
    [`${APP}_event`]: {
        en: 'Event',
        ru: 'Событие',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_create_event`]: {
        en: 'Create event',
        ru: 'Создать событие',
    },
    [`${APP}_bind_alarm`]: {
        en: 'Link accident to an event',
        ru: 'Привязать аварию к событию',
    },
    [`${APP}_bind_alarms`]: {
        en: 'Link accidents to an event',
        ru: 'Привязать аварии к событию',
    },
    [`${APP}_unbind_alarm`]: {
        en: 'Unbind alarm',
        ru: 'Открепить аварию от события',
    },
    [`${APP}_create_event_form`]: {
        en: 'Create event',
        ru: 'Создание события',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_priority_is_required`]: {
        en: 'Priority is required',
        ru: 'Приоритет обязателен',
    },
    [`${APP}_event_is_required`]: {
        en: 'Event is required',
        ru: 'Выбор события обязателен',
    },
    [`${APP}_closing_time`]: {
        en: 'Closing time',
        ru: 'Время закрытия',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_cause`]: {
        en: 'Cause',
        ru: 'Причина',
    },
    [`${APP}_cause_is_required`]: {
        en: 'Cause is required',
        ru: 'Причина обязательна',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_description_is_required`]: {
        en: 'Description is required',
        ru: 'Описание обязательно',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_you_want_unbind_alarm`]: {
        en: 'Are you sure you want to unpin reserve from events?',
        ru: 'Вы действительно хотите открепить аварию от события?',
    },
    [`${APP}_you_want_unbind_alarms`]: {
        en: 'Are you sure you want to unpin reserves from events?',
        ru: 'Вы действительно хотите открепить аварии от события?',
    },
    [`${APP}_unbind`]: {
        en: 'Unbind',
        ru: 'Открепить',
    },
    [`${APP}_unbind_alarm_button`]: {
        en: 'Unbind alarm',
        ru: 'Открепить аварию',
    },
    [`${APP}_unbind_alarms_button`]: {
        en: 'Unbind alarms',
        ru: 'Открепить аварии',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
};

export default locale;

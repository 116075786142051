import * as React from 'react';

import { KeyCoordinatorsFilterState } from '../../hooks/use-key-coordinators-filter';
import KeyCoordinatorsRegionOrBranchFilter from './key-coordinators-region-or-branch-filter';

import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';

type KeyCoordinatorsRegionOrBranchFilterConnectorProps = {
    filterValues: KeyCoordinatorsFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<KeyCoordinatorsFilterState>>;
};

const KeyCoordinatorsRegionOrBranchFilterConnector: React.FC<KeyCoordinatorsRegionOrBranchFilterConnectorProps> = (
    props,
) => {
    const { filterValues, setFilterValues } = props;
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    if (regionAndBranchAvailableValues === undefined) return null;

    return (
        <KeyCoordinatorsRegionOrBranchFilter
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            availableValues={regionAndBranchAvailableValues.data}
        />
    );
};

export default KeyCoordinatorsRegionOrBranchFilterConnector;

import * as React from 'react';

import { Search } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { inputLabelClasses } from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { FilterSearchStateValue, SearchFilter } from './types';

type SearchDisplayFilterProps<FilterState> = {
    filterValues: FilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
    searchFilters: SearchFilter<FilterState>[];
    currentSearchField: keyof FilterState;
    setCurrentSearchField: React.Dispatch<React.SetStateAction<keyof FilterState>>;
    sx?: BoxProps['sx'];
};

const SearchDisplayFilter = <FilterState,>(props: SearchDisplayFilterProps<FilterState>) => {
    const { filterValues, setFilterValues, searchFilters, currentSearchField, setCurrentSearchField, sx } = props;

    const [field, setField] = React.useState({ currentField: currentSearchField, prevField: '' });

    const currentFieldValue = (filterValues[field.currentField] as unknown) as FilterSearchStateValue;

    const [text, setText] = React.useState(currentFieldValue !== null ? currentFieldValue : '');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openSearchFields = Boolean(anchorEl);

    const handleClickSearchFields = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
        [],
    );

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setText(event.target.value);
    }, []);

    const onSearch = React.useCallback(() => {
        setCurrentSearchField(field.currentField);
        setFilterValues((prevState) => ({
            ...prevState,
            [field.currentField]: text,
            [field.prevField]: null,
        }));
    }, [field.currentField, field.prevField, setCurrentSearchField, setFilterValues, text]);

    const menuItemClick = React.useCallback(
        (_name: keyof FilterState) => () => {
            const nextFieldValue = (filterValues[_name] as unknown) as FilterSearchStateValue;
            setField((prevState) => ({ currentField: _name, prevField: prevState.currentField as string }));
            setText(nextFieldValue !== null ? nextFieldValue : '');
            setAnchorEl(null);
        },
        [filterValues],
    );

    const handleCloseSearchFields = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const enterKeyPress = React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === 'Enter') onSearch();
        },
        [onSearch],
    );

    return (
        <Box
            sx={{
                pt: 0,
                pr: 0.625,
                pb: 0.625,
                pl: 0,
                ...sx,
            }}
        >
            <TextField
                label={searchFilters.find((elem) => elem.field === field.currentField)?.label}
                value={text}
                sx={{
                    fontSize: '0.65rem',
                    [`& .${inputLabelClasses.root}`]: {
                        top: 1,
                    },
                }}
                size="small"
                onChange={handleChange}
                onKeyPress={enterKeyPress}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size="small" type="submit" onClick={onSearch}>
                                <Search sx={{ fontSize: '1.3rem' }} />
                            </IconButton>
                            {searchFilters.length > 1 ? (
                                <>
                                    <IconButton size="small" onClick={handleClickSearchFields}>
                                        {openSearchFields ? (
                                            <KeyboardArrowUpIcon sx={{ fontSize: '1.3rem' }} />
                                        ) : (
                                            <KeyboardArrowDownIcon sx={{ fontSize: '1.3rem' }} />
                                        )}
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={openSearchFields}
                                        onClose={handleCloseSearchFields}
                                    >
                                        {searchFilters.map((elem) => (
                                            <MenuItem
                                                key={elem.id}
                                                onClick={menuItemClick(elem.field)}
                                                selected={elem.field === field.currentField}
                                            >
                                                {elem.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            ) : null}
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default SearchDisplayFilter;

import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useTimeOnBatteriesFilter from '../hooks/use-time-on-batteries-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { PositionsBatteryHistoryColumnsOrderRequestModel } from 'services/positions/positions-models';
import { usePositionsBatteryHistoryGetListQuery } from 'services/positions/positions-services';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import columnLocalization from 'utils/column-localization';
import renderDateCell from 'utils/render-date-cell';

const TimeOnBatteriesTable: React.FC = () => {
    const { Pagination, rowsState } = usePagination(`${APP}_time_on_batteries_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    const { TimeOnBatteriesFilterDialog, TimeOnBatteriesFilterToolbar, filterValues } = useTimeOnBatteriesFilter();

    const { data, isFetching, refetch } = usePositionsBatteryHistoryGetListQuery({
        value: {
            position: filterValues.position,
            branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : undefined,
            startDateTimeDown: filterValues.startDateTimeDown.id
                ? new Date(filterValues.startDateTimeDown.id).toISOString()
                : undefined,
            endDateTimeDown: filterValues.endDateTimeDown.id
                ? new Date(filterValues.endDateTimeDown.id).toISOString()
                : undefined,
            startDatePowerOff: filterValues.startDatePowerOff.id
                ? new Date(filterValues.startDatePowerOff.id).toISOString()
                : undefined,
            endDatePowerOff: filterValues.endDatePowerOff.id
                ? new Date(filterValues.endDatePowerOff.id).toISOString()
                : undefined,
        },
        order: sortModel[0] as PositionsBatteryHistoryColumnsOrderRequestModel,
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: POSITION_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip sx={{ userSelect: 'text' }} onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns = React.useMemo(
        (): GridColDef[] => [
            {
                renderHeader: columnLocalization(`${APP}_position`),
                renderCell: renderCellChip,
                field: 'position',
                flex: 0.4,
                minWidth: 80,
            },
            {
                renderHeader: columnLocalization(`${APP}_region`),
                field: 'region',
                flex: 0.6,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                flex: 0.6,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_power_off_date`),
                renderCell: renderDateCell('yyyy-MM-dd HH:mm:ss'),
                field: 'startTime',
                flex: 0.5,
                minWidth: 80,
            },
            {
                renderHeader: columnLocalization(`${APP}_bs_down_date`),
                renderCell: renderDateCell('yyyy-MM-dd HH:mm:ss'),
                field: 'downTime',
                flex: 0.5,
                minWidth: 80,
            },
            {
                renderHeader: columnLocalization(`${APP}_time_on_batteries`),
                field: 'lifetime',
                flex: 0.5,
                minWidth: 50,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <TimeOnBatteriesFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <TimeOnBatteriesFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default TimeOnBatteriesTable;

import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateValidationError } from '@mui/lab/internal/pickers/date-utils';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FilterDateStateValue } from './types';

import DateIntervalDisplayItemContent from 'components/extended/data-grid/filter/date-interval-display-item-content';
import DateIntervalHelperText from 'components/extended/data-grid/filter/date-interval-helper-text';
import { add, format, set, sub } from 'date-fns';

type DateIntervalFilterProps<FilterState> = {
    startDateField: keyof FilterState;
    endDateField: keyof FilterState;
    startDateValue: FilterDateStateValue;
    endDateValue: FilterDateStateValue;
    startDateLabel: string | JSX.Element;
    endDateLabel: string | JSX.Element;
    label: string | JSX.Element;
    expanded: boolean;
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
    dateFormat?: string;
    mask?: string;
    minDate?: Date;
    maxDate?: Date;
    maximumInterval?: Duration;
};

const FilterDateInterval = <FilterState,>(props: DateIntervalFilterProps<FilterState>) => {
    const {
        startDateField,
        endDateField,
        startDateValue,
        endDateValue,
        startDateLabel,
        endDateLabel,
        label,
        expanded,
        setLocalFilterValues,
        dateFormat = 'dd.MM.yyyy',
        mask = '__.__.____',
        minDate,
        maxDate,
        setIsError,
        maximumInterval,
    } = props;

    const [startDateErrMessage, setStartDateErrMessage] = React.useState<DateValidationError>(null);
    const [endDateErrMessage, setEndDateErrMessage] = React.useState<DateValidationError>(null);

    const handleDateChange = React.useCallback(
        (field: keyof FilterState) => (date: Date | null) => {
            if (date !== null) {
                try {
                    const changeDate = set(new Date(date), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
                    const _label = format(new Date(changeDate), dateFormat);
                    setLocalFilterValues((prevState) => ({
                        ...prevState,
                        [field]: { id: changeDate, name: _label },
                    }));
                } catch (e) {
                    setLocalFilterValues((prevState) => ({
                        ...prevState,
                        [field]: { id: date, name: null },
                    }));
                }
            } else {
                setLocalFilterValues((prevState) => ({ ...prevState, [field]: { id: null, name: null } }));
            }
        },
        [dateFormat, setLocalFilterValues],
    );

    const onError = React.useCallback(
        (callback) => (error: DateValidationError) => {
            callback(error);
            setIsError(!!error);
        },
        [setIsError],
    );

    return (
        <Accordion
            defaultExpanded={expanded}
            disableGutters
            sx={{ [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } }}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 60 }}>
                <div>
                    <Typography>{label}</Typography>
                    <Typography>
                        <DateIntervalDisplayItemContent startDateValue={startDateValue} endDateValue={endDateValue} />
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <DatePicker
                                maxDate={endDateValue.id ? new Date(endDateValue.id) : maxDate}
                                minDate={
                                    endDateValue.id && maximumInterval
                                        ? sub(new Date(endDateValue.id), maximumInterval)
                                        : minDate
                                }
                                value={startDateValue.id}
                                mask={mask}
                                views={['day']}
                                inputFormat={dateFormat}
                                label={startDateLabel}
                                onChange={handleDateChange(startDateField)}
                                onError={onError(setStartDateErrMessage)}
                                InputProps={{ placeholder: undefined }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        helperText={
                                            <DateIntervalHelperText
                                                startDateValue={startDateValue}
                                                endDateValue={endDateValue}
                                                error={startDateErrMessage}
                                            />
                                        }
                                        inputProps={{ ...params.inputProps, placeholder: undefined }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                maxDate={
                                    startDateValue.id && maximumInterval
                                        ? add(new Date(startDateValue.id), maximumInterval)
                                        : maxDate
                                }
                                minDate={startDateValue.id ? new Date(startDateValue.id) : minDate}
                                mask={mask}
                                views={['day']}
                                inputFormat={dateFormat}
                                label={endDateLabel}
                                value={endDateValue.id}
                                onChange={handleDateChange(endDateField)}
                                onError={onError(setEndDateErrMessage)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        helperText={
                                            <DateIntervalHelperText
                                                startDateValue={startDateValue}
                                                endDateValue={endDateValue}
                                                error={endDateErrMessage}
                                            />
                                        }
                                        inputProps={{ ...params.inputProps, placeholder: undefined }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilterDateInterval;

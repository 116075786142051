import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Sharing positions',
        ru: 'Шаринговые позиции',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_owner`]: {
        en: 'Owner',
        ru: 'Ведущий',
    },
    [`${APP}_internal_number`]: {
        en: 'Number VK',
        ru: 'Номер ВК',
    },
    [`${APP}_external_number`]: {
        en: 'Number MTS',
        ru: 'Номер МТС',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_range`]: {
        en: 'Range',
        ru: 'Диапазон',
    },
    [`${APP}_infrastructure_owner`]: {
        en: 'Infrastructure owner',
        ru: 'Владелец инфраструктуры',
    },
    [`${APP}_transport_owner`]: {
        en: 'Transport owner',
        ru: 'Владелец транспорта',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
};

export default locale;

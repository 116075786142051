import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { APP } from '../../../../constants';
import { TroubleTicketViewEditResponsibleFormInitialValues } from '../connectors/trouble-ticket-view-edit-responsible-form-connector';
import TroubleTicketViewManagementCardsAccordion from '../trouble-ticket-view-management-cards-accordion';

import Translate from 'components/extended/translate';
import {
    SmartQueueRoleResponseModel,
    SmartQueueTicketHistoryRoleResponseModelIEnumerableApiResponse,
} from 'services/smart-queue/smart-queue-models';
import { useSmartQueueDictionaryRoleGetListQuery } from 'services/smart-queue/smart-queue-services';

const dutyOfficersData = [
    {
        id: 1,
        role: 'Инженер ЭБСиТ Чита',
        fullName: 'Димов Александр Сергеевич',
        today: 'Основной дежурный',
        phone: '+7 (914) 460-0e-13',
        altPhone: '+7 (914) 460-06-44',
    },
    {
        id: 2,
        role: 'Инженер ЭБСиТ Чита',
        fullName: 'Макарчук Дмитрий Михайлович',
        today: null,
        phone: '+7 (914) 455-0e-13',
        altPhone: '+7 (914) 444-06-44',
    },
];

type TroubleTicketViewEditResponsibleFormProps = {
    onClose: () => void;
    submitCallback: (data: any) => void;
    initialValues: TroubleTicketViewEditResponsibleFormInitialValues;
    isLoading: boolean;
    historyResponsibleGroup?: SmartQueueTicketHistoryRoleResponseModelIEnumerableApiResponse['data'];
    errors?: string[];
};

const formId = 'trouble-ticket-view-edit-responsible';

const rolesOptionsOverrides = (data: SmartQueueRoleResponseModel[] | undefined) =>
    data ? data.map((elem) => ({ id: elem.id, name: elem.name })) : [];

const TroubleTicketViewEditResponsibleForm: React.FC<TroubleTicketViewEditResponsibleFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading, historyResponsibleGroup } = props;

    const [notifyPerson, setNotifyPerson] = React.useState(false);

    const [rolesValue, setRolesValue] = React.useState(initialValues.responsibleGroup.name);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        setValue,
        watch,
    } = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
        mode: 'all',
    });

    const { data: roleData, isFetching } = useSmartQueueDictionaryRoleGetListQuery({
        value: {
            role: rolesValue,
        },
        pagination: {
            rowPerPage: 10,
            pageNumber: 1,
        },
    });

    const options = React.useMemo(() => rolesOptionsOverrides(roleData?.data), [roleData?.data]);

    const handleHistoryResponsibleGroupClick = React.useCallback(
        (value: { id: number; name: string }) => () => setValue('responsibleGroup', value),
        [setValue],
    );

    const handleNotifyPersonClick = React.useCallback(() => setNotifyPerson((prevState) => !prevState), []);

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="responsibleGroup"
                        control={control}
                        rules={{
                            required: 'responsible_group_is_required',
                        }}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                {/* TODO подумать как вынести асинхронщину для Autocomplete так чтобы исключить ререндеры всей формы */}
                                <Autocomplete
                                    {...field}
                                    size="small"
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    filterOptions={(x) => x}
                                    options={options}
                                    loading={isFetching}
                                    value={field.value}
                                    onChange={(event, data) => field.onChange(data)}
                                    onInputChange={(event, newInputValue) => {
                                        setRolesValue(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={<Translate app={APP} id="responsible_group" />}
                                            value={rolesValue}
                                            error={invalid && isTouched}
                                        />
                                    )}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Grid container direction="row" sx={{ py: 1 }} spacing={{ xs: 0.5, lg: 1 }}>
                        {historyResponsibleGroup?.map((elem) => (
                            <Grid item key={elem.id}>
                                <Chip
                                    onClick={handleHistoryResponsibleGroupClick({ id: elem.roleId, name: elem.role })}
                                    size="small"
                                    label={elem.role}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {watch('responsibleGroup')?.name === 'Инженер ЭБСиТ Чита' ? ( // TODO тут должна быть проверка на наличие данных из DutySchedule
                        <TroubleTicketViewManagementCardsAccordion data={dutyOfficersData} />
                    ) : null}
                    <Controller
                        name="comment"
                        control={control}
                        rules={{
                            required: 'comment_is_required',
                        }}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="comment" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="comment" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={notifyPerson} onClick={handleNotifyPersonClick} />}
                        label={<Translate app={APP} id="notify_person_on_duty" />}
                    />
                    {notifyPerson && (
                        <>
                            {watch('responsibleGroup')?.name === 'Инженер ЭБСиТ Чита' ? ( // TODO тут должна быть проверка на наличие данных из DutySchedule
                                <Controller
                                    name="management"
                                    control={control}
                                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                        <FormControl
                                            size="small"
                                            fullWidth
                                            error={invalid && isTouched}
                                            sx={{ marginTop: 1, width: '100%' }}
                                        >
                                            <InputLabel margin="dense" htmlFor={field.name}>
                                                <Translate app={APP} id="management" />
                                            </InputLabel>
                                            <Select
                                                {...field}
                                                id={field.name}
                                                label={<Translate app={APP} id="management" />}
                                            >
                                                <MenuItem value="">
                                                    <Translate app={APP} id="not_selected" />
                                                </MenuItem>
                                                {dutyOfficersData !== undefined
                                                    ? dutyOfficersData.map((elem) => (
                                                          <MenuItem key={elem.id} value={elem.fullName}>
                                                              {elem.fullName}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </Select>
                                            {invalid && isTouched && error?.message && (
                                                <FormHelperText error>
                                                    <Translate app={APP} id={error.message} />
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            ) : null}
                            {watch('management') ? null : (
                                <Controller
                                    name="fullName"
                                    control={control}
                                    rules={{
                                        required: 'full_name_is_required',
                                    }}
                                    render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                        <FormControl
                                            size="small"
                                            fullWidth
                                            error={invalid && isTouched}
                                            sx={{ marginTop: 1, width: '100%' }}
                                        >
                                            <InputLabel margin="dense" htmlFor={field.name}>
                                                <Translate app={APP} id="full_name" />
                                            </InputLabel>
                                            <OutlinedInput
                                                {...field}
                                                id={field.name}
                                                label={<Translate app={APP} id="full_name" />}
                                            />
                                            {invalid && isTouched && error?.message && (
                                                <FormHelperText error>
                                                    <Translate app={APP} id={error.message} />
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            )}
                            <Controller
                                name="phone"
                                control={control}
                                shouldUnregister
                                rules={{
                                    required: 'phone_is_required',
                                    pattern: {
                                        value: /^[0-9]{11}$/i,
                                        message: 'phone_invalid_format',
                                    },
                                }}
                                render={({ field, fieldState: { isTouched, invalid, error } }) => (
                                    <FormControl
                                        size="small"
                                        fullWidth
                                        error={invalid && isTouched}
                                        sx={{ marginTop: 1, width: '100%' }}
                                    >
                                        <InputLabel margin="dense" htmlFor={field.name}>
                                            <Translate app={APP} id="phone" />
                                        </InputLabel>
                                        <OutlinedInput
                                            id={field.name}
                                            {...field}
                                            label={<Translate app={APP} id="phone" />}
                                        />
                                        {invalid && isTouched && error?.message && (
                                            <FormHelperText error>
                                                <Translate app={APP} id={error.message} />
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </>
                    )}
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="save" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TroubleTicketViewEditResponsibleForm;

import Box, { BoxProps } from '@mui/material/Box';

type FilterDisplayWrapperProps = {
    sx?: BoxProps['sx'];
};

const FilterDisplayWrapper: React.FC<FilterDisplayWrapperProps> = (props) => {
    const { children, sx } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 48,
                pb: 2,
                px: 0,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default FilterDisplayWrapper;

import { TOPOLOGY_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import { TopologyTopologyFilterValueRequestModel, TopologyTopologyResponseModelApiResponse } from './topology-models';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const topologyServices = createApi({
    reducerPath: 'topologyServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: TOPOLOGY_SERVICE_URL,
    }),
    endpoints: (build) => ({
        topologyGet: build.query<TopologyTopologyResponseModelApiResponse, TopologyTopologyFilterValueRequestModel>({
            query: (queryArg) => ({ url: `/api/Topology/Get`, method: 'POST', body: queryArg }),
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useTopologyGetQuery } = topologyServices;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}`]: {
        en: 'Planned Work',
        ru: 'Плановая работа',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отменить',
    },
    [`${APP}_vising_status`]: {
        en: 'Vising status',
        ru: 'Статус визирования',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_impact_service`]: {
        en: 'Impact on the service',
        ru: 'Влияние на сервис',
    },
    [`${APP}_last_modified`]: {
        en: 'Last modified',
        ru: 'Последнее изменение',
    },
    [`${APP}_information`]: {
        en: 'Information',
        ru: 'Информация',
    },
    [`${APP}_coordinators`]: {
        en: 'Coordinators',
        ru: 'Согласующие',
    },
    [`${APP}_notificators`]: {
        en: 'Notificators',
        ru: 'Оповещаемые',
    },
    [`${APP}_executors`]: {
        en: 'Executors',
        ru: 'Исполнители',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Комментарии',
    },
    [`${APP}_network_elements`]: {
        en: 'Network elements',
        ru: 'Сетевые элементы',
    },
    [`${APP}_files`]: {
        en: 'Files',
        ru: 'Файлы',
    },
    [`${APP}_date_start`]: {
        en: 'Start time',
        ru: 'Время начала',
    },
    [`${APP}_date_end`]: {
        en: 'End time',
        ru: 'Время завершения',
    },
    [`${APP}_date_start_service`]: {
        en: 'Service interruption start time',
        ru: 'Время начала прерывания сервиса',
    },
    [`${APP}_date_end_service`]: {
        en: 'Service interruption end time',
        ru: 'Время завершения прерывания сервиса',
    },
    [`${APP}_date_interrupt_service`]: {
        en: 'Service interruption time',
        ru: 'Время прерывания сервиса',
    },
    [`${APP}_date_send_to_work`]: {
        en: 'Send to work time',
        ru: 'Время отправки в работу',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Название',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_initiator`]: {
        en: 'Initiator',
        ru: 'Инициатор',
    },
    [`${APP}_executor`]: {
        en: 'Executor',
        ru: 'Исполнитель',
    },
    [`${APP}_influence`]: {
        en: 'Influence',
        ru: 'Влияние',
    },
    [`${APP}_technical_influence`]: {
        en: 'Technical influence',
        ru: 'Техническое влияние',
    },
    [`${APP}_client_influence`]: {
        en: 'Influence on client',
        ru: 'Клиентское влияние',
    },
    [`${APP}_location`]: {
        en: 'Location',
        ru: 'Место проведения',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_coordinator`]: {
        en: 'Coordinator',
        ru: 'Согласующий',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_date`]: {
        en: 'Date',
        ru: 'Дата',
    },
    [`${APP}_notificator`]: {
        en: 'Notificator',
        ru: 'Оповещаемый',
    },
    [`${APP}_lm_code`]: {
        en: 'LM Code',
        ru: 'LM Code',
    },
    [`${APP}_model`]: {
        en: 'Model',
        ru: 'Модель',
    },
    [`${APP}_ip`]: {
        en: 'IP',
        ru: 'IP',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_customers`]: {
        en: 'Customers',
        ru: 'Заказчики',
    },
    [`${APP}_initiators`]: {
        en: 'Initiators',
        ru: 'Инициаторы',
    },
    [`${APP}_approved`]: {
        en: 'Approved',
        ru: 'Согласовано',
    },
    [`${APP}_yes`]: {
        en: 'Yes',
        ru: 'Да',
    },
    [`${APP}_no`]: {
        en: 'No',
        ru: 'Нет',
    },
};

export default locale;

import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';

import { APP } from '../constants';
import useEventsFilter from '../hooks/use-events-filter';

import DataGridCellTooltip from 'components/extended/data-grid/data-grid-cell-tooltip';
import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import Translate from 'components/extended/translate';
import { format } from 'date-fns';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { useMonitoringEventGetListQuery } from 'services/monitoring/monitoring-services';
import { APP as EVENT_VIEW_APP } from 'sub-apps/monitoring/event-view/constants';
import columnLocalization from 'utils/column-localization';

const renderCellDateTooltip = (params: GridRenderCellParams<string>) =>
    params.value !== undefined && DataGridCellTooltip(format(new Date(params.value), 'dd-MM-yyyy HH:mm'));

const renderCellTooltip = (params: GridRenderCellParams<string>) => params.value && DataGridCellTooltip(params.value);

const EventsTable: React.FC = () => {
    const { EventsFilterDialog, EventsFilterToolbar, filterValues } = useEventsFilter();

    const { Pagination, rowsState } = usePagination(`${APP}_monitoring_events_grid`, '5');

    const { createTab } = useGlobalTabs();

    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    const { data, refetch, isFetching } = useMonitoringEventGetListQuery({
        value: {
            searchText: filterValues.searchText,
            searchNumber: filterValues.searchNumber ? Number(filterValues.searchNumber) : null,
            state: Number(filterValues.state?.id),
            positionCount: Number(filterValues.positionCount?.id),
            plan: Number(filterValues.plan?.id),
            ticket: Number(filterValues.ticket?.id),
            responsible: [],
            branches: filterValues.branches?.map((elem) => String(elem.id)),
        },
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
        order: {
            field: 'Priority',
            sort: 'Asc',
        },
    });

    const chipOnClick = React.useCallback(
        (params) => () =>
            createTab({
                id: undefined,
                app: EVENT_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip onClick={chipOnClick(params)} size="small" label={params.value} sx={{ userSelect: 'text' }} />
        ),
        [chipOnClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_event_number`),
                field: 'id',
                renderCell: renderCellChip,
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_description`),
                field: 'description',
                renderCell: renderCellTooltip,
                flex: 3,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt`),
                field: 'planId',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw`),
                field: 'ticketId',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_created_user`),
                field: 'createdUser',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_ticket_status`),
                field: 'status',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_time_open`),
                field: 'timeOpen',
                renderCell: renderCellDateTooltip,
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_region`),
                field: 'region',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                flex: 1,
                sortable: false,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <EventsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        components={{
                            Pagination,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                rowsPerPageOptions: [5, 10, 15, 20, 25],
                                isFetching,
                            },
                        }}
                    />
                    <EventsFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default EventsTable;

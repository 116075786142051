import * as React from 'react';

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const Widgets = Loadable(React.lazy(() => import('./widgets')));

const widgetsApp: AppConfig = {
    name: APP,
    element: () => <Widgets />,
    locale,
};

export default widgetsApp;

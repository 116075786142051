import * as React from 'react';

import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

const Logo: React.FC = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            baseProfile="tiny-ps"
            viewBox="0 0 100 32"
            width="100"
            height="32"
        >
            <path
                id="Заливка цветом 2"
                fill={theme.palette.primary.main}
                d="M19.38 5.27C27.62 5.1 32.82 8.26 37.49 11.7C38.62 12.53 41.78 14.66 42 16.13C39.84 20.26 30.47 25.02 25.05 26.31C20.41 27.42 15.6 26.35 13.87 23.3C15.91 23.74 18.41 24.99 21.13 24.56C28.12 23.44 32.09 21.42 36.49 17.96C37.24 17.32 37.99 16.68 38.74 16.04C37.97 14.58 35.75 13.4 34.4 12.45C30.14 9.43 23.31 5.96 15.87 8.03C10.51 9.52 7.07 12.45 3.18 15.29C2.62 14.71 2.07 14.12 1.51 13.54C3.96 10.42 10.15 7.42 14.45 6.11C16.09 5.83 17.73 5.55 19.38 5.27Z"
            />

            <path
                transform="rotate(1 20.8427 17.9509)"
                stroke={theme.palette.secondary.main}
                fill="none"
                strokeWidth="2.7"
                id="svg_1"
                d="m20.84267,25.85306c-4.17939,0 -7.56469,-3.3853 -7.56469,-7.56469c0,-4.17939 3.3853,-7.56469 7.56469,-7.56469c4.17939,0 7.56469,3.3853 7.56469,7.56469c0,4.17939 -3.3853,7.56469 -7.56469,7.56469z"
            />
            <path
                id="iOKO"
                aria-label="iOKO"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="m55.8 13.7v10.3h-2.8v-10.3zm-2.9-2.7q0-0.6 0.4-1 0.4-0.4 1.1-0.4 0.7 0 1.1 0.4 0.5 0.4 0.5 1 0 0.7-0.5 1.1-0.4 0.4-1.1 0.4-0.7 0-1.1-0.4-0.4-0.4-0.4-1.1zm16.7 5.8v0.6q0 2-0.7 3.6-0.7 1.5-2.1 2.4-1.3 0.8-3 0.8-1.8 0-3.1-0.8-1.4-0.9-2.1-2.4-0.8-1.5-0.8-3.5v-0.7q0-2.1 0.8-3.6 0.7-1.6 2-2.4 1.4-0.9 3.1-0.9 1.8 0 3.1 0.9 1.4 0.8 2.1 2.4 0.7 1.5 0.7 3.6zm-2.9 0.6v-0.6q0-2.2-0.7-3.4-0.8-1.1-2.3-1.1-1.4 0-2.2 1.1-0.8 1.1-0.8 3.3v0.7q0 2.1 0.8 3.3 0.8 1.1 2.3 1.1 1.4 0 2.2-1.1 0.7-1.1 0.7-3.3zm12.9 6.6l-3.5-5.6-1.5 1.6v4h-2.9v-13.9h2.9v6.3l1.2-1.7 3.6-4.6h3.5l-5 6.2 5.1 7.7zm15.6-7.2v0.6q0 2-0.7 3.6-0.8 1.5-2.1 2.4-1.4 0.8-3.1 0.8-1.7 0-3.1-0.8-1.3-0.9-2.1-2.4-0.7-1.5-0.7-3.5v-0.7q0-2.1 0.7-3.6 0.7-1.6 2.1-2.4 1.3-0.9 3.1-0.9 1.7 0 3.1 0.9 1.3 0.8 2 2.4 0.8 1.5 0.8 3.6zm-2.9 0.6v-0.6q0-2.2-0.8-3.4-0.8-1.1-2.2-1.1-1.5 0-2.2 1.1-0.8 1.1-0.8 3.3v0.7q0 2.1 0.7 3.3 0.8 1.1 2.3 1.1 1.4 0 2.2-1.1 0.8-1.1 0.8-3.3z"
            />
        </svg>
    );
};

export default Logo;

import { WEBAPI_SERVICE_URL } from '../constants';

import mockServices from 'services/mocks/mockServices';

mockServices.onGet(`/${WEBAPI_SERVICE_URL}/me`).reply(() => [
    200,
    {
        status: 'Success',
        errors: null,
        total: 0,
        data: {
            id: 333,
            email: 'wlatt1991@yandex.ru',
        },
    },
]);

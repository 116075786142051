import * as React from 'react';

import TimeOnBatteriesFilterDialog from '../views/filter/time-on-batteries-filter-dialog';
import TimeOnBatteriesFilterToolbar from '../views/filter/time-on-batteries-filter-toolbar';

import {
    FilterCheckBoxStateValue,
    FilterDateStateValue,
    FilterSearchStateValue,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type TimeOnBatteriesFilterState = {
    position: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
    startDateTimeDown: FilterDateStateValue;
    endDateTimeDown: FilterDateStateValue;
    startDatePowerOff: FilterDateStateValue;
    endDatePowerOff: FilterDateStateValue;
};

const initialState: TimeOnBatteriesFilterState = {
    position: null,
    branches: null,
    startDateTimeDown: { id: null, name: null },
    endDateTimeDown: { id: null, name: null },
    startDatePowerOff: { id: null, name: null },
    endDatePowerOff: { id: null, name: null },
};

const useTimeOnBatteriesFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof TimeOnBatteriesFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof TimeOnBatteriesFilterState>('position');

    return {
        TimeOnBatteriesFilterDialog: () => (
            <TimeOnBatteriesFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        TimeOnBatteriesFilterToolbar: () => (
            <TimeOnBatteriesFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useTimeOnBatteriesFilter;

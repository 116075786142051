import * as React from 'react';

import TroubleTicketsFilterDialog from '../views/filter/trouble-tickets-filter-dialog';
import TroubleTicketsFilterToolbar from '../views/filter/trouble-tickets-filter-toolbar';

import {
    AvailableValue,
    FilterCheckBoxStateValue,
    FilterDateStateValue,
    FilterRadioStateValue,
    FilterSearchStateValue,
    FilterSwitchStateValue,
    SwitchLabelsValues,
} from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export const showInWorkLabelValues: SwitchLabelsValues = {
    true: 'show',
    false: 'hide',
};
export const overdueLabelValues: SwitchLabelsValues = {
    true: 'display_only_expired',
    false: 'display_all',
};
export const withInfluenceLabelValues: SwitchLabelsValues = {
    true: 'display_only_influence',
    false: 'display_all',
};
export const keyBSLabelValues: SwitchLabelsValues = {
    true: 'display_only_key_bs',
    false: 'display_all',
};
export const withGeouniteLabelValues: SwitchLabelsValues = {
    true: 'display_only_geounite',
    false: 'display_all',
};
export const powerLabelValues: SwitchLabelsValues = {
    true: 'display_only_power',
    false: 'display_all',
};
export const periodicLabelValues: SwitchLabelsValues = {
    true: 'display_only_periodic',
    false: 'display_all',
};

export type TroubleTicketsFilterState = {
    ttNumber: FilterSearchStateValue;
    priorities: FilterCheckBoxStateValue;
    showInWork: FilterSwitchStateValue;
    displayClosed: FilterSwitchStateValue;
    startDate: FilterDateStateValue;
    endDate: FilterDateStateValue;
    overdue: FilterSwitchStateValue;
    incidentStatus: FilterRadioStateValue;
    withInfluence: FilterSwitchStateValue;
    keyBS: FilterSwitchStateValue;
    withGeounite: FilterSwitchStateValue;
    power: FilterSwitchStateValue;
    periodic: FilterSwitchStateValue;
    creationSystem: FilterRadioStateValue;
    serviceStatus: FilterCheckBoxStateValue;
    status: FilterCheckBoxStateValue;
    placeOfOrigin: FilterCheckBoxStateValue;
    order: AvailableValue;
    groupRole: AvailableValue;
    roles: AvailableValue[];
};

// TODO initialState перенести внутрь хука и добавить запрос за словарем для сортировки
const initialState: TroubleTicketsFilterState = {
    ttNumber: null,
    priorities: null,
    showInWork: { id: false, name: showInWorkLabelValues.false },
    displayClosed: { id: false, name: showInWorkLabelValues.false },
    startDate: { id: null, name: null },
    endDate: { id: null, name: null },
    overdue: { id: false, name: overdueLabelValues.false },
    incidentStatus: null,
    withInfluence: { id: false, name: withInfluenceLabelValues.false },
    keyBS: { id: false, name: keyBSLabelValues.false },
    withGeounite: { id: false, name: withGeouniteLabelValues.false },
    power: { id: false, name: powerLabelValues.false },
    periodic: { id: false, name: periodicLabelValues.false },
    creationSystem: null,
    serviceStatus: null,
    status: null,
    placeOfOrigin: null,
    order: { id: 'Cdt', name: 'Сортировка по CDT' },
    groupRole: { id: '', name: '' },
    roles: [],
};

const useTroubleTicketsFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof TroubleTicketsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof TroubleTicketsFilterState>('ttNumber');

    return {
        TroubleTicketsFilterDialog: () => (
            <TroubleTicketsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        TroubleTicketsFilterToolbar: () => (
            <TroubleTicketsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useTroubleTicketsFilter;

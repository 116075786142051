import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import { APP } from '../../../../constants';
import { TroubleTicketViewPossibleReasonsTtFormInitialValues } from '../connectors/trouble-ticket-view-possible-reasons-tt-form-connector';

import Translate from 'components/extended/translate';

type TroubleTicketViewPossibleReasonsTtFormProps = {
    onClose: () => void;
    submitCallback: () => void;
    initialValues: TroubleTicketViewPossibleReasonsTtFormInitialValues;
    isLoading: boolean;
    errors?: string[];
};

const formId = 'trouble-ticket-view-possible-reasons-tt';

const TroubleTicketViewPossibleReasonsTtForm: React.FC<TroubleTicketViewPossibleReasonsTtFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues: initialValues,
        mode: 'all',
    });

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="region"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="region" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="region" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="branch"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="branch" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="branch" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="shortDescription"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="short_description" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    multiline
                                    label={<Translate app={APP} id="short_description" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="priority"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="priority" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="priority" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="role"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="role" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="role" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="serviceStatus"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="service_status" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="service_status" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="solutionStatus"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="solution_status" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="solution_status" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="createdDate"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="created_date" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="created_date" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="initiator"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="initiator" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="initiator" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="bind_to_incident" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TroubleTicketViewPossibleReasonsTtForm;

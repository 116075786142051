import * as React from 'react';

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const Home = Loadable(React.lazy(() => import('./home')));

const homeApp: AppConfig = {
    name: APP,
    element: () => <Home />,
    locale,
};

export default homeApp;

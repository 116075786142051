import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { TroubleTicketsFilterState } from '../../hooks/use-trouble-tickets-filter';

import { nanoid } from '@reduxjs/toolkit';
import BoolFieldButtonGroupDisplayFilter from 'components/extended/data-grid/filter/bool-field-button-group-display-filter';
import ButtonGroupDisplayFilter from 'components/extended/data-grid/filter/button-group-display-filter';
import ButtonGroupDisplayFilterWrapper from 'components/extended/data-grid/filter/button-group-display-filter-wrapper';
import DateIntervalDisplayItemContent from 'components/extended/data-grid/filter/date-interval-display-item-content';
import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemMenu from 'components/extended/data-grid/filter/filter-display-item-menu';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import {
    AvailableValue,
    BoolFieldButtonGroupFilterFields,
    ButtonGroupFilter,
    FilterToolbarProps,
    SearchFilter,
} from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';
import {
    useSmartQueueDictionaryOrderGetListQuery,
    useSmartQueueDictionaryPriorityGetListQuery,
} from 'services/smart-queue/smart-queue-services';

const searchFilters: SearchFilter<TroubleTicketsFilterState>[] = [
    { field: 'ttNumber', id: nanoid(), label: <Translate app={APP} id="tt" /> },
];

const boolFieldButtonGroupFilter: BoolFieldButtonGroupFilterFields<TroubleTicketsFilterState> = [
    { id: '1', field: 'overdue', label: <Translate app={APP} id="overdue" /> },
    { id: '2', field: 'withInfluence', label: <Translate app={APP} id="with_influence" /> },
    { id: '3', field: 'keyBS', label: <Translate app={APP} id="key_bs" /> },
    { id: '4', field: 'withGeounite', label: <Translate app={APP} id="with_geounite" /> },
    { id: '5', field: 'power', label: <Translate app={APP} id="power" /> },
    { id: '6', field: 'periodic', label: <Translate app={APP} id="periodic" /> },
];

const TroubleTicketsFilterToolbar: React.FC<FilterToolbarProps<TroubleTicketsFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, saveFilter, ...other } = props;

    const { data: priorityAvailableValues } = useSmartQueueDictionaryPriorityGetListQuery();

    const priorityButtonGroupFilters: ButtonGroupFilter<TroubleTicketsFilterState>[] = React.useMemo(
        () => [
            {
                id: '1',
                field: 'priorities',
                availableValues: priorityAvailableValues ? priorityAvailableValues.data : [],
            },
        ],
        [priorityAvailableValues],
    );

    const { data: orderAvailableValues } = useSmartQueueDictionaryOrderGetListQuery();

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<TroubleTicketsFilterState>({
        ...other,
    });

    const handleClickOrder = React.useCallback(
        (value: AvailableValue) => {
            setFilterValues((prevState) => ({ ...prevState, order: value }));
        },
        [setFilterValues],
    );

    return (
        <>
            <ButtonGroupDisplayFilterWrapper>
                <ButtonGroupDisplayFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    buttonGroupFilters={priorityButtonGroupFilters}
                />
                <BoolFieldButtonGroupDisplayFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    filterFields={boolFieldButtonGroupFilter}
                />
            </ButtonGroupDisplayFilterWrapper>
            <FilterDisplayWrapper>
                <FilterDisplayItemWrapper>
                    <SearchDisplayFilter
                        filterValues={filterValues}
                        searchFilters={searchFilters}
                        setFilterValues={setFilterValues}
                        currentSearchField={currentSearchField}
                        setCurrentSearchField={setCurrentSearchField}
                    />
                    <FilterDisplayItemMenu
                        title={<Translate app={APP} id="order" />}
                        value={filterValues.order.name}
                        availableValues={orderAvailableValues ? orderAvailableValues.data : []}
                        onClick={handleClickOrder}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="show_in_work" />}
                        Content={<Translate app={APP} id={filterValues.showInWork.name} />}
                        onClick={onClickFilterItem('showInWork')}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="display_closed" />}
                        Content={<Translate app={APP} id={filterValues.displayClosed.name} />}
                        onClick={onClickFilterItem('displayClosed')}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="incident_status" />}
                        Content={<DisplayItemContent valueLabel={filterValues.incidentStatus?.name} />}
                        onClick={onClickFilterItem('incidentStatus')}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="service_status" />}
                        Content={
                            <DisplayItemContent valueLabel={filterValues.serviceStatus?.map((elem) => elem.name)} />
                        }
                        onClick={onClickFilterItem('serviceStatus')}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="status" />}
                        Content={<DisplayItemContent valueLabel={filterValues.status?.map((elem) => elem.name)} />}
                        onClick={onClickFilterItem('status')}
                    />
                    <FilterDisplayItem
                        title={<Translate app={APP} id="period" />}
                        Content={
                            <DateIntervalDisplayItemContent
                                endDateValue={filterValues.endDate}
                                startDateValue={filterValues.startDate}
                            />
                        }
                        onClick={onClickFilterItem('startDate')}
                    />
                    {filterValues.groupRole.name && (
                        <FilterDisplayItem
                            title={<Translate app={APP} id="roles_and_groups" />}
                            Content={<DisplayItemContent valueLabel={filterValues.groupRole.name} />}
                            onClick={onClickFilterItem('groupRole')}
                        />
                    )}
                    {filterValues.roles.length > 0 && (
                        <FilterDisplayItem
                            title={<Translate app={APP} id="roles_and_groups" />}
                            Content={<DisplayItemContent valueLabel={filterValues.roles.map((elem) => elem.name)} />}
                            onClick={onClickFilterItem('roles')}
                        />
                    )}
                </FilterDisplayItemWrapper>
                <Box sx={{ display: 'flex', ml: 'auto' }}>
                    <Tooltip title={<Translate id="filter" />}>
                        <IconButton onClick={onClickOpenFilter}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    {saveFilter && (
                        <Tooltip title={<Translate id="save_filter" />}>
                            <IconButton onClick={saveFilter}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </FilterDisplayWrapper>
        </>
    );
};

export default TroubleTicketsFilterToolbar;

import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { PositionsFilterState } from '../../hooks/use-positions-filter';

import { nanoid } from '@reduxjs/toolkit';
import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import { FilterToolbarProps, SearchFilter } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';

const searchFilters: SearchFilter<PositionsFilterState>[] = [
    { field: 'search', id: nanoid(), label: <Translate app={APP} id="search" /> },
];

const PositionsFilterToolbar: React.FC<FilterToolbarProps<PositionsFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, saveFilter, ...other } = props;

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<PositionsFilterState>({ ...other });

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <SearchDisplayFilter
                    filterValues={filterValues}
                    searchFilters={searchFilters}
                    setFilterValues={setFilterValues}
                    currentSearchField={currentSearchField}
                    setCurrentSearchField={setCurrentSearchField}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="priority" />}
                    Content={<DisplayItemContent valueLabel={filterValues.priorities?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('priorities')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="regions_branches" />}
                    Content={<DisplayItemContent valueLabel={filterValues.branches?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('branches')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="system" />}
                    Content={<DisplayItemContent valueLabel={filterValues.systems?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('systems')}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate id="filter" />}>
                    <IconButton onClick={onClickOpenFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                {saveFilter && (
                    <Tooltip title={<Translate id="save_filter" />}>
                        <IconButton onClick={saveFilter}>
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </FilterDisplayWrapper>
    );
};

export default PositionsFilterToolbar;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { APP } from '../../constants';
import { KeyCoordinatorsFormInitialValues } from '../connectors/key-coordinators-form-add-connector';

import Translate from 'components/extended/translate';
import { RegionResponseModel } from 'services/webapi/generated/webapi-services';

type KeyCoordinatorsFormProps = {
    onClose: () => void;
    initialValues: KeyCoordinatorsFormInitialValues;
    submitCallback: (arg: KeyCoordinatorsFormInitialValues) => void;
    isLoading: boolean;
    regions: RegionResponseModel[];
    groupedBranches: { [key: number]: { id: string; name: string }[] };
    errors?: string[];
};

const formId = 'key-coordinators-form';

const options = [
    {
        id: 1,
        name: 'Иванов Иван Иванович',
    },
    {
        id: 2,
        name: 'Петров Петр Петрович',
    },
];

const KeyCoordinatorsForm: React.FC<KeyCoordinatorsFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading, regions, groupedBranches } = props;

    const [rolesValue, setRolesValue] = React.useState(initialValues.coordinator ? initialValues.coordinator.name : '');

    // TODO тут должен быть запрос за данными

    const {
        handleSubmit,
        control,
        formState: { isValid },
        setValue,
        watch,
    } = useForm<KeyCoordinatorsFormInitialValues>({
        defaultValues: initialValues,
        mode: 'all',
    });

    const regionValue = watch('region');

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="region"
                        control={control}
                        rules={{
                            required: 'field_required',
                        }}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="region" />
                                </InputLabel>
                                <Select
                                    {...field}
                                    onChange={(event) => {
                                        setValue('branch', '');
                                        field.onChange(event);
                                    }}
                                    label={<Translate app={APP} id="region" />}
                                    id={field.name}
                                >
                                    <MenuItem value="">
                                        <Translate app={APP} id="not_selected" />
                                    </MenuItem>
                                    {regions.map((elem) => (
                                        <MenuItem key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="branch"
                        control={control}
                        rules={{
                            required: 'field_required',
                        }}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 2, width: '100%' }}
                                disabled={!regionValue}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="branch" />
                                </InputLabel>
                                <Select {...field} label={<Translate app={APP} id="branch" />} id={field.name}>
                                    {regionValue !== '' &&
                                        groupedBranches[regionValue].map((elem) => (
                                            <MenuItem key={elem.id} value={elem.id}>
                                                {elem.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="isRequired"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                sx={{ marginTop: 1 }}
                                control={<Checkbox {...field} size="small" checked={field.value} />}
                                label={<Translate app={APP} id="required" />}
                            />
                        )}
                    />
                    <Controller
                        name="coordinator"
                        control={control}
                        rules={{
                            required: 'field_required',
                        }}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <Autocomplete
                                    {...field}
                                    size="small"
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    filterOptions={(x) => x}
                                    options={options}
                                    loading={false}
                                    value={field.value}
                                    onChange={(event, data) => field.onChange(data)}
                                    onInputChange={(event, newInputValue) => {
                                        setRolesValue(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={<Translate app={APP} id="coordinator" />}
                                            value={rolesValue}
                                            error={invalid && isTouched}
                                        />
                                    )}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="save" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default KeyCoordinatorsForm;

import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { AvailableValue, FilterRadioStateValue } from './types';

import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';

type FilterRadioProps<FilterState> = {
    field: keyof FilterState;
    value: FilterRadioStateValue;
    label: string | JSX.Element;
    expanded: boolean;
    availableValues: AvailableValue[];
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
};

const FilterRadio = <FilterState,>(props: FilterRadioProps<FilterState>) => {
    const { field, value, label, expanded, availableValues, setLocalFilterValues } = props;

    const handleToggle = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, _value: string) => {
            const __value = availableValues.filter((item) => item.id === Number(_value));
            setLocalFilterValues((prevState) => ({
                ...prevState,
                [field]: { id: _value, name: __value[0].name },
            }));
        },
        [availableValues, field, setLocalFilterValues],
    );

    return (
        <Accordion
            defaultExpanded={expanded}
            disableGutters
            sx={{ [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } }}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 60 }}>
                <div>
                    <Typography>{label}</Typography>
                    <Typography>
                        <DisplayItemContent valueLabel={value?.name} />
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <RadioGroup value={value?.id !== undefined ? value.id : null} onChange={handleToggle}>
                    {availableValues.map((_value) => (
                        <FormControlLabel
                            key={_value.id}
                            value={_value.id}
                            control={<Radio color="primary" />}
                            label={_value.name}
                        />
                    ))}
                </RadioGroup>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilterRadio;

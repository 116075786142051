import * as React from 'react';

import Box from '@mui/material/Box';

import { KeyedObject, ServiceResponseStatusEnum } from '../types';

import Loader from 'components/loader';
import useAppDispatch from 'hooks/use-app-dispatch';
import useAppSelector from 'hooks/use-app-selector';
import jwtDecode from 'jwt-decode';
import { authServices } from 'services/auth/auth-services';
import appSlice, { AppStateProps } from 'store/app-slice';
import { verifyToken } from 'utils/token';

type InitProviderProps = { children: React.ReactElement };

const InitProvider: React.FC<InitProviderProps> = (props) => {
    const { children } = props;
    const isInit = useAppSelector(appSlice.selectors.isInitSelector);
    const authToken = useAppSelector(appSlice.selectors.authTokenSelector);
    const accessToken = useAppSelector(appSlice.selectors.accessTokenSelector);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const initialization = async (): Promise<AppStateProps['auth']> => {
            if (accessToken !== null) {
                if (verifyToken(accessToken)) return 'authorized';

                try {
                    const decodedAccessToken: KeyedObject = jwtDecode(accessToken);
                    const refreshToken = decodedAccessToken?.refreshToken;

                    if (refreshToken && typeof refreshToken === 'string' && verifyToken(refreshToken)) {
                        const refreshResult = await dispatch(
                            authServices.endpoints.refresh.initiate({
                                refreshToken,
                            }),
                        );

                        if ('data' in refreshResult) {
                            const { data: response } = refreshResult;

                            if (response.status === ServiceResponseStatusEnum.Success) {
                                const newAccessToken = response.data.token;

                                if (newAccessToken) {
                                    dispatch(appSlice.actions.setAccessToken(newAccessToken));
                                    return 'authorized';
                                }
                            }
                        }
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            }

            if (verifyToken(authToken)) return 'authenticated';

            return null;
        };

        initialization().then((res) => {
            dispatch(appSlice.actions.setAuth(res));
            dispatch(appSlice.actions.setInit(true));
        });
        // eslint-disable-next-line
    }, [isInit]);

    if (isInit) return children;

    return (
        <Box
            sx={{
                height: '100vh',
            }}
        >
            <Loader />
        </Box>
    );
};

export default InitProvider;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import { APP } from '../../../../constants';
import { TroubleTicketViewPossibleReasonsOplFormInitialValues } from '../connectors/trouble-ticket-view-possible-reasons-opl-form-connector';

import Translate from 'components/extended/translate';

type TroubleTicketViewPossibleReasonsOplFormProps = {
    onClose: () => void;
    submitCallback: () => void;
    initialValues: TroubleTicketViewPossibleReasonsOplFormInitialValues;
    isLoading: boolean;
    errors?: string[];
};

const formId = 'trouble-ticket-view-possible-reasons-plw';

const TroubleTicketViewPossibleReasonsOplForm: React.FC<TroubleTicketViewPossibleReasonsOplFormProps> = (props) => {
    const { onClose, submitCallback, initialValues, errors, isLoading } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues: initialValues,
        mode: 'all',
    });

    return (
        <>
            <DialogContent dividers sx={{ px: 2, pt: 1, pb: 2 }}>
                <form noValidate onSubmit={handleSubmit(submitCallback)} id={formId}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="status" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="status" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="title" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="title" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="description" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    multiline
                                    label={<Translate app={APP} id="description" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="object"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="object" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="object" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="type" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="type" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="region"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="region" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="region" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="branch"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="branch" />
                                </InputLabel>
                                <OutlinedInput {...field} id={field.name} label={<Translate app={APP} id="branch" />} />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="dateStart"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="date_start" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="date_start" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="dateEnd"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="date_end" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="date_end" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="dateStartService"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="date_start_service" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="date_start_service" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="dateEndService"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="date_end_service" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="date_end_service" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="initiator"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="initiator" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="initiator" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="executor"
                        control={control}
                        render={({ field, fieldState: { isTouched, invalid, error } }) => (
                            <FormControl
                                size="small"
                                fullWidth
                                error={invalid && isTouched}
                                disabled
                                sx={{ marginTop: 1, width: '100%' }}
                            >
                                <InputLabel margin="dense" htmlFor={field.name}>
                                    <Translate app={APP} id="executor" />
                                </InputLabel>
                                <OutlinedInput
                                    {...field}
                                    id={field.name}
                                    label={<Translate app={APP} id="executor" />}
                                />
                                {invalid && isTouched && error?.message && (
                                    <FormHelperText error>
                                        <Translate app={APP} id={error.message} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </form>
                {errors &&
                    errors.map((message) => (
                        <Alert key={message} sx={{ mt: 2 }} severity="error">
                            {message}
                        </Alert>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button disableElevation size="small" variant="outlined" onClick={onClose}>
                    <Translate app={APP} id="cancel" />
                </Button>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    form={formId}
                >
                    <Translate app={APP} id="close_in_favor_plw" />
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TroubleTicketViewPossibleReasonsOplForm;

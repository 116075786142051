import * as React from 'react';

import { Add } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type AutoRefetchProps = {
    refetchCallback: () => void;
    refetchTime?: number;
    isFetching?: boolean;
};

const formattingTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
};

const maxTimerValue = 599;

const AutoRefetch: React.FC<AutoRefetchProps> = (props) => {
    const { refetchTime = 60, refetchCallback, isFetching } = props;

    const controlMaxValueTimer = React.useCallback(() => (refetchTime > maxTimerValue ? maxTimerValue : refetchTime), [
        refetchTime,
    ]);
    const timerStep = React.useCallback(() => Math.round(refetchTime / 3), [refetchTime]);

    const [timer, setTimer] = React.useState({ seconds: controlMaxValueTimer(), progress: 100 });

    React.useEffect(() => {
        const update = () => {
            if (timer.seconds > 0) {
                setTimer((prevState) => {
                    const nextProgress =
                        prevState.progress - 100 / Math.round((prevState.seconds / prevState.progress) * 100);
                    return {
                        seconds: prevState.seconds - 1,
                        progress: nextProgress < 0 ? 0 : nextProgress,
                    };
                });
            } else {
                refetchCallback();
            }
        };
        const timeOut = setTimeout(update, 1000);
        return () => clearTimeout(timeOut);
    }, [refetchCallback, refetchTime, timer.seconds]);

    React.useEffect(() => {
        if (isFetching) {
            setTimer({ seconds: refetchTime, progress: 100 });
        }
    }, [isFetching, refetchTime]);

    const handleClickAdd = React.useCallback(() => {
        setTimer((prevState) => {
            const newTimer = prevState.seconds + timerStep();
            const newProgress =
                prevState.progress + (100 / Math.round((newTimer / prevState.progress) * 100)) * refetchTime;
            return {
                seconds: newTimer > controlMaxValueTimer() ? controlMaxValueTimer() : newTimer,
                progress: newProgress >= 100 ? 100 : newProgress,
            };
        });
    }, [controlMaxValueTimer, timerStep, refetchTime]);

    return (
        <>
            <IconButton
                disabled={timer.seconds > controlMaxValueTimer() - timerStep()}
                onClick={handleClickAdd}
                disableTouchRipple
                disableFocusRipple
                disableRipple
            >
                <Add />
            </IconButton>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginRight: '0.5em' }}>
                <CircularProgress variant="determinate" size={40} value={timer.progress} color="inherit" />
                <Typography sx={{ fontSize: 12 }} position="absolute">
                    {formattingTime(timer.seconds)}
                </Typography>
            </Box>
        </>
    );
};

export default AutoRefetch;

import * as React from 'react';

import Box from '@mui/material/Box';

type TabPanelProps = {
    tabId: string;
    visible: boolean;
    children?: React.ReactNode;
};

const GlobalTabPanel: React.FC<TabPanelProps> = (props) => {
    const [isTouched, setIsTouched] = React.useState(false);

    const { children, tabId, visible } = props;

    React.useEffect(() => {
        if (visible) {
            setIsTouched(true);
        }
    }, [visible]);

    return (
        <Box component="div" hidden={!visible} role="tabpanel" id={tabId}>
            {isTouched ? children : null}
        </Box>
    );
};

export default React.memo(GlobalTabPanel, (prevProps, nextProps) => prevProps.visible === nextProps.visible);

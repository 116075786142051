import * as React from 'react';
import { useIntl } from 'react-intl';

import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import { inputLabelClasses } from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import { APP } from '../../../constants';

import Translate from 'components/extended/translate';

type TroubleTicketViewCommentInputProps = {
    handleSendComment: (comment: string) => () => void;
    isLoading: boolean;
};

const TroubleTicketViewCommentInput: React.FC<TroubleTicketViewCommentInputProps> = (props) => {
    const { handleSendComment, isLoading } = props;

    const [commentText, setCommentText] = React.useState('');

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCommentText(event.target.value);
    }, []);

    const enterKeyPress = React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === 'Enter') handleSendComment(commentText)();
        },
        [commentText, handleSendComment],
    );

    const intl = useIntl();

    return (
        <TextField
            value={commentText}
            sx={{
                fontSize: '0.65rem',
                [`& .${inputLabelClasses.root}`]: {
                    top: 1,
                },
                width: '100%',
            }}
            size="small"
            onChange={handleChange}
            onKeyPress={enterKeyPress}
            placeholder={intl.formatMessage({ id: `${APP}_enter_your_comment` })}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <LoadingButton
                            disabled={commentText.length === 0}
                            variant="contained"
                            size="small"
                            endIcon={<SendIcon />}
                            onClick={handleSendComment(commentText)}
                            loading={isLoading}
                        >
                            <Translate app={APP} id="send" />
                        </LoadingButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default TroubleTicketViewCommentInput;

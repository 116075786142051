import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { EquipmentFilterState } from '../../hooks/use-equipment-filter';

import { nanoid } from '@reduxjs/toolkit';
import DisplayItemContent from 'components/extended/data-grid/filter/display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import { FilterToolbarProps, SearchFilter } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';

const searchFilters: SearchFilter<EquipmentFilterState>[] = [
    { field: 'search', id: nanoid(), label: <Translate app={APP} id="search" /> },
];

const EquipmentFilterToolbar: React.FC<FilterToolbarProps<EquipmentFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, variant, ...other } = props;

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<EquipmentFilterState>({ ...other });

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                {variant === 'app' ? (
                    <SearchDisplayFilter
                        filterValues={filterValues}
                        searchFilters={searchFilters}
                        setFilterValues={setFilterValues}
                        currentSearchField={currentSearchField}
                        setCurrentSearchField={setCurrentSearchField}
                    />
                ) : null}
                <FilterDisplayItem
                    title={<Translate app={APP} id="regions_branches" />}
                    Content={<DisplayItemContent valueLabel={filterValues.branches?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('branches')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="vendor" />}
                    Content={<DisplayItemContent valueLabel={filterValues.vendors?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('vendors')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="equipment_type" />}
                    Content={<DisplayItemContent valueLabel={filterValues.equipmentTypes?.map((elem) => elem.name)} />}
                    onClick={onClickFilterItem('equipmentTypes')}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate id="filter" />}>
                    <IconButton onClick={onClickOpenFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </FilterDisplayWrapper>
    );
};

export default EquipmentFilterToolbar;

import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps, dialogClasses } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';

import { APP } from '../../constants';
import AccessesFormAddConnector from '../connectors/accesses-form-add-connector';
import AccessesFormUpdateConnector from '../connectors/accesses-form-update-connector';

import Translate from 'components/extended/translate';

type AccessesDialogProps = DialogProps & {
    onClose: () => void;
    positionNumber: string;
    accessId?: number;
};

const AccessesDialog: React.FC<AccessesDialogProps> = (props) => {
    const { open, onClose, accessId, positionNumber } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="xs"
            fullWidth
            fullScreen={fullScreen}
            sx={{ [`.${dialogClasses.paper}`]: { padding: 0 } }}
        >
            <DialogTitle
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1, pl: 2, pr: 1 }}
            >
                <Typography>
                    {accessId ? <Translate app={APP} id="edit_access" /> : <Translate app={APP} id="add_access" />}
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {accessId ? (
                <AccessesFormUpdateConnector onClose={onClose} accessId={accessId} />
            ) : (
                <AccessesFormAddConnector onClose={onClose} positionNumber={positionNumber} />
            )}
        </Dialog>
    );
};

export default AccessesDialog;

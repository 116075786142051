import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useEprViewFilter from '../hooks/use-epr-view-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import Translate from 'components/extended/translate';
import usePagination from 'hooks/use-pagination';
import columnLocalization from 'utils/column-localization';

const rows = [
    {
        id: 0,
        staffId: 'gwx881822',
        remedyId: 'no_remedy_id',
        rusName: 'Горноулова Екатерина Игоревна',
        region: 'Сибирь',
        branch: 'Новосибирский',
        team: 'IM FIX Voice',
        fo1cAssign: 0,
        fo1cCreate: 0,
        fo1cExecute: 0,
        ttAssign: 0,
        ttRestore: 0,
        ttClose: 0,
        ttCreate: 0,
        ttBind: 0,
        ttComment: 0,
        tt: 0,
        pw: 0,
        pwSo: 0,
        pwVisa: 0,
        pwSoEx: 0,
        msupCreate: 69,
        msupReassign: 55,
        msupCancel: 0,
        msupClose: 0,
        workHours: 583,
        bonus: 0.038,
        info: {
            id: 8633,
            dateTime: '2022-01-11T23:32:51',
            initiatorId: 327,
            comment: 'В2С',
            data: {
                id: '-2147482644',
                year: 2021,
                quarter: 4,
                teamId: 300028,
                staffId: 'gwx881822',
                bonus: 0.038,
            },
        },
    },
    {
        id: 1,
        staffId: 'gwx985875',
        remedyId: 'no_remedy_id',
        rusName: 'Гурлач Маргарита Витальевна',
        region: 'Сибирь',
        branch: 'Новосибирский',
        team: 'IM FIX Voice',
        fo1cAssign: 0,
        fo1cCreate: 0,
        fo1cExecute: 0,
        ttAssign: 0,
        ttRestore: 0,
        ttClose: 0,
        ttCreate: 0,
        ttBind: 0,
        ttComment: 0,
        tt: 0,
        pw: 0,
        pwSo: 0,
        pwVisa: 0,
        pwSoEx: 0,
        msupCreate: 65,
        msupReassign: 49,
        msupCancel: 0,
        msupClose: 0,
        workHours: 610,
        bonus: 0.105,
        info: {
            id: 8634,
            dateTime: '2022-01-11T23:34:03',
            initiatorId: 327,
            comment: 'В2С',
            data: {
                id: '-2147482644',
                year: 2021,
                quarter: 4,
                teamId: 300028,
                staffId: 'gwx985875',
                bonus: 0.105,
            },
        },
    },
    {
        id: 2,
        staffId: 'uwx1128112',
        remedyId: 'В392230',
        rusName: 'Уасова Алина Бахитовна',
        region: 'Сибирь',
        branch: 'Новосибирский',
        team: 'IM FIX Voice',
        fo1cAssign: 0,
        fo1cCreate: 0,
        fo1cExecute: 0,
        ttAssign: 0,
        ttRestore: 0,
        ttClose: 0,
        ttCreate: 0,
        ttBind: 0,
        ttComment: 0,
        tt: 0,
        pw: 0,
        pwSo: 0,
        pwVisa: 0,
        pwSoEx: 0,
        msupCreate: 0,
        msupReassign: 0,
        msupCancel: 0,
        msupClose: 0,
        workHours: 64,
        bonus: 0,
        info: null,
    },
    {
        id: 3,
        staffId: 'kwx967587',
        remedyId: 'no_remedy_id',
        rusName: 'Хамдамов Мухаммаджон Джалолиддинович',
        region: 'Сибирь',
        branch: 'Новосибирский',
        team: 'IM FIX Voice',
        fo1cAssign: 0,
        fo1cCreate: 0,
        fo1cExecute: 0,
        ttAssign: 0,
        ttRestore: 0,
        ttClose: 0,
        ttCreate: 0,
        ttBind: 0,
        ttComment: 0,
        tt: 0,
        pw: 0,
        pwSo: 0,
        pwVisa: 0,
        pwSoEx: 0,
        msupCreate: 40,
        msupReassign: 4,
        msupCancel: 0,
        msupClose: 0,
        workHours: 484,
        bonus: 0.085,
        info: {
            id: 8636,
            dateTime: '2022-01-11T23:35:06',
            initiatorId: 327,
            comment: 'В2С',
            data: {
                id: '-2147482640',
                year: 2021,
                quarter: 4,
                teamId: 300028,
                staffId: 'kwx967587',
                bonus: 0.085,
            },
        },
    },
    {
        id: 4,
        staffId: 'swx1015860',
        remedyId: 'no_remedy_id',
        rusName: 'Шипулина Евгения Евгеньевна',
        region: 'Сибирь',
        branch: 'Новосибирский',
        team: 'IM FIX Voice',
        fo1cAssign: 0,
        fo1cCreate: 0,
        fo1cExecute: 0,
        ttAssign: 0,
        ttRestore: 0,
        ttClose: 0,
        ttCreate: 0,
        ttBind: 0,
        ttComment: 0,
        tt: 0,
        pw: 0,
        pwSo: 0,
        pwVisa: 0,
        pwSoEx: 0,
        msupCreate: 53,
        msupReassign: 6,
        msupCancel: 0,
        msupClose: 0,
        workHours: 517,
        bonus: 0.043,
        info: {
            id: 8637,
            dateTime: '2022-01-11T23:35:30',
            initiatorId: 327,
            comment: 'В2С',
            data: {
                id: '-2147482643',
                year: 2021,
                quarter: 4,
                teamId: 300028,
                staffId: 'swx1015860',
                bonus: 0.043,
            },
        },
    },
];

const renderCellRounding = (count: number) => (params: GridRenderCellParams) =>
    params?.value ? params.value.toFixed(count) : 0;

const renderCellTotal = (params: GridRenderCellParams) => params.value + 4;

const EprViewTable: React.FC = () => {
    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip
                size="small"
                label={params.row.rusName.length > 30 ? [`${params.value?.substring(0, 30)} ...`] : params.value}
            />
        ),
        [],
    );

    const { EprListFilterToolbar } = useEprViewFilter();

    const { Pagination } = usePagination(`${APP}_epr_view_grid`, '5');

    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_full_name`),
                field: 'rusName',
                renderCell: renderCellChip,
                width: 280,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`region`),
                field: 'region',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`branch`),
                field: 'branch',
                width: 150,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_fo_1c_assign`),
                field: 'fo1cAssign',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_fo_1c_create`),
                renderCell: renderCellRounding(3),
                field: 'fo1cCreate',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_fo_1c_execute`),
                renderCell: renderCellRounding(3),
                field: 'fo1cExecute',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_assign`),
                renderCell: renderCellRounding(3),
                field: 'ttAssign',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_restore`),
                renderCell: renderCellRounding(3),
                field: 'ttRestore',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_close`),
                renderCell: renderCellRounding(3),
                field: 'ttClose',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_create`),
                renderCell: renderCellRounding(3),
                field: 'ttCreate',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_bind`),
                renderCell: renderCellRounding(3),
                field: 'ttBind',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_comment`),
                renderCell: renderCellRounding(3),
                field: 'ttComment',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt`),
                renderCell: renderCellRounding(3),
                field: 'tt',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw`),
                renderCell: renderCellRounding(3),
                field: 'pw',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw_so`),
                renderCell: renderCellRounding(3),
                field: 'pwSo',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw_visa`),
                renderCell: renderCellRounding(3),
                field: 'pwVisa',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw_so_ex`),
                renderCell: renderCellRounding(3),
                field: 'pwSoEx',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_msup_create`),
                renderCell: renderCellRounding(3),
                field: 'msupCreate',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_msup_cancel`),
                renderCell: renderCellRounding(3),
                field: 'msupCancel',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_msup_close`),
                renderCell: renderCellRounding(3),
                field: 'msupClose',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_work_hours`),
                renderCell: renderCellRounding(0),
                field: 'workHours',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_bonus`),
                renderCell: renderCellRounding(0),
                field: 'bonus',
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_total`),
                renderCell: renderCellTotal,
                field: 'total',
                sortable: false,
            },
        ],
        [renderCellChip],
    );

    return (
        <>
            {rows !== undefined && (
                <>
                    <EprListFilterToolbar />
                    <DataGrid
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                        }}
                        density="compact"
                        autoHeight
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        getRowHeight={() => 'auto'}
                        onSortModelChange={setSortModel}
                        components={{
                            Pagination,
                        }}
                        componentsProps={{
                            pagination: {
                                total: rows.length,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                </>
            )}
            <DataGridLoader visible={false} text={<Translate app={APP} id="loading" />} />
        </>
    );
};

export default EprViewTable;

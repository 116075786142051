import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import Zoom from '@mui/material/Zoom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { TransitionProps } from '@mui/material/transitions';

import LoadingBar from 'components/extended/loading-bar';
import Locales from 'components/locales';
import useAppDispatch from 'hooks/use-app-dispatch';
import useSelector from 'hooks/use-app-selector';
import NavigationScroll from 'layouts/navigation-scroll';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import ModalProvider from 'mui-modal-provider';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import InitProvider from 'providers/init-provider';
import Routes from 'routes';
import appSlice from 'store/app-slice';
import customizationSlice from 'store/customization';
import themes from 'themes';

const snackbarAnchor: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const confirmOptions: ConfirmOptions = {
    dialogProps: {
        TransitionComponent: React.forwardRef(
            (
                props: TransitionProps & {
                    children: React.ReactElement<any, any>;
                },
                ref: React.Ref<unknown>,
            ) => {
                const { children, ...other } = props;

                return (
                    <Zoom in ref={ref} {...other}>
                        {children}
                    </Zoom>
                );
            },
        ),
    },
    cancellationText: 'Отменить',
    cancellationButtonProps: {
        variant: 'outlined',
        color: 'secondary',
    },
    confirmationButtonProps: { autoFocus: true },
    confirmationText: 'Да',
    allowClose: false,
};

const App: React.FC = () => {
    const customization = useSelector(customizationSlice.selectors.customizationSelector);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const location = window.location;
        dispatch(
            appSlice.actions.setEntryPoint({
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            }),
        );
        // eslint-disable-next-line
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <LoadingBar />
                <Locales>
                    <ModalProvider>
                        <ConfirmProvider defaultOptions={confirmOptions}>
                            <SnackbarProvider anchorOrigin={snackbarAnchor}>
                                <NavigationScroll>
                                    <InitProvider>
                                        <Routes />
                                    </InitProvider>
                                </NavigationScroll>
                            </SnackbarProvider>
                        </ConfirmProvider>
                    </ModalProvider>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

import { MONITORING_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import {
    MonitoringAlarmFilterRequestModel,
    MonitoringAlarmResponseModelIEnumerableApiResponse,
    MonitoringEquipmentTypeResponseModelIEnumerableApiResponse,
    MonitoringEventAddAlarmsRequestModel,
    MonitoringEventByTypeFilterValueRequestModelFilterRequestModel,
    MonitoringEventByTypeResponseModelIEnumerableApiResponse,
    MonitoringEventColumns,
    MonitoringEventCreateRequestModel,
    MonitoringEventDeleteAlarmsRequestModel,
    MonitoringEventFilterRequestModel,
    MonitoringEventHistoryResponseModelIEnumerableApiResponse,
    MonitoringEventPositionsResponseModelIEnumerableApiResponse,
    MonitoringEventResponseModelApiResponse,
    MonitoringEventResponseModelIEnumerableApiResponse,
    MonitoringEventStatisticsResponseModelIEnumerableApiResponse,
    MonitoringEventTypeResponseModelIEnumerableApiResponse,
    MonitoringFullEventResponseModelApiResponse,
    MonitoringPlanExistenceResponseModelIEnumerableApiResponse,
    MonitoringPositionCountResponseModelIEnumerableApiResponse,
    MonitoringPositionTypeResponseModelIEnumerableApiResponse,
    MonitoringSearchByResponseModelIEnumerableApiResponse,
    MonitoringStateResponseModelIEnumerableApiResponse,
    MonitoringStringApiResponse,
    MonitoringSystemResponseModelIEnumerableApiResponse,
    MonitoringTicketExistenceResponseModelIEnumerableApiResponse,
    MonitoringTicketStatusResponseModelIEnumerableApiResponse,
} from './monitoring-models';
import {
    MONITORING_ALARM,
    MONITORING_EVENT,
    MONITORING_EVENTS,
    MONITORING_EVENTS_BY_TYPE,
    MONITORING_EVENT_ALARMS,
    MONITORING_EVENT_BY_POSITION,
    MONITORING_EVENT_HISTORY,
    MONITORING_EVENT_POSITIONS,
    MONITORING_EVENT_STATISTIC,
} from './tags';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const monitoringServices = createApi({
    reducerPath: 'monitoringServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: MONITORING_SERVICE_URL,
    }),
    tagTypes: [
        MONITORING_ALARM,
        MONITORING_EVENTS,
        MONITORING_EVENTS_BY_TYPE,
        MONITORING_EVENT_HISTORY,
        MONITORING_EVENT_ALARMS,
        MONITORING_EVENT_POSITIONS,
        MONITORING_EVENT_STATISTIC,
        MONITORING_EVENT,
        MONITORING_EVENT_BY_POSITION,
    ],
    endpoints: (build) => ({
        monitoringAlarmGetList: build.query<
            MonitoringAlarmResponseModelIEnumerableApiResponse,
            MonitoringAlarmFilterRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Alarm/GetList`, method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id: alarmId }) => ({ type: MONITORING_ALARM, id: alarmId } as const)),
                          { type: MONITORING_ALARM, id: 'LIST' },
                      ]
                    : [{ type: MONITORING_ALARM, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventGetList: build.query<
            MonitoringEventResponseModelIEnumerableApiResponse,
            MonitoringEventFilterRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Event/GetList`, method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id: eventId }) => ({ type: MONITORING_EVENTS, id: eventId } as const)),
                          { type: MONITORING_EVENTS, id: 'LIST' },
                      ]
                    : [{ type: MONITORING_EVENTS, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventByTypeGetList: build.query<
            MonitoringEventByTypeResponseModelIEnumerableApiResponse,
            MonitoringEventByTypeFilterValueRequestModelFilterRequestModel
        >({
            query: (queryArg) => ({ url: `/api/EventByType/GetList`, method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(
                              ({ id: eventId }) => ({ type: MONITORING_EVENTS_BY_TYPE, id: eventId } as const),
                          ),
                          { type: MONITORING_EVENTS_BY_TYPE, id: 'LIST' },
                      ]
                    : [{ type: MONITORING_EVENTS_BY_TYPE, id: 'LIST' }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventGet: build.query<MonitoringFullEventResponseModelApiResponse, string>({
            query: (eventId) => ({ url: `/api/Event/FullInfo/${eventId}` }),
            providesTags: (result, error, eventId) => [{ type: MONITORING_EVENT, eventId }],
        }),
        monitoringEventHistoryGet: build.query<
            MonitoringEventHistoryResponseModelIEnumerableApiResponse,
            {
                eventId: number;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Event/History/${queryArg.eventId}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { eventId }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: MONITORING_EVENT_HISTORY, id } as const)),
                          { type: MONITORING_EVENT_HISTORY, id: `LIST-${eventId}` },
                      ]
                    : [{ type: MONITORING_EVENT_HISTORY, id: `LIST-${eventId}` }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventAlarmsGet: build.query<
            MonitoringAlarmResponseModelIEnumerableApiResponse,
            {
                eventId: number;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Event/Alarms/${queryArg.eventId}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { eventId }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: MONITORING_EVENT_ALARMS, id } as const)),
                          { type: MONITORING_EVENT_ALARMS, id: `LIST-${eventId}` },
                      ]
                    : [{ type: MONITORING_EVENT_ALARMS, id: `LIST-${eventId}` }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventPositionsGet: build.query<
            MonitoringEventPositionsResponseModelIEnumerableApiResponse,
            {
                eventId: number;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Event/Positions/${queryArg.eventId}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { eventId }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: MONITORING_EVENT_POSITIONS, id } as const)),
                          { type: MONITORING_EVENT_POSITIONS, id: `LIST-${eventId}` },
                      ]
                    : [{ type: MONITORING_EVENT_POSITIONS, id: `LIST-${eventId}` }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventStatisticGet: build.query<
            MonitoringEventStatisticsResponseModelIEnumerableApiResponse,
            {
                eventId: number;
                rowPerPage: number;
                pageNumber: number;
            }
        >({
            query: (queryArg) => ({
                url: `/api/Event/Statistics/${queryArg.eventId}`,
                params: { RowPerPage: queryArg.rowPerPage, PageNumber: queryArg.pageNumber },
            }),
            providesTags: (result, error, { eventId }) => [{ type: MONITORING_EVENT_STATISTIC, id: `LIST-${eventId}` }],
            keepUnusedDataFor: 0,
        }),
        monitoringEventCreate: build.mutation<
            MonitoringEventResponseModelApiResponse,
            MonitoringEventCreateRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Event`, method: 'POST', body: queryArg }),
            invalidatesTags: () => [{ type: MONITORING_EVENTS, id: 'LIST' }],
        }),
        monitoringBindAlarm: build.mutation<MonitoringStringApiResponse, MonitoringEventAddAlarmsRequestModel>({
            query: (queryArg) => ({ url: `/api/Event/Alarms/Add`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, { id: eventId }) => [
                { type: MONITORING_EVENT_ALARMS, id: `LIST-${eventId}` },
            ],
        }),
        monitoringUnbindAlarm: build.mutation<MonitoringStringApiResponse, MonitoringEventDeleteAlarmsRequestModel>({
            query: (queryArg) => ({ url: `/api/Event/Alarms/Delete`, method: 'DELETE', body: queryArg }),
            invalidatesTags: (result, error, { id: eventId }) => [
                { type: MONITORING_EVENT_ALARMS, id: `LIST-${eventId}` },
            ],
        }),
        monitoringDictionarySystemGetList: build.query<MonitoringSystemResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/System/GetList` }),
        }),
        monitoringDictionaryEquipmentTypeGetList: build.query<
            MonitoringEquipmentTypeResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/EquipmentType/GetList` }),
        }),
        monitoringDictionaryPositionCountGetList: build.query<
            MonitoringPositionCountResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/PositionCount/GetList` }),
        }),
        monitoringDictionaryPlanExistenceGetList: build.query<
            MonitoringPlanExistenceResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/PlanExistence/GetList` }),
        }),
        monitoringDictionaryStateGetList: build.query<MonitoringStateResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/State/GetList` }),
        }),
        monitoringDictionarySearchByGetList: build.query<MonitoringSearchByResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/SearchBy/GetList` }),
        }),
        monitoringDictionaryTicketExistenceGetList: build.query<
            MonitoringTicketExistenceResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/TicketExistence/GetList` }),
        }),
        monitoringDictionaryTicketStatusGetList: build.query<
            MonitoringTicketStatusResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/TicketStatus/GetList` }),
        }),
        monitoringDictionaryEventTypeGetList: build.query<MonitoringEventTypeResponseModelIEnumerableApiResponse, void>(
            {
                query: () => ({ url: `/api/Dictionary/EventType/GetList` }),
            },
        ),
        monitoringDictionaryPositionTypeGetList: build.query<
            MonitoringPositionTypeResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/PositionType/GetList` }),
        }),
        monitoringEventGetListByPosition: build.query<
            MonitoringEventResponseModelIEnumerableApiResponse,
            {
                positionNumber: string;
                Field: MonitoringEventColumns;
                Sort: 'Asc' | 'Desc';
            }
        >({
            query: ({ positionNumber }) => ({ url: `/api/Event/GetListByPosition/${positionNumber}` }),
            providesTags: (result, error, { positionNumber }) => [
                { type: MONITORING_EVENT_BY_POSITION, id: positionNumber },
            ],
            keepUnusedDataFor: 0,
        }),
        monitoringEventClose: build.mutation<MonitoringStringApiResponse, { eventId: number; positionNumber: string }>({
            query: ({ eventId }) => ({ url: `/api/Event/${eventId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, { positionNumber }) => [
                { type: MONITORING_EVENT_BY_POSITION, id: positionNumber },
            ],
        }),
    }),
});

export const {
    useMonitoringAlarmGetListQuery,
    useMonitoringEventGetListQuery,
    useMonitoringEventByTypeGetListQuery,
    useMonitoringEventCreateMutation,
    useMonitoringBindAlarmMutation,
    useMonitoringUnbindAlarmMutation,
    useMonitoringEventHistoryGetQuery,
    useMonitoringEventAlarmsGetQuery,
    useMonitoringEventGetQuery,
    useMonitoringEventPositionsGetQuery,
    useMonitoringEventStatisticGetQuery,
    useMonitoringDictionarySystemGetListQuery,
    useMonitoringDictionaryEquipmentTypeGetListQuery,
    useMonitoringDictionaryPositionCountGetListQuery,
    useMonitoringDictionaryPlanExistenceGetListQuery,
    useMonitoringDictionaryStateGetListQuery,
    useMonitoringDictionarySearchByGetListQuery,
    useMonitoringDictionaryTicketExistenceGetListQuery,
    useMonitoringDictionaryTicketStatusGetListQuery,
    useMonitoringDictionaryEventTypeGetListQuery,
    useMonitoringDictionaryPositionTypeGetListQuery,
    useMonitoringEventGetListByPositionQuery,
    useMonitoringEventCloseMutation,
} = monitoringServices;

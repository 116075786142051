import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_feedbacks`]: {
        en: 'Feedback',
        ru: 'Обратная связь',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_all`]: {
        en: 'All',
        ru: 'Все',
    },
    [`${APP}_app`]: {
        en: 'App',
        ru: 'Приложение',
    },
    [`${APP}_new_request`]: {
        en: 'New request',
        ru: 'Новое обращение',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_create`]: {
        en: 'Create',
        ru: 'Создать',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_description_is_required`]: {
        en: 'Description is required',
        ru: 'Описание обязательно',
    },
    [`${APP}_app_is_required`]: {
        en: 'App is required',
        ru: 'Приложение обязательно',
    },
    [`${APP}_developer_answer_is_required`]: {
        en: 'Developer answer is required',
        ru: 'Ответ разработчика обязателен',
    },
    [`${APP}_status_is_required`]: {
        en: 'Status is required',
        ru: 'Статус обязателен',
    },
    [`${APP}_developer_response`]: {
        en: 'Developer response',
        ru: 'Ответ разработчика',
    },
    [`${APP}_my_requests`]: {
        en: 'My requests',
        ru: 'Мои обращения',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_by_popularity`]: {
        en: 'By popularity',
        ru: 'По популярности',
    },
    [`${APP}_by_date`]: {
        en: 'By created date',
        ru: 'По дате создания',
    },
    [`${APP}_error_response`]: {
        en: 'Response error',
        ru: 'Ошибка загрузки данных',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
    [`${APP}_developer_answer`]: {
        en: 'Developer answer',
        ru: 'Ответ разработчика',
    },
    [`${APP}_change_status`]: {
        en: 'Change status',
        ru: 'Изменить статус',
    },
    [`${APP}_drag_and_upload`]: {
        en: 'Drag and drop an image here or click',
        ru: 'Перетащите изображения в это поле или нажмите для выбора с компьютера',
    },
};

export default locale;

import * as React from 'react';

import { APP } from '../../constants';
import { BlockedCellFilterState } from '../../hooks/use-blocked-cell-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    usePositionsDictionaryBlockTypeGetListQuery,
    usePositionsDictionaryTypeGetListQuery,
} from 'services/positions/positions-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const BlockedCellFilterDialog: React.FC<FilterDialogProps<BlockedCellFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<BlockedCellFilterState>(props);

    const { data: blockTypeAvailableValues } = usePositionsDictionaryBlockTypeGetListQuery();
    const { data: typeAvailableValues } = usePositionsDictionaryTypeGetListQuery();
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBox
                field="types"
                value={localFilterValues.types}
                label={<Translate app={APP} id="type" />}
                expanded={expandedField === 'types'}
                availableValues={typeAvailableValues ? typeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="blockTypes"
                value={localFilterValues.blockTypes}
                label={<Translate app={APP} id="block_type" />}
                expanded={expandedField === 'blockTypes'}
                availableValues={blockTypeAvailableValues ? blockTypeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default BlockedCellFilterDialog;

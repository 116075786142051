import { AUTH_SERVICE_URL } from '../constants';
import {
    AuthAccessTokenResponseModelApiResponse,
    AuthAccountResponseModelApiResponse,
    AuthAuthTokenResponseModelApiResponse,
    AuthChangePasswordRequestModel,
    AuthLoginRequestModel,
    AuthRefreshTokenInfoResponseModelIEnumerableApiResponse,
    AuthRefreshTokenRequestModel,
    AuthRefreshTokenResponseModelApiResponse,
    AuthResetPasswordRequestModel,
    AuthSendPhoneVerificationCode,
    AuthStringApiResponse,
    AuthVerifyPhoneRequestModel,
} from './auth-models';
import { AUTH_ACCOUNT } from './tags';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store';
import { client } from 'utils/client';

export const authServices = createApi({
    reducerPath: 'authServices',
    baseQuery: fetchBaseQuery({
        baseUrl: AUTH_SERVICE_URL,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const authToken = state.app.authToken;
            const accessToken = state.app.accessToken;

            headers.set('client-id', client.getFingerprint().toString());

            if (accessToken) {
                headers.set('Authorization', `Bearer ${accessToken}`);
            } else if (authToken) {
                headers.set('Authorization', `Bearer ${authToken}`);
            }

            return headers;
        },
    }),
    tagTypes: [AUTH_ACCOUNT],
    endpoints: (build) => ({
        getAccessToken: build.mutation<AuthAccessTokenResponseModelApiResponse, void>({
            query: () => ({ url: `/api/AccessToken`, method: 'POST' }),
        }),
        changePassword: build.mutation<AuthStringApiResponse, AuthChangePasswordRequestModel>({
            query: (queryArg) => ({
                url: `/api/Account/ChangePassword`,
                method: 'PUT',
                body: queryArg,
            }),
        }),
        sendPhoneVerificationCode: build.mutation<AuthStringApiResponse, AuthSendPhoneVerificationCode>({
            query: (queryArg) => ({
                url: `/api/Account/SendPhoneVerificationCode`,
                method: 'PUT',
                body: queryArg,
            }),
        }),
        verifyPhone: build.mutation<AuthStringApiResponse, AuthVerifyPhoneRequestModel>({
            query: (queryArg) => ({
                url: `/api/Account/VerifyPhone`,
                method: 'PUT',
                body: queryArg,
            }),
        }),
        postAuthToken: build.mutation<AuthAuthTokenResponseModelApiResponse, AuthLoginRequestModel>({
            query: (queryArg) => ({ url: `/api/AuthToken`, method: 'POST', body: queryArg }),
        }),
        deleteRefreshTokenById: build.mutation<AuthStringApiResponse, number>({
            query: (id) => ({ url: `/api/RefreshToken/${id}`, method: 'DELETE' }),
        }),
        getList: build.query<AuthRefreshTokenInfoResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/RefreshToken/GetList` }),
        }),
        refresh: build.mutation<AuthRefreshTokenResponseModelApiResponse, AuthRefreshTokenRequestModel>({
            query: (queryArg) => ({
                url: `/api/AccessToken/Refresh`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getAccountById: build.query<AuthAccountResponseModelApiResponse, number>({
            query: (id) => ({ url: `/api/Account/${id}`, method: 'GET' }),
            providesTags: (result, error, id) => [{ type: AUTH_ACCOUNT, id }],
        }),
        resetPassword: build.mutation<AuthStringApiResponse, AuthResetPasswordRequestModel>({
            query: (queryArg) => ({
                url: `/api/Account/ResetPassword`,
                method: 'PUT',
                body: queryArg,
            }),
        }),
    }),
});

export const {
    useGetAccessTokenMutation,
    useChangePasswordMutation,
    useResetPasswordMutation,
    useSendPhoneVerificationCodeMutation,
    useVerifyPhoneMutation,
    usePostAuthTokenMutation,
    useDeleteRefreshTokenByIdMutation,
    useGetListQuery,
    useGetAccountByIdQuery,
} = authServices;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Positions list',
        ru: 'Список позиций',
    },
    [`${APP}_number`]: {
        en: 'Number',
        ru: 'Номер',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Имя',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_id`]: {
        en: 'ID',
        ru: 'ID',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_system`]: {
        en: 'system',
        ru: 'Система',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
};

export default locale;

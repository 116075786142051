import * as React from 'react';

import { APP } from '../../constants';
import { PositionsFilterState } from '../../hooks/use-positions-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    usePositionsDictionaryPriorityGetListQuery,
    usePositionsDictionarySystemGetListQuery,
} from 'services/positions/positions-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const PositionsFilterDialog: React.FC<FilterDialogProps<PositionsFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<PositionsFilterState>(props);

    const { data: priorityAvailableValues } = usePositionsDictionaryPriorityGetListQuery();
    const { data: systemAvailableValues } = usePositionsDictionarySystemGetListQuery();
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBox
                field="priorities"
                value={localFilterValues.priorities}
                label={<Translate app={APP} id="priority" />}
                expanded={expandedField === 'priorities'}
                availableValues={priorityAvailableValues !== undefined ? priorityAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="systems"
                value={localFilterValues.systems}
                label={<Translate app={APP} id="system" />}
                expanded={expandedField === 'systems'}
                availableValues={systemAvailableValues !== undefined ? systemAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default PositionsFilterDialog;

import * as React from 'react';

import BlockedCellFilterDialog from '../views/filter/blocked-cell-filter-dialog';
import BlockedCellFilterToolbar from '../views/filter/blocked-cell-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type BlockedCellFilterState = {
    search: FilterSearchStateValue;
    types: FilterCheckBoxStateValue;
    blockTypes: FilterCheckBoxStateValue;
    branches: FilterCheckBoxStateValue;
};

const initialState: BlockedCellFilterState = {
    search: null,
    types: null,
    blockTypes: null,
    branches: null,
};

const useBlockedCellFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof BlockedCellFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof BlockedCellFilterState>('search');

    return {
        BlockedCellFilterDialog: () => (
            <BlockedCellFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        BlockedCellFilterToolbar: () => (
            <BlockedCellFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
            />
        ),
        filterValues,
    };
};

export default useBlockedCellFilter;

import * as React from 'react';

import { APP } from '../../constants';
import { EventsFilterState } from '../../hooks/use-events-filter';

import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import FilterRadio from 'components/extended/data-grid/filter/filter-radio';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    useMonitoringDictionaryPlanExistenceGetListQuery,
    useMonitoringDictionaryPositionCountGetListQuery,
    useMonitoringDictionaryStateGetListQuery,
    useMonitoringDictionaryTicketExistenceGetListQuery,
} from 'services/monitoring/monitoring-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const EventsFilterDialog: React.FC<FilterDialogProps<EventsFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<EventsFilterState>(props);

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();
    const { data: stateAvailableValues } = useMonitoringDictionaryStateGetListQuery();
    const { data: positionCountAvailableValues } = useMonitoringDictionaryPositionCountGetListQuery();
    const { data: planExistenceAvailableValues } = useMonitoringDictionaryPlanExistenceGetListQuery();
    const { data: ticketExistenceAvailableValues } = useMonitoringDictionaryTicketExistenceGetListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterRadio
                field="state"
                value={localFilterValues.state}
                label={<Translate app={APP} id="state" />}
                expanded={expandedField === 'state'}
                availableValues={stateAvailableValues !== undefined ? stateAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterRadio
                field="positionCount"
                value={localFilterValues.positionCount}
                label={<Translate app={APP} id="position_count" />}
                expanded={expandedField === 'positionCount'}
                availableValues={positionCountAvailableValues !== undefined ? positionCountAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterRadio
                field="plan"
                value={localFilterValues.plan}
                label={<Translate app={APP} id="plan" />}
                expanded={expandedField === 'plan'}
                availableValues={planExistenceAvailableValues !== undefined ? planExistenceAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterRadio
                field="ticket"
                value={localFilterValues.ticket}
                label={<Translate app={APP} id="ticket" />}
                expanded={expandedField === 'ticket'}
                availableValues={
                    ticketExistenceAvailableValues !== undefined ? ticketExistenceAvailableValues.data : []
                }
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default EventsFilterDialog;

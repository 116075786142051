import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useEventsFilter from '../hooks/use-events-filter';

import DataGridCellTooltip from 'components/extended/data-grid/data-grid-cell-tooltip';
import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import Translate from 'components/extended/translate';
import { format } from 'date-fns';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { useMonitoringEventByTypeGetListQuery } from 'services/monitoring/monitoring-services';
import { APP as EVENT_VIEW_APP } from 'sub-apps/monitoring/event-view/constants';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import { APP as TT_VIEW_APP } from 'sub-apps/trouble-ticket-view/constants';
import columnLocalization from 'utils/column-localization';

const renderCellDateTooltip = (params: GridRenderCellParams<string>) =>
    params.value !== undefined && DataGridCellTooltip(format(new Date(params.value), 'dd-MM-yyyy HH:mm'));

const renderCellTooltip = (params: GridRenderCellParams<string>) =>
    params.value !== undefined && DataGridCellTooltip(params.value);

const EventsByTypeTable: React.FC = () => {
    const { EventsFilterDialog, EventsFilterToolbar, filterValues } = useEventsFilter();

    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params, appName) => () =>
            createTab({
                id: undefined,
                app: appName,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    // TODO избавиться от дублирования кода
    const renderCellChipPosition = React.useCallback(
        (params: GridRenderCellParams<string>) =>
            params.value && (
                <Chip
                    onClick={chipOnClick(params, POSITION_VIEW_APP)}
                    size="small"
                    label={params.value}
                    sx={{ userSelect: 'text' }}
                />
            ),
        [chipOnClick],
    );

    const renderCellChipEvent = React.useCallback(
        (params: GridRenderCellParams<string>) =>
            params.value && (
                <Chip
                    onClick={chipOnClick(params, EVENT_VIEW_APP)}
                    size="small"
                    label={params.value}
                    sx={{ userSelect: 'text' }}
                />
            ),
        [chipOnClick],
    );

    const renderCellChipTt = React.useCallback(
        (params: GridRenderCellParams<string>) =>
            params.value && (
                <Chip
                    onClick={chipOnClick(params, TT_VIEW_APP)}
                    size="small"
                    label={params.value}
                    sx={{ userSelect: 'text' }}
                />
            ),
        [chipOnClick],
    );

    const { Pagination, rowsState } = usePagination(`${APP}_monitoring_events_by_type_grid`, '10');

    const { data, refetch, isFetching } = useMonitoringEventByTypeGetListQuery({
        value: {
            searchString: filterValues.searchString,
            branches: filterValues.branches?.map((elem) => String(elem.id)),
            positionTypeIds: filterValues.positionType?.map((elem) => Number(elem.id)),
            eventTypeIds: filterValues.eventType?.map((elem) => Number(elem.id)),
        },
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_site_id`),
                field: 'position',
                renderCell: renderCellChipPosition,
                width: 80,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_event`),
                field: 'eventId',
                renderCell: renderCellChipEvent,
                width: 80,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_site_name`),
                field: 'name',
                width: 150,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_address`),
                field: 'address',
                flex: 1,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_bs_type`),
                field: 'positionType',
                width: 150,
                sortable: false,
                align: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_date`),
                field: 'insertTime',
                renderCell: renderCellDateTooltip,
                width: 150,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_tt_type_name`),
                field: 'eventType',
                renderCell: renderCellTooltip,
                width: 120,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_remedy`),
                renderCell: renderCellChipTt,
                field: 'ticketId',
                width: 120,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_pw`),
                field: 'planId',
                width: 120,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_per_active_count`),
                field: 'activeCountPercent',
                sortable: false,
                align: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_not_active_count`),
                field: 'notActiveCount',
                width: 120,
                sortable: false,
                align: 'center',
            },
            {
                renderHeader: columnLocalization(`${APP}_restore_count`),
                field: 'restoreCount',
                sortable: false,
                align: 'center',
            },
        ],
        [renderCellChipEvent, renderCellChipPosition, renderCellChipTt],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <EventsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.actionsCell}`]: {
                                gridGap: 0,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                            [`& .${gridClasses.overlay}`]: {
                                backgroundColor: 'transparent',
                            },
                        }}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        components={{
                            Pagination,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                rowsPerPageOptions: [5, 10, 15, 25],
                                isFetching,
                            },
                        }}
                    />
                    <EventsFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default EventsByTypeTable;

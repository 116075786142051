// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import Graph from 'react-graph-vis';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import imagesTypesUrl from './images-types-url';

const getParsedData = (data) => {
    const nodes = data.nodes.map((node) => ({
        id: node.id,
        label: node.attribute.name,
        image: imagesTypesUrl[node.attribute.type],
        x: node.attribute.x * 3000,
        y: node.attribute.y * 3000,
    }));
    const edges = data.edges.map((edge) => ({ from: edge.fromId, to: edge.toId }));
    return { nodes, edges };
};

const TopologyReactGraphVis = (props) => {
    const { data, branchPrefix } = props;
    const theme = useTheme();

    const options = React.useMemo(
        () => ({
            height: `${window.innerHeight - 150}px`,
            nodes: {
                size: 16,
                shape: 'image',
                font: {
                    color: theme.palette.text.primary,
                },
            },
            layout: {
                improvedLayout: false,
            },
            physics: false,
            edges: {
                color: theme.palette.text.disabled,
                chosen: false,
                arrows: {
                    to: {
                        enabled: false,
                    },
                    from: {
                        enabled: false,
                    },
                },
            },
        }),
        [theme.palette.text.disabled, theme.palette.text.primary],
    );

    const graphData = React.useMemo(() => getParsedData(data), [data]);

    return (
        <Box sx={{ position: 'relative' }}>
            <Graph
                key={`${branchPrefix}-graph-${theme.palette.background.paper}`}
                graph={graphData}
                options={options}
            />
        </Box>
    );
};

export default TopologyReactGraphVis;

import * as React from 'react';

import Loader from './loader';

function Loadable<T = any>(
    Component: React.FC<T> | React.LazyExoticComponent<() => JSX.Element> | React.ComponentType<React.ReactNode>,
) {
    return (props: T) => (
        <React.Suspense fallback={<Loader />}>
            <Component {...props} />
        </React.Suspense>
    );
}

export default Loadable;

import { RootState } from '..';

import { createSelector } from '@reduxjs/toolkit';

export const globalTabsSelector = (state: RootState) => state.globalTabs.tabs;
export const globalTabsActiveSelector = createSelector(globalTabsSelector, (tabs) => {
    const result = tabs.find((tab) => tab.isActive);

    return result === undefined ? null : result;
});

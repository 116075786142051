import * as React from 'react';

import { APP } from '../../constants';
import { PositionsStatisticsFilterState } from '../../hooks/use-positions-statistics-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDateInterval from 'components/extended/data-grid/filter/filter-date-interval';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import FilterSwitch from 'components/extended/data-grid/filter/filter-switch';
import { FilterDialogProps, SwitchLabelsValues } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import { usePositionsDictionaryTypeGetListQuery } from 'services/positions/positions-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const switchLabelsValues: SwitchLabelsValues = { true: '', false: '' };

const PositionsStatisticsFilterDialog: React.FC<FilterDialogProps<PositionsStatisticsFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
        isError,
        setIsError,
    } = useManagementFilterDialog<PositionsStatisticsFilterState>(props);

    const { data: typeAvailableValues } = usePositionsDictionaryTypeGetListQuery();
    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
            isError={isError}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="types"
                value={localFilterValues.types}
                label={<Translate app={APP} id="type" />}
                expanded={expandedField === 'types'}
                availableValues={typeAvailableValues ? typeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterSwitch
                field="isTraffic"
                value={localFilterValues.isTraffic}
                label={<Translate app={APP} id="traffic_loss" />}
                labelsValues={switchLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                disableValueLabel
                app={APP}
            />
            <FilterSwitch
                field="isPower"
                value={localFilterValues.isPower}
                label={<Translate app={APP} id="no_power" />}
                labelsValues={switchLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                disableValueLabel
                app={APP}
            />
            <FilterSwitch
                field="isDoor"
                value={localFilterValues.isDoor}
                label={<Translate app={APP} id="door_sensor" />}
                labelsValues={switchLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                disableValueLabel
                app={APP}
            />
            <FilterSwitch
                field="isTemp"
                value={localFilterValues.isTemp}
                label={<Translate app={APP} id="temperature_sensor" />}
                labelsValues={switchLabelsValues}
                setLocalFilterValues={setLocalFilterValues}
                disableValueLabel
                app={APP}
            />
            <FilterDateInterval
                startDateField="startDate"
                endDateField="endDate"
                startDateValue={localFilterValues.startDate}
                endDateValue={localFilterValues.endDate}
                startDateLabel={<Translate id="with" />}
                endDateLabel={<Translate id="before" />}
                expanded={expandedField === 'startDate' || expandedField === 'endDate'}
                label={<Translate app={APP} id="date" />}
                setLocalFilterValues={setLocalFilterValues}
                setIsError={setIsError}
            />
        </FilterDialog>
    );
};

export default PositionsStatisticsFilterDialog;

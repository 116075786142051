import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { TopologyFilterState } from '../../hooks/use-topology-filter';

import Translate from 'components/extended/translate';

type TopologyBranchFilterProps = {
    filterValues: TopologyFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<TopologyFilterState>>;
    branches: { id: string; name: string }[];
};

const TopologyBranchFilter: React.FC<TopologyBranchFilterProps> = (props) => {
    const { filterValues, setFilterValues, branches } = props;

    const handleChange = React.useCallback((event: any) => setFilterValues({ branch: event.target.value }), [
        setFilterValues,
    ]);

    return (
        <FormControl fullWidth size="small" sx={{ minWidth: 300 }}>
            <Select value={filterValues.branch} onChange={handleChange} displayEmpty>
                <MenuItem value="" disabled>
                    <Translate app={APP} id="not_selected" />
                </MenuItem>
                {branches.map((elem) => (
                    <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TopologyBranchFilter;

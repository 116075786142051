import * as React from 'react';

import PositionsFilterDialog from '../views/filter/positions-filter-dialog';
import PositionsFilterToolbar from '../views/filter/positions-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type PositionsFilterState = {
    search: FilterSearchStateValue;
    extended: boolean;
    systems: FilterCheckBoxStateValue;
    priorities: FilterCheckBoxStateValue;
    branches: FilterCheckBoxStateValue;
};

const initialState: PositionsFilterState = {
    search: null,
    extended: true,
    systems: null,
    priorities: null,
    branches: null,
};

const usePositionsFilter = <SavedFilter,>(savedFilter: SavedFilter) => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof PositionsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof PositionsFilterState>('search');

    React.useEffect(() => {
        if (savedFilter !== undefined) {
            setFilterValues((prevState) => ({
                ...prevState,
                ...savedFilter,
            }));
        }
    }, [savedFilter]);

    return {
        PositionsFilterDialog: () => (
            <PositionsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        PositionsFilterToolbar: ({ saveFilter }: { saveFilter?: () => void }) => (
            <PositionsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
                saveFilter={saveFilter}
            />
        ),
        filterValues,
    };
};
export default usePositionsFilter;

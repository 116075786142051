import * as React from 'react';

import { DataGrid, GridColDef, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useSharingPositionsFilter from '../hooks/use-sharing-positions-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import Translate from 'components/extended/translate';
import usePagination from 'hooks/use-pagination';
import { PositionsSharingColumnsOrderRequestModel } from 'services/positions/positions-models';
import { usePositionsSharingGetListQuery } from 'services/positions/positions-services';
import columnLocalization from 'utils/column-localization';

const columns: GridColDef[] = [
    {
        renderHeader: columnLocalization(`${APP}_region`),
        field: 'region',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_branch`),
        field: 'branch',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_position`),
        field: 'position',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_owner`),
        field: 'owner',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_internal_number`),
        field: 'internalNumber',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_external_number`),
        field: 'externalNumber',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_address`),
        field: 'address',
        flex: 0.5,
        minWidth: 150,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_range`),
        field: 'range',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_infrastructure_owner`),
        field: 'infrastructureOwner',
        flex: 0.5,
        minWidth: 130,
        sortable: false,
    },
    {
        renderHeader: columnLocalization(`${APP}_transport_owner`),
        field: 'transportOwner',
        flex: 0.5,
        minWidth: 100,
        sortable: false,
    },
];

type SharingPositionsTableProps = {
    positionNumber?: string;
};

const SharingPositionsTable: React.FC<SharingPositionsTableProps> = (props) => {
    const { positionNumber } = props;

    const { Pagination, rowsState } = usePagination(`${APP}_sharing_positions_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { SharingPositionsFilterDialog, SharingPositionsFilterToolbar, filterValues } = useSharingPositionsFilter(
        positionNumber,
    );

    const { data, refetch, isFetching } = usePositionsSharingGetListQuery({
        value: {
            position: filterValues.positionNumber,
            search: filterValues.search,
            branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : undefined,
            ownerIds: filterValues.owners ? filterValues.owners.map((elem) => Number(elem.id)) : undefined,
            rangeIds: filterValues.ranges ? filterValues.ranges.map((elem) => Number(elem.id)) : undefined,
        },
        order: sortModel[0] as PositionsSharingColumnsOrderRequestModel,
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    return (
        <>
            {data !== undefined && (
                <>
                    <SharingPositionsFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                            [`& .${gridClasses.overlay}`]: {
                                backgroundColor: 'transparent',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <SharingPositionsFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching} text={<Translate id="loading" />} />
        </>
    );
};

export default SharingPositionsTable;

import * as selectors from './selectors';
import { AppStateProps } from './types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authServices } from 'services/auth/auth-services';
import { webapiServices } from 'services/webapi/generated/webapi-services';

const initialState: AppStateProps = {
    user: null,
    authToken: null,
    accessToken: null,
    isInit: false,
    auth: null,
    entryPoint: {
        path: '',
        search: '',
        hash: '',
    },
};

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        logout: (state) => ({
            ...initialState,
            entryPoint: state.entryPoint,
        }),
        setAuth: (state, action: PayloadAction<AppStateProps['auth']>) => ({
            ...state,
            auth: action.payload,
            accessToken: action.payload !== 'authorized' ? null : state.accessToken,
            authToken: action.payload !== 'authenticated' ? null : state.authToken,
        }),
        setAccessToken: (state, action: PayloadAction<AppStateProps['accessToken']>) => ({
            ...state,
            accessToken: action.payload,
        }),
        setInit: (state, action: PayloadAction<AppStateProps['isInit']>) => ({
            ...state,
            isInit: action.payload,
        }),
        setEntryPoint: (state, action: PayloadAction<AppStateProps['entryPoint']>) => ({
            ...state,
            entryPoint: action.payload,
        }),
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authServices.endpoints.postAuthToken.matchFulfilled, (state, action) => {
                state.authToken = action.payload.data?.token ?? null;
                state.auth = action.payload.data?.token ? 'authenticated' : state.auth;
            })
            .addMatcher(authServices.endpoints.getAccessToken.matchFulfilled, (state, action) => {
                if (action.payload.data?.token) {
                    state.accessToken = action.payload.data.token;
                    state.authToken = null;
                    state.auth = 'authorized';
                }
            })
            .addMatcher(authServices.endpoints.refresh.matchFulfilled, (state, action) => {
                if (action.payload.data?.token) {
                    state.accessToken = action.payload.data.token;
                    state.authToken = null;
                    state.auth = 'authorized';
                }
            })
            .addMatcher(webapiServices.endpoints.getApiMe.matchFulfilled, (state, action) => {
                state.user = action.payload.data ?? null;
            });
    },
});

const appSlice = {
    reducer: persistReducer({ key: 'app', storage, whitelist: ['authToken', 'accessToken'] }, slice.reducer),
    actions: slice.actions,
    selectors,
};

export default appSlice;

export * from './types';

// Alarm
export const MONITORING_ALARM = 'MONITORING-ALARM';
// Event
export const MONITORING_EVENTS = 'MONITORING-EVENTS';
export const MONITORING_EVENTS_BY_TYPE = 'MONITORING-EVENTS-BY-TYPE';
export const MONITORING_EVENT_HISTORY = 'MONITORING-EVENT-HISTORY';
export const MONITORING_EVENT_ALARMS = 'MONITORING-EVENT-ALARMS';
export const MONITORING_EVENT = 'MONITORING_EVENT';
export const MONITORING_EVENT_POSITIONS = 'MONITORING_EVENT_POSITIONS';
export const MONITORING_EVENT_STATISTIC = 'MONITORING_EVENT_STATISTIC';
export const MONITORING_EVENT_BY_POSITION = 'MONITORING_EVENT_BY_POSITION';

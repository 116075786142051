import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { TroubleTicketVariantsEnum, troubleTicketViewGridSpacing } from '../../../constants';
import TroubleTicketViewCommentCard from './cards/trouble-ticket-view-comment-card';
import TroubleTicketViewCommentAccordionCards from './trouble-ticket-view-comment-accordion-cards';
import TroubleTicketViewCommentsTable from './trouble-ticket-view-comments-table';
import TroubleTicketViewCommentsToolbar from './trouble-ticket-view-comments-toolbar';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import {
    SmartQueueTicketCommentResponseModel,
    SmartQueueTicketCommentResponseModelIEnumerableApiResponse,
} from 'services/smart-queue/smart-queue-models';

export type TroubleTicketViewGroupedComment = {
    user: string;
    role: string;
    comments: {
        id: number;
        action: string;
        comment: string;
        createdDate: string;
    }[];
};

const GroupingCommentsReduceCallback = (
    accumulator: {
        [prop: SmartQueueTicketCommentResponseModel['user']]: TroubleTicketViewGroupedComment;
    },
    currentValue: SmartQueueTicketCommentResponseModel,
) => {
    accumulator[currentValue.user] = accumulator[currentValue.user] || {
        user: currentValue.user,
        role: currentValue.role,
        comments: [],
    };
    accumulator[currentValue.user].comments.push({
        id: currentValue.id,
        action: currentValue.action,
        comment: currentValue.comment,
        createdDate: currentValue.createdDate,
    });
    return accumulator;
};

type TroubleTicketViewCommentsMainProps = {
    data: SmartQueueTicketCommentResponseModelIEnumerableApiResponse['data'];
    variant: TroubleTicketVariantsEnum;
    isFetching: boolean;
    ttNumber: number;
};

const TroubleTicketViewCommentsMain: React.FC<TroubleTicketViewCommentsMainProps> = (props) => {
    const { data, variant, isFetching, ttNumber } = props;

    const [isGroupComments, setIsGroupComments] = React.useState(false);
    const [isImportant, setIsImportant] = React.useState(false);

    const handleClickImportant = React.useCallback(() => setIsImportant((prevState) => !prevState), []);
    const handleClickGroupComment = React.useCallback(() => setIsGroupComments((prevState) => !prevState), []);

    const importantComments = React.useMemo(() => data.filter((elem) => elem.isImportant), [data]);

    const groupedImportantComments = React.useMemo(
        () => Object.values(importantComments.reduce(GroupingCommentsReduceCallback, {})),
        [importantComments],
    );

    const groupedComments = React.useMemo(() => Object.values(data.reduce(GroupingCommentsReduceCallback, {})), [data]);

    return (
        <Box sx={{ position: 'relative' }}>
            <Grid container direction="column" spacing={troubleTicketViewGridSpacing}>
                <Grid item>
                    <TroubleTicketViewCommentsToolbar
                        isGroupComments={isGroupComments}
                        onClickGroupComments={handleClickGroupComment}
                        isImportant={isImportant}
                        onClickImportant={handleClickImportant}
                        variant={variant}
                        ttNumber={ttNumber}
                    />
                </Grid>
                <Grid item>
                    {variant === TroubleTicketVariantsEnum.View && (
                        <Grid container direction="column" spacing={troubleTicketViewGridSpacing}>
                            {isImportant && isGroupComments && (
                                <Grid item>
                                    {groupedImportantComments.map((elem) => (
                                        <TroubleTicketViewCommentAccordionCards
                                            key={elem.user}
                                            user={elem.user}
                                            role={elem.role}
                                            comments={elem.comments}
                                        />
                                    ))}
                                </Grid>
                            )}
                            {isImportant &&
                                !isGroupComments &&
                                importantComments.map((elem) => (
                                    <Grid item key={elem.id}>
                                        <TroubleTicketViewCommentCard
                                            comment={elem.comment}
                                            action={elem.action}
                                            createdDate={elem.createdDate}
                                            role={elem.role}
                                            user={elem.user}
                                        />
                                    </Grid>
                                ))}
                            {!isImportant && isGroupComments && (
                                <Grid item>
                                    {groupedComments.map((elem) => (
                                        <TroubleTicketViewCommentAccordionCards
                                            key={elem.user}
                                            user={elem.user}
                                            role={elem.role}
                                            comments={elem.comments}
                                        />
                                    ))}
                                </Grid>
                            )}
                            {!isImportant &&
                                !isGroupComments &&
                                data.map((elem) => (
                                    <Grid item key={elem.id}>
                                        <TroubleTicketViewCommentCard
                                            comment={elem.comment}
                                            action={elem.action}
                                            createdDate={elem.createdDate}
                                            role={elem.role}
                                            user={elem.user}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                    {variant === TroubleTicketVariantsEnum.Table && <TroubleTicketViewCommentsTable data={data} />}
                </Grid>
            </Grid>
            <DataGridLoader visible={isFetching} />
        </Box>
    );
};

export default TroubleTicketViewCommentsMain;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Trouble tickets',
        ru: 'Инциденты',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_p`]: {
        en: 'P',
        ru: 'П',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_created_date`]: {
        en: 'Created date',
        ru: 'Дата создания',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_short_description`]: {
        en: 'Short description',
        ru: 'Краткое описание',
    },
    [`${APP}_role`]: {
        en: 'Role',
        ru: 'Роль',
    },
    [`${APP}_service_status`]: {
        en: 'Service status',
        ru: 'Статус услуги',
    },
    [`${APP}_element`]: {
        en: 'Element',
        ru: 'Элемент',
    },
    [`${APP}_cdt`]: {
        en: 'CDT',
        ru: 'CDT',
    },
    [`${APP}_event`]: {
        en: 'Event',
        ru: 'Событие',
    },
    [`${APP}_possible_reasons`]: {
        en: 'Possible reasons',
        ru: 'Возможные причины',
    },
    [`${APP}_weight`]: {
        en: 'Time to decide/weight',
        ru: 'Время до решения/вес',
    },
    [`${APP}_show_in_work`]: {
        en: 'Show in work',
        ru: 'Показывать в работе',
    },
    [`${APP}_display_closed`]: {
        en: 'Display closed',
        ru: 'Отображать закрытые',
    },
    [`${APP}_incident_status`]: {
        en: 'Incident status',
        ru: 'Статус аварии',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_period`]: {
        en: 'Period',
        ru: 'Период',
    },
    [`${APP}_priorities`]: {
        en: 'Priorities',
        ru: 'Приоритеты',
    },
    [`${APP}_overdue`]: {
        en: 'Overdue',
        ru: 'Просроченные',
    },
    [`${APP}_with_influence`]: {
        en: 'With influence',
        ru: 'С влиянием',
    },
    [`${APP}_key_bs`]: {
        en: 'Key BS',
        ru: 'Ключевые БС',
    },
    [`${APP}_with_geounite`]: {
        en: 'With geounite',
        ru: 'С учетом геоюнита',
    },
    [`${APP}_power`]: {
        en: 'Power',
        ru: 'По питанию',
    },
    [`${APP}_periodic`]: {
        en: 'Periodic',
        ru: 'Периодические',
    },
    [`${APP}_creation_system`]: {
        en: 'Creation system',
        ru: 'Система создания',
    },
    [`${APP}_place_of_origin`]: {
        en: 'Place of origin',
        ru: 'Место возникновения',
    },
    [`${APP}_in_work`]: {
        en: 'In work',
        ru: 'В работе',
    },
    [`${APP}_on_control`]: {
        en: 'On control',
        ru: 'На контроле',
    },
    [`${APP}_order`]: {
        en: 'Order',
        ru: 'Сортировка',
    },
    [`${APP}_roles_and_groups`]: {
        en: 'Roles and groups',
        ru: 'Роли и группы',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Комментарии',
    },
    [`${APP}_change_responsible`]: {
        en: 'Change responsible',
        ru: 'Изменить ответственных',
    },
    [`${APP}_bind`]: {
        en: 'Bind',
        ru: 'Подчинить',
    },
    [`${APP}_change_priority`]: {
        en: 'Change priority',
        ru: 'Изменить приоритет',
    },
    [`${APP}_edit_description`]: {
        en: 'Edit description',
        ru: 'Изменить описание',
    },
    [`${APP}_change_status`]: {
        en: 'Change status',
        ru: 'Изменить статус',
    },
    [`${APP}_close`]: {
        en: 'Close',
        ru: 'Закрыть',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_plw`]: {
        en: 'PLW',
        ru: 'ПР',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отменить',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_show`]: {
        en: 'Show',
        ru: 'Отображаются',
    },
    [`${APP}_hide`]: {
        en: 'Hide',
        ru: 'Скрыты',
    },
    [`${APP}_display_only_expired`]: {
        en: 'Display only expired',
        ru: 'Отображать только просроченные',
    },
    [`${APP}_display_all`]: {
        en: 'Display all',
        ru: 'Отображать все',
    },
    [`${APP}_display_only_influence`]: {
        en: 'Display only with influence',
        ru: 'Отображать только с влиянием',
    },
    [`${APP}_display_only_key_bs`]: {
        en: 'Display only key BS',
        ru: 'Отображать только ключевые БС',
    },
    [`${APP}_display_only_geounite`]: {
        en: 'Display only with geounite',
        ru: 'Отображать только с учетом геоюнита',
    },
    [`${APP}_display_only_power`]: {
        en: 'Display only power',
        ru: 'Отображать только по питанию',
    },
    [`${APP}_display_only_periodic`]: {
        en: 'Display only periodic',
        ru: 'Отображать только периодические',
    },
};

export default locale;

import * as React from 'react';

import { TopologyFilterState } from '../../hooks/use-topology-filter';
import TopologyBranchFilter from './topology-branch-filter';

type TopologyBranchFilterConnectorProps = {
    filterValues: TopologyFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<TopologyFilterState>>;
};

const data = [
    { id: 'UF', name: 'Уфимский филиал' },
    { id: 'KZ', name: 'Казанский филиал' },
    { id: 'NS', name: 'Новосибирский филиал' },
];

const TopologyBranchFilterConnector: React.FC<TopologyBranchFilterConnectorProps> = (props) => {
    const { filterValues, setFilterValues } = props;
    // TODO хз будт ли тут отдельный эндпоинт для филиалов топологии
    return <TopologyBranchFilter filterValues={filterValues} setFilterValues={setFilterValues} branches={data} />;
};

export default TopologyBranchFilterConnector;

import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { WorkTypesMatrixFilterState } from '../../hooks/use-work-types-matrix-filter';

import Translate from 'components/extended/translate';

type WorkTypesMatrixObjectFilterProps = {
    filterValues: WorkTypesMatrixFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<WorkTypesMatrixFilterState>>;
    availableValues: { id: number; name: string }[];
};

const WorkTypesMatrixObjectFilter: React.FC<WorkTypesMatrixObjectFilterProps> = (props) => {
    const { filterValues, setFilterValues, availableValues } = props;

    const handleChange = React.useCallback(
        (value: any) => {
            setFilterValues({ object: value.target.value });
        },
        [setFilterValues],
    );

    return (
        <FormControl fullWidth size="small" sx={{ minWidth: 300 }}>
            <Select value={filterValues.object ? filterValues.object : ''} onChange={handleChange} displayEmpty>
                <MenuItem value="">
                    <Translate app={APP} id="all_objects" />
                </MenuItem>
                {availableValues.map((elem) => (
                    <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default WorkTypesMatrixObjectFilter;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_information`]: {
        en: 'Information',
        ru: 'Информация',
    },
    [`${APP}_management`]: {
        en: 'Management',
        ru: 'Дежурные',
    },
    [`${APP}_diagnostic`]: {
        en: 'Diagnostic',
        ru: 'Диагностика',
    },
    [`${APP}_temperature`]: {
        en: 'Temperature',
        ru: 'Температура',
    },
    [`${APP}_vswr`]: {
        en: 'VSWR',
        ru: 'КСВ',
    },
    [`${APP}_plw`]: {
        en: 'PLW',
        ru: 'ПР',
    },
    [`${APP}_bs_indicated_in_plw`]: {
        en: 'BS indicated in PLW',
        ru: 'БС указана в ПР',
    },
    [`${APP}_bs_maybe_involved_in_plw`]: {
        en: 'BS maybe involved in PLW',
        ru: 'БС возможно учавствует в ПР',
    },
    [`${APP}_equipment`]: {
        en: 'Equipment',
        ru: 'Оборудование',
    },
    [`${APP}_sharing`]: {
        en: 'Sharing',
        ru: 'Шаринг',
    },
    [`${APP}_statistics`]: {
        en: 'Statistics',
        ru: 'Статистика',
    },
    [`${APP}_netcool_alarms`]: {
        en: 'Netcool alarms',
        ru: 'Аварии на другом оборудовании',
    },
    [`${APP}_history`]: {
        en: 'History',
        ru: 'История',
    },
    [`${APP}_alarms`]: {
        en: 'Alarms',
        ru: 'Аварии',
    },
    [`${APP}_events`]: {
        en: 'Events',
        ru: 'События',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Коммментарии',
    },
    [`${APP}_visitors`]: {
        en: 'Visitors',
        ru: 'Посетители',
    },
    [`${APP}_transport`]: {
        en: 'Transport',
        ru: 'Транспорт',
    },
    [`${APP}_electricity_suppliers`]: {
        en: 'Electricity Suppliers',
        ru: 'Поставщики электроэнергии',
    },
    [`${APP}_managers`]: {
        en: 'Managers',
        ru: 'Менеджеры',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_oss`]: {
        en: 'OSS',
        ru: 'OSS',
    },
    [`${APP}_oss_name`]: {
        en: 'OSS name',
        ru: 'Имя на OSS',
    },
    [`${APP}_ip`]: {
        en: 'IP',
        ru: 'IP',
    },
    [`${APP}_cell_ids`]: {
        en: 'Cell IDs',
        ru: 'Cell IDs',
    },
    [`${APP}_update`]: {
        en: 'Update',
        ru: 'Обновить',
    },
    [`${APP}_active_alarms`]: {
        en: 'Active alarms',
        ru: 'Активные аварии',
    },
    [`${APP}_element`]: {
        en: 'Element',
        ru: 'Элемент',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_alarm_text`]: {
        en: 'Alarm text',
        ru: 'Текст аварии',
    },
    [`${APP}_event`]: {
        en: 'Event',
        ru: 'Событие',
    },
    [`${APP}_appearance_time`]: {
        en: 'Appearance time',
        ru: 'Время появления',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
    [`${APP}_number`]: {
        en: 'Number',
        ru: 'Номер',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_description`]: {
        en: 'Description',
        ru: 'Описание',
    },
    [`${APP}_rsite`]: {
        en: 'RSite',
        ru: 'RSite',
    },
    [`${APP}_a`]: {
        en: 'A',
        ru: 'А',
    },
    [`${APP}_n`]: {
        en: 'N',
        ru: 'Н',
    },
    [`${APP}_r`]: {
        en: 'R',
        ru: 'П',
    },
    [`${APP}_created_time`]: {
        en: 'Created time',
        ru: 'Время создания',
    },
    [`${APP}_alert`]: {
        en: 'Alert',
        ru: 'Предупреждение',
    },
    [`${APP}_you_want_change_priority`]: {
        en: 'Do you really want to change the priority?',
        ru: 'Вы действительно хотите изменить приоритет?',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_change`]: {
        en: 'Change',
        ru: 'Изменить',
    },
    [`${APP}_edit_visitor`]: {
        en: 'Edit visitor',
        ru: 'Изменить посетителя',
    },
    [`${APP}_add_visitor`]: {
        en: 'Add visitor',
        ru: 'Добавить посетителя',
    },
    [`${APP}_visitor`]: {
        en: 'Visitor',
        ru: 'Посетитель',
    },
    [`${APP}_visitor_is_required`]: {
        en: 'Visitor is required',
        ru: 'Посетитель обязателен',
    },
    [`${APP}_phone`]: {
        en: 'Phone',
        ru: 'Телефон',
    },
    [`${APP}_phone_is_required`]: {
        en: 'Phone is required',
        ru: 'Телефон обязателен',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_edit_comment`]: {
        en: 'Edit comment',
        ru: 'Редактировать комментарий',
    },
    [`${APP}_add_comment`]: {
        en: 'Add comment',
        ru: 'Добавить комментарий',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_priority_is_required`]: {
        en: 'Priority is required',
        ru: 'Приоритет обязателен',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_edit`]: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_edit_access`]: {
        en: 'Edit access',
        ru: 'Редактировать доступ',
    },
    [`${APP}_add_access`]: {
        en: 'Add access',
        ru: 'Добавить доступ',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Название',
    },
    [`${APP}_contacts`]: {
        en: 'Contacts',
        ru: 'Контакты',
    },
    [`${APP}_edit_electricity_supplier`]: {
        en: 'Edit electricity supplier',
        ru: 'Редактировать поставщика электроэнергии',
    },
    [`${APP}_add_electricity_supplier`]: {
        en: 'Add electricity supplier',
        ru: 'Добавить поставщика электроэнергии',
    },
    [`${APP}_title_is_required`]: {
        en: 'Title is required',
        ru: 'Название обязательно',
    },
    [`${APP}_contacts_is_required`]: {
        en: 'Contacts is required',
        ru: 'Контакты обязательны',
    },
    [`${APP}_battery_history`]: {
        en: 'Battery history',
        ru: 'Время на аккумуляторах',
    },
    [`${APP}_minutes`]: {
        en: 'min.',
        ru: 'мин.',
    },
    [`${APP}_bs_type`]: {
        en: 'BS type',
        ru: 'Тип БС',
    },
    [`${APP}_provider`]: {
        en: 'Provider',
        ru: 'Провайдер',
    },
    [`${APP}_id_channel_internal`]: {
        en: 'ID channel internal',
        ru: 'ID канала ВК',
    },
    [`${APP}_id_channel_external`]: {
        en: 'ID channel external',
        ru: 'ID канала провайдера',
    },
    [`${APP}_origin`]: {
        en: 'Origin',
        ru: 'Город А',
    },
    [`${APP}_destination`]: {
        en: 'Destination',
        ru: 'Город Б',
    },
    [`${APP}_edit_transport`]: {
        en: 'Edit transport',
        ru: 'Редактировать транспорт',
    },
    [`${APP}_add_transport`]: {
        en: 'Add transport',
        ru: 'Добавить транспорт',
    },
    [`${APP}_bs_type_is_required`]: {
        en: 'BS type is required',
        ru: 'Тип БС обязателен',
    },
    [`${APP}_provider_is_required`]: {
        en: 'BS type is required',
        ru: 'Тип БС обязателен',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Имя',
    },
    [`${APP}_edit_manager`]: {
        en: 'Edit manager',
        ru: 'Редактировать менеджера',
    },
    [`${APP}_add_manager`]: {
        en: 'Add manager',
        ru: 'Добавить менеджера',
    },
    [`${APP}_name_is_required`]: {
        en: 'Name is_required',
        ru: 'Имя обязательно',
    },
    [`${APP}_created`]: {
        en: 'Created',
        ru: 'Создал',
    },
    [`${APP}_closed_time`]: {
        en: 'Closed time',
        ru: 'Время закрытия',
    },
    [`${APP}_closed`]: {
        en: 'Closed',
        ru: 'Закрыл',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_traffic_hour`]: {
        en: 'Downtime (h.)',
        ru: 'Время простоя (ч.)',
    },
    [`${APP}_traffic`]: {
        en: 'Downtime (m.)',
        ru: 'Время простоя (м.)',
    },
    [`${APP}_power`]: {
        en: 'No power (m.)',
        ru: 'Отсутствие питания (м.)',
    },
    [`${APP}_temp`]: {
        en: 'Temperature sensor (m.)',
        ru: 'Датчик температуры (м.)',
    },
    [`${APP}_door`]: {
        en: 'Door sensor (m.)',
        ru: 'Датчик двери (м.)',
    },
    [`${APP}_date`]: {
        en: 'Date',
        ru: 'Дата',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_owner`]: {
        en: 'Owner',
        ru: 'Ведущий',
    },
    [`${APP}_internal_number`]: {
        en: 'Number VK',
        ru: 'Номер ВК',
    },
    [`${APP}_external_number`]: {
        en: 'Number MTS',
        ru: 'Номер МТС',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_range`]: {
        en: 'Range',
        ru: 'Диапазон',
    },
    [`${APP}_infrastructure_owner`]: {
        en: 'Infrastructure owner',
        ru: 'Владелец инфраструктуры',
    },
    [`${APP}_transport_owner`]: {
        en: 'Transport owner',
        ru: 'Владелец транспорта',
    },
    [`${APP}_vendor`]: {
        en: 'Vendor',
        ru: 'Вендор',
    },
    [`${APP}_model`]: {
        en: 'Model',
        ru: 'Модель',
    },
    [`${APP}_internal_name`]: {
        en: 'Name in NMS',
        ru: 'Название в NMS',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the record?',
        ru: 'Вы действительно хотите удалить запись?',
    },
    [`${APP}_loading`]: {
        en: 'Loading...',
        ru: 'Загрузка...',
    },
    [`${APP}_equipment_type`]: {
        en: 'Equipment Type',
        ru: 'Тип Оборудования',
    },
    [`${APP}_traffic_loss`]: {
        en: 'Traffic loss',
        ru: 'Потеря трафика',
    },
    [`${APP}_no_power`]: {
        en: 'No power',
        ru: 'Отсутствие питания',
    },
    [`${APP}_door_sensor`]: {
        en: 'Door sensor',
        ru: 'Датчик двери',
    },
    [`${APP}_temperature_sensor`]: {
        en: 'Temperature sensor',
        ru: 'Датчик температуры',
    },
    [`${APP}_warning`]: {
        en: 'Warning',
        ru: 'Предупреждение',
    },
    [`${APP}_occurrence_time`]: {
        en: 'Occurrence time',
        ru: 'Время возникновения',
    },
    [`${APP}_last_occurrence_time`]: {
        en: 'Last occurrence time',
        ru: 'Последнее время возникновения',
    },
    [`${APP}_cleaning_time`]: {
        en: 'Cleaning time',
        ru: 'Время очищения',
    },
    [`${APP}_additional_information`]: {
        en: 'Additional information',
        ru: 'Дополнительная информация',
    },
    [`${APP}_elimination_information`]: {
        en: 'Elimination time',
        ru: 'Время устранения',
    },
    [`${APP}_main_phone_number`]: {
        en: 'Main phone number',
        ru: 'Основной телефон',
    },
    [`${APP}_additional_number`]: {
        en: 'Additional phone number',
        ru: 'Дополнительный телефон',
    },
    [`${APP}_cun_ssrc_cs`]: {
        en: 'CunSSR CS, %',
        ru: 'CunSSR CS, %',
    },
    [`${APP}_cdrcs`]: {
        en: 'CDR CS, %',
        ru: 'CDR CS, %',
    },
    [`${APP}_traffic_voice`]: {
        en: 'Traffic Voice, Erl',
        ru: 'Traffic Voice, Erl',
    },
    [`${APP}_cun_ssr_ps`]: {
        en: 'CunSSR PS, %',
        ru: 'CunSSR PS, %',
    },
    [`${APP}_dr_ps`]: {
        en: 'DR PS, %',
        ru: 'DR PS, %',
    },
    [`${APP}_ps_data_vol`]: {
        en: 'PS Data Vol. Mb',
        ru: 'PS Data Vol. Mb',
    },
    [`${APP}_kpi`]: {
        en: 'KPI',
        ru: 'KPI',
    },
    [`${APP}_extended_search`]: {
        en: 'Extended search',
        ru: 'Расширенный поиск',
    },
};

export default locale;

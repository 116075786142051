import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Key coordinators',
        ru: 'Ключевые согласующие',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_required`]: {
        en: 'Required',
        ru: 'Обязательный',
    },
    [`${APP}_edit`]: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_you_want_delete_coordinator`]: {
        en: 'Do you really want to delete the coordinator?',
        ru: 'Вы действительно хотите удалить координатора?',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_all_branches_and_regions`]: {
        en: 'All branches and regions',
        ru: 'Все регионы и филиалы',
    },
    [`${APP}_field_required`]: {
        en: 'Field is required',
        ru: 'Обязательное поле',
    },
    [`${APP}_not_selected`]: {
        en: 'Not selected',
        ru: 'Не выбрано',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_coordinator`]: {
        en: 'Coordinator',
        ru: 'Согласующий',
    },
};

export default locale;

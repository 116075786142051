import * as React from 'react';

import { AppConfig } from '..';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const FeedbackItemPage = Loadable(React.lazy(() => import('./views')));

const app: AppConfig = {
    name: APP,
    element: (tab) => <FeedbackItemPage tab={tab} />,
    locale,
};

export default app;

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';
import TroubleTickets from './trouble-tickets';

const app: AppConfig = {
    name: APP,
    element: () => <TroubleTickets />,
    locale,
};

export default app;

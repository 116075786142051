/* tslint:disable */
/* eslint-disable */
/**
 * smartQueue_development
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const SmartQueueDeadlineLevels = {
    Default: 'Default',
    Normal: 'Normal',
    Danger: 'Danger',
    Overdue: 'Overdue',
} as const;

export type SmartQueueDeadlineLevels = typeof SmartQueueDeadlineLevels[keyof typeof SmartQueueDeadlineLevels];

import * as React from 'react';

import { APP } from '../../constants';
import WorkTypesMatrixEditFormConnector from '../connector/work-types-matrix-edit-form-connector';

import Translate from 'components/extended/translate';
import TroubleTicketViewDialog from 'sub-apps/trouble-ticket-view/views/trouble-ticket-view-dialog';

type WorkTypesMatrixEditDialogProps = {
    open: boolean;
    onClose: () => void;
};

const WorkTypesMatrixEditDialog: React.FC<WorkTypesMatrixEditDialogProps> = (props) => {
    const { open, onClose } = props;

    return (
        <TroubleTicketViewDialog
            onClose={onClose}
            open={open}
            title={<Translate app={APP} id="edit" />}
            fullWidth
            maxWidth="xs"
        >
            <WorkTypesMatrixEditFormConnector onClose={onClose} />
        </TroubleTicketViewDialog>
    );
};

export default WorkTypesMatrixEditDialog;

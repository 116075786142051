import * as React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { APP } from '../../constants';
import { PlannedWorksFilterState, plannedWorksOrderAvailableValues } from '../../hooks/use-planned-works-filter';

import { nanoid } from '@reduxjs/toolkit';
import DateIntervalDisplayItemContent from 'components/extended/data-grid/filter/date-interval-display-item-content';
import FilterDisplayItem from 'components/extended/data-grid/filter/filter-display-item';
import FilterDisplayItemMenu from 'components/extended/data-grid/filter/filter-display-item-menu';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import SearchDisplayFilter from 'components/extended/data-grid/filter/search-display-filter';
import { AvailableValue, FilterToolbarProps, SearchFilter } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterToolbar from 'hooks/use-management-filter-toolbar';

const searchFilters: SearchFilter<PlannedWorksFilterState>[] = [
    { field: 'pwNumber', id: nanoid(), label: <Translate app={APP} id="pw" /> },
];

const PlannedWorksFilterToolbar: React.FC<FilterToolbarProps<PlannedWorksFilterState>> = (props) => {
    const { filterValues, setFilterValues, currentSearchField, setCurrentSearchField, saveFilter, ...other } = props;

    const { onClickFilterItem, onClickOpenFilter } = useManagementFilterToolbar<PlannedWorksFilterState>({
        ...other,
    });

    const handleClickOrder = React.useCallback(
        (value: AvailableValue) => {
            setFilterValues((prevState) => ({ ...prevState, order: value }));
        },
        [setFilterValues],
    );

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <SearchDisplayFilter
                    filterValues={filterValues}
                    searchFilters={searchFilters}
                    setFilterValues={setFilterValues}
                    currentSearchField={currentSearchField}
                    setCurrentSearchField={setCurrentSearchField}
                />
                <FilterDisplayItemMenu
                    title={<Translate app={APP} id="order" />}
                    value={filterValues.order.name}
                    availableValues={plannedWorksOrderAvailableValues}
                    onClick={handleClickOrder}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="hide_is_apply" />}
                    Content={<Translate app={APP} id={filterValues.isApply.name} />}
                    onClick={onClickFilterItem('isApply')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="show_archived" />}
                    Content={<Translate app={APP} id={filterValues.isArchive.name} />}
                    onClick={onClickFilterItem('isArchive')}
                />
                <FilterDisplayItem
                    title={<Translate app={APP} id="date" />}
                    Content={
                        <DateIntervalDisplayItemContent
                            startDateValue={filterValues.startDate}
                            endDateValue={filterValues.endDate}
                        />
                    }
                    onClick={onClickFilterItem('startDate')}
                />
            </FilterDisplayItemWrapper>
            <Box sx={{ display: 'flex', ml: 'auto' }}>
                <Tooltip title={<Translate id="filter" />}>
                    <IconButton onClick={onClickOpenFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                {saveFilter && (
                    <Tooltip title={<Translate id="save_filter" />}>
                        <IconButton onClick={saveFilter}>
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </FilterDisplayWrapper>
    );
};

export default PlannedWorksFilterToolbar;

import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSProperties } from '@mui/styles';

import Transitions from 'components/extended/transitions';
import useAppDispatch from 'hooks/use-app-dispatch';
import useSelector from 'hooks/use-app-selector';
import customizationSlice from 'store/customization';

const popperStyle: CSSProperties = {
    zIndex: 1200,
};

const LocalizationSection: React.FC = () => {
    const customization = useSelector(customizationSlice.selectors.customizationSelector);
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = React.useRef<any>(null);

    const handleListItemClick = React.useCallback(
        (
            event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
            lng: string,
        ) => {
            dispatch(customizationSlice.actions.themeLocale(lng));
            setOpen(false);
        },
        [dispatch],
    );

    const handleToggle = React.useCallback(() => {
        setOpen((_prevOpen) => !_prevOpen);
    }, []);

    const handleClose = React.useCallback((event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    }, []);

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1,
                    },
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            border: '1px solid',
                            borderColor:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.primary.main,
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                            },
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <Typography variant="h5" sx={{ textTransform: 'uppercase' }} color="inherit">
                            {customization.locale}
                        </Typography>
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement="bottom-start"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ],
                }}
                style={popperStyle}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 280,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: customization.borderRadius,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250,
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        selected={customization.locale === 'ru'}
                                        onClick={(event) => handleListItemClick(event, 'ru')}
                                    >
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Русский</Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={customization.locale === 'en'}
                                        onClick={(event) => handleListItemClick(event, 'en')}
                                    >
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">English</Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default LocalizationSection;

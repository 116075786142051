import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import Translate from 'components/extended/translate';

const columnLocalization = (headerLocaleId: string, app?: string, tooltipLocaleId?: string) => () => {
    if (tooltipLocaleId !== undefined) {
        return (
            <Tooltip title={<Translate app={app} id={tooltipLocaleId} />}>
                <Box>
                    <Translate app={app} id={headerLocaleId} />
                </Box>
            </Tooltip>
        );
    }
    return <Translate app={app} id={headerLocaleId} />;
};

export default columnLocalization;

import { NEWS_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import {
    NewsNewsRequestModel,
    NewsNewsResponseModelApiResponse,
    NewsNewsResponseModelIEnumerableApiResponse,
    NewsStringApiResponse,
} from './news-models';
import { NEWS_LIST } from './tags';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const newsServices = createApi({
    reducerPath: 'newsServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: NEWS_SERVICE_URL,
    }),
    tagTypes: [NEWS_LIST],
    endpoints: (build) => ({
        newsGetList: build.query<
            NewsNewsResponseModelIEnumerableApiResponse,
            { rowPerPage: number; pageNumber: number }
        >({
            query: (queryArg) => ({
                url: `/api/News/GetList?pageNumber=${queryArg.pageNumber}&rowPerPage=${queryArg.rowPerPage}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result && result?.data
                    ? [
                          ...result.data.map(({ id }) => ({ type: NEWS_LIST, id } as const)),
                          { type: NEWS_LIST, id: 'LIST' },
                      ]
                    : [{ type: NEWS_LIST, id: 'LIST' }],
        }),
        newsAdd: build.mutation<NewsNewsResponseModelApiResponse, NewsNewsRequestModel>({
            query: (queryArg) => ({ url: `/api/News`, method: 'POST', body: queryArg }),
            invalidatesTags: [{ type: NEWS_LIST, id: 'LIST' }],
        }),
        newsDelete: build.mutation<NewsStringApiResponse, { id: number }>({
            query: (queryArg) => ({ url: `/api/News/${queryArg.id}`, method: 'DELETE' }),
            invalidatesTags: [{ type: NEWS_LIST, id: 'LIST' }],
        }),
        newsLike: build.mutation<NewsNewsResponseModelApiResponse, { newsId: number }>({
            query: (queryArg) => ({ url: `/api/News/Like`, method: 'POST', body: queryArg }),
        }),
        newsDislike: build.mutation<NewsNewsResponseModelApiResponse, { newsId: number }>({
            query: (queryArg) => ({ url: `/api/News/Like/Delete/${queryArg.newsId}`, method: 'DELETE' }),
        }),
    }),
});

export const {
    useNewsGetListQuery,
    useNewsAddMutation,
    useNewsDeleteMutation,
    useNewsLikeMutation,
    useNewsDislikeMutation,
} = newsServices;

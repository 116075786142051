import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip, { ChipProps } from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { format } from 'date-fns';

type TroubleTicketViewCommentCardProps = {
    action: string;
    comment: string;
    createdDate: string;
    user?: string;
    role?: string;
    actionColor?: ChipProps['color'];
};

const TroubleTicketViewCommentCard: React.FC<TroubleTicketViewCommentCardProps> = (props) => {
    const { action, comment, user, role, createdDate, actionColor = 'default' } = props;

    return (
        <Card>
            <CardContent>
                <Grid container direction="column" spacing={{ xs: 0.5, lg: 1 }}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <Grid container direction="row" spacing={{ xs: 0.5, lg: 1 }}>
                                    <Grid item>
                                        <Chip color={actionColor} size="small" label={action} />
                                    </Grid>
                                    {(user || role) && (
                                        <Grid item>
                                            <Typography color="textSecondary">
                                                {user && role ? `${user} (${role})` : `${user || role}`}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography color="textSecondary">
                                    {format(new Date(createdDate), 'dd.MM.yyyy hh:mm')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography>{comment}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TroubleTicketViewCommentCard;

import * as React from 'react';

import Box from '@mui/material/Box';

import Translate from '../translate';

const NoRowsOverlay: React.FC = () => (
    <Box
        sx={{
            width: 'auto',
            height: '100%',
            minHeight: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Translate id="no_data" />
    </Box>
);

export default NoRowsOverlay;

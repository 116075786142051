import * as React from 'react';

import { APP } from '../../constants';
import { EprListFilterState } from '../../hooks/use-epr-list-filter';

import FilterDisplayItemMenu from 'components/extended/data-grid/filter/filter-display-item-menu';
import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';
import { AvailableValue, FilterOnlyToolbarProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';

const directionAvailableValues = [
    {
        id: 0,
        name: 'Automatization',
    },
    {
        id: 1,
        name: 'Back Office',
    },
    {
        id: 2,
        name: 'COE',
    },
    {
        id: 3,
        name: 'Construction MSN',
    },
    {
        id: 4,
        name: 'Construction RAN',
    },
    {
        id: 5,
        name: 'FME',
    },
    {
        id: 6,
        name: 'Front Office',
    },
    {
        id: 7,
        name: 'HQ',
    },
    {
        id: 8,
        name: 'Network Management',
    },
    {
        id: 9,
        name: 'Planing',
    },
    {
        id: 10,
        name: 'Quality',
    },
    {
        id: 11,
        name: 'Site Service',
    },
    {
        id: 12,
        name: 'VK',
    },
];
const groupAvailableValues = [
    {
        id: 0,
        name: 'Все группы',
    },
    {
        id: 1,
        name: 'Expert',
    },
    {
        id: 2,
        name: 'TL Team (ConstMultiService)',
    },
    {
        id: 3,
        name: 'Волга',
    },
    {
        id: 4,
        name: 'Восток',
    },
    {
        id: 5,
        name: 'Северо-Запад',
    },
    {
        id: 6,
        name: 'Урал',
    },
];

type EprListFilterToolbarProps = FilterOnlyToolbarProps<EprListFilterState>;

const EprListFilterToolbar: React.FC<EprListFilterToolbarProps> = (props) => {
    const { filterValues, setFilterValues } = props;

    const handleClickOrder = React.useCallback(
        (field: string) => (value: AvailableValue) => {
            setFilterValues((prevState) => ({ ...prevState, [field]: value }));
        },
        [setFilterValues],
    );

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <FilterDisplayItemMenu
                    title={<Translate app={APP} id="direction" />}
                    value={filterValues.direction.name}
                    availableValues={directionAvailableValues || []}
                    onClick={handleClickOrder('direction')}
                />
                <FilterDisplayItemMenu
                    title={<Translate app={APP} id="group" />}
                    value={filterValues.group.name}
                    availableValues={groupAvailableValues || []}
                    onClick={handleClickOrder('group')}
                />
            </FilterDisplayItemWrapper>
        </FilterDisplayWrapper>
    );
};

export default EprListFilterToolbar;

import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';

import { IconChecks } from '@tabler/icons';
import { StringColorProps } from 'types';

type PresetColorProps = {
    color: StringColorProps;
    presetColor: string;
    setPresetColor: (s: string) => void;
};

const PresetColor: React.FC<PresetColorProps> = ({ color, presetColor, setPresetColor }) => (
    <Grid item sx={{ mr: 1.5 }}>
        <ButtonBase sx={{ borderRadius: 12 }} onClick={() => setPresetColor(color?.id!)}>
            <Avatar
                variant="rounded"
                color="inherit"
                sx={{
                    background: `linear-gradient(135deg, ${color.primary} 50%, ${color.secondary} 50%)`,
                    opacity: presetColor === color.id ? 0.6 : 1,
                    width: 24,
                    height: 24,
                }}
            >
                {presetColor === color.id && <IconChecks size="70%" color="#fff" />}
            </Avatar>
        </ButtonBase>
    </Grid>
);

export default PresetColor;

import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Grid',
        ru: 'Таблица',
    },
    [`${APP}_item_page`]: {
        en: 'Item',
        ru: 'Элемент',
    },
};

export default locale;

import * as React from 'react';

import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

const useManagementFilterDialog = <State>(props: FilterDialogProps<State>) => {
    const { open, setOpen, expandedField, setExpandedField, filterValues, setFilterValues, initialState } = props;

    const [localFilterValues, setLocalFilterValues] = React.useState(deepCopy(filterValues));

    const [isError, setIsError] = React.useState(false);

    const resetFilter = React.useCallback(() => setLocalFilterValues(deepCopy(initialState)), [initialState]);

    const closeAndSaveFilter = React.useCallback(() => {
        setFilterValues(localFilterValues);
        setOpen(false);
        setExpandedField(undefined);
    }, [localFilterValues, setExpandedField, setFilterValues, setOpen]);

    const closeModalWindow = React.useCallback(() => setExpandedField(undefined), [setExpandedField]);

    return {
        open,
        setOpen,
        expandedField,
        localFilterValues,
        setLocalFilterValues,
        resetFilter,
        closeAndSaveFilter,
        closeModalWindow,
        isError,
        setIsError,
    };
};

export default useManagementFilterDialog;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_profile`]: {
        en: 'Profile',
        ru: 'Профиль',
    },
    [`${APP}_security`]: {
        en: 'Security',
        ru: 'Безопасность',
    },
    [`${APP}_authorized_devices`]: {
        en: 'Authorized devices',
        ru: 'Авторизованные устройства',
    },
    [`${APP}_device`]: {
        en: 'Device',
        ru: 'Устройство',
    },
    [`${APP}_system`]: {
        en: 'System',
        ru: 'Система',
    },
    [`${APP}_id`]: {
        en: 'ID',
        ru: 'ID',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Название',
    },
    [`${APP}_client`]: {
        en: 'Client',
        ru: 'Клиент',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_browser`]: {
        en: 'Browser',
        ru: 'Браузер',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the token?',
        ru: 'Вы действительно хотите удалить токен? Доступ к приложению с этого устройства будет недоступен',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_loading`]: {
        en: 'Loading',
        ru: 'Загрузка',
    },
    [`${APP}_upload_avatar`]: {
        en: 'Upload Avatar',
        ru: 'Загрузить фото',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_phone`]: {
        en: 'Phone',
        ru: 'Телефон',
    },
    [`${APP}_email`]: {
        en: 'Email',
        ru: 'Email',
    },
    [`${APP}_birthday`]: {
        en: 'Birthday',
        ru: 'День рождения',
    },
};

export default locale;

import * as React from 'react';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';

type CreatePlannedWorkTypeFormControllerConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    watch: UseFormWatch<CreatePlannedWorkFormInputs>;
    setValue: UseFormSetValue<CreatePlannedWorkFormInputs>;
};

const data = [
    {
        id: 1,
        name: 'Прокладка патчкордов, кабелей в новых лотках в Автозале',
        minCreateTime: 0,
        minCreateTimeB2b: 1,
        maxWorkingTime: 24,
    },
    {
        id: 2,
        name: 'Снятие принтаутов, трассировок и backup’ов',
        minCreateTime: 3,
        minCreateTimeB2b: 4,
        maxWorkingTime: 48,
    },
];

const CreatePlannedWorkTypeFormControllerConnector: React.FC<CreatePlannedWorkTypeFormControllerConnectorProps> = (
    props,
) => {
    const { control, watch, setValue } = props;
    const objectValue = watch('object');
    const timeSpendingValue = watch('timeSpending');

    // TODO тут должен быть запрос за данными с учетом object и timeSpending

    const handleChange = React.useCallback(
        (event: any) => {
            const selectedType = data.find((type) => type.id === Number(event.target.value));
            if (selectedType !== undefined)
                setValue('type', {
                    id: selectedType.id,
                    minCreateTimeB2b: selectedType.minCreateTimeB2b,
                    minCreateTime: selectedType.minCreateTime,
                    maxWorkingTime: selectedType.maxWorkingTime,
                });
        },
        [setValue],
    );

    return (
        <Controller
            name="type"
            control={control}
            rules={{
                required: `${APP}_field_required`,
            }}
            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                <FormControl
                    disabled={!objectValue || !timeSpendingValue}
                    size="small"
                    fullWidth
                    error={invalid && isTouched}
                    sx={{ marginTop: 2 }}
                >
                    <InputLabel margin="dense" htmlFor={field.name}>
                        <Translate app={APP} id="type" />
                    </InputLabel>
                    <Select
                        {...field}
                        value={field.value === '' ? field.value : field.value.id}
                        onChange={handleChange}
                        label={<Translate app={APP} id="type" />}
                        id={field.name}
                    >
                        <MenuItem value="" disabled>
                            <Translate app={APP} id="not_selected" />
                        </MenuItem>
                        {data.map((elem) => (
                            <MenuItem key={elem.id} value={elem.id}>
                                {elem.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {invalid && isTouched && error?.message && (
                        <FormHelperText error>
                            <Translate app={APP} id={error.message} />
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default CreatePlannedWorkTypeFormControllerConnector;

import { FLUENTD_SERVICE_URL } from '../constants';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const commonFluentdServices = createApi({
    reducerPath: 'fluentdServices',
    baseQuery: fetchBaseQuery({
        baseUrl: FLUENTD_SERVICE_URL,
    }),
    endpoints: () => ({}),
    tagTypes: ['fluentd'],
});

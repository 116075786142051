import * as React from 'react';

import TroubleTicketViewDialog from '../../../trouble-ticket-view-dialog';
import TroubleTicketViewPossibleReasonsTtFormConnector from '../connectors/trouble-ticket-view-possible-reasons-tt-form-connector';

type TroubleTicketViewPossibleReasonsTtDialogProps = {
    open: boolean;
    onClose: () => void;
    ttNumber: number;
    openedTtNumber: number;
};

const TroubleTicketViewPossibleReasonsTtDialog: React.FC<TroubleTicketViewPossibleReasonsTtDialogProps> = (props) => {
    const { open, onClose, ttNumber, openedTtNumber } = props;

    return (
        <TroubleTicketViewDialog onClose={onClose} open={open} title={openedTtNumber} fullWidth maxWidth="sm">
            <TroubleTicketViewPossibleReasonsTtFormConnector
                onClose={onClose}
                ttNumber={ttNumber}
                openedTtNumber={openedTtNumber}
            />
        </TroubleTicketViewDialog>
    );
};

export default TroubleTicketViewPossibleReasonsTtDialog;

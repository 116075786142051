import * as React from 'react';

import TroubleTicketsCloseTtForm from '../forms/trouble-tickets-close-tt-form';

type TroubleTicketsCloseTtFormConnectorProps = {
    onClose: () => void;
};

export type TroubleTicketsCloseTtFormInitialValues = {
    comment: string;
};

const initialValues: TroubleTicketsCloseTtFormInitialValues = {
    comment: '',
};

const TroubleTicketsCloseTtFormConnector: React.FC<TroubleTicketsCloseTtFormConnectorProps> = (props) => {
    const { onClose } = props;

    // TODO тут должен дергаться хук мутации на закрытие

    const handleCloseTt = React.useCallback(() => {
        // TODO тут должна запускаться мутация на закрытие
        onClose();
    }, [onClose]);

    // TODO тут должна быть проверка статуса выполнения мутации

    return (
        <TroubleTicketsCloseTtForm
            onClose={onClose}
            submitCallback={handleCloseTt}
            isLoading={false}
            initialValues={initialValues}
        />
    );
};

export default TroubleTicketsCloseTtFormConnector;

import * as React from 'react';

import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';

import Translate from 'components/extended/translate';

type FilterDialogProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleReset: () => void;
    handleCloseAndSave: () => void;
    isError?: boolean;
    handleClose?: () => void;
    resetButtonText?: string | JSX.Element;
    applyButtonText?: string | JSX.Element;
};

const FilterDialog: React.FC<FilterDialogProps> = (props) => {
    const {
        open,
        setOpen,
        handleClose,
        children,
        handleReset,
        resetButtonText = <Translate id="reset" />,
        applyButtonText = <Translate id="apply" />,
        handleCloseAndSave,
        isError = false,
    } = props;

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseDialog = React.useCallback(() => {
        setOpen(false);
        if (handleClose) handleClose();
    }, [handleClose, setOpen]);

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleCloseDialog} fullWidth>
            <DialogTitle
                id="customized-dialog-title"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: 3,
                    pr: 2.25,
                    pt: 0,
                    pb: 1.5,
                }}
            >
                <Translate id="filter" />
                <IconButton onClick={handleCloseDialog} size="small">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ px: 1, py: 0 }}>
                {children}
            </DialogContent>
            <DialogActions sx={{ pt: 1.5, pr: 1.5, pb: 0 }}>
                <Button disableElevation onClick={handleReset} variant="outlined" color="inherit" size="small">
                    {resetButtonText}
                </Button>
                <Button
                    disableElevation
                    autoFocus
                    onClick={handleCloseAndSave}
                    variant="contained"
                    color="primary"
                    disabled={isError}
                    size="small"
                >
                    {applyButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;

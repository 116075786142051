import { resetError, setError } from './actions';
import { ErrorStateProps } from './types';

import { createReducer } from 'redux-act';

const initialState: ErrorStateProps = {
    isError: false,
    isRepeat: false,
    error: null,
};

export default createReducer({}, initialState)
    .on(setError, (state, data) => ({
        isError: true,
        isRepeat: data.isRepeat,
        error: data.error,
    }))
    .on(resetError, () => ({
        isError: false,
        isRepeat: false,
        error: null,
    }));

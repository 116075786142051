import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';
import Positions from './positions';

const app: AppConfig = {
    name: APP,
    element: () => <Positions />,
    locale,
};

export default app;

import * as React from 'react';

import DialogContent from '@mui/material/DialogContent';

import WorkTypesMatrixEditForm from '../forms/work-types-matrix-edit-form';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';

type WorkTypesMatrixEditFormConnectorProps = {
    onClose: () => void;
};

export type WorkTypesMatrixEditFormInitialValues = {
    object: string;
    type: string;
    minCreateTime: number;
    minCreateTimeB2b: number;
    allowedSpendDay: number;
    needApprove: number;
};

const data = {
    object: 'NSS(CS Core)',
    type: 'Аварийно-восстановительные работы по которым нет влияние на трафик',
    minCreateTime: 0,
    minCreateTimeB2b: 0,
    allowedSpendDay: 1,
    needApprove: 1,
};

const isSuccess = true;
const isFetching = false;

const WorkTypesMatrixEditFormConnector: React.FC<WorkTypesMatrixEditFormConnectorProps> = (props) => {
    const { onClose } = props;
    // TODO тут должен быть запрос за данными

    const handleSaveChange = React.useCallback(async () => {
        // TODO тут должна дергаться мутация с проверкой статуса
        onClose();
    }, [onClose]);

    const initialValues: WorkTypesMatrixEditFormInitialValues = React.useMemo(() => data, []);

    if (isSuccess) {
        return (
            <WorkTypesMatrixEditForm
                initialValues={initialValues}
                isLoading={false}
                submitCallback={handleSaveChange}
                onClose={onClose}
            />
        );
    }
    if (isFetching) {
        return (
            <DialogContent sx={{ position: 'relative', height: 50 }}>
                <DataGridLoader visible={isFetching} />
            </DialogContent>
        );
    }
    return null;
};

export default WorkTypesMatrixEditFormConnector;

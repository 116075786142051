import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}`]: {
        en: 'Widgets',
        ru: 'Виджеты',
    },
    [`${APP}_alarms_chart`]: {
        en: 'Alarms log on BS',
        ru: 'Лог аварий на БС',
    },
    [`${APP}_cdt_chart`]: {
        en: 'CDT',
        ru: 'CDT',
    },
    [`${APP}_by_hours`]: {
        en: 'By hours',
        ru: 'По часам',
    },
    [`${APP}_by_days`]: {
        en: 'By days',
        ru: 'По дням',
    },
    [`${APP}_by_role`]: {
        en: 'Assignment role',
        ru: 'Роль назначения',
    },
    [`${APP}_by_responsible`]: {
        en: 'Area of responsibility',
        ru: 'Зона ответственности',
    },
};

export default locale;

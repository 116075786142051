import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useTheme from '@mui/material/styles/useTheme';

import { LoginFormInputs } from './login-form';

import Translate from 'components/extended/translate';
import { AuthAuthTokenResponseModelApiResponse } from 'services/auth/auth-models';
import { usePostAuthTokenMutation } from 'services/auth/auth-services';

type FormInputs = {
    username: string;
    password: string;
    code: string;
};

type SecondFactorFormProps = {
    loginFormInputsValues: LoginFormInputs;
    onBack: () => void;
};

const SecondFactorForm: React.FC<SecondFactorFormProps> = (props) => {
    const { onBack, loginFormInputsValues } = props;
    const [postAuthToken] = usePostAuthTokenMutation();
    const [submitErrors, setSubmitErrors] = React.useState<string[]>([]);
    const theme = useTheme();

    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
    } = useForm<FormInputs>({
        defaultValues: {
            username: loginFormInputsValues.username,
            password: loginFormInputsValues.password,
            code: '',
        },
        mode: 'all',
    });

    const onSubmit = React.useCallback(
        async (valuesForm: FormInputs) => {
            setSubmitErrors([]);

            const res = await postAuthToken({
                ...valuesForm,
                utcTime: Math.round(Date.now() / 1000),
            });

            if ('error' in res && 'data' in res.error) {
                const data = res.error.data as AuthAuthTokenResponseModelApiResponse;
                setSubmitErrors(data.errors);
            }

            return res;
        },
        [postAuthToken],
    );

    const handleCodeChange = React.useCallback(
        (onChangeCallback: (...event: any[]) => void) => (
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
            if ((event.target?.value.length < 7 && /^\d+$/.test(event.target?.value)) || event.target?.value === '') {
                onChangeCallback(event);
            }
        },
        [],
    );

    const handleBack = React.useCallback(() => {
        onBack();
    }, [onBack]);

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Controller
                name="code"
                control={control}
                rules={{
                    required: 'code_verification_is_required',
                    minLength: {
                        value: 6,
                        message: 'code_verification_must_consist_of_6_digits',
                    },
                    // valueAsNumber: true,
                }}
                render={({ field: { onChange, ...rest }, fieldState: { isTouched, error: _error, invalid } }) => (
                    <FormControl
                        fullWidth
                        error={Boolean(isTouched && invalid)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-verification-code">
                            <Translate id="code_verification" />
                        </InputLabel>
                        <OutlinedInput
                            autoFocus
                            id="outlined-adornment-verification-code"
                            fullWidth
                            onChange={handleCodeChange(onChange)}
                            {...rest}
                        />
                        {invalid && isTouched && _error?.message && (
                            <FormHelperText error id="standard-weight-helper-text-verify-code">
                                <Translate id={_error.message} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />

            {submitErrors.length > 0 && (
                <Box sx={{ mt: 2, mb: 2.5 }}>
                    {submitErrors.map((_error) => (
                        <FormHelperText error key={_error}>
                            {_error}
                        </FormHelperText>
                    ))}
                </Box>
            )}

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disableElevation
                    disabled={!isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                >
                    <Translate id="login" />
                </LoadingButton>
            </Box>

            <Box sx={{ mt: 3 }}>
                <Button
                    disableElevation
                    fullWidth
                    type="submit"
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                >
                    <Translate id="return_login_form" />
                </Button>
            </Box>
        </form>
    );
};

export default SecondFactorForm;

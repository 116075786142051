import * as React from 'react';

import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, gridClasses } from '@mui/x-data-grid';

import { APP } from '../constants';
import useAccessesFilter from '../hooks/use-accesses-filter';
import AccessesDialog from './dialogs/accesses-dialog';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import EditDeleteRowMenu from 'components/extended/edit-delete-row-menu';
import Translate from 'components/extended/translate';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { useConfirm } from 'material-ui-confirm';
import { useModal } from 'mui-modal-provider';
import { PositionsAccessColumnsOrderRequestModel } from 'services/positions/generated-models';
import {
    usePositionsAccessDeleteMutation,
    usePositionsAccessGetListQuery,
} from 'services/positions/positions-services';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import columnLocalization from 'utils/column-localization';
import ConfirmOptionsCustom from 'utils/confirm-options-custom';
import renderDateCell from 'utils/render-date-cell';

const AccessesTable: React.FC = () => {
    const { Pagination, rowsState } = usePagination(`${APP}_accesses_grid`, '5');
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { AccessesFilterDialog, AccessesFilterToolbar, filterValues } = useAccessesFilter();
    const confirm = useConfirm();
    const { showModal } = useModal();

    const { data, isFetching, refetch } = usePositionsAccessGetListQuery({
        value: {
            search: filterValues.search,
            branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : undefined,
            isClosed: false,
        },
        order: sortModel[0] as PositionsAccessColumnsOrderRequestModel,
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    const [mutate, { isLoading: deleteAccessLoading }] = usePositionsAccessDeleteMutation();

    const deleteAccess = React.useCallback(
        (accessId: number) => () => {
            confirm(
                ConfirmOptionsCustom({
                    title: null,
                    description: <Translate id="you_want_delete_record" app={APP} />,
                    confirmationText: <Translate id="delete" app={APP} />,
                    cancellationText: <Translate id="cancel" app={APP} />,
                }),
            ).then(() => {
                mutate(accessId);
            });
        },
        [confirm, mutate],
    );

    const editAccess = React.useCallback(
        (accessId: number, positionNumber: string) => () => {
            const modal = showModal(AccessesDialog, {
                accessId,
                positionNumber,
                onClose: () => {
                    modal.hide();
                },
            });
        },
        [showModal],
    );

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: POSITION_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip sx={{ userSelect: 'text' }} onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                renderHeader: columnLocalization(`${APP}_position`),
                field: 'position',
                renderCell: renderCellChip,
                flex: 0.4,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_region`),
                field: 'region',
                flex: 0.6,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization(`${APP}_branch`),
                field: 'branch',
                flex: 0.6,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization(`${APP}_phone`),
                field: 'phone',
                flex: 0.5,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_comment`),
                field: 'comment',
                flex: 0.5,
                minWidth: 100,
                sortable: false,
            },
            {
                renderHeader: columnLocalization(`${APP}_created_time`),
                renderCell: renderDateCell('yyyy-MM-dd HH:mm:ss'),
                field: 'createdTime',
                flex: 0.5,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_created_user`),
                field: 'createdUser',
                flex: 0.5,
                minWidth: 100,
            },
            {
                renderHeader: columnLocalization(`${APP}_system`),
                field: 'system',
                flex: 0.5,
                minWidth: 80,
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.05,
                minWidth: 40,
                getActions: (params: any) => [
                    <EditDeleteRowMenu
                        onEditClick={editAccess(params.id, params.row.position)}
                        onDeleteClick={deleteAccess(params.id)}
                    />,
                ],
            },
        ],
        [deleteAccess, editAccess, renderCellChip],
    );

    return (
        <>
            {data !== undefined && (
                <>
                    <AccessesFilterToolbar />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <AccessesFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching || deleteAccessLoading} text={<Translate id="loading" />} />
        </>
    );
};

export default AccessesTable;

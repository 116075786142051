import * as React from 'react';

import Toolbar from '@mui/material/Toolbar';

const ButtonGroupDisplayFilterWrapper: React.FC = (props) => {
    const { children } = props;

    return (
        <Toolbar
            sx={{
                flexWrap: 'wrap',
                minHeight: {
                    xs: 'auto',
                },
                justifyContent: {
                    xs: 'center',
                    sm: 'initial',
                },
                pb: {
                    xs: 2,
                },
                px: {
                    xs: 0,
                },
                pt: {
                    xs: 0,
                },
            }}
        >
            {children}
        </Toolbar>
    );
};

export default ButtonGroupDisplayFilterWrapper;

import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';
import TimeOnBatteries from './time-on-batteries';

const app: AppConfig = {
    name: APP,
    element: () => <TimeOnBatteries />,
    locale,
};

export default app;

import * as React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

import DateTimePicker from '@mui/lab/DateTimePicker';
import { DateTimeValidationError } from '@mui/lab/internal/pickers/date-time-utils';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { APP } from '../constants';
import { CreatePlannedWorkFormInputs } from './create-planned-work-main';

import Translate from 'components/extended/translate';
import { add } from 'date-fns';

type CreatePlannedWorkDatesWorkIntervalConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    watch: UseFormWatch<CreatePlannedWorkFormInputs>;
    dateMask?: string;
    dateFormat?: string;
};

const CreatePlannedWorkDatesWorkIntervalContainer: React.FC<CreatePlannedWorkDatesWorkIntervalConnectorProps> = (
    props,
) => {
    const { control, watch, dateMask, dateFormat } = props;

    // TODO над валидацией дат и выводом ошибок еще нужно поработать

    const [startDateError, setStartDateError] = React.useState<string>('');
    const [endDateError, setEndDateError] = React.useState<string>('');

    const handleStartDateError = React.useCallback((error: DateTimeValidationError) => {
        switch (error) {
            case 'invalidDate':
                setStartDateError(`${APP}_invalid_date`);
                break;
            case 'minDate':
            case 'minTime':
                setStartDateError(`${APP}_date_cannot_be_less_min`);
                break;
            case 'maxDate':
            case 'maxTime':
                setStartDateError(`${APP}_date_cannot_be_greater_end_date`);
                break;
            case null:
                setStartDateError('');
        }
    }, []);

    const handleEndDateError = React.useCallback((error: DateTimeValidationError) => {
        switch (error) {
            case 'invalidDate':
                setEndDateError(`${APP}_invalid_date`);
                break;
            case 'minDate':
            case 'minTime':
                setEndDateError(`${APP}_date_cannot_be_less_start_date`);
                break;
            case 'maxDate':
            case 'maxTime':
                setEndDateError(`${APP}_date_cannot_be_greater_max`);
                break;
            case null:
                setEndDateError('');
        }
    }, []);

    const typeValue = watch('type');
    const isInfluenceB2bValue = watch('isInfluenceB2b');
    const startDateWorkValue = watch('startDateWork');
    const endDateWorkValue = watch('endDateWork');

    const getMinDateStartWork = React.useCallback(() => {
        if (typeValue !== '') {
            return add(new Date(), {
                hours: isInfluenceB2bValue ? typeValue.minCreateTimeB2b : typeValue.minCreateTime,
            });
        }
        return undefined;
    }, [isInfluenceB2bValue, typeValue]);

    const getMaxDateEndWork = React.useCallback(() => {
        if (typeValue !== '' && startDateWorkValue !== null) {
            return add(startDateWorkValue, {
                hours: typeValue.maxWorkingTime,
            });
        }
        return undefined;
    }, [startDateWorkValue, typeValue]);

    return (
        <Grid container spacing={2} direction="row">
            <Grid item xs={12} md={6}>
                <Controller
                    name="startDateWork"
                    control={control}
                    rules={{
                        required: `${APP}_field_required`,
                        validate: () => startDateError === '' || startDateError,
                    }}
                    render={({ field: { onChange, value, ...other }, fieldState: { isTouched, invalid, error } }) => (
                        <DateTimePicker
                            onChange={onChange}
                            value={value}
                            disabled={!typeValue}
                            minDateTime={getMinDateStartWork()}
                            maxDateTime={endDateWorkValue !== null ? endDateWorkValue : undefined}
                            ampm={false}
                            mask={dateMask}
                            views={['day', 'hours', 'minutes']}
                            onError={handleStartDateError}
                            inputFormat={dateFormat}
                            label={<Translate app={APP} id="date_start" />}
                            renderInput={(params) => (
                                <FormControl fullWidth sx={{ marginTop: isInfluenceB2bValue ? 2 : 1 }}>
                                    <TextField
                                        {...params}
                                        {...other}
                                        error={params.error || invalid}
                                        size="small"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: undefined,
                                        }}
                                    />
                                    {invalid && error?.message && (
                                        <FormHelperText error>
                                            <Translate app={APP} id={error.message} />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name="endDateWork"
                    control={control}
                    rules={{
                        required: `${APP}_field_required`,
                        validate: () => endDateError === '' || endDateError,
                    }}
                    render={({ field: { onChange, value, ...other }, fieldState: { isTouched, invalid, error } }) => (
                        <DateTimePicker
                            onChange={onChange}
                            value={value}
                            disabled={!typeValue}
                            minDateTime={startDateWorkValue !== null ? startDateWorkValue : undefined}
                            maxDateTime={getMaxDateEndWork()}
                            ampm={false}
                            mask={dateMask}
                            onError={handleEndDateError}
                            views={['day', 'hours', 'minutes']}
                            inputFormat={dateFormat}
                            label={<Translate app={APP} id="date_end" />}
                            InputProps={{ placeholder: undefined }}
                            renderInput={(params) => (
                                <FormControl fullWidth sx={{ marginTop: isInfluenceB2bValue ? 2 : 1 }}>
                                    <TextField
                                        {...params}
                                        {...other}
                                        error={params.error || invalid}
                                        size="small"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: undefined,
                                        }}
                                    />
                                    {invalid && error?.message && (
                                        <FormHelperText error>
                                            <Translate app={APP} id={error.message} />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default CreatePlannedWorkDatesWorkIntervalContainer;

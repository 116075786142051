import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Work types matrix',
        ru: 'Матрица типов работ',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_min_create_time`]: {
        en: 'Min create time, h',
        ru: 'Минимальное время заведения работ, ч',
    },
    [`${APP}_min_expert_time`]: {
        en: 'Min expert time, h',
        ru: 'Сроки экспертного согласования, ч',
    },
    [`${APP}_min_create_time_b2b`]: {
        en: 'Min create time B2B, h',
        ru: 'Минимальное время заведения работ b2b, ч',
    },
    [`${APP}_min_expert_time_b2b`]: {
        en: 'Min expert time B2B, h',
        ru: 'Сроки экспертного согласования b2b, ч',
    },
    [`${APP}_allowed_spend_day`]: {
        en: 'Allowed to spend the day',
        ru: 'Разрешено проводить днем',
    },
    [`${APP}_need_approve`]: {
        en: 'Need approve',
        ru: 'Требуется согласование',
    },
    [`${APP}_edit`]: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    [`${APP}_all_objects`]: {
        en: 'All objects',
        ru: 'Все обьекты',
    },
    [`${APP}_edit`]: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_save`]: {
        en: 'Save',
        ru: 'Сохранить',
    },
    [`${APP}_field_required`]: {
        en: 'Field is required',
        ru: 'Поле обязательно для заполнения',
    },
    [`${APP}_can_only_positive_integer`]: {
        en: 'Сan only be a positive integer',
        ru: 'Может быть только целым положительным числом',
    },
    [`${APP}_yes`]: {
        en: 'Yes',
        ru: 'Да',
    },
    [`${APP}_no`]: {
        en: 'No',
        ru: 'Нет',
    },
};

export default locale;

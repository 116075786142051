export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export interface ColorProps {
    readonly [key: string]: string;
}

export type StringColorProps = {
    id?: string;
    label?: string;
    color?: string;
    primary?: string;
    secondary?: string;
};

export type KeyedObject = {
    [key: string]: string | number | KeyedObject | any;
};

export type Locales = {
    [key: string]: { en?: string; ru: string };
};

export type AccessTokenPayload = {
    exp: number;
    firstName: string;
    lastName: string;
    phone: string;
    refreshToken: string;
    role: string;
    secondName: string;
    userId: number;
    username: string;
};

export type AuthTokenPayload = {
    userId: number;
    phone: string;
    needChangePassword: boolean;
    needVerifyPhone: boolean;
    exp: number;
};

// eslint-disable-next-line no-shadow
export enum ServiceResponseStatusEnum {
    Success = 'Success',
    Error = 'Error',
}

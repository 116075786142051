import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'BS Statistics',
        ru: 'Статистика БС',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_traffic_hour`]: {
        en: 'Downtime (h.)',
        ru: 'Время простоя (ч.)',
    },
    [`${APP}_traffic`]: {
        en: 'Downtime (m.)',
        ru: 'Время простоя (м.)',
    },
    [`${APP}_power`]: {
        en: 'No power (m.)',
        ru: 'Отсутствие питания (м.)',
    },
    [`${APP}_temp`]: {
        en: 'Temperature sensor (m.)',
        ru: 'Датчик температуры (м.)',
    },
    [`${APP}_door`]: {
        en: 'Door sensor (m.)',
        ru: 'Датчик двери (м.)',
    },
    [`${APP}_date`]: {
        en: 'Date',
        ru: 'Дата',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_traffic_loss`]: {
        en: 'Traffic loss',
        ru: 'Потеря трафика',
    },
    [`${APP}_no_power`]: {
        en: 'No power',
        ru: 'Отсутствие питания',
    },
    [`${APP}_door_sensor`]: {
        en: 'Door sensor',
        ru: 'Датчик двери',
    },
    [`${APP}_temperature_sensor`]: {
        en: 'Temperature sensor',
        ru: 'Датчик температуры',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
};

export default locale;

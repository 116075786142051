import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Accesses',
        ru: 'Доступы',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_phone`]: {
        en: 'Phone',
        ru: 'Телефон',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_created_time`]: {
        en: 'Created date',
        ru: 'Дата создания',
    },
    [`${APP}_created_user`]: {
        en: 'Created',
        ru: 'Создал',
    },
    [`${APP}_system`]: {
        en: 'System',
        ru: 'Система',
    },
    [`${APP}_warning`]: {
        en: 'Warning',
        ru: 'Предупреждение',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the record?',
        ru: 'Вы действительно хотите удалить запись?',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_phone_is_required`]: {
        en: 'Phone is required',
        ru: 'Телефон обязателен',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_accesses`]: {
        en: 'Accesses',
        ru: 'Доступы',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
    [`${APP}_edit_access`]: {
        en: 'Edit access',
        ru: 'Редактировать доступ',
    },
    [`${APP}_add_access`]: {
        en: 'Add access',
        ru: 'Добавить доступ',
    },
};

export default locale;

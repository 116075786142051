import { AppConfig } from '../index';
import { APP } from './constants';
import locale from './locales/locale';
import Topology from './topology';

const app: AppConfig = {
    name: APP,
    element: () => <Topology />,
    locale,
};

export default app;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Blocked cell',
        ru: 'Заблокированные сектора',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_element`]: {
        en: 'Element',
        ru: 'Элемент',
    },
    [`${APP}_cell_id`]: {
        en: 'Cell Id',
        ru: 'Cell Id',
    },
    [`${APP}_block_type`]: {
        en: 'Block type',
        ru: 'Тип блокировки',
    },
    [`${APP}_user`]: {
        en: 'User',
        ru: 'Пользователь',
    },
    [`${APP}_reason`]: {
        en: 'Reason',
        ru: 'Причина',
    },
    [`${APP}_time`]: {
        en: 'Time',
        ru: 'Время',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
};

export default locale;

import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Translate from 'components/extended/translate';

type EditDeleteRowMenuProps = {
    onEditClick: () => void;
    onDeleteClick: () => void;
};

const EditDeleteRowMenu: React.FC<EditDeleteRowMenuProps> = (props) => {
    const { onEditClick, onDeleteClick } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuOpen = Boolean(anchorEl);

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onClick = React.useCallback(
        (callback: () => void) => () => {
            callback();
            closeMenu();
        },
        [closeMenu],
    );

    return (
        <>
            <IconButton size="small" onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={menuOpen} onClose={closeMenu}>
                <MenuList dense sx={{ py: 0 }}>
                    <MenuItem onClick={onClick(onEditClick)}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Translate id="edit" />
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={onClick(onDeleteClick)}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Translate id="delete" />
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

export default EditDeleteRowMenu;

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type TranslateProps = {
    id: string | number;
    app?: string;
    defaultMessage?: string;
};

const Translate: React.FC<TranslateProps> = ({ app, id, defaultMessage }) => {
    const appName = app !== undefined ? `${app}_` : '';
    return <FormattedMessage id={`${appName}${id}`} defaultMessage={defaultMessage} />;
};

export default Translate;

import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { KeyCoordinatorsFilterState } from '../../hooks/use-key-coordinators-filter';

import Translate from 'components/extended/translate';
import { RegionResponseModelIEnumerableApiResponse } from 'services/webapi/generated/webapi-services';

type KeyCoordinatorsRegionOrBranchFilterProps = {
    filterValues: KeyCoordinatorsFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<KeyCoordinatorsFilterState>>;
    availableValues: RegionResponseModelIEnumerableApiResponse['data'];
};

const getBranchesPrefixFromSelectedValue = (
    selectedValue: string | number,
    availableValues: RegionResponseModelIEnumerableApiResponse['data'],
) => {
    if (isNaN(Number(selectedValue))) {
        return [String(selectedValue)];
    }
    return availableValues.find((elem) => elem.id === selectedValue)?.branches.map((_elem) => _elem.prefix);
};

const regionBranchResponseOverrides = (data?: RegionResponseModelIEnumerableApiResponse['data']) => {
    if (data) {
        const result: { id: number | string; name: string }[] = [];
        data.forEach((elem) => {
            result.push({ id: elem.id, name: elem.name });
            elem.branches.forEach((_elem) => {
                result.push({ id: _elem.prefix, name: _elem.name });
            });
        });
        return result;
    }
    return [];
};

const KeyCoordinatorsRegionOrBranchFilter: React.FC<KeyCoordinatorsRegionOrBranchFilterProps> = (props) => {
    const { filterValues, setFilterValues, availableValues } = props;

    const handleChange = React.useCallback(
        (value: any) => {
            setFilterValues({
                selectedValue: value.target.value,
                branches: getBranchesPrefixFromSelectedValue(value.target.value, availableValues),
            });
        },
        [availableValues, setFilterValues],
    );

    const availableValuesOverrides = React.useMemo(() => regionBranchResponseOverrides(availableValues), [
        availableValues,
    ]);

    return (
        <FormControl fullWidth size="small" sx={{ minWidth: 300 }}>
            <Select
                value={filterValues.selectedValue ? filterValues.selectedValue : ''}
                onChange={handleChange}
                displayEmpty
            >
                <MenuItem value="">
                    <Translate app={APP} id="all_branches_and_regions" />
                </MenuItem>
                {availableValuesOverrides.map((elem) => (
                    <MenuItem sx={{ paddingLeft: isNaN(Number(elem.id)) ? 4 : 2 }} key={elem.id} value={elem.id}>
                        {elem.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default KeyCoordinatorsRegionOrBranchFilter;

import { Locales as localesType } from 'types';

const globalLocales: localesType = {
    account_profile: {
        en: 'Account Profile',
        ru: 'Профиль',
    },
    profile: {
        en: 'Profile',
        ru: 'Профиль',
    },
    mail: {
        en: 'Mail',
        ru: 'Mail',
    },
    search: {
        en: 'Search',
        ru: 'Поиск',
    },
    calendar: {
        en: 'Calendar',
        ru: 'Календарь',
    },
    checkout: {
        en: 'Checkout',
        ru: 'Checkout',
    },
    date_time: {
        en: 'Date & Time',
        ru: 'Date & Time',
    },
    recaptcha: {
        en: 'reCaptcha',
        ru: 'reCaptcha',
    },
    pagination: {
        en: 'Pagination',
        ru: 'Pagination',
    },
    authentication: {
        en: 'Authentication',
        ru: 'Authentication',
    },
    login: {
        en: 'Login',
        ru: 'Войти',
    },
    logout: {
        en: 'Logout',
        ru: 'Выйти',
    },
    forgot_password: {
        en: 'Forgot password',
        ru: 'Забыли пароль?',
    },
    password: {
        en: 'Password',
        ru: 'Пароль',
    },
    old_password: {
        en: 'Current password',
        ru: 'Текущий пароль',
    },
    new_password: {
        en: 'New password',
        ru: 'Новый пароль',
    },
    email: {
        en: 'Email',
        ru: 'Электронная почта',
    },
    invalid_email: {
        en: 'Invalid email',
        ru: 'Некорректная электронная почта',
    },
    email_is_required: {
        en: 'Email is required',
        ru: 'Электронная почта обязательна',
    },
    password_is_required: {
        en: 'Password is required',
        ru: 'Пароль обязателен',
    },
    phone_is_required: {
        en: 'Phone is required',
        ru: 'Номер телефона обязателен',
    },
    phone_invalid_format: {
        en: 'Invalid phone format',
        ru: 'Неверный формат телефона',
    },
    send_verify_phone_code: {
        en: 'Send verification code',
        ru: 'Отправить код подтверждения',
    },
    send: {
        en: 'Send',
        ru: 'Отправить',
    },
    email_must_consist_of_255_characters: {
        en: 'The email must consist of a maximum of 255 characters',
        ru: 'Электронная почта должна состоять максимум из 255 символов',
    },
    password_must_consist_of255_characters: {
        en: 'The password must consist of a maximum of 255 characters',
        ru: 'Пароль должен состоять максимум из 255 символов',
    },
    code_verification_is_required: {
        en: 'Verification code is required',
        ru: 'Код подтверждения обязателен',
    },
    phone: {
        en: 'Phone',
        ru: 'Номер телефона',
    },
    code_verification_must_consist_of_6_digits: {
        en: 'Verification code must consist of 6 digits',
        ru: 'Код подтверждения должен состоять из 6 цифр',
    },
    reset_password: {
        en: 'Reset password',
        ru: 'Сбросить пароль',
    },
    reset_password_title: {
        en: 'Reset password',
        ru: 'Сброс пароля',
    },
    code_verification: {
        en: 'Verification code',
        ru: 'Код подтверждения',
    },
    resend_verification_code: {
        en: 'Resend verification code',
        ru: 'Отправить код повторно',
    },
    resend_verification_code_timeout: {
        en: 'Send code again in',
        ru: 'Отправить код повторно через',
    },
    error_404: {
        en: 'Error 404',
        ru: 'Error 404',
    },
    change: {
        en: 'Change language',
        ru: 'Сменить язык',
    },
    theme: {
        en: 'Theme',
        ru: 'Тема и оформление',
    },
    theme_light: {
        en: 'Light',
        ru: 'Светлая',
    },
    theme_dark: {
        en: 'Dark',
        ru: 'Тёмная',
    },
    error_something_is_wrong: {
        en: 'Something is wrong',
        ru: 'Что-то пошло не так',
    },
    error_repeat: {
        en: 'Try again',
        ru: 'Повторить',
    },
    error_description: {
        en: 'We are already working on this bug',
        ru: 'Мы уже работаем над данной ошибкой',
    },
    demo_apps: {
        en: 'Demo apps',
        ru: 'Демо приложения',
    },
    monitoring: {
        en: 'Monitoring',
        ru: 'Мониторинг',
    },
    epr: {
        en: 'EPR',
        ru: 'EPR',
    },
    login_data: {
        en: 'Login',
        ru: 'Введите учетные данные',
    },
    code_verification_sent: {
        en: 'Verification code sent to email and phone number',
        ru: 'Код подтверждения отправлен на email и номер телефона',
    },
    return_login_form: {
        en: 'Back',
        ru: 'Назад',
    },
    login_title: {
        en: 'Enter your credentials to continue',
        ru: 'Введите учетные данные',
    },
    second_factor_title: {
        en: 'Enter verification code',
        ru: 'Введите код подтверждения',
    },
    change_password_title: {
        en: 'Set a new password',
        ru: 'Установите новый пароль',
    },
    verify_phone_title: {
        en: 'Verify phone',
        ru: 'Подтвердите телефон',
    },
    save: {
        en: 'Save',
        ru: 'Сохранить',
    },
    all: {
        en: 'All',
        ru: 'Все',
    },
    before: {
        en: 'Before',
        ru: 'До',
    },
    with: {
        en: 'With',
        ru: 'С',
    },
    invalid_date: {
        en: 'Invalid Date',
        ru: 'Неверная дата',
    },
    end_date_less_start_date: {
        en: 'The end date cannot be less than the start date',
        ru: 'Конечная дата не может быть меньше начальной',
    },
    min_date: {
        en: 'The specified date is less than the allowed one',
        ru: 'Указана дата меньше допустимой',
    },
    start_date_more_end_date: {
        en: 'The start date cannot be greater than the end date',
        ru: 'Начальная дата не может быть больше конечной',
    },
    max_date: {
        en: 'The specified date is longer than the allowed one',
        ru: 'Указана дата больше допустимой',
    },
    filter: {
        en: 'Filter',
        ru: 'Фильтр',
    },
    reset: {
        en: 'Reset',
        ru: 'Сбросить',
    },
    apply: {
        en: 'Apply',
        ru: 'Применить',
    },
    close: {
        en: 'Close',
        ru: 'Закрыть',
    },
    save_filter: {
        en: 'Save filter',
        ru: 'Сохранить фильтр',
    },
    loading: {
        en: 'Loading',
        ru: 'Загрузка',
    },
    edit: {
        en: 'Edit',
        ru: 'Редактировать',
    },
    delete: {
        en: 'Delete',
        ru: 'Удалить',
    },
    positions: {
        en: 'Positions',
        ru: 'Позиции',
    },
    smart_queue: {
        en: 'Smart queue',
        ru: 'Умная очередь',
    },
    promotion_page: {
        en: 'Promotion page',
        ru: 'Презентация',
    },
    region: {
        en: 'Region',
        ru: 'Регион',
    },
    branch: {
        en: 'Branch',
        ru: 'Филиал',
    },
    planned_works: {
        en: 'Planned works',
        ru: 'Плановые работы',
    },
    open_original: {
        en: 'Open original',
        ru: 'Открыть оригинал',
    },
    required_field: {
        en: 'Required field',
        ru: 'Обязательное поле',
    },
    no_data: {
        en: 'No data',
        ru: 'Нет данных',
    },
};

export default globalLocales;

import * as React from 'react';
import ReduxLoadingBar from 'react-redux-loading-bar';

import useTheme from '@mui/material/styles/useTheme';

import CSS from 'csstype';

const LoadingBar: React.FC = () => {
    const theme = useTheme();

    const loadingBarStyle: CSS.Properties = {
        zIndex: 10000,
        position: 'fixed',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    };

    return <ReduxLoadingBar style={loadingBarStyle} />;
};

export default LoadingBar;

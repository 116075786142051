import * as React from 'react';

import { AppConfig } from '../../index';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const ActiveAlarms = Loadable(React.lazy(() => import('./active-alarms')));

const app: AppConfig = {
    name: APP,
    element: () => <ActiveAlarms />,
    locale,
};

export default app;

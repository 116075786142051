import * as React from 'react';

import EquipmentFilterDialog from '../views/filter/equipment-filter-dialog';
import EquipmentFilterToolbar from '../views/filter/equipment-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type EquipmentFilterState = {
    search: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
    vendors: FilterCheckBoxStateValue;
    equipmentTypes: FilterCheckBoxStateValue;
    positionNumber?: FilterSearchStateValue;
};

const useEquipmentFilter = (positionNumber?: string) => {
    const initialState: EquipmentFilterState = React.useMemo(
        (): EquipmentFilterState => ({
            search: null,
            positionNumber,
            branches: null,
            vendors: null,
            equipmentTypes: null,
        }),
        [positionNumber],
    );

    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof EquipmentFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof EquipmentFilterState>('search');

    return {
        EquipmentFilterDialog: () => (
            <EquipmentFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        EquipmentFilterToolbar: () => (
            <EquipmentFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
                variant={positionNumber ? 'position-view' : 'app'}
            />
        ),
        filterValues,
    };
};

export default useEquipmentFilter;

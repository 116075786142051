import * as React from 'react';

import { WorkTypesMatrixFilterState } from '../../hooks/use-work-types-matrix-filter';
import WorkTypesMatrixObjectFilterConnector from './work-types-matrix-object-filter-connector';

import FilterDisplayItemWrapper from 'components/extended/data-grid/filter/filter-display-item-wrapper';
import FilterDisplayWrapper from 'components/extended/data-grid/filter/filter-display-wrapper';

type WorkTypesMatrixFilterToolbarProps = {
    filterValues: WorkTypesMatrixFilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<WorkTypesMatrixFilterState>>;
};

const WorkTypesMatrixFilterToolbar: React.FC<WorkTypesMatrixFilterToolbarProps> = (props) => {
    const { filterValues, setFilterValues } = props;

    return (
        <FilterDisplayWrapper>
            <FilterDisplayItemWrapper>
                <WorkTypesMatrixObjectFilterConnector filterValues={filterValues} setFilterValues={setFilterValues} />
            </FilterDisplayItemWrapper>
        </FilterDisplayWrapper>
    );
};

export default WorkTypesMatrixFilterToolbar;

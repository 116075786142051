import * as React from 'react';

import TroubleTicketViewEditInformationForm from '../forms/trouble-ticket-view-edit-information-form';

import {
    useSmartQueueDescriptionChangeMutation,
    useSmartQueueTicketDescriptionGetQuery,
} from 'services/smart-queue/smart-queue-services';
import { ServiceResponseStatusEnum } from 'types';

type TroubleTicketViewEditInformationFormConnectorProps = {
    onClose: () => void;
    ttNumber: number;
};

export type TroubleTicketViewEditInformationFormInitialValues = {
    shortDescription: string;
    description: string;
    comment: string;
};

const TroubleTicketViewEditInformationFormConnector: React.FC<TroubleTicketViewEditInformationFormConnectorProps> = (
    props,
) => {
    const { onClose, ttNumber } = props;

    const [changeDescription, { isLoading, data: mutationResponse }] = useSmartQueueDescriptionChangeMutation();

    const { data } = useSmartQueueTicketDescriptionGetQuery(ttNumber);

    const initialValues: TroubleTicketViewEditInformationFormInitialValues = React.useMemo(
        () => ({
            shortDescription: data ? data.data.shortDescription : '',
            description: data ? data.data.description : '',
            comment: '',
        }),
        [data],
    );

    const handleEditInformation = React.useCallback(
        async (formData: TroubleTicketViewEditInformationFormInitialValues) => {
            const result = await changeDescription({
                number: ttNumber,
                shortDescription: formData.shortDescription,
                description: formData.description,
                comment: formData.comment,
            });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [changeDescription, onClose, ttNumber],
    );

    if (data === undefined) return null;

    return (
        <TroubleTicketViewEditInformationForm
            initialValues={initialValues}
            isLoading={isLoading}
            submitCallback={handleEditInformation}
            onClose={onClose}
            errors={mutationResponse?.errors}
        />
    );
};

export default TroubleTicketViewEditInformationFormConnector;

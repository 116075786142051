import * as React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { AvailableValue, ButtonGroupFilter, FilterButtonGroupStateValue } from './types';

type ButtonGroupFilterProps<FilterState> = {
    filterValues: FilterState;
    setFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
    buttonGroupFilters: ButtonGroupFilter<FilterState>[];
    sx?: BoxProps['sx'];
};

const ButtonGroupDisplayFilter = <FilterState,>(props: ButtonGroupFilterProps<FilterState>) => {
    const { filterValues, setFilterValues, buttonGroupFilters, sx } = props;

    const handleChange = React.useCallback(
        (field: keyof FilterState) => (event: React.MouseEvent<HTMLElement>, newAlignment: AvailableValue[]) => {
            setFilterValues((prevState) => ({ ...prevState, [field]: newAlignment }));
        },
        [setFilterValues],
    );

    return (
        <Box
            sx={{
                position: 'relative',
                display: {
                    xs: 'none',
                    md: 'flex',
                },
                flexWrap: 'wrap',
                alignItems: 'center',
                pt: 0,
                pb: 0.625,
                pl: 0,
                ...sx,
            }}
        >
            {buttonGroupFilters.map((elem) => (
                <ToggleButtonGroup
                    key={elem.id}
                    color="standard"
                    value={(filterValues[elem.field] as unknown) as FilterButtonGroupStateValue}
                    onChange={handleChange(elem.field)}
                    sx={{
                        pr: 0.625,
                        pl: 0,
                        [`& .${toggleButtonClasses.root}`]: {
                            height: 40,
                            py: 0.75,
                            px: 2,
                            mb: {
                                xs: 1,
                                sm: 1,
                                md: 0,
                            },
                        },
                    }}
                >
                    {elem.availableValues.map((_elem) => (
                        <ToggleButton key={_elem.id} value={_elem}>
                            {_elem.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            ))}
        </Box>
    );
};

export default ButtonGroupDisplayFilter;

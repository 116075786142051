import * as React from 'react';

import TroubleTicketViewDialog from '../../../trouble-ticket-view-dialog';
import TroubleTicketViewPossibleReasonsOplFormConnector from '../connectors/trouble-ticket-view-possible-reasons-opl-form-connector';

type TroubleTicketViewPossibleReasonsOplDialogProps = {
    open: boolean;
    onClose: () => void;
    oplNumber: number;
};

const TroubleTicketViewPossibleReasonsOplDialog: React.FC<TroubleTicketViewPossibleReasonsOplDialogProps> = (props) => {
    const { open, onClose, oplNumber } = props;

    return (
        <TroubleTicketViewDialog onClose={onClose} open={open} title={oplNumber} fullWidth maxWidth="sm">
            <TroubleTicketViewPossibleReasonsOplFormConnector onClose={onClose} />
        </TroubleTicketViewDialog>
    );
};

export default TroubleTicketViewPossibleReasonsOplDialog;

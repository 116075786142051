import * as React from 'react';

import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import NavItem from '../nav-item';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import Translate from 'components/extended/translate';
import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';

type NavCollapseProps = {
    menu: MenuItemResponseModel;
    level: number;
};

const NavCollapse: React.FC<NavCollapseProps> = (props) => {
    const { menu, level } = props;
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const handleClick = React.useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    // menu collapse & item
    const menus = menu.childs?.map((item) =>
        item.childs.length > 0 ? (
            <NavCollapse key={item.id} menu={item} level={level + 1} />
        ) : (
            <NavItem key={item.id} item={item} level={level + 1} />
        ),
    );

    const menuIcon: React.ReactNode = (
        <Icon
            style={{
                fontSize: '1rem',
            }}
        >
            {menu.icon ?? 'folder'}
        </Icon>
    );

    return menu.isVisible ? (
        <>
            <ListItemButton
                sx={{
                    borderRadius: 0,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${16 + (level - 1) * 24}px`,
                }}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: 30 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body1" color="inherit" sx={{ my: 'auto' }}>
                            <Translate id={menu.name} defaultMessage={menu.name} />
                        </Typography>
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '24px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light,
                        },
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    ) : null;
};

export default NavCollapse;

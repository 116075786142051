import { appsLocales } from '../sub-apps';
import globalLocales from './global-locales';

import { Locales } from 'types';

const allLocales: Locales = {
    ...globalLocales,
    ...appsLocales,
};

export default allLocales;

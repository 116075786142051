import * as React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import FallbackErrorCard from 'components/cards/fallback-error-card';
import { useSendLogMutation } from 'services/fluentd/generated/fluentd-services';
import logger from 'utils/logger';
import { v4 as uuid } from 'uuid';

const ErrorBoundary: React.FC = (props) => {
    const { children } = props;
    const errorUuid = uuid();

    const [sendLog] = useSendLogMutation();

    const myErrorHandler = React.useCallback(
        async (error: any, info: { componentStack: string }) => {
            if (process.env.NODE_ENV !== 'development') {
                const logData = logger.error({
                    id: errorUuid,
                    error: {
                        name: error.name,
                        message: error.message,
                    },
                    stack: info.componentStack,
                });

                await sendLog({ frontLogRequestModel: logData });
            }
        },
        [errorUuid, sendLog],
    );

    const fallback = React.useCallback(
        ({ error, resetErrorBoundary }) => (
            <FallbackErrorCard errorId={errorUuid} error={error} resetErrorBoundary={resetErrorBoundary} />
        ),
        [errorUuid],
    );

    return (
        <ReactErrorBoundary FallbackComponent={fallback} onError={myErrorHandler}>
            {children}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;

import { SERVICES_URL } from '../constants';

export const WEBAPI_SERVICE_URL = `/${SERVICES_URL}/webapi`;
export const AUTH_SERVICE_URL = `/${SERVICES_URL}/auth`;
export const FLUENTD_SERVICE_URL = `/${SERVICES_URL}/fluentd`;
export const POSITIONS_SERVICE_URL = `/${SERVICES_URL}/positions`;
export const MONITORING_SERVICE_URL = `/${SERVICES_URL}/monitoring`;
export const SMART_QUEUE_SERVICE_URL = `/${SERVICES_URL}/smartQueue`;
export const FEEDBACK_SERVICE_URL = `/${SERVICES_URL}/feedback`;
export const FILE_SERVICE_URL = `/${SERVICES_URL}/file`;
export const TOPOLOGY_SERVICE_URL = `/${SERVICES_URL}/topology`;
export const NEWS_SERVICE_URL = `/${SERVICES_URL}/news`;

import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Event',
        ru: 'Событие',
    },
    [`${APP}_alarms`]: {
        en: 'Alarms',
        ru: 'Аварии',
    },
    [`${APP}_comments`]: {
        en: 'Comments',
        ru: 'Комментарии',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_elements`]: {
        en: 'Elements',
        ru: 'Элементы',
    },
    [`${APP}_history`]: {
        en: 'History',
        ru: 'История',
    },
    [`${APP}_statistic`]: {
        en: 'Statistic',
        ru: 'Статистика',
    },
    [`${APP}_close`]: {
        en: 'Close',
        ru: 'Закрыть',
    },
    [`${APP}_change`]: {
        en: 'Change',
        ru: 'Изменить',
    },
    [`${APP}_open`]: {
        en: 'Open',
        ru: 'Открыт',
    },
    [`${APP}_closed`]: {
        en: 'Closed',
        ru: 'Закрыт',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_responsible`]: {
        en: 'Responsible',
        ru: 'Ответственный',
    },
    [`${APP}_cause`]: {
        en: 'Cause',
        ru: 'Причина',
    },
    [`${APP}_user_action`]: {
        en: 'Action',
        ru: 'Действие',
    },
    [`${APP}_user`]: {
        en: 'User',
        ru: 'Пользователь',
    },
    [`${APP}_old_value`]: {
        en: 'Old value',
        ru: 'Старое значение',
    },
    [`${APP}_new_value`]: {
        en: 'New value',
        ru: 'Новое значение',
    },
    [`${APP}_date_open`]: {
        en: 'Date open',
        ru: 'Дата',
    },
    [`${APP}_number`]: {
        en: 'Number',
        ru: 'Номер',
    },
    [`${APP}_name`]: {
        en: 'Name',
        ru: 'Название',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
    [`${APP}_position`]: {
        en: 'Position',
        ru: 'Позиция',
    },
    [`${APP}_active_count`]: {
        en: 'Percentage of accident activity per event',
        ru: 'Процент активности аварий в событие',
    },
    [`${APP}_restore_count`]: {
        en: 'Number of repetitions',
        ru: 'Количество повторений',
    },
    [`${APP}_not_active_count`]: {
        en: 'Time of inactivity since the last elimination',
        ru: 'Время неактивности после последнего устранения',
    },
    [`${APP}_priority`]: {
        en: 'Priority',
        ru: 'Приоритет',
    },
};

export default locale;

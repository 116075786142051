import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'EPR',
        ru: 'EPR',
    },
    [`${APP}_full_name`]: {
        en: 'Full name',
        ru: 'ФИО',
    },
    [`${APP}_team`]: {
        en: 'Team',
        ru: 'Группа',
    },
    [`${APP}_q1`]: {
        en: 'Q1',
        ru: 'Q1',
    },
    [`${APP}_q2`]: {
        en: 'Q2',
        ru: 'Q2',
    },
    [`${APP}_q3`]: {
        en: 'Q3',
        ru: 'Q3',
    },
    [`${APP}_q4`]: {
        en: 'Q4',
        ru: 'Q4',
    },
    [`${APP}_direction`]: {
        en: 'Direction',
        ru: 'Функция',
    },
    [`${APP}_group`]: {
        en: 'Group',
        ru: 'Группа',
    },

    [`${APP}_fo_1c_assign`]: {
        en: 'For1cAssign',
        ru: 'For1cAssign',
    },
    [`${APP}_fo_1c_create`]: {
        en: 'For1cCreate',
        ru: 'For1cCreate',
    },
    [`${APP}_fo_1c_execute`]: {
        en: 'Fo1cExecute',
        ru: 'Fo1cExecute',
    },
    [`${APP}_tt_assign`]: {
        en: 'TTAssign',
        ru: 'TTAssign',
    },
    [`${APP}_tt_restore`]: {
        en: 'TTRestore',
        ru: 'TTRestore',
    },
    [`${APP}_tt_close`]: {
        en: 'TTClose',
        ru: 'TTClose',
    },
    [`${APP}_tt_create`]: {
        en: 'TTCreate',
        ru: 'TTCreate',
    },
    [`${APP}_tt_bind`]: {
        en: 'TTBind',
        ru: 'TTBind',
    },
    [`${APP}_tt_comment`]: {
        en: 'TTComment',
        ru: 'TTComment',
    },
    [`${APP}_tt`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'PW',
    },
    [`${APP}_pw_so`]: {
        en: 'PW SO',
        ru: 'PW SO',
    },
    [`${APP}_pw_visa`]: {
        en: 'PW Visa',
        ru: 'PW Visa',
    },
    [`${APP}_pw_so_ex`]: {
        en: 'PW SO EX',
        ru: 'PW SO EX',
    },
    [`${APP}_msup_create`]: {
        en: 'MSUP Create',
        ru: 'MSUP Create',
    },
    [`${APP}_msup_cancel`]: {
        en: 'MSUP Cancel',
        ru: 'MSUP Cancel',
    },
    [`${APP}_msup_close`]: {
        en: 'MSUP Close',
        ru: 'MSUP Close',
    },
    [`${APP}_work_hours`]: {
        en: 'Work hours',
        ru: 'Рабочие часы',
    },
    [`${APP}_bonus`]: {
        en: 'Bonus',
        ru: 'Бонус',
    },
    [`${APP}_total`]: {
        en: 'Total',
        ru: 'Итого',
    },
};

export default locale;

import * as React from 'react';

import EquipmentTable from './equipment-table';

import MainCard from 'components/cards/main-card';

type EquipmentTableContainerProps = {
    positionNumber?: string;
};

const EquipmentTableContainer: React.FC<EquipmentTableContainerProps> = (props) => {
    const { positionNumber } = props;

    return positionNumber ? (
        <MainCard sx={{ width: '100%', position: 'relative' }}>
            <EquipmentTable positionNumber={positionNumber} />
        </MainCard>
    ) : (
        <EquipmentTable />
    );
};

export default EquipmentTableContainer;

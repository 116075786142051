import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import Translate from '../../translate';
import { FilterSwitchStateValue, SwitchLabelsValues } from './types';

type FilterSwitchProps<FilterState> = {
    field: keyof FilterState;
    value: FilterSwitchStateValue;
    label: string | JSX.Element;
    labelsValues: SwitchLabelsValues;
    setLocalFilterValues: React.Dispatch<React.SetStateAction<FilterState>>;
    app: string;
    disableValueLabel?: boolean;
};

const FilterSwitch = <FilterState,>(props: FilterSwitchProps<FilterState>) => {
    const { field, value, setLocalFilterValues, label, labelsValues, disableValueLabel = false, app } = props;

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLocalFilterValues((prevState) => ({
                ...prevState,
                [field]: { id: event.target.checked, name: labelsValues[`${event.target.checked}`] },
            }));
        },
        [field, labelsValues, setLocalFilterValues],
    );
    // TODO кажется что компоненты фильтра нужно переделать, решение на скорую руку
    return (
        <Accordion>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minHeight: 60,
                    paddingLeft: 2,
                }}
            >
                <Box
                    sx={{
                        pl: 0,
                    }}
                >
                    <Typography>{label}</Typography>
                    {!disableValueLabel ? (
                        <Typography>
                            <Translate app={app} id={value.name} />
                        </Typography>
                    ) : null}
                </Box>
                <Switch checked={value.id} onChange={onChange} color="primary" />
            </Box>
        </Accordion>
    );
};

export default FilterSwitch;

import * as React from 'react';

import SharingPositionsFilterDialog from '../views/filter/sharing-positions-filter-dialog';
import SharingPositionsFilterToolbar from '../views/filter/sharing-positions-filter-toolbar';

import { FilterCheckBoxStateValue, FilterSearchStateValue } from 'components/extended/data-grid/filter/types';
import { deepCopy } from 'utils/deep-copy';

export type SharingPositionsFilterState = {
    search: FilterSearchStateValue;
    branches: FilterCheckBoxStateValue;
    owners: FilterCheckBoxStateValue;
    ranges: FilterCheckBoxStateValue;
    positionNumber?: FilterSearchStateValue;
};

const useSharingPositionsFilter = (positionNumber?: string) => {
    const initialState: SharingPositionsFilterState = React.useMemo(
        (): SharingPositionsFilterState => ({
            search: null,
            positionNumber,
            branches: null,
            owners: null,
            ranges: null,
        }),
        [positionNumber],
    );

    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));
    const [open, setOpen] = React.useState(false);
    const [expandedField, setExpandedField] = React.useState<keyof SharingPositionsFilterState>();
    const [currentSearchField, setCurrentSearchField] = React.useState<keyof SharingPositionsFilterState>('search');

    return {
        SharingPositionsFilterDialog: () => (
            <SharingPositionsFilterDialog
                open={open}
                setOpen={setOpen}
                expandedField={expandedField}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                initialState={initialState}
                setExpandedField={setExpandedField}
            />
        ),
        SharingPositionsFilterToolbar: () => (
            <SharingPositionsFilterToolbar
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setOpen={setOpen}
                currentSearchField={currentSearchField}
                setCurrentSearchField={setCurrentSearchField}
                setExpandedField={setExpandedField}
                variant={positionNumber ? 'position-view' : 'app'}
            />
        ),
        filterValues,
    };
};

export default useSharingPositionsFilter;

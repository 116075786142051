import { FILE_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import {
    FileBaseFileResponseModelApiResponse,
    FileFileResponseModelApiResponse,
    FileImageResizeRequestModel,
    FileStringApiResponse,
} from './file-models';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const fileServices = createApi({
    reducerPath: 'fileServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: FILE_SERVICE_URL,
    }),
    endpoints: (build) => ({
        uploadImage: build.mutation<FileBaseFileResponseModelApiResponse, FormData>({
            query: (queryArg) => ({ url: `/api/file/Image`, method: 'POST', body: queryArg }),
        }),
        deleteImage: build.mutation<FileStringApiResponse, { id: number; formData: FormData }>({
            query: (queryArg) => ({ url: `/api/file/Image/${queryArg.id}`, method: 'DELETE', body: queryArg.formData }),
        }),
        getImage: build.query<FileFileResponseModelApiResponse, FileImageResizeRequestModel>({
            query: (queryArg) => ({
                url: `/api/file/Image/ResizeAndGetImage`,
                method: 'POST',
                body: queryArg,
            }),
        }),
    }),
});

export const { useUploadImageMutation, useDeleteImageMutation, useGetImageQuery } = fileServices;

import * as React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';

import RowMenuItem from './row-menu-item';

const RowMenuContext = React.createContext<{ closeMenu: () => void } | null>(null);

type RowMenuExtensions = {
    Item: typeof RowMenuItem;
};

export const useRowMenu = () => {
    const context = React.useContext(RowMenuContext);

    if (!context) {
        throw new Error('<RowMenuItem> component must be used within a <RowMenu> component.');
    }

    return context;
};

const RowMenu: React.FC & RowMenuExtensions = (props) => {
    const { children } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuOpen = Boolean(anchorEl);

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <RowMenuContext.Provider value={{ closeMenu }}>
            <IconButton size="small" onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={menuOpen} onClose={closeMenu}>
                <MenuList dense sx={{ py: 0 }}>
                    {children}
                </MenuList>
            </Menu>
        </RowMenuContext.Provider>
    );
};

RowMenu.Item = RowMenuItem;

export default RowMenu;

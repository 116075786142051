import { commonWebapiServices as api } from '../common-webapi-services';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApiApplication: build.query<GetApiApplicationApiResponse, GetApiApplicationApiArg>({
            query: () => ({ url: `/api/Application` }),
        }),
        post: build.mutation<PostApiResponse, PostApiArg>({
            query: (queryArg) => ({
                url: `/api/Application`,
                method: 'POST',
                body: queryArg.applicationCreateRequestModel,
            }),
        }),
        put: build.mutation<PutApiResponse, PutApiArg>({
            query: (queryArg) => ({
                url: `/api/Application`,
                method: 'PUT',
                body: queryArg.applicationUpdateRequestModel,
            }),
        }),
        deleteApiApplicationById: build.mutation<DeleteApiApplicationByIdApiResponse, DeleteApiApplicationByIdApiArg>({
            query: (queryArg) => ({ url: `/api/Application/${queryArg.id}`, method: 'DELETE' }),
        }),
        getApiApplicationInfo: build.query<GetApiApplicationInfoApiResponse, GetApiApplicationInfoApiArg>({
            query: () => ({ url: `/api/ApplicationInfo` }),
        }),
        getRegionList: build.query<GetRegionListApiResponse, GetRegionListApiArg>({
            query: () => ({ url: `/api/Dictionary/Region/GetList` }),
        }),
        getBranchList: build.query<GetBranchListApiResponse, GetBranchListApiArg>({
            query: () => ({ url: `/api/Dictionary/Branch/GetList` }),
        }),
        getApiMe: build.query<GetApiMeApiResponse, GetApiMeApiArg>({
            query: () => ({ url: `/api/Me` }),
        }),
        setDefaultMenu: build.mutation<SetDefaultMenuApiResponse, SetDefaultMenuApiArg>({
            query: (queryArg) => ({
                url: `/api/Me/SetDefaultMenu`,
                method: 'POST',
                body: queryArg.setDefaultMenuRequestModel,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as webapiServices };
export type GetApiApplicationApiResponse = /** status 200 Success */ ApplicationResponseModelIEnumerableApiResponse;
export type GetApiApplicationApiArg = void;
export type PostApiResponse = /** status 200 Success */ ApplicationResponseModelApiResponse;
export type PostApiArg = {
    applicationCreateRequestModel: ApplicationCreateRequestModel;
};
export type PutApiResponse = /** status 200 Success */ ApplicationResponseModelApiResponse;
export type PutApiArg = {
    applicationUpdateRequestModel: ApplicationUpdateRequestModel;
};
export type DeleteApiApplicationByIdApiResponse = /** status 200 Success */ StringApiResponse;
export type DeleteApiApplicationByIdApiArg = {
    id: number;
};
export type GetApiApplicationInfoApiResponse = /** status 200 Success */ ApplicationInfoResponseModelApiResponse;
export type GetApiApplicationInfoApiArg = void;
export type GetRegionListApiResponse = /** status 200 Success */ RegionResponseModelIEnumerableApiResponse;
export type GetRegionListApiArg = void;
export type GetBranchListApiResponse = /** status 200 Success */ BranchWithRegionResponseModelIEnumerableApiResponse;
export type GetBranchListApiArg = void;
export type GetApiMeApiResponse = /** status 200 Success */ CurrentAccountResponseModelApiResponse;
export type GetApiMeApiArg = void;
export type SetDefaultMenuApiResponse = /** status 200 Success */ StringApiResponse;
export type SetDefaultMenuApiArg = {
    setDefaultMenuRequestModel: SetDefaultMenuRequestModel;
};
export type Statuses = 'Success' | 'Error';
export type ApplicationResponseModel = {
    id: number;
    name: string;
};
export type ApplicationResponseModelIEnumerableApiResponse = {
    status: Statuses;
    data: ApplicationResponseModel[];
    errors: string[];
    total: number;
};
export type ApplicationResponseModelApiResponse = {
    status: Statuses;
    data: ApplicationResponseModel;
    errors: string[];
    total: number;
};
export type ApplicationCreateRequestModel = {
    name: string;
};
export type ApplicationUpdateRequestModel = {
    name: string;
    id: number;
};
export type StringApiResponse = {
    status: Statuses;
    data: string;
    errors: string[];
    total: number;
};
export type ApplicationInfoResponseModel = {
    name: string;
    version: string;
};
export type ApplicationInfoResponseModelApiResponse = {
    status: Statuses;
    data: ApplicationInfoResponseModel;
    errors: string[];
    total: number;
};
export type BranchResponseModel = {
    id: number;
    name: string;
    prefix: string;
};
export type RegionResponseModel = {
    id: number;
    name: string;
    branches: BranchResponseModel[];
};
export type RegionResponseModelIEnumerableApiResponse = {
    status: Statuses;
    data: RegionResponseModel[];
    errors: string[];
    total: number;
};
export type BranchWithRegionResponseModel = {
    id: number;
    name: string;
    prefix: string;
    region: string;
};
export type BranchWithRegionResponseModelIEnumerableApiResponse = {
    status: Statuses;
    data: BranchWithRegionResponseModel[];
    errors: string[];
    total: number;
};
export type MenuItemResponseModel = {
    id: number;
    name: string;
    shortName: string;
    icon: string;
    path: string;
    position: number;
    isUnique: boolean;
    isExternal: boolean;
    isClosable: boolean;
    isDefault: boolean;
    isVisible: boolean;
    application: string;
    childs: MenuItemResponseModel[];
};
export type RoleResponseModel = {
    id: number;
    name: string;
};
export type CurrentAccountResponseModel = {
    id: number;
    username: string;
    firstName: string;
    secondName: string;
    lastName: string;
    phone: string;
    menu: MenuItemResponseModel[];
    roles: RoleResponseModel[];
};
export type CurrentAccountResponseModelApiResponse = {
    status: Statuses;
    data: CurrentAccountResponseModel;
    errors: string[];
    total: number;
};
export type SetDefaultMenuRequestModel = {
    menuId?: number;
};
export const {
    useGetApiApplicationQuery,
    usePostMutation,
    usePutMutation,
    useDeleteApiApplicationByIdMutation,
    useGetApiApplicationInfoQuery,
    useGetRegionListQuery,
    useGetBranchListQuery,
    useGetApiMeQuery,
    useSetDefaultMenuMutation,
} = injectedRtkApi;

import { Locales } from '../../../types';
import { APP } from '../constants';

const locale: Locales = {
    [`${APP}_error_boundary`]: {
        en: 'Error boundary',
        ru: 'Перехватчик ошибки',
    },
    [`${APP}_toggle_local_error`]: {
        en: 'Toggle local error',
        ru: 'Имитировать локальную ошибку',
    },
    [`${APP}_toggle_global_error`]: {
        en: 'Toggle global error',
        ru: 'Имитировать глобальную ошибку',
    },
};

export default locale;

import * as React from 'react';

import SharingPositionsTable from './sharing-positions-table';

import MainCard from 'components/cards/main-card';

type SharingPositionsTableContainerProps = {
    positionNumber?: string;
};

const SharingPositionsTableContainer: React.FC<SharingPositionsTableContainerProps> = (props) => {
    const { positionNumber } = props;

    return positionNumber ? (
        <MainCard sx={{ width: '100%', position: 'relative' }}>
            <SharingPositionsTable positionNumber={positionNumber} />
        </MainCard>
    ) : (
        <SharingPositionsTable />
    );
};

export default SharingPositionsTableContainer;

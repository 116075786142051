import * as React from 'react';

import { AppConfig } from '..';
import { APP } from './constants';
import locale from './locales/locale';

import Loadable from 'components/loadable';

const FeedbacksViews = Loadable(React.lazy(() => import('./views/feedback-list-page/feedback-list-page-connector')));

const feedbackApp: AppConfig = {
    name: APP,
    element: () => <FeedbacksViews />,
    locale,
};

export default feedbackApp;

import * as React from 'react';

import { PaletteMode } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import useTheme from '@mui/material/styles/useTheme';

import PresetColor from './preset-color';

import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';
import colors from 'assets/scss/_themes-vars.module.scss';
import Translate from 'components/extended/translate';
import useAppDispatch from 'hooks/use-app-dispatch';
import useAppSelector from 'hooks/use-app-selector';
import customizationSlice from 'store/customization';

const light: PaletteMode = 'light';
const dark: PaletteMode = 'dark';

const ThemeCard: React.FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const customization = useAppSelector(customizationSlice.selectors.customizationSelector);

    const colorOptions = [
        {
            id: 'default',
            primary: theme.palette.mode === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
            secondary: theme.palette.mode === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
        },
        {
            id: 'theme1',
            primary: theme.palette.mode === 'dark' ? theme1.darkPrimaryMain : theme1.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme1.darkSecondaryMain : theme1.secondaryMain,
        },
        {
            id: 'theme2',
            primary: theme.palette.mode === 'dark' ? theme2.darkPrimaryMain : theme2.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme2.darkSecondaryMain : theme2.secondaryMain,
        },
        {
            id: 'theme5',
            primary: theme.palette.mode === 'dark' ? theme5.darkPrimaryMain : theme5.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme5.darkSecondaryMain : theme5.secondaryMain,
        },
        {
            id: 'theme6',
            primary: theme.palette.mode === 'dark' ? theme6.darkPrimaryMain : theme6.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme6.darkSecondaryMain : theme6.secondaryMain,
        },
    ];

    const onChangeThemeMode = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
            const val: PaletteMode = value === 'light' ? 'light' : 'dark';
            dispatch(customizationSlice.actions.setThemeMode(val));
        },
        [dispatch],
    );

    const onPresetColor = React.useCallback(
        (presetColor: string) => dispatch(customizationSlice.actions.presetColors(presetColor)),
        [dispatch],
    );

    return (
        <Card
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
                mb: 2,
            }}
        >
            <CardContent
                sx={{
                    p: 2,
                    '&:last-child': {
                        pb: 2,
                    },
                }}
            >
                <Grid container direction="column">
                    <Grid item>
                        <FormControl component="fieldset">
                            <RadioGroup row value={customization.themeMode} onChange={onChangeThemeMode}>
                                <FormControlLabel
                                    value={light}
                                    control={<Radio size="small" sx={{ p: 0.5 }} />}
                                    label={<Translate id="theme_light" />}
                                    sx={{
                                        ml: -0.5,
                                        '& .MuiFormControlLabel-label': {
                                            color: theme.palette.grey[900],
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value={dark}
                                    control={<Radio size="small" sx={{ p: 0.5 }} />}
                                    label={<Translate id="theme_dark" />}
                                    sx={{
                                        ml: -0.5,
                                        '& .MuiFormControlLabel-label': {
                                            color: theme.palette.grey[900],
                                        },
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ mt: 1.5 }}>
                        <Grid container alignItems="left">
                            {colorOptions.map((color) => (
                                <PresetColor
                                    key={color.id}
                                    color={color}
                                    presetColor={customization.presetColor}
                                    setPresetColor={onPresetColor}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ThemeCard;

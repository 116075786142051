import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Events by type',
        ru: 'События по типам',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions/Branches',
        ru: 'Регионы/Филиалы',
    },
    [`${APP}_site_id`]: {
        en: 'Site ID',
        ru: '№ БС',
    },
    [`${APP}_event`]: {
        en: 'Event',
        ru: 'Событие',
    },
    [`${APP}_site_name`]: {
        en: 'Site name',
        ru: 'Название',
    },
    [`${APP}_address`]: {
        en: 'Address',
        ru: 'Адрес',
    },
    [`${APP}_bs_type`]: {
        en: 'BS type',
        ru: 'Тип БС',
    },
    [`${APP}_date`]: {
        en: 'Date of creation',
        ru: 'Дата создания события',
    },
    [`${APP}_tt_type_name`]: {
        en: 'Event type',
        ru: 'Тип события',
    },
    [`${APP}_rsite`]: {
        en: 'Rsite',
        ru: 'Rsite',
    },
    [`${APP}_remedy`]: {
        en: 'TT',
        ru: 'TT',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_role`]: {
        en: 'Role',
        ru: 'Роль',
    },
    [`${APP}_per_active_count`]: {
        en: 'Percentage of accident activity in the event',
        ru: 'Процент активности аварии в событии',
    },
    [`${APP}_not_active_count`]: {
        en: 'Crash inactivity since last restore',
        ru: 'Неактивность аварии после последнего восстановления',
    },
    [`${APP}_restore_count`]: {
        en: 'Number of occurrences of an accident in an event',
        ru: 'Количество повторений аварии в событии',
    },
    [`${APP}_region`]: {
        en: 'Region',
        ru: 'Регион',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_state`]: {
        en: 'Condition',
        ru: 'Состояние',
    },
    [`${APP}_position_count`]: {
        en: 'Position Count',
        ru: 'Количество БС',
    },
    [`${APP}_plan`]: {
        en: 'Existence of an incident',
        ru: 'Наличие инцидента',
    },
    [`${APP}_ticket`]: {
        en: 'Existence of an ticket',
        ru: 'Наличие плановой раоты',
    },
    [`${APP}_search`]: {
        en: 'Search',
        ru: 'Поиск',
    },
    [`${APP}_position_type`]: {
        en: 'Position type',
        ru: 'Тип объекта',
    },
    [`${APP}_event_type`]: {
        en: 'Event type',
        ru: 'Тип события',
    },
};

export default locale;

import * as React from 'react';

import AccessesForm from '../forms/accesses-form';

import { IOKO } from 'constants/sub-apps';
import { PositionsAccessCreateRequestModel } from 'services/positions/positions-models';
import { usePositionsAccessAddMutation } from 'services/positions/positions-services';
import { ServiceResponseStatusEnum } from 'types';

export type AccessesFormInitialValues = {
    comment: PositionsAccessCreateRequestModel['comment'];
    phone: PositionsAccessCreateRequestModel['phone'];
};

type AccessesFormConnectorProps = {
    onClose: () => void;
    positionNumber: string;
};

const initialValues: AccessesFormInitialValues = {
    comment: '',
    phone: '',
};

const AccessesFormAddConnector: React.FC<AccessesFormConnectorProps> = (props) => {
    const { onClose, positionNumber } = props;

    const [addAccess, { isLoading, data }] = usePositionsAccessAddMutation();

    const onSubmitAddAccess = React.useCallback(
        async (formValues: AccessesFormInitialValues) => {
            const result = await addAccess({ ...formValues, position: positionNumber, systemId: IOKO });
            if ('data' in result) {
                if (result.data.status === ServiceResponseStatusEnum.Success) {
                    onClose();
                }
            }
        },
        [addAccess, onClose, positionNumber],
    );

    return (
        <AccessesForm
            onClose={onClose}
            submitCallback={onSubmitAddAccess}
            initialValues={initialValues}
            isLoading={isLoading}
            errors={data?.errors}
        />
    );
};

export default AccessesFormAddConnector;

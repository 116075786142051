import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GRID_SPACING } from '../../constants';

import Translate from 'components/extended/translate';

type FallbackErrorCardProps = {
    error: Error | null;
    errorId?: string;
    resetErrorBoundary?: (...args: Array<unknown>) => void;
};

const FallbackErrorCard: React.FC<FallbackErrorCardProps> = (props) => {
    const { error, resetErrorBoundary, errorId } = props;

    return (
        <Card
            sx={{
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CardContent>
                <Grid container justifyContent="center" spacing={GRID_SPACING}>
                    <Grid item xs={12}>
                        <Box sx={{ maxWidth: 350, margin: '0 auto', textAlign: 'center' }}>
                            <Grid container spacing={GRID_SPACING}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" component="div">
                                        <Translate id="error_something_is_wrong" />
                                    </Typography>
                                </Grid>
                                {errorId && (
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {errorId}
                                        </Typography>
                                    </Grid>
                                )}
                                {error?.message && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{error.message}</Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Translate id="error_description" />
                                    </Typography>
                                </Grid>
                                {resetErrorBoundary && (
                                    <Grid item xs={12}>
                                        <Button variant="contained" size="large" onClick={resetErrorBoundary}>
                                            <Translate id="error_repeat" />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FallbackErrorCard;

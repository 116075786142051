import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'app';
import 'assets/scss/style.scss';
import config from 'config';
import 'material-icons/iconfont/material-icons.css';
import { PersistGate } from 'redux-persist/integration/react';
import 'services/mocks';
import { persistentStore, store } from 'store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistentStore}>
                <BrowserRouter basename={config.basename}>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

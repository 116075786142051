import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Electricity Suppliers',
        ru: 'Поставщики электроэнергии',
    },
    [`${APP}_edit_electricity_supplier`]: {
        en: 'Edit electricity supplier',
        ru: 'Редактировать поставщика электроэнергии',
    },
    [`${APP}_add_electricity_supplier`]: {
        en: 'Add electricity supplier',
        ru: 'Добавить поставщика электроэнергии',
    },
    [`${APP}_title_is_required`]: {
        en: 'Title is required',
        ru: 'Название обязательно',
    },
    [`${APP}_contacts_is_required`]: {
        en: 'Contacts is required',
        ru: 'Контакты обязательны',
    },
    [`${APP}_title`]: {
        en: 'Title',
        ru: 'Название',
    },
    [`${APP}_contacts`]: {
        en: 'Contacts',
        ru: 'Контакты',
    },
    [`${APP}_cancel`]: {
        en: 'Cancel',
        ru: 'Отмена',
    },
    [`${APP}_submit`]: {
        en: 'Submit',
        ru: 'Отправить',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_no_data`]: {
        en: 'No data',
        ru: 'Нет данных',
    },
    [`${APP}_electricity_suppliers`]: {
        en: 'Electricity Suppliers',
        ru: 'Поставщики электроэнергии',
    },
    [`${APP}_created_time`]: {
        en: 'Created time',
        ru: 'Время создания',
    },
    [`${APP}_created`]: {
        en: 'Created',
        ru: 'Создал',
    },
    [`${APP}_you_want_delete_record`]: {
        en: 'Do you really want to delete the record?',
        ru: 'Вы действительно хотите удалить запись?',
    },
    [`${APP}_delete`]: {
        en: 'Delete',
        ru: 'Удалить',
    },
};

export default locale;

import { SMART_QUEUE_SERVICE_URL } from '../constants';
import { fetchBaseQueryProtected } from '../fetch-base-query-protected';
import {
    SmartQueueActivityStatusResponseModelIEnumerableApiResponse,
    SmartQueueImportanceStatusResponseModelIEnumerableApiResponse,
    SmartQueueOrderResponseModelIEnumerableApiResponse,
    SmartQueuePositionsResponseModelIEnumerableApiResponse,
    SmartQueuePriorityResponseModelIEnumerableApiResponse,
    SmartQueueRoleAdditionalGroupResponseModelIEnumerableApiResponse,
    SmartQueueRoleClassificationResponseModelIEnumerableApiResponse,
    SmartQueueRoleFilterValueRequestModelFilterRequestModel,
    SmartQueueRoleGroupResponseModelIEnumerableApiResponse,
    SmartQueueRoleResponseModelIEnumerableApiResponse,
    SmartQueueRoleTypeResponseModelIEnumerableApiResponse,
    SmartQueueServiceStatusResponseModelIEnumerableApiResponse,
    SmartQueueSolutionStatusResponseModelIEnumerableApiResponse,
    SmartQueueTicketAutoStopResponseModelApiResponse,
    SmartQueueTicketBindChangeRequestModel,
    SmartQueueTicketChildResponseModelIEnumerableApiResponse,
    SmartQueueTicketCommentAddRequestModel,
    SmartQueueTicketCommentResponseModelApiResponse,
    SmartQueueTicketCommentResponseModelIEnumerableApiResponse,
    SmartQueueTicketDescriptionChangeRequestModel,
    SmartQueueTicketDescriptionResponseModelApiResponse,
    SmartQueueTicketFilterValueRequestModelFilterRequestModel,
    SmartQueueTicketHistoryResponseModelIEnumerableApiResponse,
    SmartQueueTicketHistoryRoleResponseModelIEnumerableApiResponse,
    SmartQueueTicketInfoResponseModelApiResponse,
    SmartQueueTicketPossibleReasonTicketResponseModelIEnumerableApiResponse,
    SmartQueueTicketPriorityChangeRequestModel,
    SmartQueueTicketResponseModelApiResponse,
    SmartQueueTicketResponseModelIEnumerableApiResponse,
    SmartQueueTicketResponsibleChangeRequestModel,
    SmartQueueTicketResponsibleResponseModelApiResponse,
    SmartQueueTicketStatusChangeRequestModel,
    SmartQueueTicketTimeResponseModelApiResponse,
    SmartQueueTicketTypeResponseModelIEnumerableApiResponse,
} from './smart-queue-models';
import {
    SMART_QUEUE_POSSIBLE_REASONS_TICKETS,
    SMART_QUEUE_TICKET,
    SMART_QUEUE_TICKET_CHILD,
    SMART_QUEUE_TICKET_COMMENTS,
    SMART_QUEUE_TICKET_DESCRIPTION,
    SMART_QUEUE_TICKET_HISTORY,
    SMART_QUEUE_TICKET_HISTORY_ROLES,
    SMART_QUEUE_TICKET_INFO,
    SMART_QUEUE_TICKET_POSITIONS,
    SMART_QUEUE_TICKET_RESPONSIBLE,
    SMART_QUEUE_TICKET_TIME,
} from './tags';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const smartQueueServices = createApi({
    reducerPath: 'smartQueueServices',
    baseQuery: fetchBaseQueryProtected({
        baseUrl: SMART_QUEUE_SERVICE_URL,
    }),
    tagTypes: [
        SMART_QUEUE_TICKET,
        SMART_QUEUE_TICKET_CHILD,
        SMART_QUEUE_TICKET_DESCRIPTION,
        SMART_QUEUE_TICKET_RESPONSIBLE,
        SMART_QUEUE_TICKET_TIME,
        SMART_QUEUE_TICKET_POSITIONS,
        SMART_QUEUE_TICKET_COMMENTS,
        SMART_QUEUE_TICKET_HISTORY,
        SMART_QUEUE_TICKET_HISTORY_ROLES,
        SMART_QUEUE_POSSIBLE_REASONS_TICKETS,
        SMART_QUEUE_TICKET_INFO,
    ],
    endpoints: (build) => ({
        // Dictionary
        smartQueueDictionaryActivityStatusGetList: build.query<
            SmartQueueActivityStatusResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/ActivityStatus/GetList` }),
        }),
        smartQueueDictionaryImportanceStatusGetList: build.query<
            SmartQueueImportanceStatusResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/ImportanceStatus/GetList` }),
        }),
        smartQueueDictionaryPriorityGetList: build.query<SmartQueuePriorityResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Priority/GetList` }),
        }),
        smartQueueDictionaryRoleAdditionalGroupGetList: build.query<
            SmartQueueRoleAdditionalGroupResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/RoleAdditionalGroup/GetList` }),
        }),
        smartQueueDictionaryRoleClassificationGetList: build.query<
            SmartQueueRoleClassificationResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/RoleClassification/GetList` }),
        }),
        smartQueueDictionaryRoleGroupGetList: build.query<SmartQueueRoleGroupResponseModelIEnumerableApiResponse, void>(
            {
                query: () => ({ url: `/api/Dictionary/RoleGroup/GetList` }),
            },
        ),
        smartQueueDictionaryRoleTypeGetList: build.query<SmartQueueRoleTypeResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/RoleType/GetList` }),
        }),
        smartQueueDictionaryServiceStatusGetList: build.query<
            SmartQueueServiceStatusResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/ServiceStatus/GetList` }),
        }),
        smartQueueDictionarySolutionStatusGetList: build.query<
            SmartQueueSolutionStatusResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/SolutionStatus/GetList` }),
        }),
        smartQueueDictionaryTicketTypeGetList: build.query<
            SmartQueueTicketTypeResponseModelIEnumerableApiResponse,
            void
        >({
            query: () => ({ url: `/api/Dictionary/TicketType/GetList` }),
        }),
        smartQueueDictionaryRoleGetList: build.query<
            SmartQueueRoleResponseModelIEnumerableApiResponse,
            SmartQueueRoleFilterValueRequestModelFilterRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Dictionary/Role/GetList`, method: 'POST', body: queryArg }),
            keepUnusedDataFor: 0,
        }),
        smartQueueDictionaryOrderGetList: build.query<SmartQueueOrderResponseModelIEnumerableApiResponse, void>({
            query: () => ({ url: `/api/Dictionary/Order/GetList` }),
        }),
        // Ticket
        smartQueueTicketGetList: build.query<
            SmartQueueTicketResponseModelIEnumerableApiResponse,
            SmartQueueTicketFilterValueRequestModelFilterRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/GetList`, method: 'POST', body: queryArg }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: SMART_QUEUE_TICKET, id } as const)),
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                      ]
                    : [{ type: SMART_QUEUE_TICKET, id: 'LIST' }],
        }),
        smartQueueTicketInfoGet: build.query<SmartQueueTicketInfoResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Info/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_INFO, id: ttNumber }],
        }),
        smartQueueTicketChildGet: build.query<SmartQueueTicketChildResponseModelIEnumerableApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Child/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_CHILD, id: ttNumber }],
        }),
        smartQueueTicketDescriptionGet: build.query<SmartQueueTicketDescriptionResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Description/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_DESCRIPTION, id: ttNumber }],
        }),
        smartQueueTicketResponsibleGet: build.query<SmartQueueTicketResponsibleResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Responsible/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_RESPONSIBLE, id: ttNumber }],
        }),
        smartQueueTicketTimeGet: build.query<SmartQueueTicketTimeResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Time/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_TIME, id: ttNumber }],
        }),
        smartQueueTicketPositionsGet: build.query<SmartQueuePositionsResponseModelIEnumerableApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Positions/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_POSITIONS, id: ttNumber }],
        }),
        smartQueueTicketCommentsGet: build.query<SmartQueueTicketCommentResponseModelIEnumerableApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/Comments/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_COMMENTS, id: ttNumber }],
        }),
        smartQueueAutoStopToggle: build.mutation<SmartQueueTicketAutoStopResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/ToggleAutoStop/${ttNumber}`, method: 'POST' }),
            invalidatesTags: (result, error, ttNumber) => [
                { type: SMART_QUEUE_TICKET, id: ttNumber },
                { type: SMART_QUEUE_TICKET_INFO, id: ttNumber },
            ],
        }),
        smartQueueHistoryGet: build.query<SmartQueueTicketHistoryResponseModelIEnumerableApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/History/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_HISTORY, id: ttNumber }],
        }),
        smartQueuePriorityChange: build.mutation<
            SmartQueueTicketInfoResponseModelApiResponse,
            SmartQueueTicketPriorityChangeRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/Priority`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) =>
                result
                    ? [
                          { type: SMART_QUEUE_TICKET, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET_HISTORY, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                          { type: SMART_QUEUE_TICKET_INFO, id: queryArg.number },
                      ]
                    : [],
        }),
        smartQueueResponsibleChange: build.mutation<
            SmartQueueTicketResponsibleResponseModelApiResponse,
            SmartQueueTicketResponsibleChangeRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/Responsible`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) =>
                result
                    ? [
                          { type: SMART_QUEUE_TICKET_RESPONSIBLE, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET_HISTORY, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                      ]
                    : [],
        }),
        smartQueueDescriptionChange: build.mutation<
            SmartQueueTicketDescriptionResponseModelApiResponse,
            SmartQueueTicketDescriptionChangeRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/Description`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) =>
                result
                    ? [
                          { type: SMART_QUEUE_TICKET_DESCRIPTION, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET_HISTORY, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                      ]
                    : [],
        }),
        smartQueueStatusChange: build.mutation<
            SmartQueueTicketInfoResponseModelApiResponse,
            SmartQueueTicketStatusChangeRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/Status`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) =>
                result
                    ? [
                          { type: SMART_QUEUE_TICKET, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET_HISTORY, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                          { type: SMART_QUEUE_TICKET_INFO, id: queryArg.number },
                      ]
                    : [],
        }),
        smartQueueBindTt: build.mutation<
            SmartQueueTicketInfoResponseModelApiResponse,
            SmartQueueTicketBindChangeRequestModel
        >({
            query: (queryArg) => ({ url: `/api/Ticket/Bind`, method: 'PUT', body: queryArg }),
            invalidatesTags: (result, error, queryArg) =>
                result
                    ? [
                          { type: SMART_QUEUE_TICKET, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET_HISTORY, id: queryArg.number },
                          { type: SMART_QUEUE_TICKET, id: 'LIST' },
                          { type: SMART_QUEUE_TICKET_INFO, id: queryArg.number },
                      ]
                    : [],
        }),
        smartQueueCommentAdd: build.mutation<
            SmartQueueTicketCommentResponseModelApiResponse,
            SmartQueueTicketCommentAddRequestModel
        >({
            query: (queryArg) => ({ url: '/api/Ticket/Comment', method: 'POST', body: queryArg }),
            invalidatesTags: (result, error, queryArg) => [{ type: SMART_QUEUE_TICKET_COMMENTS, id: queryArg.number }],
        }),
        smartQueueHistoryRolesGet: build.query<SmartQueueTicketHistoryRoleResponseModelIEnumerableApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/HistoryRoles/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET_HISTORY_ROLES, id: ttNumber }],
        }),
        smartQueuePossibleReasonsTicketsGet: build.query<
            SmartQueueTicketPossibleReasonTicketResponseModelIEnumerableApiResponse,
            number
        >({
            query: (ttNumber) => ({ url: `/api/Ticket/PossibleReasonTickets/${ttNumber}` }),
            providesTags: (result, error, ttNumber) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: SMART_QUEUE_POSSIBLE_REASONS_TICKETS, id } as const)),
                          { type: SMART_QUEUE_POSSIBLE_REASONS_TICKETS, id: `LIST-${ttNumber}` },
                      ]
                    : [{ type: SMART_QUEUE_POSSIBLE_REASONS_TICKETS, id: `LIST-${ttNumber}` }],
        }),
        smartQueueTicketGet: build.query<SmartQueueTicketResponseModelApiResponse, number>({
            query: (ttNumber) => ({ url: `/api/Ticket/${ttNumber}` }),
            providesTags: (result, error, ttNumber) => [{ type: SMART_QUEUE_TICKET, id: ttNumber }],
        }),
    }),
});

export const {
    useSmartQueueDictionaryActivityStatusGetListQuery,
    useSmartQueueDictionaryImportanceStatusGetListQuery,
    useSmartQueueDictionaryPriorityGetListQuery,
    useSmartQueueDictionaryRoleAdditionalGroupGetListQuery,
    useSmartQueueDictionaryRoleClassificationGetListQuery,
    useSmartQueueDictionaryRoleGroupGetListQuery,
    useSmartQueueDictionaryRoleTypeGetListQuery,
    useSmartQueueDictionaryServiceStatusGetListQuery,
    useSmartQueueDictionarySolutionStatusGetListQuery,
    useSmartQueueDictionaryTicketTypeGetListQuery,
    useSmartQueueDictionaryRoleGetListQuery,
    useSmartQueueDictionaryOrderGetListQuery,
    useSmartQueueTicketGetListQuery,
    useSmartQueueTicketInfoGetQuery,
    useSmartQueueTicketChildGetQuery,
    useSmartQueueTicketDescriptionGetQuery,
    useSmartQueueTicketResponsibleGetQuery,
    useSmartQueueTicketTimeGetQuery,
    useSmartQueueTicketPositionsGetQuery,
    useSmartQueueTicketCommentsGetQuery,
    useSmartQueueAutoStopToggleMutation,
    useSmartQueueHistoryGetQuery,
    useSmartQueuePriorityChangeMutation,
    useSmartQueueResponsibleChangeMutation,
    useSmartQueueDescriptionChangeMutation,
    useSmartQueueStatusChangeMutation,
    useSmartQueueBindTtMutation,
    useSmartQueueCommentAddMutation,
    useSmartQueueHistoryRolesGetQuery,
    useSmartQueuePossibleReasonsTicketsGetQuery,
    useSmartQueueTicketGetQuery,
} = smartQueueServices;

import * as React from 'react';
import { useIntl } from 'react-intl';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader, { cardHeaderClasses } from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';

import ThemeCard from './theme-card';

import Translate from 'components/extended/translate';
import useAppDispatch from 'hooks/use-app-dispatch';
import useAppSelector from 'hooks/use-app-selector';
import useGlobalTabs from 'hooks/use-global-tabs';
import appSlice from 'store/app-slice';
import customizationSlice from 'store/customization';
import { APP } from 'sub-apps/profile/constants';

const UserCard: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const user = useAppSelector(appSlice.selectors.userSelector);
    const customization = useAppSelector(customizationSlice.selectors.customizationSelector);
    const intl = useIntl();

    const { createTab } = useGlobalTabs();

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = React.useCallback(() => {
        setExpanded((_prev) => !_prev);
    }, []);

    const handleToggleLang = React.useCallback(() => {
        dispatch(customizationSlice.actions.themeLocale(customization.locale === 'ru' ? 'en' : 'ru'));
    }, [customization, dispatch]);

    const handleLogout = React.useCallback(() => {
        dispatch(customizationSlice.actions.setMenuOpen(false));
        dispatch(appSlice.actions.logout());
    }, [dispatch]);

    const handleProfileClick = React.useCallback(
        () =>
            createTab({
                app: APP,
                name: intl.formatMessage({ id: `${APP}_profile` }),
                isUnique: true,
            }),
        [createTab, intl],
    );

    return (
        <Card
            sx={{
                p: 0,
                background: 'transparent',
            }}
        >
            <CardHeader
                sx={{
                    [`& .${cardHeaderClasses.action}`]: {
                        alignSelf: 'center',
                    },
                }}
                avatar={<Avatar sx={{ width: 36, height: 36 }} />}
                title={user?.firstName}
                subheader={user?.roles.map((role) => role.name).join(', ')}
                action={
                    <IconButton
                        size="small"
                        onClick={handleExpandClick}
                        sx={{
                            transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                            marginLeft: 'auto',
                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                        }}
                    >
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ p: 2, pt: 0, '&:last-child': { pb: 2 } }}>
                    <ThemeCard />
                    <Stack spacing={1.5} direction="row">
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ textTransform: 'uppercase', minWidth: 10 }}
                            onClick={handleToggleLang}
                        >
                            {customization.locale}
                        </Button>
                        <Button variant="outlined" size="small" onClick={handleProfileClick}>
                            <Translate id="profile" />
                        </Button>
                        <Button variant="outlined" size="small" onClick={handleLogout}>
                            <Translate id="logout" />
                        </Button>
                    </Stack>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default UserCard;

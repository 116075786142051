import * as React from 'react';

import { APP } from '../../constants';
import { EventsFilterState } from '../../hooks/use-events-filter';

import FilterCheckBox from 'components/extended/data-grid/filter/filter-checkbox';
import FilterCheckBoxTree from 'components/extended/data-grid/filter/filter-checkbox-tree';
import FilterDialog from 'components/extended/data-grid/filter/filter-dialog';
import { FilterDialogProps } from 'components/extended/data-grid/filter/types';
import Translate from 'components/extended/translate';
import useManagementFilterDialog from 'hooks/use-management-filter-dialog';
import {
    useMonitoringDictionaryEventTypeGetListQuery,
    useMonitoringDictionaryPositionTypeGetListQuery,
} from 'services/monitoring/monitoring-services';
import { useGetRegionListQuery } from 'services/webapi/generated/webapi-services';
import RegionBranchResponseOverrides from 'utils/region-branch-response-overrides';

const EventsFilterDialog: React.FC<FilterDialogProps<EventsFilterState>> = (props) => {
    const {
        open,
        setOpen,
        expandedField,
        resetFilter,
        setLocalFilterValues,
        localFilterValues,
        closeAndSaveFilter,
        closeModalWindow,
    } = useManagementFilterDialog<EventsFilterState>(props);

    const { data: regionAndBranchAvailableValues } = useGetRegionListQuery();
    const { data: positionTypeAvailableValues } = useMonitoringDictionaryPositionTypeGetListQuery();
    const { data: eventTypeAvailableValues } = useMonitoringDictionaryEventTypeGetListQuery();

    const regionAndBranchAvailableValuesOverrides = RegionBranchResponseOverrides(regionAndBranchAvailableValues);

    return (
        <FilterDialog
            open={open}
            setOpen={setOpen}
            handleReset={resetFilter}
            handleCloseAndSave={closeAndSaveFilter}
            handleClose={closeModalWindow}
        >
            <FilterCheckBoxTree
                field="branches"
                value={localFilterValues.branches}
                label={<Translate app={APP} id="regions_branches" />}
                expanded={expandedField === 'branches'}
                availableValues={regionAndBranchAvailableValuesOverrides}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="positionType"
                value={localFilterValues.positionType}
                label={<Translate app={APP} id="position_type" />}
                expanded={expandedField === 'positionType'}
                availableValues={positionTypeAvailableValues !== undefined ? positionTypeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
            <FilterCheckBox
                field="eventType"
                value={localFilterValues.eventType}
                label={<Translate app={APP} id="event_type" />}
                expanded={expandedField === 'eventType'}
                availableValues={eventTypeAvailableValues !== undefined ? eventTypeAvailableValues.data : []}
                setLocalFilterValues={setLocalFilterValues}
            />
        </FilterDialog>
    );
};

export default EventsFilterDialog;

import * as React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';

type CreatePlannedWorkAutoHallFormControllerConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    watch: UseFormWatch<CreatePlannedWorkFormInputs>;
};

const options = [
    {
        id: 1,
        name: 'Орловская обл., Орёл г., Васильевская ул., д. 138 1 эт, тех п 1 Орел Коммутатор',
    },
    {
        id: 2,
        name: 'Россия, Алтайский край., Барнаул г., Мало-Тобольская ул., дом 18,   апп 101-1 VIMPELCOM-REGION BARNAUL',
    },
];

const CreatePlannedWorkAutoHallFormControllerConnector: React.FC<CreatePlannedWorkAutoHallFormControllerConnectorProps> = (
    props,
) => {
    const { control, watch } = props;

    const autoHallValue = watch('autoHall');
    const branchesValue = watch('branches');

    const [autoHall, setAutoHall] = React.useState(
        autoHallValue.length > 0 ? autoHallValue.map((elem) => elem.name) : '',
    );
    // TODO тут нужно отправлять запрос за options с учетем филиалов
    // TODO при выборе изменять поле workPlace
    return (
        <Controller
            name="autoHall"
            control={control}
            rules={{
                required: `${APP}_field_required`,
            }}
            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                <FormControl sx={{ marginTop: 1 }} size="small" fullWidth error={invalid && isTouched}>
                    <Autocomplete
                        {...field}
                        disabled={!branchesValue.length}
                        multiple
                        size="small"
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        filterOptions={(x) => x}
                        options={options}
                        loading={false}
                        value={field.value}
                        disableCloseOnSelect
                        onChange={(event, data) => field.onChange(data)}
                        onInputChange={(event, newInputValue) => {
                            setAutoHall(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<Translate app={APP} id="auto_hall" />}
                                value={autoHall}
                                error={invalid && isTouched}
                            />
                        )}
                    />
                    {invalid && isTouched && error?.message && (
                        <FormHelperText error>
                            <Translate app={APP} id={error.message} />
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default CreatePlannedWorkAutoHallFormControllerConnector;

import { MenuItemResponseModel } from 'services/webapi/generated/webapi-services';

export const menuFlat = (menuItem: MenuItemResponseModel): MenuItemResponseModel[] => {
    const { childs } = menuItem;

    if (childs.length > 0) {
        const _childs: MenuItemResponseModel[] = [];
        childs.forEach((child) => {
            menuFlat(child).forEach((_child) => _childs.push(_child));
        });

        return _childs;
    }

    return [menuItem];
};

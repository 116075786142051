import * as React from 'react';

import Translate from 'components/extended/translate';

type DisplayItemContentProps = {
    valueLabel: null | undefined | string[] | string;
};

const DisplayItemContent: React.FC<DisplayItemContentProps> = (props) => {
    const { valueLabel } = props;

    if (valueLabel !== null && valueLabel !== undefined && valueLabel.length > 0) {
        return <>{Array.isArray(valueLabel) ? valueLabel.join(', ') : valueLabel}</>;
    }

    return <Translate id="all" />;
};

export default DisplayItemContent;

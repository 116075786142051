import * as React from 'react';

import Chip from '@mui/material/Chip';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortDirection,
    GridSortModel,
    gridClasses,
} from '@mui/x-data-grid';

import { APP } from '../constants';
import usePositionsFilter from '../hooks/use-positions-filter';

import DataGridLoader from 'components/extended/data-grid/data-grid-loader';
import NoRowsOverlay from 'components/extended/data-grid/no-rows-overlay';
import useGlobalTabs from 'hooks/use-global-tabs';
import usePagination from 'hooks/use-pagination';
import { PositionsPositionColumnsOrderRequestModel } from 'services/positions/positions-models';
import {
    usePositionsAccountGetQuery,
    usePositionsAccountSetDefaultFilterMutation,
    usePositionsPositionGetListQuery,
} from 'services/positions/positions-services';
import { APP as POSITION_VIEW_APP } from 'sub-apps/position-view/constants';
import columnLocalization from 'utils/column-localization';

const PositionsTable: React.FC = () => {
    const { data: userData, isLoading: userLoading } = usePositionsAccountGetQuery(false);

    const { Pagination, rowsState } = usePagination(`${APP}_positions_grid`, '5');

    const { PositionsFilterDialog, PositionsFilterToolbar, filterValues } = usePositionsFilter(
        userData?.data.defaultFilter?.value,
    );

    const [mutate] = usePositionsAccountSetDefaultFilterMutation();

    const { createTab } = useGlobalTabs();

    const chipOnClick = React.useCallback(
        (params: GridRenderCellParams<string>) => () =>
            createTab({
                id: undefined,
                app: POSITION_VIEW_APP,
                name: params.value,
                path: params.value,
                isUnique: true,
            }),
        [createTab],
    );

    const renderCellChip = React.useCallback(
        (params: GridRenderCellParams<string>) => (
            <Chip sx={{ userSelect: 'text' }} onClick={chipOnClick(params)} size="small" label={params.value} />
        ),
        [chipOnClick],
    );

    const columns = React.useMemo(
        (): GridColDef[] => [
            {
                renderHeader: columnLocalization('number', APP),
                renderCell: renderCellChip,
                field: 'number',
                flex: 0.3,
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
            },
            {
                renderHeader: columnLocalization('name', APP),
                field: 'name',
                flex: 0.8,
                minWidth: 150,
            },
            {
                renderHeader: columnLocalization('address', APP),
                field: 'address',
                flex: 1.9,
                minWidth: 150,
                sortable: false,
            },
            {
                renderHeader: columnLocalization('priority', APP),
                field: 'priority',
                flex: 0.4,
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
            },
        ],
        [renderCellChip],
    );

    const saveFilter = React.useCallback(() => {
        mutate({
            value: {
                search: filterValues.search,
                extended: filterValues.extended,
                systemIds: filterValues.systems ? filterValues.systems.map((elem) => Number(elem.id)) : [],
                priorityIds: filterValues.priorities ? filterValues.priorities.map((elem) => Number(elem.id)) : [],
                branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : [],
            },
        });
    }, [
        filterValues.branches,
        filterValues.extended,
        filterValues.priorities,
        filterValues.search,
        filterValues.systems,
        mutate,
    ]);

    const savedSort = userData?.data.defaultFilter?.order
        ? {
              field: userData.data.defaultFilter.order.field.toLowerCase(),
              sort: userData.data.defaultFilter.order.sort.toLowerCase() as GridSortDirection,
          }
        : undefined;

    const [sortModel, setSortModel] = React.useState<GridSortModel>(savedSort ? [savedSort] : []);

    const { data, refetch, isFetching } = usePositionsPositionGetListQuery({
        value: {
            search: filterValues.search,
            extended: filterValues.extended,
            systemIds: filterValues.systems ? filterValues.systems.map((elem) => Number(elem.id)) : [],
            priorityIds: filterValues.priorities ? filterValues.priorities.map((elem) => Number(elem.id)) : [],
            branches: filterValues.branches ? filterValues.branches.map((elem) => String(elem.id)) : [],
        },
        order: sortModel[0] as PositionsPositionColumnsOrderRequestModel,
        pagination: { rowPerPage: rowsState.rowPerPage, pageNumber: rowsState.pageNumber + 1 },
    });

    return (
        <>
            {data !== undefined && (
                <>
                    <PositionsFilterToolbar saveFilter={saveFilter} />
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={data.data}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 0.5,
                            },
                            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                                whiteSpace: 'break-spaces',
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                                lineHeight: 'unset !important',
                                maxHeight: 'fit-content !important',
                                position: 'inherit',
                            },
                            [`& .${gridClasses.virtualScroller}`]: {
                                marginTop: '0px !important',
                            },
                            [`& .${gridClasses.overlay}`]: {
                                backgroundColor: 'transparent',
                            },
                        }}
                        components={{
                            Pagination,
                            NoRowsOverlay,
                        }}
                        componentsProps={{
                            pagination: {
                                total: data.total,
                                refetchCallback: refetch,
                                forcedRefetch: true,
                                isFetching,
                                rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
                            },
                        }}
                    />
                    <PositionsFilterDialog />
                </>
            )}
            <DataGridLoader visible={isFetching || userLoading} text="loading" />
        </>
    );
};

export default PositionsTable;

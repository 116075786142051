import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_feedback`]: {
        en: 'Feedback',
        ru: 'Обратная связь',
    },
    [`${APP}_error_id`]: {
        en: 'Request ID not valid',
        ru: 'Неверный ID обращения',
    },
    [`${APP}_add`]: {
        en: 'Add',
        ru: 'Добавить',
    },
    [`${APP}_comment`]: {
        en: 'Comment',
        ru: 'Комментарий',
    },
    [`${APP}_comment_is_required`]: {
        en: 'Comment is required',
        ru: 'Комментарий обязателен',
    },
    [`${APP}_no_comments`]: {
        en: 'There is not a single comment yet',
        ru: 'Пока еще нет ни одного комментария',
    },
    [`${APP}_developer_answer`]: {
        en: 'Developer answer',
        ru: 'Ответ разработчика',
    },
};

export default locale;

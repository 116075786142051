import * as React from 'react';

import KeyCoordinatorsDialog from '../views/dialogs/key-coordinators-dialog';
import KeyCoordinatorsFilterToolbar from '../views/filter/key-coordinators-filter-toolbar';

import { useModal } from 'mui-modal-provider';
import { deepCopy } from 'utils/deep-copy';

export type KeyCoordinatorsFilterState = {
    selectedValue?: number | string;
    branches?: string[];
};

const initialState: KeyCoordinatorsFilterState = {
    selectedValue: undefined,
    branches: undefined,
};

const useKeyCoordinatorsFilter = () => {
    const [filterValues, setFilterValues] = React.useState(deepCopy(initialState));

    const { showModal } = useModal();

    const handleAddClick = React.useCallback(() => {
        const modal = showModal(KeyCoordinatorsDialog, {
            onClose: () => {
                modal.hide();
            },
        });
    }, [showModal]);

    return {
        KeyCoordinatorsFilterToolbar: () => (
            <KeyCoordinatorsFilterToolbar
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                handleAddClick={handleAddClick}
            />
        ),
        filterValues,
    };
};

export default useKeyCoordinatorsFilter;

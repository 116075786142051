import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import TroubleTicketViewCommentCard from './cards/trouble-ticket-view-comment-card';
import { TroubleTicketViewGroupedComment } from './trouble-ticket-view-comments-main';

type TroubleTicketViewCommentAccordionCardsProps = TroubleTicketViewGroupedComment;

const TroubleTicketViewCommentAccordionCards: React.FC<TroubleTicketViewCommentAccordionCardsProps> = (props) => {
    const { user, role, comments } = props;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 60 }}>
                <Typography>{`${user} (${role})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {comments.map((elem) => (
                    <TroubleTicketViewCommentCard
                        key={elem.id}
                        comment={elem.comment}
                        action={elem.action}
                        createdDate={elem.createdDate}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default TroubleTicketViewCommentAccordionCards;

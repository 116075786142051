import * as React from 'react';

import { APP } from '../../constants';
import CreatePlannedWorkSaveTemplateFormConnector from '../connectors/create-planned-work-save-template-form-connector';

import Translate from 'components/extended/translate';
import TroubleTicketViewDialog from 'sub-apps/trouble-ticket-view/views/trouble-ticket-view-dialog';

type CreatePlannedWorkSaveTemplateFormDialogProps = {
    open: boolean;
    onClose: () => void;
};

const CreatePlannedWorkSaveTemplateFormDialog: React.FC<CreatePlannedWorkSaveTemplateFormDialogProps> = (props) => {
    const { open, onClose } = props;

    return (
        <TroubleTicketViewDialog
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            open={open}
            title={<Translate app={APP} id="save_template" />}
        >
            <CreatePlannedWorkSaveTemplateFormConnector onClose={onClose} />
        </TroubleTicketViewDialog>
    );
};

export default CreatePlannedWorkSaveTemplateFormDialog;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useTheme from '@mui/material/styles/useTheme';

import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'components/extended/translate';
import { AuthAuthTokenResponseModelApiResponse } from 'services/auth/auth-models';
import { usePostAuthTokenMutation } from 'services/auth/auth-services';
import * as Yup from 'yup';

export type LoginFormInputs = {
    username: string;
    password: string;
};

type LoginFormProps = {
    defaultValues: LoginFormInputs;
    onSuccessSubmit: (data: LoginFormInputs) => void;
};

const LoginForm: React.FC<LoginFormProps> = (props) => {
    const { defaultValues, onSuccessSubmit } = props;
    const theme = useTheme();
    const [showPassword, setShowPassword] = React.useState(false);
    const [submitErrors, setSubmitErrors] = React.useState<string[]>([]);
    const [postAuthToken] = usePostAuthTokenMutation();

    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
    } = useForm<LoginFormInputs>({
        defaultValues,
        resolver: yupResolver(
            Yup.object().shape({
                username: Yup.string().email('invalid_email').required('email_is_required'),
                password: Yup.string().required('password_is_required'),
            }),
        ),
        mode: 'all',
    });

    const onSubmit = React.useCallback(
        async (valuesForm: LoginFormInputs) => {
            setSubmitErrors([]);

            const res = await postAuthToken({
                ...valuesForm,
                utcTime: Math.round(Date.now() / 1000),
            });

            if ('error' in res && 'data' in res.error) {
                const data = res.error.data as AuthAuthTokenResponseModelApiResponse;
                setSubmitErrors(data.errors);
            }

            if ('data' in res) {
                const { status, errors, data } = res.data;

                if (status === 'Success') {
                    if (data.isSecondFactor) {
                        onSuccessSubmit(valuesForm);
                    }
                } else {
                    setSubmitErrors(errors);
                }
            }

            return res;
        },
        [onSuccessSubmit, postAuthToken],
    );

    const handleClickShowPassword = React.useCallback(() => {
        setShowPassword((_prevState) => !_prevState);
    }, []);

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="username"
                control={control}
                render={({ field, fieldState: { isTouched, invalid, error: _error } }) => (
                    <FormControl fullWidth error={invalid && isTouched} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">
                            <Translate id="email" />
                        </InputLabel>
                        <OutlinedInput id="outlined-adornment-email-login" autoFocus {...field} type="email" />
                        {invalid && isTouched && _error?.message && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                <Translate id={_error.message} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { isTouched, invalid, error: _error } }) => (
                    <FormControl fullWidth error={invalid && isTouched} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-password-login">
                            <Translate id="password" />
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {invalid && isTouched && _error?.message && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                <Translate id={_error.message} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />

            {submitErrors.length > 0 && (
                <Box sx={{ mt: 2, mb: 2.5 }}>
                    {submitErrors.map((_error) => (
                        <FormHelperText key={_error} error>
                            {_error}
                        </FormHelperText>
                    ))}
                </Box>
            )}

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disabled={!isValid}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                >
                    <Translate id="login" />
                </LoadingButton>
            </Box>
        </form>
    );
};

export default LoginForm;

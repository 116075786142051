import { APP } from '../constants';

import { Locales } from 'types';

const locale: Locales = {
    [`${APP}_root_page`]: {
        en: 'Planned Work',
        ru: 'Плановые работы',
    },
    [`${APP}_number`]: {
        en: 'Number',
        ru: 'Номер',
    },
    [`${APP}_subject_work`]: {
        en: 'Subject to work',
        ru: 'Предмет работы',
    },
    [`${APP}_start_work`]: {
        en: 'Start of work',
        ru: 'Начало работы',
    },
    [`${APP}_end_work`]: {
        en: 'End of work',
        ru: 'Конец работы',
    },
    [`${APP}_initiator`]: {
        en: 'Initiator',
        ru: 'Инициатор',
    },
    [`${APP}_object`]: {
        en: 'Object',
        ru: 'Обьект',
    },
    [`${APP}_type`]: {
        en: 'Type',
        ru: 'Тип',
    },
    [`${APP}_status`]: {
        en: 'Status',
        ru: 'Статус',
    },
    [`${APP}_branch`]: {
        en: 'Branch',
        ru: 'Филиал',
    },
    [`${APP}_viewed_endorser`]: {
        en: 'Viewed by the endorser',
        ru: 'Просмотрено визирующим',
    },
    [`${APP}_not_viewed_endorser`]: {
        en: 'Not viewed by the endorser',
        ru: 'Не просмотрено визирующим',
    },
    [`${APP}_approval`]: {
        en: 'Approval',
        ru: 'Согласование',
    },
    [`${APP}_approved`]: {
        en: 'Approved',
        ru: 'Согласовано',
    },
    [`${APP}_pw`]: {
        en: 'PW',
        ru: 'ПР',
    },
    [`${APP}_order`]: {
        en: 'Order',
        ru: 'Сортировка',
    },
    [`${APP}_hide_is_apply`]: {
        en: 'Hide agreed ',
        ru: 'Скрыть согласованные',
    },
    [`${APP}_show_archived`]: {
        en: 'Show archived',
        ru: 'Показать архивные',
    },
    [`${APP}_date`]: {
        en: 'Date',
        ru: 'Дата',
    },
    [`${APP}_object_types`]: {
        en: 'Object, types',
        ru: 'Обьекты, типы',
    },
    [`${APP}_regions_branches`]: {
        en: 'Regions, branches',
        ru: 'Регионы, филиалы',
    },
    [`${APP}_showed`]: {
        en: 'Showed',
        ru: 'Отображаются',
    },
    [`${APP}_hidden`]: {
        en: 'Hidden',
        ru: 'Скрыты',
    },
};

export default locale;

import * as React from 'react';
import { Control, Controller, UseFormResetField } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { APP } from '../../constants';
import { CreatePlannedWorkFormInputs } from '../create-planned-work-main';

import Translate from 'components/extended/translate';

type CreatePlannedWorkObjectFormControllerConnectorProps = {
    control: Control<CreatePlannedWorkFormInputs>;
    resetField: UseFormResetField<CreatePlannedWorkFormInputs>;
};

const data = [
    {
        id: 1,
        name: 'IMS',
    },
    {
        id: 2,
        name: 'PS Core',
    },
];

const CreatePlannedWorkObjectFormControllerConnector: React.FC<CreatePlannedWorkObjectFormControllerConnectorProps> = (
    props,
) => {
    const { control, resetField } = props;

    // TODO тут должен быть запрос за данными

    return (
        <Controller
            name="object"
            control={control}
            rules={{
                required: `${APP}_field_required`,
            }}
            render={({ field, fieldState: { isTouched, invalid, error } }) => (
                <FormControl size="small" fullWidth error={invalid && isTouched} sx={{ marginTop: 2 }}>
                    <InputLabel margin="dense" htmlFor={field.name}>
                        <Translate app={APP} id="object" />
                    </InputLabel>
                    <Select
                        {...field}
                        onChange={(event) => {
                            resetField('timeSpending');
                            resetField('type');
                            field.onChange(event);
                        }}
                        label={<Translate app={APP} id="object" />}
                        id={field.name}
                    >
                        <MenuItem value="" disabled>
                            <Translate app={APP} id="not_selected" />
                        </MenuItem>
                        {data.map((elem) => (
                            <MenuItem key={elem.id} value={elem.id}>
                                {elem.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {invalid && isTouched && error?.message && (
                        <FormHelperText error>
                            <Translate app={APP} id={error.message} />
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default CreatePlannedWorkObjectFormControllerConnector;
